import {
  Badge,
  Box,
  Card,
  CardBody,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { Evidence, Project } from "../../types";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

export const EvidenceTab: React.FC<{
  project?: Project;
  evidences?: Evidence[];
}> = ({ project, evidences }) => {
  const [evidenceByControls, setEvidenceByControls] = useState<
    { id: string; name: string; count: number }[] | undefined
  >();

  const navigate = useNavigate();

  useEffect(() => {
    const evidenceByControls: any = {};

    for (const control of project?.controls ?? []) {
      const evidences = control.evidence;

      for (const evidence of evidences) {
        if (evidence.id in evidenceByControls) {
          evidenceByControls[evidence.id].count++;
        } else {
          evidenceByControls[evidence.id] = { name: evidence.name, count: 1 };
        }
      }
    }

    setEvidenceByControls(
      Object.entries(evidenceByControls).map(([key, value]: [any, any]) => {
        return { id: key, name: value.name, count: value.count };
      })
    );
  }, [project]);

  const columnHelper = createColumnHelper<{
    id: string;
    name: string;
    count: number;
  }>();

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "id",
      header: () => <span>ID</span>,
      cell: (info) => {
        return info.row.original.id;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => {
        const value = info.getValue();
        return value;
      },
      header: () => <span>Name</span>,
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "count",
      header: () => <span># Of Controls</span>,
      cell: (props) => {
        return <Badge variant="blue">{props.row.original.count}</Badge>;
      },
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <IconButton
              aria-label="View"
              icon={<BsEye />}
              style={{ background: "transparent" }}
              onClick={(e) =>
                navigate(`/doc-library/evidence/${props.row.original.id}`)
              }
            />
          </HStack>
        );
      },
    }),
  ];

  return (
    <Box py={5} px={10}>
      <Card variant={"outline"}>
        <CardBody>
          <DataTable columns={columns} data={evidenceByControls ?? []} />
        </CardBody>
      </Card>
    </Box>
  );
};
