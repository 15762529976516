import {
  Box,
  Stack,
  HStack,
  Button,
  Badge,
  Text,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  chakra,
  CircularProgress,
  SimpleGrid,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import {
  useAcknowledgeProtocolAction,
  useMyProtocols,
} from "../../api/endpoints/my";
import { PageHeader } from "../../components/page-header";
import { HiOutlineIdentification } from "react-icons/hi";

export const MyProtocolsPage: React.FC = () => {
  const [selectedPolicyIndex, setSelectedPolicyIndex] = useState<number>(0);

  const [searchParams] = useSearchParams();

  const { data: protocols } = useMyProtocols();
  const { mutate: acknowledgeProtocol } = useAcknowledgeProtocolAction();

  useEffect(() => {
    if (searchParams.get("id") && (protocols ?? []).length > 0) {
      setSelectedPolicyIndex(
        protocols?.findIndex((x) => x.protocolId == searchParams.get("id")) ?? 0
      );
    }
  }, [searchParams, protocols]);

  const acknowledgedProtocols = useMemo(() => {
    return protocols?.filter((x) => x.acknowledged);
  }, [protocols]);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Company Protocols"></PageHeader>
      <Card variant={"outline"} m={5}>
        <CardHeader pb={0}>
          <HStack>
            <Heading variant={"md"}>Protocols</Heading>
          </HStack>
          <Text fontWeight={"400"} variant={"sm"}>
            Below are a set of internal guidelines that establish rules and
            expectations for employees. Please read through and acknowledge each
            one.
          </Text>
        </CardHeader>
        <CardBody>
          <Card variant={"outline"}>
            <CardBody>
              <HStack
                justifyContent={"space-between"}
                flexDir={{ base: "column", md: "row" }}
                display={"flex"}
                width={"100%"}
              >
                <HStack width={{ base: "100%", md: "auto" }}>
                  {protocols && protocols.length > 0 && (
                    <CircularProgress
                      value={
                        ((acknowledgedProtocols?.length ?? 0) /
                          protocols?.length) *
                        100
                      }
                      color="brand.primary.500"
                      size={"1.5rem"}
                    />
                  )}
                  <Text variant={"sm"} color={"brand.text"} fontWeight={"400"}>
                    You have acknowledged{" "}
                    <chakra.span fontWeight={"600"}>
                      {protocols?.filter((x) => x.acknowledged).length}
                    </chakra.span>{" "}
                    out of{" "}
                    <chakra.span fontWeight={"600"}>
                      {protocols?.length}
                    </chakra.span>{" "}
                    protocols.
                  </Text>
                </HStack>
                <HStack width={{ base: "100%", md: "auto" }}>
                  <Button
                    width={{ base: "50%", md: "auto" }}
                    variant={"outline"}
                    colorScheme={"brand.primary"}
                    onClick={() =>
                      setSelectedPolicyIndex(selectedPolicyIndex - 1)
                    }
                    isDisabled={selectedPolicyIndex === 0}
                  >
                    Prev
                  </Button>
                  <Button
                    width={{ base: "50%", md: "auto" }}
                    variant={"outline"}
                    colorScheme={"brand.primary"}
                    onClick={() =>
                      setSelectedPolicyIndex(selectedPolicyIndex + 1)
                    }
                    isDisabled={
                      selectedPolicyIndex === (protocols?.length ?? 0) - 1
                    }
                  >
                    Next
                  </Button>
                </HStack>
              </HStack>
            </CardBody>
          </Card>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={5}
            mt={5}
            height={"100%"}
          >
            <Stack divider={<Divider />}>
              {protocols?.map((protocol, index) => (
                <HStack
                  key={index}
                  p={3}
                  alignItems={"start"}
                  onClick={() => setSelectedPolicyIndex(index)}
                  cursor={"pointer"}
                  background={
                    selectedPolicyIndex === index
                      ? "brand.gray.100"
                      : "transparent"
                  }
                  borderRadius={"lg"}
                >
                  <Stack
                    background={"brand.tintGray.100"}
                    p={2}
                    borderRadius={5}
                    height={"fit-content"}
                  >
                    <Icon as={HiOutlineIdentification} color={"brand.accent"} />
                  </Stack>
                  <VStack alignItems={"flex-start"} ml={2}>
                    <Text
                      variant={"sm"}
                      fontWeight={"700"}
                      color={"brand.text"}
                    >
                      {protocol.name}
                    </Text>
                    <Badge
                      variant={protocol.acknowledged ? "green" : "gray"}
                      fontWeight={"400"}
                    >
                      {protocol.acknowledged
                        ? "Acknowledged"
                        : "Unacknowledged"}
                    </Badge>
                  </VStack>
                </HStack>
              ))}
            </Stack>
            <Stack>
              <Card variant={"outline"} height={"100vh"}>
                {!protocols?.[selectedPolicyIndex]?.acknowledged && (
                  <CardHeader
                    width={"100%"}
                    borderBottom={"1px solid"}
                    borderColor={"brand.border"}
                  >
                    <Button
                      width={"100%"}
                      variant="solid"
                      colorScheme="brand.primary"
                      onClick={(e) => {
                        acknowledgeProtocol({
                          id:
                            protocols?.[selectedPolicyIndex]?.protocolId ?? "",
                          version:
                            protocols?.[selectedPolicyIndex]?.version ?? 0,
                        });
                      }}
                    >
                      Acknowledge
                    </Button>
                  </CardHeader>
                )}
                <CardBody fontSize={"sm"} overflowY={"auto"} height={"100%"}>
                  <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={protocols?.[selectedPolicyIndex]?.content ?? ""}
                    skipHtml
                    remarkPlugins={[remarkGfm]}
                  />
                </CardBody>
              </Card>
            </Stack>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Box>
  );
};
