import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Text,
  Skeleton,
  Progress,
  Icon,
  WrapItem,
  useToast,
} from "@chakra-ui/react";

import { DownloadIcon } from "lucide-react";
import { useVMRSDecodeOrder } from "../../api/endpoints/vmrs";
import "./index.css";
import { useContext, useEffect, useState } from "react";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import CsvDownloader from "react-csv-downloader";
import { IoCheckmarkDoneCircle, IoWarningOutline } from "react-icons/io5";
import { AppInsightsContext } from "../../providers/app-insights-provider";

type VMRSPartConversionResult = {
  partName: string;
  externalId: string;
  vmrsPartName: string;
  vmrsPartId: string;
  isFetching: boolean;
  errors: string[];
};

export const ConversionResultsTable: React.FC<{
  id: string;
  showTableOnly?: boolean;
}> = ({ id, showTableOnly = false }) => {
  const {
    data: vmrsDecodeOrder,
    isSuccess: isFetchVMRSDecodeOrderSuccess,
    refetch: refetchVMRSDecodeOrder,
  } = useVMRSDecodeOrder(id ?? "", id !== undefined);

  const appInsightsContext = useContext(AppInsightsContext);
  const toast = useToast();
  const [conversionResults, setConversionResults] = useState<
    VMRSPartConversionResult[]
  >([]);

  const handleInputChange = (index: number, field: string, value: string) => {
    appInsightsContext.trackEvent({
      name: "VMRS Conversion Result Input Change",
      properties: {
        field,
        oldValue:
          conversionResults[index]?.[field as keyof VMRSPartConversionResult],
        newValue: value,
      },
    });

    setConversionResults((prevResults) => {
      const newResults = [...prevResults];
      newResults[index] = {
        ...newResults[index],
        [field]: value,
      };
      return newResults;
    });
  };

  const columnHelper = createColumnHelper<VMRSPartConversionResult>();

  const columns = [
    columnHelper.accessor((row) => row.partName, {
      id: "partName",
      header: () => (
        <span>
          {vmrsDecodeOrder?.requestType === "Parts"
            ? "Part Name"
            : "Repair Notes"}
        </span>
      ),
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.externalId, {
      id: "externalId",
      header: () => (
        <span>
          {vmrsDecodeOrder?.requestType === "Parts" ? "Part ID" : "Task ID"}
        </span>
      ),
      cell: (info) => info.getValue() ?? "N/A",
    }),
    columnHelper.accessor((row) => row.vmrsPartName, {
      id: "vmrsPartName",
      header: () => <span>VMRS Part Name</span>,
      cell: (info) => (
        <>
          <Skeleton
            startColor="gray.100"
            endColor="gray.300"
            isLoaded={!info.row.original.isFetching}
            fadeDuration={4}
          >
            <div
              contentEditable
              suppressContentEditableWarning
              style={{ backgroundColor: "brand.accent" }}
              onBlur={(e) => {
                handleInputChange(
                  info.row.index,
                  "vmrsPartName",
                  e.currentTarget.textContent || ""
                );
                toast({
                  title: "Feedback Received!",
                  description:
                    "Your feedback will help improve accuracy of future results.",
                  status: "success",
                  duration: 2500,
                  isClosable: true,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
            >
              {info.getValue() ?? "N/A"}
            </div>
          </Skeleton>
          {info.row.original.isFetching && (
            <Progress size="xs" isIndeterminate />
          )}
        </>
      ),
    }),
    columnHelper.accessor((row) => row.vmrsPartId, {
      id: "vmrsPartId",
      header: () => <span>VMRS Code</span>,
      cell: (info) => (
        <>
          <Skeleton
            startColor="gray.100"
            endColor="gray.300"
            isLoaded={!info.row.original.isFetching}
            fadeDuration={4}
          >
            <div
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                handleInputChange(
                  info.row.index,
                  "vmrsPartId",
                  e.currentTarget.textContent || ""
                );
                toast({
                  title: "Feedback Received!",
                  description:
                    "Your feedback will help improve accuracy of future results.",
                  status: "success",
                  duration: 2500,
                  isClosable: true,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
            >
              {info.getValue() ?? "N/A"}
            </div>
          </Skeleton>
          {info.row.original.isFetching && (
            <Progress size="xs" isIndeterminate />
          )}
        </>
      ),
    }),
    columnHelper.accessor((row) => row.errors.join(", "), {
      id: "errors",
      header: () => <span>Status</span>,
      cell: (info) => (
        <>
          <Skeleton
            startColor="gray.100"
            endColor="gray.300"
            isLoaded={!info.row.original.isFetching}
            fadeDuration={4}
          >
            <WrapItem>
              {info.getValue()?.length > 0 ? (
                <Icon
                  as={IoWarningOutline}
                  color="red.500"
                  width={8}
                  height={8}
                />
              ) : (
                <Icon
                  as={IoCheckmarkDoneCircle}
                  color="green.500"
                  width={8}
                  height={8}
                />
              )}
            </WrapItem>
          </Skeleton>
          {info.row.original.isFetching && (
            <Progress size="xs" isIndeterminate />
          )}
        </>
      ),
    }),
  ];

  useEffect(() => {
    if (vmrsDecodeOrder) {
      const results = vmrsDecodeOrder.orderLines.flatMap((orderLine) => {
        if (orderLine.status.toLowerCase() === "waiting") {
          return [
            {
              partName: orderLine.description,
              externalId: orderLine.externalId,
              vmrsPartName: "N/A",
              vmrsPartId: "N/A",
              isFetching: true,
              errors: orderLine.errors,
            },
          ];
        } else {
          return orderLine.parts.map((part) => ({
            partName: orderLine.description,
            externalId: orderLine.externalId,
            vmrsPartName: part.component ?? "N/A",
            vmrsPartId: part.code ?? "N/A",
            isFetching: false,
            errors: orderLine.errors,
          }));
        }
      });

      setConversionResults(results);
    }
  }, [vmrsDecodeOrder]);

  useEffect(() => {
    if (isFetchVMRSDecodeOrderSuccess) {
      let pollInterval: NodeJS.Timeout | null = null;

      if (
        vmrsDecodeOrder?.orderLines?.some(
          (line) => line.status.toLowerCase() === "waiting"
        )
      ) {
        pollInterval = setInterval(() => {
          refetchVMRSDecodeOrder();
        }, 5000);
      }

      if (
        vmrsDecodeOrder?.orderLines?.every(
          (line) => line.status === "completed"
        ) &&
        pollInterval
      ) {
        clearInterval(pollInterval);
      }

      return () => {
        if (pollInterval) {
          clearInterval(pollInterval);
        }
      };
    }
  }, [vmrsDecodeOrder, isFetchVMRSDecodeOrderSuccess]);

  return (
    <Box>
      <Box mb={2}>
        <Text
          color={"fg.muted"}
          fontSize={12}
          fontWeight={"normal"}
          align={"end"}
        >
          * This system is still learning and data may not always be accurate.
          Please double check the data before downloading.
        </Text>
      </Box>
      {showTableOnly && (
        <DataTable
          columns={columns}
          data={conversionResults}
          isSearchEnabled={false}
          suppressPaginationFooter
        ></DataTable>
      )}
      {!showTableOnly && (
        <DataTable
          columns={columns}
          data={conversionResults}
          isSearchEnabled={true}
          actionChildren={
            <CsvDownloader
              //@ts-ignore
              datas={conversionResults}
              filename={"conversion_results.csv"}
              columns={[
                {
                  displayName:
                    vmrsDecodeOrder?.requestType === "Parts"
                      ? "Part Name"
                      : "Description",
                  id: "partName",
                },
                {
                  displayName:
                    vmrsDecodeOrder?.requestType === "Parts"
                      ? "Part ID"
                      : "Task ID",
                  id: "externalId",
                },
                { displayName: "VMRS Part Name", id: "vmrsPartName" },
                { displayName: "VMRS Code", id: "vmrsPartId" },
              ]}
            >
              <Button
                width={{ base: "100%", md: "auto" }}
                leftIcon={<DownloadIcon size={".875rem"} />}
                variant="outline"
              >
                Download CSV
              </Button>
            </CsvDownloader>
          }
        ></DataTable>
      )}
    </Box>
  );
};
