import {
  Box,
  Button,
  Collapse,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { BsCaretRightFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PopoverIcon } from "../layouts/app-layout";

interface NavItemProps {
  href?: string;
  label: string;
  subtle?: boolean;
  to?: string;
  icon: ReactElement;
  endElement?: ReactElement;
  children?: ReactNode;
  fuzzy?: boolean;
}

interface NavSubItemProps {
  label: string;
  to?: string;
  fuzzy?: boolean;
}

export const NavSubItem = (props: NavSubItemProps) => {
  const { label, to, fuzzy } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const toSegments = to?.split("/");
  const locationSegments = location.pathname.split("/");

  const active =
    to &&
    (fuzzy
      ? toSegments?.[1] === locationSegments?.[1]
      : to === location.pathname);

  return (
    <Button
      variant="tertiary"
      justifyContent="start"
      width="full"
      bg={active ? "brand.gray.300" : "transparent"}
      onClick={() => navigate(to ?? "")}
    >
      {label}
    </Button>
  );
};

export const NavItem = (props: NavItemProps) => {
  const { subtle, icon, children, label, endElement, href, to, fuzzy } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const toSegments = to?.split("/");
  const locationSegments = location.pathname.split("/");
  const { isOpen, onToggle } = useDisclosure();

  const active =
    to &&
    (fuzzy
      ? toSegments?.[1] === locationSegments?.[1]
      : to === location.pathname);

  return (
    <Box>
      <Button
        variant="tertiary"
        bg={active ? "brand.gray.300" : "transparent"}
        onClick={children ? onToggle : () => navigate(to ?? "")}
        justifyContent="space-between"
        width="full"
      >
        <HStack spacing="3">
          {icon}
          <Text as="span">{label}</Text>
        </HStack>
        {children && <PopoverIcon isOpen={isOpen} />}
      </Button>
      {children && (
        <Collapse in={isOpen} animateOpacity>
          <Stack spacing="1" alignItems="stretch" ps="8" py="1">
            {children}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
};
