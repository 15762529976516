import { useState, useMemo } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Select,
  InputGroup,
  InputLeftAddon,
  Text,
  Stack,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { Vehicle } from "../../types";
import { ComplianceViolation } from "../../types-new";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../vehicles/date-picker.css";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { Edit } from "lucide-react";

export const VehicleViolationsTab = ({ vehicle }: { vehicle?: Vehicle }) => {
  const { mutate: updateVehicle } = useUpdateVehicle();
  const [violations, setViolations] = useState<ComplianceViolation[]>(
    vehicle?.complianceData.violations || []
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedViolation, setSelectedViolation] =
    useState<ComplianceViolation | null>(null);
  const [newViolation, setNewViolation] = useState<
    Partial<ComplianceViolation>
  >({});

  const columns: ColumnDef<ComplianceViolation>[] = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "violationDate",
        cell: ({ getValue }) => {
          const date = new Date(getValue<string>());
          return isNaN(date.getTime())
            ? "Invalid Date"
            : date.toLocaleDateString();
        },
      },
      {
        header: "Type",
        accessorKey: "ruleName",
      },
      {
        header: "Description",
        accessorKey: "description",
      },
      {
        header: "Fine Amount",
        accessorKey: "fine",
        cell: ({ getValue }) => `$${getValue<number>().toFixed(2)}`,
      },
      {
        header: "Resolution Date",
        accessorKey: "resolutionDate",
        cell: ({ getValue }) => {
          const date = getValue<string>();
          return date ? (
            new Date(date).toLocaleDateString()
          ) : (
            <Badge colorScheme="red">Unresolved</Badge>
          );
        },
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <IconButton
            p={0}
            m={0}
            aria-label="Edit violation"
            icon={<Edit size={16} />}
            size="16px"
            onClick={() => {
              setSelectedViolation(row.original);
              onOpen();
            }}
          />
        ),
      },
    ],
    []
  );

  const handleSaveViolation = async () => {
    if (selectedViolation) {
      const updatedViolations = violations.map((v) =>
        v.id === selectedViolation.id
          ? { ...v, resolutionDate: selectedViolation.resolutionDate }
          : v
      );
      setViolations(updatedViolations);
      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            violations: updatedViolations,
          },
        };
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }
    } else if (
      newViolation.violationDate &&
      newViolation.ruleName &&
      newViolation.description &&
      newViolation.fine !== undefined
    ) {
      const newViolationRecord: ComplianceViolation = {
        id: Date.now().toString(),
        violationDate: new Date(newViolation.violationDate).toISOString(),
        ruleName: newViolation.ruleName,
        description: newViolation.description,
        fine: newViolation.fine,
        resolutionDate: newViolation.resolutionDate,
      };
      const updatedViolations = [...violations, newViolationRecord];
      setViolations(updatedViolations);
      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            violations: updatedViolations,
          },
        };
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }
    }
    setNewViolation({});
    setSelectedViolation(null);
    onClose();
  };

  return (
    <Box>
      <DataTable
        data={violations}
        columns={columns}
        suppressPaginationFooter={true}
        actionChildren={
          <Button
            variant={"inverted"}
            onClick={() => {
              setSelectedViolation(null);
              onOpen();
            }}
          >
            Add New Violation
          </Button>
        }
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedViolation ? "Edit Violation" : "Add New Violation"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} justifyContent="flex-start">
              {selectedViolation ? (
                <Stack width="100%">
                  <Text>
                    Date:{" "}
                    {/* {new Date(
                      selectedViolation.violationDate
                    ).toLocaleDateString()} */}
                  </Text>
                  <Text>Type: {selectedViolation.ruleName}</Text>
                  <Text>Description: {selectedViolation.description}</Text>
                  <Text>Fine Amount: ${selectedViolation.fine.toFixed(2)}</Text>
                  <FormControl mt={4} isRequired>
                    <FormLabel>Resolution Date</FormLabel>
                    <DatePicker
                      selected={
                        selectedViolation.resolutionDate
                          ? new Date(selectedViolation.resolutionDate)
                          : null
                      }
                      onChange={(date) =>
                        setSelectedViolation({
                          ...selectedViolation,
                          resolutionDate: date ? date.toISOString() : undefined,
                        })
                      }
                      dateFormat="MM/dd/yyyy"
                      customInput={<Input />}
                    />
                  </FormControl>
                </Stack>
              ) : (
                <>
                  <FormControl isRequired>
                    <FormLabel>Date</FormLabel>
                    <DatePicker
                      selected={
                        newViolation.violationDate
                          ? new Date(newViolation.violationDate)
                          : null
                      }
                      onChange={(date) =>
                        setNewViolation({
                          ...newViolation,
                          violationDate: date ? date.toISOString() : undefined,
                        })
                      }
                      dateFormat="MM/dd/yyyy"
                      customInput={<Input />}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={newViolation.ruleName || ""}
                      onChange={(e) =>
                        setNewViolation({
                          ...newViolation,
                          ruleName: e.target.value,
                        })
                      }
                      placeholder="Select violation type"
                    >
                      <option value="Clean Truck Check">
                        Clean Truck Check
                      </option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Description</FormLabel>
                    <Input
                      value={newViolation.description || ""}
                      onChange={(e) =>
                        setNewViolation({
                          ...newViolation,
                          description: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Fine Amount</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children="$" />
                      <Input
                        type="number"
                        value={newViolation.fine || ""}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, "");
                          setNewViolation({
                            ...newViolation,
                            fine: value === "" ? undefined : parseFloat(value),
                          });
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Resolution Date</FormLabel>
                    <DatePicker
                      selected={
                        newViolation.resolutionDate
                          ? new Date(newViolation.resolutionDate)
                          : null
                      }
                      onChange={(date) =>
                        setNewViolation({
                          ...newViolation,
                          resolutionDate: date ? date.toISOString() : undefined,
                        })
                      }
                      dateFormat="MM/dd/yyyy"
                      customInput={<Input />}
                    />
                  </FormControl>
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={"inverted"} mr={3} onClick={handleSaveViolation}>
              {selectedViolation ? "Save Changes" : "Add Violation"}
            </Button>
            <Button variant={"outline"} color="red.500" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
