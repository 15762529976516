import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiServiceErr } from ".";
import { ProviderIntegrationSummary } from "../../types-new";

const providerRoute = "/api/v2/providers";
const queryKey = "provider-integrations";

export const useProviders = (enabled: boolean = true) => {
    const { getToken } = useAuth();
    return useQuery<ProviderIntegrationSummary[], ApiServiceErr>({
        queryKey: [queryKey],
        queryFn: async () => {
            const result = await axios.get(
                `${providerRoute}/summaries`,
                { headers: { 'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}` } }
            );

            return result.data;
        },
        enabled,
    });
}

export const useCreateProviderSecret = () => {
    const { getToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ providerId, ...rest }: { providerId: string, [key: string]: any }) => {
            const response = await axios.post(
                `${providerRoute}/${providerId}/secrets`,
                rest,
                { headers: { 'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json" } }
            );

            return response.data;
        },
        onSuccess: (result, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKey] });
            toast.success(`Created provider connection.`)
        },
        onError: (error, variables, context) => {
            console.log(error);
            toast.error(`Error creating provider connection.`)
        },
    });
}

export const useDeleteProviderSecret = () => {
    const { getToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ providerId, secretId }: { providerId: string, secretId: string }) => {
            return await axios.delete(
                `${providerRoute}/${providerId}/secrets/${secretId}`,
                { headers: { 'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}` } }
            );
        },
        onSuccess: (result, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKey] });
            toast.success(`Provider disconnected.`)
        },
        onError: (error, variables, context) => {
            toast.error(`Error disconnecting provider.`)
            console.log(error);
        },
    });
}