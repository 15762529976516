import { useUser, useOrganization, useAuth } from "@clerk/clerk-react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

export const LDIdentifyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const { orgRole } = useAuth();
  const ldClient = useLDClient();

  useEffect(() => {
    if (!user && !organization) return;
    ldClient?.identify(
      {
        kind: "multi",
        user: {
          key: user?.id,
          name: user?.fullName,
          email: user?.primaryEmailAddress?.emailAddress,
          role: orgRole,
        },
        organization: {
          key: organization?.id,
          name: organization?.name,
        },
      },
      undefined,
      (err: any, flags: any) => {}
    );
  }, [user, organization, orgRole, ldClient]);

  return <>{children}</>;
};
