import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Evidence } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useEvidences = () => {
    const { getToken } = useAuth();
    return useQuery<Evidence[], ApiServiceErr>({
      queryKey: ["evidence"],
      queryFn:  async () => {
       const result =  await axios.get(
          `/api/evidence`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );

        return result.data;
      }
    });
}

export const useEvidence = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Evidence, ApiServiceErr>({
    queryKey: ["evidence", id],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/evidence/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useCreateEvidence = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({evidence, formData}: {evidence: Evidence, formData: FormData}) => {
      return await axios.post(
        `/api/evidence`,
        formData,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['evidence'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useUpdateEvidence = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, evidence, formData}: {id: string, evidence: Evidence, formData: FormData}) => {
      return await axios.put(
        `/api/evidence/${id}`,
        formData,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['evidence', variables.id], variables.evidence);
      queryClient.invalidateQueries({ queryKey: ['evidence'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDeleteEvidence = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/evidence/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['evidence'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDetachFileFromEvidence = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({evidenceId, fileId}: {evidenceId: string, fileId: string}) => {
      const response = await axios.post(
        `/api/evidence/${evidenceId}/detach/${fileId}`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['evidence'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}