// passFailQuestionType.tsx
import {
  Serializer,
  ComponentCollection,
  QuestionCompositeModel,
  QuestionTextModel,
  QuestionFileModel,
  Question,
} from "survey-core";

// Function to initialize the Pass/Fail question type
export const initPassFailQuestionType = () => {
  // Define the type name for the Pass/Fail question
  const PASS_FAIL_TYPE = "passfail";
  // Check if the Pass/Fail question type already exists to avoid re-initialization
  if (Serializer.findClass(PASS_FAIL_TYPE)) {
    return;
  }

  // Add the Pass/Fail question type to the ComponentCollection
  ComponentCollection.Instance.add({
    name: PASS_FAIL_TYPE,
    title: "Pass / Fail",
    iconName: "icon-apply",
    // Define the JSON structure for the Pass/Fail question elements
    elementsJSON: [
      {
        type: "boolean",
        name: "result",
        renderAs: "switch",
        title: "Result",
        labelTrue: "Pass",
        labelFalse: "Fail",
        isRequired: true,
      },
      {
        type: "comment",
        name: "commentForPass",
        title: "Comment for Pass",
        visibleIf:
          "{panel.result} = true and {panel.requireCommentForPass} = true",
        isRequired: true,
        startWithNewLine: true,
      },
      {
        type: "file",
        name: "photoForPass",
        title: "Photo for Pass",
        visibleIf:
          "{panel.result} = true and {panel.requirePhotoForPass} = true",
        acceptedTypes: ".jpeg, .jpg, .png, .webp",
        isRequired: true,
        startWithNewLine: true,
        allowMultiple: false,
        allowImagesPreview: true,
      },
      {
        type: "comment",
        name: "commentForFail",
        title: "Comment for Failure",
        visibleIf:
          "{panel.result} = false and {panel.requireCommentForFail} = true",
        isRequired: true,
        startWithNewLine: true,
      },
      {
        type: "file",
        name: "photoForFail",
        title: "Photo for Fail",
        visibleIf:
          "{panel.result} = false and {panel.requirePhotoForFail} = true",
        acceptedTypes: ".jpeg, .jpg, .png, .webp",
        isRequired: true,
        startWithNewLine: true,
        allowMultiple: false,
        allowImagesPreview: true,
      },
    ],
    // Callback function to add custom properties to the Pass/Fail question type upon initialization
    onInit() {
      // Add custom properties to control the visibility and requirements of comments and photos for pass and fail
      Serializer.addProperty(PASS_FAIL_TYPE, {
        name: "requireCommentForPass:boolean",
        default: false,
        category: "general",
      });
      Serializer.addProperty(PASS_FAIL_TYPE, {
        name: "requirePhotoForPass:boolean",
        default: false,
        category: "general",
      });
      Serializer.addProperty(PASS_FAIL_TYPE, {
        name: "requireCommentForFail:boolean",
        default: true,
        category: "general",
      });
      Serializer.addProperty(PASS_FAIL_TYPE, {
        name: "requirePhotoForFail:boolean",
        default: false,
        category: "general",
      });
    },
    // Callback function to handle logic when the Pass/Fail question is loaded
    onLoaded(question: QuestionCompositeModel) {
      question.contentPanel.questions.forEach((q: Question) => {
        // Ensure the question is either a QuestionTextModel or QuestionFileModel before proceeding
        if (q instanceof QuestionTextModel || q instanceof QuestionFileModel) {
          // Dynamically set the visibility based on specific properties
          // Directly check the property that matches the question's purpose
          if (q.name.includes("ForPass")) {
            q.visible = question.getPropertyValue(
              q.name.includes("comment")
                ? "requireCommentForPass"
                : "requirePhotoForPass"
            );
          } else if (q.name.includes("ForFail")) {
            q.visible = question.getPropertyValue(
              q.name.includes("comment")
                ? "requireCommentForFail"
                : "requirePhotoForFail"
            );
          }
        }
      });
    },
    onValueChanged(
      question: QuestionCompositeModel,
      name: string,
      newValue: any
    ) {
      const panel = question.contentPanel;
      const resultValue = question.getValue("result"); // Get the current value of the result property
      panel.questions.forEach((q: Question) => {
        if (
          (q.name === "commentForPass" &&
            question.getPropertyValue("requireCommentForPass")) ||
          (q.name === "photoForPass" &&
            question.getPropertyValue("requirePhotoForPass")) ||
          (q.name === "commentForFail" &&
            question.getPropertyValue("requireCommentForFail")) ||
          (q.name === "photoForFail" &&
            question.getPropertyValue("requirePhotoForFail"))
        ) {
          // Set the visible property based on the new value
          if (q.name.includes("Pass")) {
            q.visible = resultValue;
          } else {
            q.visible = !resultValue;
          }
        }
      });
    },
    // Callback function to handle property changes in the Pass/Fail question
    onPropertyChanged(
      question: QuestionCompositeModel,
      propertyName: string,
      newValue: any
    ) {
      const panel = question.contentPanel;
      panel.questions.forEach((q: Question) => {
        if (
          (q.name === "commentForPass" &&
            propertyName === "requireCommentForPass") ||
          (q.name === "photoForPass" &&
            propertyName === "requirePhotoForPass") ||
          (q.name === "commentForFail" &&
            propertyName === "requireCommentForFail") ||
          (q.name === "photoForFail" && propertyName === "requirePhotoForFail")
        ) {
          // Set the visible property based on the new value
          q.visible = newValue;
        }
      });
    },
  });
};

export default initPassFailQuestionType;
