// @ts-nocheck
import { registerBlockType } from "@quillforms/blocks";
import LessonBlock from "./LessonBlock";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

let initialized = false;

export function registerBlocks(types?: BlockTypeInterface[]) {
  if (!initialized) {
    initialized = true;
    registerCoreBlocks();

    registerBlockType("lesson", {
      supports: {
        editable: false,
      },
      display: LessonBlock,
    });
  }
}
