import {
  Box,
  Stack,
  Button,
  Text,
  HStack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import {
  useMyCertificates,
  useMyCourses,
  useMyRefresherSummaries,
} from "../../../api/endpoints/my";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteCertificate,
  useUploadCertificate,
} from "../../../api/endpoints/certificates";
import { PageHeader } from "../../../components/page-header";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRef } from "react";
import * as Yup from "yup";
import { Form, Field, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { Select } from "chakra-react-select";
import { TrainingTasksTab } from "./tasks-tab";
import { TrainingLibraryTab } from "./library-tab";

export const MyTrainingPage: React.FC = () => {
  const { data: courses, isLoading: coursesLoading } = useMyCourses();
  const { data: certificates, isLoading: certificatesLoading } =
    useMyCertificates();
  const { data: refresherSummaries, isLoading: refresherSummariesLoading } =
    useMyRefresherSummaries();

  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isTrial, trialEligibleCourses, demoCourses } = useFlags();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { mutate: uploadCertificate, isError: uploadCertificateError } =
    useUploadCertificate();

  const { mutate: deleteCertificate, isError: deleteCertificateError } =
    useDeleteCertificate();

  const schema = Yup.object().shape({
    issuedBy: Yup.object().required("Issued By is required."),
    identifier: Yup.string().min(3).required("Identifier is required."),
    validFrom: Yup.date().required("Valid from date is required."),
    validTo: Yup.date(),
    file: Yup.mixed().test(
      "fileSize",
      "The certificate file is too large",
      (value: any) => {
        if (!value) return false;
        return value.size <= 5242880;
      }
    ),
  });

  const initialValues: {
    name?: string;
    issuedBy?: string;
    identifier?: string;
    validFrom?: string;
    validTo?: string;
    file?: File;
  } = {
    name: undefined,
    issuedBy: undefined,
    identifier: undefined,
    validFrom: undefined,
    validTo: undefined,
    file: undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      console.log(values);
      await uploadCertificate({
        data: {
          identifier: values.identifier,
          name: values.issuedBy.value,
          issuedBy: values.issuedBy.issuer,
          validFrom: values.validFrom,
          validTo: values.validTo,
          file: values.file,
        },
      });
      onToggle();
    },
  });

  const certificateOptions: readonly any[] = [
    {
      label: "ASE",
      options: [
        { label: "xEV Level 1", value: "xEV Level 1", issuer: "ASE" },
        { label: "xEV Level 2", value: "xEV Level 2", issuer: "ASE" },
        { label: "Other", value: "other", issuer: "ASE" },
      ],
    },
  ];

  const hiddenCertificateFileInput = useRef(null);

  const handleCertificateFileClick = (event: any) => {
    //@ts-ignore
    hiddenCertificateFileInput.current.click();
  };

  const handleCertificateFileChange = (event: any) => {
    if (event?.target?.files?.length === 0) return;

    const file = event.target.files[0];

    if (file.size > 5242880) {
      toast.error("Certificate file is larger than 5MB.");
    } else {
      formik.setFieldValue("file", file);
    }
  };

  const tabs = [
    {
      name: "Tasks",
      component: <TrainingTasksTab />,
    },
    {
      name: "Library",
      component: <TrainingLibraryTab />,
    },
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"} p={0}>
      {searchParams.get("finishedCourse") === "true" && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Upload Existing Certificate</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.identifier === true &&
                      !!formik?.touched?.identifier === true
                    }
                  >
                    <FormLabel>Certificate ID</FormLabel>
                    <Field
                      as={Input}
                      id="identifier"
                      name={"identifier"}
                      type="text"
                      placeholder="The unique ID of the certificate"
                      value={formik.values.identifier}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.identifier?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="issuedBy"
                    isInvalid={
                      !!formik?.errors?.issuedBy === true &&
                      !!formik?.touched?.issuedBy === true
                    }
                  >
                    <FormLabel>Type</FormLabel>
                    <Select
                      options={certificateOptions}
                      id="issuedBy"
                      placeholder={"What type of certificate is this?"}
                      value={formik.values.issuedBy}
                      name="issuedBy"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("issuedBy", e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.issuedBy?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validFrom === true &&
                      !!formik?.touched?.validFrom === true
                    }
                  >
                    <FormLabel>Valid From</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validFrom"
                      name={"validFrom"}
                      value={formik.values.validFrom}
                      onChange={(e: any) => {
                        formik.setFieldValue("validFrom", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validFrom?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validTo === true &&
                      !!formik?.touched?.validTo === true
                    }
                  >
                    <FormLabel>Valid To</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validTo"
                      name={"validTo"}
                      value={formik.values.validTo}
                      onChange={(e: any) => {
                        formik.setFieldValue("validTo", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validTo?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.file === true &&
                      !!formik?.touched?.file === true
                    }
                  >
                    <HStack display={"flex"} mb={2}>
                      <FormLabel mb={0} mr={1}>
                        Certificate File
                      </FormLabel>
                      <Text color={"fg.muted"} fontSize={"xs"}>
                        (5MB Max File Size)
                      </Text>
                    </HStack>
                    <HStack>
                      {!formik.values.file && (
                        <Button
                          variant="solid"
                          colorScheme="brand.primary"
                          onClick={handleCertificateFileClick}
                        >
                          Attach Certificate
                        </Button>
                      )}
                      {formik.values.file !== undefined && (
                        <>
                          <Button
                            className="button-upload"
                            background={"red.500"}
                            color={"white"}
                            width={"40%"}
                            _hover={{ background: "red.500" }}
                            onClick={() => {
                              formik.setFieldValue("file", undefined);
                            }}
                          >
                            Remove Certificate
                          </Button>
                          <Text
                            width={"60%"}
                            fontSize={"xs"}
                            textOverflow={"ellipsis"}
                          >
                            {formik.values.file.name}
                          </Text>
                        </>
                      )}
                    </HStack>
                    <Input
                      type="file"
                      onChange={handleCertificateFileChange}
                      ref={hiddenCertificateFileInput}
                      style={{ display: "none" }}
                    ></Input>
                    <FormErrorMessage>
                      {formik?.errors?.file?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <Text color={"fg.muted"}>
                    By submitting this certificate you agree that the
                    certificate submitted is accurate to the best of your
                    knowledge.
                  </Text>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <PageHeader title="Training"></PageHeader>
      <Stack>
        <Tabs size={"lg"} variant="soft-rounded">
          <TabList
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab key={index} borderRadius={"md"}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />

          <TabPanels>
            {tabs.map((tab, index) => {
              return <TabPanel key={index}>{tab.component}</TabPanel>;
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
