import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MyCertificate, MyCourse, MyProtocol, Protocol, Refresher, RefresherSummary } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useMyCertificates = () => {
  const { getToken } = useAuth();
  return useQuery<MyCertificate[], ApiServiceErr>({
    queryKey: ["certificates"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/my/certificates`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useMyProtocols = () => {
    const { getToken } = useAuth();
    return useQuery<MyProtocol[], ApiServiceErr>({
      queryKey: ["protocols"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/my/protocols`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      }
    });
}

export const useMyCourses = () => {
  const { getToken } = useAuth();
  return useQuery<MyCourse[], ApiServiceErr>({
    queryKey: ["my-courses"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/my/courses`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useMyCourse = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<MyCourse, ApiServiceErr>({
    queryKey: [`my-courses-${id}`],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/my/courses/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useUpdateMyRefresher = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, data}: {id: string, data: any}) => {
      const result = await axios.put(
        `/api/my/refreshers/${id}`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['refreshers'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useMyRefresher = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Refresher, ApiServiceErr>({
    queryKey: ["refreshers"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/my/refreshers/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useMyRefresherSummaries = () => {
  const { getToken } = useAuth();
  return useQuery<RefresherSummary[], ApiServiceErr>({
    queryKey: ["refresher-summaries"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/my/refresher-summaries`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}


export const useAcknowledgeProtocolAction = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, version}: {id: string, version: number}) => {
      const result = await axios.post(
        `/api/protocols/${id}/acknowledge/${version}`,
        undefined,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}