import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InspectionTemplate } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useInspections = () => {
  const { getToken } = useAuth();
  return useQuery<InspectionTemplate[], ApiServiceErr>({
    queryKey: ["inspections"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/inspections`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useInspection = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<InspectionTemplate, ApiServiceErr>({
    queryKey: ["inspections", id],
    queryFn:  async () => {
      const result = await axios.get(
        `/api/inspections/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}


export const useUpdateInspection = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, body}: {id: string, body: {results: any}}) => {
      const result = await axios.put(
        `/api/inspections/${id}`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['inspections'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDeleteInspectionTemplate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/inspection-templates/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-templates'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}