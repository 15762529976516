import { Box, Stack } from "@chakra-ui/layout";
import React from "react";
import { PageHeader } from "../../components/page-header";
import { OrganizationDetailsSection } from "./organization-details-section";
import { OrganizationComplianceSection } from "./organization-compliance-section";
import { OrganizationDangerZoneSection } from "./organization-dangerzone-section";
import { OrganizationTrainingSection } from "./organization-training-section";

export const SettingsPage: React.FC = () => {
  return (
    <Box height={"100vh"} overflow={"auto"}>
      <PageHeader title="Organization Settings"></PageHeader>
      <Stack p={5}>
        <OrganizationDetailsSection />
        <OrganizationTrainingSection />
        <OrganizationComplianceSection />
        <OrganizationDangerZoneSection />
      </Stack>
    </Box>
  );
};
