import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  useDisclosure,
  Box,
  Stack,
  Button,
  CardBody,
  Card,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { BsPencil } from "react-icons/bs";
import * as Yup from "yup";
import { Evidence } from "../../../types";
import { EvidenceForm } from "./form";
import {
  useCreateEvidence,
  useDeleteEvidence,
  useEvidences,
} from "../../../api/endpoints/evidence";
import { toast } from "react-toastify";
import { DeleteButton } from "../../../components/delete-button";
import { PlusIcon } from "lucide-react";

export const EvidenceTab: React.FC = () => {
  const columnHelper = createColumnHelper<Evidence>();
  const navigate = useNavigate();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const { data: evidences, isError, isLoading } = useEvidences();
  const { mutate: deleteEvidence } = useDeleteEvidence();
  const { mutate: createEvidence, isError: createError } = useCreateEvidence();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      header: () => <span>Description</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.files, {
      id: "files",
      header: () => <span>Files</span>,
      cell: (info) => info.getValue()?.length,
      footer: (props) => props.column.id,
    }),

    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <Box>
            <DeleteButton
              title="Delete Evidence"
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete evidence"
              onDelete={() => deleteEvidence({ id: props.row.original.id })}
            />
            <IconButton
              color={"brand.accent"}
              aria-label="edit evidence"
              icon={<BsPencil />}
              onClick={() =>
                navigate(`/doc-library/evidence/${props.row.original.id}`)
              }
            />
          </Box>
        );
      },
    }),
  ];

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Evidence name is required."),
    description: Yup.string()
      .min(3)
      .required("Evidence description is required."),
    collectedOn: Yup.date()
      .typeError("Valid Collection Date is required.")
      .required("Collection Date is required."),
    content: Yup.string().when(["files", "pendingFiles"], {
      is: (files: any[], pendingFiles: any[]) =>
        files !== null &&
        files.length == 0 &&
        pendingFiles !== null &&
        pendingFiles.length == 0,
      then: (schema) =>
        schema.required(
          "Either content must be entered here or file(s) must be uploaded."
        ),
    }),
    expiresOn: Yup.date()
      .typeError("Valid Expiration Date is required.")
      .required("Expiration Date is required."),
    // files: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: "",
      collectedOn: "",
      expiresOn: "",
      content: "",
      pendingFiles: [],
      files: [],
    },
    validationSchema: schema,
    onSubmit: async (values: any) => {
      const formData = new FormData();

      for (var file of values.files) {
        formData.append("files", file, file.name);
      }

      formData.append("evidence", JSON.stringify(values));

      await createEvidence({ evidence: values, formData: formData });

      if (!createError) {
        toast("Successfully created evidence.");
        onToggle();
        formik.resetForm();
      } else {
        toast.error("Error creating evidence.");
      }
    },
  });

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Compliance Evidence</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EvidenceForm formik={formik} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={evidences ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onToggle}
                >
                  Add
                </Button>
              }
              title="Evidence"
              subtitle="Add a document to be used as evidence."
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
