import {
  Badge,
  Box,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  Image,
  Center,
  HStack,
  Drawer,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { useState } from "react";
import { AwardIcon, ShieldIcon } from "lucide-react";
import threeM from "./logos/3m.png";
import abb from "./logos/abb.png";
import agi from "./logos/agi.svg";
import amply from "./logos/amply.png";
import ascendElements from "./logos/ascend-elements.webp";
import blinkCharging from "./logos/blink-charging.png";
import blueWhaleMaterials from "./logos/blue-whale-materials.png";
import chargepoint from "./logos/chargepoint.svg";
import chargerHelp from "./logos/charger-help.png";
import darktrace from "./logos/darktrace.png";
import dupont from "./logos/dupont.png";
import enelX from "./logos/enel-x.webp";
import energyHub from "./logos/energy-hub.jpg";
import engie from "./logos/engie.jpg";
import evbox from "./logos/evbox.png";
import honeywell from "./logos/honeywell.png";
import icf from "./logos/icf.jpg";
import liCycle from "./logos/li-cycle.png";
import mainspring from "./logos/mainspring.png";
import oel from "./logos/oel.png";
import pearceRenewables from "./logos/pearce-renewables.png";
import recell from "./logos/recell.webp";
import redwoodMaterials from "./logos/redwood-materials.png";
import schneiderElectric from "./logos/schneider-electric.webp";
import siemens from "./logos/siemens.png";
import stem from "./logos/stem.png";
import tesla from "./logos/tesla.png";
import tilson from "./logos/tilson.png";
import trc from "./logos/trc.png";
import ultimateToolAndSafety from "./logos/ultimate-tool-and-safety.png";
import zerova from "./logos/zerova.svg";
import mcafee from "./logos/mcafee.png";
import symantec from "./logos/symantec.png";
import crowdstrike from "./logos/crowdstrike.png";
import flipturn from "./logos/flipturn.webp";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

export const VendorIntegrationsPage: React.FC = () => {
  const integrations = [
    {
      name: "Charging Infrastructure",
      categories: [
        {
          name: "Charging Equipment Suppliers",
          vendors: [
            {
              name: "Zerova",
              image: zerova,
              certified: true,
              soc2: false,
            },
            {
              name: "ChargePoint",
              image: chargepoint,
              soc2: true,
            },
            {
              name: "ABB",
              image: abb,
              soc2: false,
            },
            {
              name: "Siemens",
              image: siemens,
              soc2: false,
            },
            {
              name: "Schneider Electric",
              image: schneiderElectric,
              soc2: false,
            },
            {
              name: "Tesla",
              image: tesla,
              certified: true,
              soc2: false,
            },
            {
              name: "EVBox",
              image: evbox,
              soc2: false,
            },
            {
              name: "Blink Charging",
              image: blinkCharging,
              soc2: false,
            },
          ],
        },
        {
          name: "Installation",
          vendors: [
            {
              name: "Tilson",
              image: tilson,
              certified: true,
              soc2: false,
            },
            {
              name: "AGI",
              image: agi,
              soc2: false,
            },
          ],
        },
        {
          name: "Operations & Maintenance",
          helperText: "Direct integration with Axle.",
          vendors: [
            {
              name: "Pearce Renewables",
              image: pearceRenewables,
              soc2: false,
            },
            {
              name: "ChargerHelp",
              image: chargerHelp,
              certified: true,
              soc2: false,
            },
            {
              name: "Flipturn",
              image: flipturn,
              soc2: true,
              certified: true,
            },
          ],
        },
        {
          name: "Contractors",
        },
        {
          name: "Energy Management Systems",
          vendors: [
            {
              name: "Mainspring",
              image: mainspring,
              certified: true,
              soc2: false,
            },
            {
              name: "Stem",
              image: stem,
              soc2: false,
            },
            {
              name: "Enel X",
              image: enelX,
              soc2: false,
            },
            {
              name: "ENGIE",
              image: engie,
              soc2: false,
            },
            {
              name: "AMPLY Power",
              image: amply,
              soc2: false,
            },
          ],
        },
        {
          name: "Incentive & Rebate Management",
          vendors: [
            {
              name: "EnergyHub",
              image: energyHub,
              soc2: false,
            },
            {
              name: "ICF",
              image: icf,
              soc2: false,
            },
            {
              name: "TRC",
              image: trc,
              soc2: false,
            },
          ],
        },
      ],
    },
    {
      name: "EV Safety and Compliance",
      categories: [
        {
          name: "Safety Equipment",
          helperText: "Direct integration with Axle.",
          vendors: [
            {
              name: "Honeywell",
              image: honeywell,
              certified: true,
              soc2: false,
            },
            {
              name: "3M",
              image: threeM,
              soc2: false,
            },
            {
              name: "DuPont",
              image: dupont,
              soc2: false,
            },
          ],
        },
        {
          name: "PPE Management",
          vendors: [
            {
              name: "OEL",
              image: oel,
              certified: true,
              soc2: false,
            },
            {
              name: "Ultimate Tool and Safety",
              image: ultimateToolAndSafety,
              certified: true,
              soc2: false,
            },
          ],
        },
        {
          name: "Audits and Inspections",
          vendors: [],
        },
        {
          name: "Weights and Measures",
          vendors: [],
        },
        {
          name: "Fire Safety",
          vendors: [],
        },
        {
          name: "CARB Clean Truck Check Inspectors",
          vendors: [],
        },
      ],
    },
    {
      name: "Battery & Energy Storage",
      categories: [
        {
          name: "Battery Health Monitoring and Recycling",
          vendors: [
            {
              name: "Redwood Materials",
              image: redwoodMaterials,
              certified: true,
              soc2: false,
            },
            {
              name: "Li-Cycle",
              image: liCycle,
              soc2: false,
            },
            {
              name: "Recell",
              image: recell,
              soc2: false,
            },
            {
              name: "Blue Whale",
              image: blueWhaleMaterials,
              soc2: false,
            },
            {
              name: "Ascend Elements",
              image: ascendElements,
              soc2: false,
            },
          ],
        },
      ],
    },
    {
      name: "Data and Cybersecurity",
      categories: [
        {
          name: "Desktop Cybersecurity",
          vendors: [
            {
              name: "McAfee",
              image: mcafee,
              soc2: false,
            },
            {
              name: "Symantec",
              image: symantec,
              certified: true,
              soc2: false,
            },
            {
              name: "Darktrace",
              image: darktrace,
              soc2: false,
            },
            {
              name: "CrowdStrike",
              image: crowdstrike,
              soc2: false,
            },
          ],
        },
      ],
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const { user } = useUser();

  const [selectedVendor, setSelectedVendor] = useState<{
    name: string;
    image: string;
    certified: boolean;
    soc2: boolean;
  } | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleVendorClick = (vendor: {
    name: string;
    image: string;
    certified: boolean;
    soc2: boolean;
  }) => {
    setSelectedVendor(vendor);
    onOpen();
  };

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Vendors"></PageHeader>
      <Stack>
        <Tabs
          size={"lg"}
          variant="soft-rounded"
          borderLeft={"1px solid"}
          borderColor={"brand.border"}
          onChange={(index) => setTabIndex(index)}
        >
          <TabList
            pt={0}
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {integrations.map((integration, index) => {
              return (
                <Tab key={index} borderRadius={"md"}>
                  {integration.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />
        </Tabs>
      </Stack>
      <Card variant={"outline"} m={5}>
        <CardBody>
          <Stack>
            {integrations[tabIndex].categories.map((category, index) => {
              if (!category.vendors || category.vendors.length === 0)
                return null;
              return (
                <Stack mb={10} key={index}>
                  <HStack>
                    <Heading fontSize={""}>{category.name}</Heading>
                    {category.helperText && (
                      <Text fontSize={"sm"} fontStyle={"italic"}>
                        {category.helperText}
                      </Text>
                    )}
                  </HStack>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                    {category?.vendors?.map((vendor, index) => {
                      return (
                        <Card
                          variant={"outline"}
                          key={index}
                          //@ts-ignore
                          onClick={() => handleVendorClick(vendor)}
                          cursor="pointer"
                          _hover={{ boxShadow: "md" }}
                        >
                          <CardBody p={2} pb={5}>
                            <Stack direction={"row"}>
                              {vendor.certified && (
                                <Badge
                                  bg={"brand.primary.500"}
                                  color={"white"}
                                  borderRadius={"none"}
                                >
                                  <HStack>
                                    <AwardIcon size={16} />{" "}
                                    <Text color={"white"}>Certified</Text>
                                  </HStack>
                                </Badge>
                              )}
                              {vendor.soc2 && (
                                <Badge
                                  bg={"purple.500"}
                                  color={"white"}
                                  borderRadius={"none"}
                                >
                                  <HStack>
                                    <ShieldIcon size={16} />{" "}
                                    <Text color={"white"}>SOC 2</Text>
                                  </HStack>
                                </Badge>
                              )}
                            </Stack>
                            <Center height={"100%"}>
                              <Stack align={"center"}>
                                <Image
                                  src={vendor.image}
                                  boxSize="150px"
                                  objectFit="contain"
                                />
                                <Text fontWeight={"bold"}>{vendor.name}</Text>
                              </Stack>
                            </Center>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </SimpleGrid>
                </Stack>
              );
            })}
          </Stack>
        </CardBody>
      </Card>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{selectedVendor?.name}</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Your Name</FormLabel>
                <Input
                  placeholder="Enter your name"
                  value={user?.fullName ?? ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Your Email</FormLabel>
                <Input
                  placeholder="Enter your email"
                  type="email"
                  value={user?.emailAddresses[0]?.emailAddress ?? ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Textarea placeholder="Enter your message" />
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="inverted"
              onClick={() => {
                toast.success("Message Sent");
                onClose();
              }}
            >
              Send Message
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
