import {
  Box,
  Button,
  Card,
  CardBody,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "@clerk/clerk-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ChevronRight, PlusIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useVehicles } from "../../api/endpoints/vehicles";
import { PageHeader } from "../../components/page-header";
import { DataTable } from "../../components/table";
import { Vehicle } from "../../types";

export const VehiclesListPage: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { enableConnections } = useFlags();
  const { orgRole } = useAuth();
  const isAdmin = orgRole && orgRole === "admin";

  const showProviderConnectionOptions = enableConnections && isAdmin;

  const getModal = () => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Telematics Integrations</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color={"brand.accent"}>
            Your vehicle information is automatically kept up to date from
            connected providers. Click the button below to establish a new
            connection or to manually sync vehicle data.
          </Text>
          <Button
            rightIcon={<ChevronRight />}
            colorScheme="brand.primary"
            variant="solid"
            marginTop={6}
            onClick={() => navigate("/connections/gps-telematics")}
            fontWeight={"bold"}
            fontSize={"medium"}
          >
            Manage Connections
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button color="brand.primary.500" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const getMenuButton = () =>
    showProviderConnectionOptions ? (
      <Menu>
        <MenuButton
          as={Button}
          width={{ base: "100%", md: "auto" }}
          leftIcon={<PlusIcon size={".875rem"} />}
          variant="outline"
        >
          Add Vehicles
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => navigate("/vehicles/create")}>
            Add Multiple Vehicles
          </MenuItem>
          <MenuItem onClick={onOpen}>Sync from Telematics Integration</MenuItem>
          <MenuItem onClick={() => navigate("/vehicles/import/csv")}>
            Import Vehicles from CSV
          </MenuItem>
          <MenuItem as="a" href="#" disabled>
            Export CSV
          </MenuItem>
        </MenuList>
      </Menu>
    ) : (
      <Button
        width={{ base: "100%", md: "auto" }}
        leftIcon={<PlusIcon size={".875rem"} />}
        variant="outline"
        onClick={() => {
          navigate("/vehicles/create");
        }}
      >
        Add Vehicle
      </Button>
    );

  const columnHelper = createColumnHelper<Vehicle>();
  const navigate = useNavigate();

  const { data: vehicles } = useVehicles();

  const columns = [
    columnHelper.accessor((row) => row.vin, {
      id: "vin",
      header: () => <span>VIN</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue() ?? "N/A",
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.make, {
      id: "make",
      header: () => <span>Make</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.model, {
      id: "model",
      header: () => <span>Model</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.year, {
      id: "year",
      header: () => <span>Year</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.color, {
      id: "color",
      header: () => <span>Color</span>,
      cell: (info) =>
        info.getValue()
          ? info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)
          : "N/A",
      footer: (props) => props.column.id,
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Vehicles"></PageHeader>
      <Stack p={5}>
        {getModal()}
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={vehicles ?? []}
              actionChildren={getMenuButton()}
              onRowClick={(row, column, rowData) => {
                console.log(row, column, rowData);
                navigate(`/vehicles/${rowData.id}`);
              }}
            ></DataTable>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
