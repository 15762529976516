import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddMemberRequest, OrganizationMetadata } from "../../types-new/organizations";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useOrganizationMetadata = () => {
    const { getToken } = useAuth();
    return useQuery<OrganizationMetadata, ApiServiceErr>({
      queryKey: ["organization-metadata"],
      queryFn:  async () => {
       const result =  await axios.get(
          `/api/organization/metadata`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return result.data;
      }
    });
}

export const useUpdateOrganizationMetadata = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (metadata: OrganizationMetadata) => {
      return await axios.put(
        `/api/organization/metadata`,
        metadata,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['organization-metadata'] });
      toast.success("Organization profile updated successfully.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error updating organization profile. Please try again.");

    },
  });
}

export const useAddUserToOrganization = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (req: AddMemberRequest) => {
      return await axios.post(
        `/api/organization/members`,
        req,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      toast.success("Member added successfully.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error adding member. Please try again.");

    },
  });
}

export const useSendMemberAccountInformation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (userId: string) => {
      return await axios.post(
        `/api/organization/members/${userId}/send-account-information`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      toast.success("Account information sent successfully.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error sending account information. Please try again.");
    },
  });
}