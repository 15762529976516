import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  List,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import * as Yup from "yup";

import { Field, Form, FormikProvider, useFormik } from "formik";
import {
  useApproveBatteryDisposalOrder,
  useCreateVehicle,
  useDeleteVehicle,
  useVehicle,
  useVehicleBattery,
  useVehicleBatteryDisposalOrder,
} from "../../api/endpoints/vehicles";
import { PageHeader } from "../../components/page-header";
import { VehicleInformationTab } from "./vehicle-information-tab";
import { BiSolidLock } from "react-icons/bi";
import { BatteryInformationTab } from "./battery-information-tab";
import { useOrganization } from "@clerk/clerk-react";
import { useSite } from "../../api/endpoints/sites";
import { DeleteButton } from "../../components/delete-button";
import { VehicleComplianceTab } from "./vehicle-compliance-tab";
import { VehiclePurchaseAndRegistrationTab } from "./vehicle-purchase-and-registration-tab";
import { VehicleFeesAndPaymentsTab } from "./vehicle-fees-and-payments-tab";
import { VehicleInspectionsTab } from "./vehicle-inspections-tab";
import { VehicleEmissionsTab } from "./vehicle-emissions-tab";
import { VehicleViolationsTab } from "./vehicle-violations-tab";
import { VehicleSystemsOfRecordTab } from "./vehicle-systems-of-record-tab";
import { VehicleTasksTab } from "./vehicle-tasks-tab";

export const VehiclesEditPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { organization } = useOrganization();

  const isEditMode = id !== undefined;

  const { data: existingVehicle, isLoading: isExistingVehicleLoading } =
    useVehicle(id ?? "", isEditMode);

  const { data: existingBattery } = useVehicleBattery(
    existingVehicle?.id ?? "",
    existingVehicle !== null && !isExistingVehicleLoading
  );

  const { data: batteryDisposalOrder } = useVehicleBatteryDisposalOrder(
    existingBattery?.id ?? "",
    existingBattery?.id !== null &&
      existingBattery?.isDisposalRequested === true
  );

  const { data: site } = useSite(
    existingVehicle?.assignedToSite.id ?? "",
    existingVehicle !== undefined
  );

  const { data: createdVehicle, mutate: createVehicle } = useCreateVehicle();
  const { mutate: approveBatteryDisposalOrder } =
    useApproveBatteryDisposalOrder();

  const { mutate: deleteVehicle } = useDeleteVehicle();

  const vinSchema = Yup.object().shape({
    vin: Yup.string().min(17).required("VIN is required."),
  });

  const vinFormik = useFormik({
    initialValues: {
      vin: "",
    },
    validationSchema: vinSchema,
    onSubmit: async (values: any) => {
      if (!isEditMode) {
        await createVehicle(values);
      }
    },
  });

  useEffect(() => {
    if (createdVehicle) {
      navigate(`/vehicles/${createdVehicle.id}`);
    }
  }, [createdVehicle]);

  const tabs = useMemo(() => {
    const nextTabs = [];

    if (existingVehicle?.id) {
      nextTabs.push(
        {
          name: "General Information",
          component: (
            <VehicleInformationTab key={1} vehicle={existingVehicle} />
          ),
          isDisabled: false,
        },
        {
          name: "Purchase & Registration",
          component: (
            <VehiclePurchaseAndRegistrationTab
              key={1}
              vehicle={existingVehicle}
            />
          ),
          isDisabled: false,
        },
        // {
        //   name: "Compliance",
        //   component: <VehicleComplianceTab key={3} vehicle={existingVehicle} />,
        //   isDisabled: false,
        // },
        {
          name: "Systems of Record",
          component: (
            <VehicleSystemsOfRecordTab key={4} vehicle={existingVehicle} />
          ),
          isDisabled: false,
        },
        {
          name: "Compliance Tasks",
          component: <VehicleTasksTab key={5} vehicle={existingVehicle} />,
          isDisabled: false,
        },
        {
          name: "Compliance Inspections",
          component: (
            <VehicleInspectionsTab key={4} vehicle={existingVehicle} />
          ),
          isDisabled: false,
        },
        {
          name: "Compliance Fees & Payments",
          component: (
            <VehicleFeesAndPaymentsTab key={5} vehicle={existingVehicle} />
          ),
          isDisabled: false,
        },
        {
          name: "Emissions",
          component: <VehicleEmissionsTab key={6} vehicle={existingVehicle} />,
          isDisabled: false,
        },
        {
          name: "Violations",
          component: <VehicleViolationsTab key={7} vehicle={existingVehicle} />,
          isDisabled: false,
        }
      );
    }

    if (existingBattery?.id) {
      nextTabs.push({
        name: "Battery Information",
        component: (
          <BatteryInformationTab
            key={2}
            vehicle={existingVehicle}
            battery={existingBattery}
          />
        ),
        isDisabled: false,
      });
    }

    nextTabs.push(
      {
        name: "Service History",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Charging Activity",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Software Updates",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Warranty",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Inspections",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Accident History",
        component: <Box key={3} />,
        isDisabled: true,
      },
      {
        name: "Upgrades & Installation",
        component: <Box key={3} />,
        isDisabled: true,
      }
    );

    return nextTabs;
  }, [existingVehicle, existingBattery]);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box height={"100vh"} overflowY="auto">
      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            Approve your battery pickup
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={5}>
              <Text>Your battery pack pick up needs your approval.</Text>
              <Heading size={"sm"}>Vendor Information</Heading>
              <List>
                <ListItem>
                  <Text
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                    fontSize={"xs"}
                  >
                    <Text fontWeight={"bold"}>Name:</Text>
                    {batteryDisposalOrder?.vendorContactName}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                    fontSize={"xs"}
                  >
                    <Text fontWeight={"bold"}>When:</Text>
                    {batteryDisposalOrder?.vendorArrivalEndDateTime}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                    fontSize={"xs"}
                  >
                    <Text fontWeight={"bold"}>Contact:</Text>
                    {batteryDisposalOrder?.vendorContactName} (
                    {batteryDisposalOrder?.vendorContactPhone})
                  </Text>
                </ListItem>
              </List>
              <Divider />
              <Heading size={"sm"}>Site Information</Heading>
              <Text
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                fontSize={"xs"}
              >
                <Text fontWeight={"bold"}>Address:</Text>
                {batteryDisposalOrder?.siteAddress}
              </Text>
              <Divider />
              <Heading size={"sm"}>Onsite Removal</Heading>
              <Text
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                fontSize={"xs"}
              >
                {batteryDisposalOrder?.onSiteRemoval === true ? "Yes" : "No"}
              </Text>
              <Divider />
              <Heading size={"sm"}>Cost & Credit Estimate</Heading>
              <Text
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                fontSize={"xs"}
              >
                <Text fontWeight={"bold"}>Estimated Transport Cost:</Text>$
                {batteryDisposalOrder?.estimatedTransportCost}
              </Text>
              <Text
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                fontSize={"xs"}
              >
                <Text fontWeight={"bold"}>Estimated Credit:</Text>$
                {batteryDisposalOrder?.estimatedCredit}
              </Text>
              <Divider />
              <Button
                colorScheme="brand.primary"
                onClick={async () => {
                  await approveBatteryDisposalOrder({
                    vehicleId: existingVehicle?.id ?? "",
                    batteryId: existingBattery?.id ?? "",
                    batteryDisposalOrderId: batteryDisposalOrder?.id ?? "",
                  });
                  onClose();
                }}
              >
                Approve
              </Button>
              <Button
                onClick={onClose}
                colorScheme="brand.primary"
                variant={"outline"}
              >
                Cancel
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <PageHeader title={existingVehicle?.vin ?? "New Vehicle"}>
        {isEditMode && (
          <HStack justify={"flex-end"} width={"100%"}>
            <DeleteButton
              color="red.500"
              name={existingVehicle?.vin ?? ""}
              deleteKey={existingVehicle?.vin ?? ""}
              variant={"outline"}
              onDelete={async () => {
                await deleteVehicle({ id: existingVehicle?.id ?? "" });
                navigate("/vehicles");
              }}
            >
              Delete Vehicle
            </DeleteButton>
          </HStack>
        )}
      </PageHeader>
      <Stack p={5}>
        {!existingVehicle && (
          <Card overflow="hidden" variant="outline" position={"relative"}>
            <CardHeader>
              <Heading size={"md"}>Add with a VIN</Heading>
            </CardHeader>
            <CardBody>
              <FormikProvider value={vinFormik}>
                <Form>
                  <HStack display={"flex"}>
                    <FormControl
                      isInvalid={
                        !!vinFormik?.errors?.vin === true &&
                        !!vinFormik?.touched?.vin === true
                      }
                      isDisabled={existingVehicle != null}
                    >
                      <FormLabel>VIN/SN</FormLabel>
                      <Field
                        as={Input}
                        id="vin"
                        name={"vin"}
                        type="text"
                        placeholder="VIN#"
                        value={vinFormik?.values?.vin}
                      />
                      <FormHelperText>
                        Vehicle identification number or serial number.
                      </FormHelperText>

                      <FormErrorMessage>
                        {vinFormik?.errors?.vin?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      variant={"inverted"}
                      onClick={vinFormik.submitForm}
                      isDisabled={
                        existingVehicle !== undefined ||
                        !vinFormik.isValid ||
                        vinFormik?.touched?.vin === undefined
                      }
                    >
                      Decode VIN
                    </Button>
                  </HStack>
                </Form>
              </FormikProvider>
            </CardBody>
            <CardFooter />
          </Card>
        )}
        {existingVehicle && (
          <>
            {batteryDisposalOrder &&
              batteryDisposalOrder.status === "Requested" && (
                <Alert status="info" variant={"left-accent"}>
                  <AlertIcon />
                  <Stack spacing={0}>
                    <Text fontWeight={"600"}>
                      Battery disposal request has been submitted.
                    </Text>
                    <Text color={"fg.muted"} size={"sm"}>
                      Preparing for battery disposal.
                    </Text>
                  </Stack>
                </Alert>
              )}
            {batteryDisposalOrder &&
              batteryDisposalOrder.status === "ApprovalRequired" && (
                <Alert
                  status="warning"
                  variant={"left-accent"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <HStack>
                    <AlertIcon />
                    <Stack spacing={0}>
                      <Text fontWeight={"600"}>
                        Battery pack pick up needs your approval.
                      </Text>
                      <Text color={"fg.muted"} size={"sm"}>
                        Pending approval.
                      </Text>
                    </Stack>
                  </HStack>
                  <Button colorScheme="orange" onClick={onOpen}>
                    Approve
                  </Button>
                </Alert>
              )}
            {batteryDisposalOrder &&
              (batteryDisposalOrder.status === "Approved" ||
                batteryDisposalOrder.status === "PreparingForPickup" ||
                batteryDisposalOrder.status === "PickupCompleted" ||
                batteryDisposalOrder.status === "TransportingToRecycler" ||
                batteryDisposalOrder.status === "RecyclerArrived") && (
                <Alert
                  status="info"
                  variant={"left-accent"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <HStack>
                    <AlertIcon />
                    <Stack spacing={0}>
                      <Text fontWeight={"600"}>
                        Battery pack disposal is in progress.
                      </Text>
                      <Text color={"fg.muted"} size={"sm"}>
                        Updates will be provided as they are available.
                      </Text>
                    </Stack>
                  </HStack>
                </Alert>
              )}
            {batteryDisposalOrder &&
              batteryDisposalOrder.status === "DisposalCompleted" && (
                <Alert status="success" variant={"left-accent"}>
                  <AlertIcon />
                  <Stack spacing={0}>
                    <Text fontWeight={"600"}>
                      Battery disposal process completed.
                    </Text>
                    <Text color={"fg.muted"} size={"sm"}>
                      Completed disposal.
                    </Text>
                  </Stack>
                </Alert>
              )}

            <Card
              overflow="hidden"
              variant="outline"
              display={!isEditMode && !createdVehicle ? "none" : "initial"}
            >
              <CardBody>
                <HStack justify={"space-between"}>
                  <Stack>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Manufacturer:</span>{" "}
                      {existingVehicle.make}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Model:</span>{" "}
                      {existingVehicle.model}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Year:</span>{" "}
                      {existingVehicle.year}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Color:</span>{" "}
                      {existingVehicle.color}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>VIN:</span>{" "}
                      {existingVehicle.vin}
                    </Text>
                  </Stack>
                  <Stack>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Fleet Name:</span>{" "}
                      {organization?.name}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>Site:</span>{" "}
                      {existingVehicle.assignedToSite?.name}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>
                        Primary Site Leader:
                      </span>{" "}
                      {site?.primarySiteLeader?.name}
                    </Text>
                    <Text>
                      <span style={{ fontWeight: "bold" }}>
                        Secondary Site Leader:
                      </span>{" "}
                      {site?.secondarySiteLeader?.name}
                    </Text>
                  </Stack>
                </HStack>
              </CardBody>
            </Card>
            <Card
              overflow="hidden"
              variant="outline"
              display={!isEditMode && !createdVehicle ? "none" : "initial"}
            >
              <CardBody>
                <Tabs
                  orientation={isMobile ? "horizontal" : "vertical"}
                  width={"100%"}
                  variant={"solid-rounded"}
                >
                  <TabList width={isMobile ? "100%" : "35%"} mr={5}>
                    {tabs.map((tab, index) => {
                      return (
                        <Tab
                          key={index}
                          borderRadius={"sm"}
                          color={"brand.text"}
                          _selected={{ background: "gray.200" }}
                          isDisabled={tab.isDisabled}
                          justifyContent={"left"}
                        >
                          {tab.name}
                          {tab.isDisabled && <BiSolidLock />}
                        </Tab>
                      );
                    })}
                  </TabList>

                  <TabPanels>
                    {tabs.map((tab, index) => {
                      return <TabPanel p={0}>{tab.component}</TabPanel>;
                    })}
                  </TabPanels>
                </Tabs>
              </CardBody>
            </Card>
          </>
        )}
      </Stack>
    </Box>
  );
};
