import {
  Box,
  HStack,
  Card,
  CardBody,
  Text,
  Stack,
  Center,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Inspection } from "../../types";
import { DataTable } from "../../components/table";
import React from "react";
import { useDeleteInspectionTemplate } from "../../api/endpoints/inspection-templates";
import { Link, useNavigate } from "react-router-dom";
import { useInspections } from "../../api/endpoints/inspections";
import { PageHeader } from "../../components/page-header";
import { ReactComponent as InspectionIcon } from "../../icons/inspection.svg";

export const InspectionsListPage: React.FC = () => {
  const columnHelper = createColumnHelper<Inspection>();
  const navigate = useNavigate();

  const {
    data: inspections,
    isError: isInspectionsError,
    isLoading: isInsepctionsLoading,
  } = useInspections();

  const { mutate: deleteInspectionTemplate } = useDeleteInspectionTemplate();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.description, {
      id: "name",
      header: () => <span>Description</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <Link
              aria-label="edit inspection"
              to={`/my/inspections/${props.row.original.id}`}
              style={{ textDecoration: "underline" }}
            >
              Continue
            </Link>
          </HStack>
        );
      },
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="My Inspections"></PageHeader>

      <Stack p={5}>
        {inspections && inspections.length > 0 && (
          <Card variant={"outline"}>
            <CardBody>
              <DataTable columns={columns} data={inspections ?? []}></DataTable>
            </CardBody>
          </Card>
        )}
        {inspections && inspections.length === 0 && (
          <Card variant={"outline"}>
            <CardBody>
              <Center display={"flex"} flexDir={"column"} p={"5%"}>
                <InspectionIcon height={"50%"} width={"50%"} />
                <Text textAlign={"center"} mt={10}>
                  You don't have any inspections assigned right now. We'll send
                  you a notification when new inspections are assigned.
                </Text>
              </Center>
            </CardBody>
          </Card>
        )}
      </Stack>
    </Box>
  );
};
