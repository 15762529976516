import {
  Card,
  CardBody,
  Heading,
  CardFooter,
  Box,
  Stack,
  HStack,
  IconButton,
  CardHeader,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Evidence } from "../../../types";
import { EvidenceForm } from "./form";
import {
  useEvidence,
  useUpdateEvidence,
} from "../../../api/endpoints/evidence";
import { ArrowLeft } from "lucide-react";

export const EvidenceEditPage: React.FC = () => {
  const { id } = useParams();

  const formatDate = (input: string): string => {
    const date = new Date(input);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-based in JS
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const { data: evidence, isError, isLoading } = useEvidence(id ?? "");
  const {
    mutate: updateEvidence,
    isError: updateError,
    data: updateData,
  } = useUpdateEvidence();

  const navigate = useNavigate();

  useEffect(() => {
    let key: keyof Evidence;

    if (!evidence) return;

    for (key in evidence) {
      if (key === "collectedOn" || key === "expiresOn") {
        formik.setFieldValue(
          key,
          formatDate(evidence[key]?.toString() ?? ""),
          true
        );
      } else {
        formik.setFieldValue(key, evidence[key], true);
      }
    }
  }, [evidence]);

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Evidence name is required."),
    description: Yup.string()
      .min(3)
      .required("Evidence description is required."),
    collectedOn: Yup.date()
      .typeError("Valid Collection Date is required.")
      .required("Collection Date is required."),
    content: Yup.string().when(["files", "pendingFiles"], {
      is: (files: any[], pendingFiles: any[]) =>
        files !== null &&
        files.length == 0 &&
        pendingFiles !== null &&
        pendingFiles.length == 0,
      then: (schema) =>
        schema.required(
          "Either content must be entered here or file(s) must be uploaded."
        ),
    }),
    expiresOn: Yup.date()
      .typeError("Valid Expiration Date is required.")
      .required("Expiration Date is required."),
    // files: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: "",
      collectedOn: "",
      expiresOn: "",
      content: "",
      files: [],
      pendingFiles: [],
    },
    validationSchema: schema,
    onSubmit: async (values: any) => {
      const formData = new FormData();
      for (var file of values.pendingFiles) {
        formData.append("files", file, file.name);
      }

      formData.append("evidence", JSON.stringify(values));

      await updateEvidence({
        id: evidence?.id ?? "",
        evidence: values,
        formData: formData,
      });

      if (!updateError) {
        toast("Successfully updated evidence.");
        formik.setFieldValue("pendingFiles", []);
        formik.values.pendingFiles = [];
      } else {
        toast.error("Error updating evidence.");
      }
    },
  });

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardHeader>
            <HStack>
              <IconButton
                aria-label="edit evidence"
                icon={<ArrowLeft />}
                style={{ background: "transparent" }}
                onClick={() => navigate("/doc-library/evidence")}
                color={"brand.accent"}
              />
              <Heading variant={"md"}>Edit Evidence</Heading>
            </HStack>
          </CardHeader>
          <CardBody>
            <EvidenceForm formik={formik} evidence={evidence} />
          </CardBody>
          <CardFooter />
        </Card>
      </Stack>
    </Box>
  );
};
