import { Box, Heading, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import React from "react";

import {
  useInspectionTemplate,
  useUpdateInspectionTemplate,
} from "../../../api/endpoints/inspection-templates";
import _ from "lodash";
import { initPassFailQuestionType } from "./passFailQuestion";
import "survey-creator-core/survey-creator-core.min.css";
import "./inspection-templates-edit.css";

import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { PageHeader } from "../../../components/page-header";
import { toast } from "react-toastify";

export const InspectionTemplatesEditPage: React.FC = () => {
  const [definition, setDefinition] = useState<any>();

  const [creator, setCreator] = useState<SurveyCreator>();

  const { id } = useParams();

  const {
    mutate: updateInspectionTemplate,
    isError: updateInspectionTemplateError,
    data: updateInspectionTemplateData,
  } = useUpdateInspectionTemplate();

  const { data, isError, isLoading } = useInspectionTemplate(id ?? "");

  useEffect(() => {
    if (data) {
      let definition: any = undefined;
      console.log(definition);
      if (data.form && data.form !== "null") {
        data.form.name = data.name;
        data.form.description = data.description;
        definition = data.form;
      } else {
        definition = {
          name: data.name,
          description: data.description,
        };
      }

      setDefinition(definition);
    }
  }, [data]);

  useEffect(() => {
    initPassFailQuestionType();

    const creatorOptions = {
      showLogicTab: false,
      showDesignerTab: true,
      showJSONEditorTab: false,
      isAutoSave: false,
      styleProps: {},
    };

    const creator = new SurveyCreator(creatorOptions);
    creator.saveSurveyFunc = async (saveNo: number, callback: any) => {
      await updateInspectionTemplate({
        id: id ?? "",
        body: {
          form: creator.JSON,
        },
      });

      callback(saveNo, true);
    };

    setCreator(creator);
  }, []);

  useEffect(() => {
    if (creator && definition) {
      creator.JSON = definition;
    }
  }, [creator, definition]);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Stack p={5}>
        {definition && data && creator && (
          <SurveyCreatorComponent
            style={{ height: "80vh", background: "red" }}
            creator={creator}
          />
        )}
      </Stack>
    </Box>
  );
};
