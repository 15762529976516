import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useOrganization } from "@clerk/clerk-react";
import {
  useAttachCurriculumToResource,
  useCurricula,
  useDetachCurriculumFromResource,
} from "../../api/endpoints/courses";
import { Select } from "chakra-react-select";
import { useTeams } from "../../api/endpoints/teams";
import { useSites } from "../../api/endpoints/sites";
import type { OrganizationMembershipResource } from "@clerk/types";
import { useEffect } from "react";

const findAddedAndRemovedObjects = (
  originalArray: any[],
  newArray: any[],
  keySelector: (item: any) => any
): { added: any[]; removed: any[] } => {
  const originalKeys = originalArray.map(keySelector);
  const newKeys = newArray.map(keySelector);

  const added = newArray.filter(
    (item) => !originalKeys.includes(keySelector(item))
  );
  const removed = originalArray.filter(
    (item) => !newKeys.includes(keySelector(item))
  );

  return { added, removed };
};

export const CourseManagementTab: React.FC = () => {
  const { data, isError, isLoading } = useCurricula();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const {
    data: teams,
    isError: teamsIsError,
    isLoading: teamsIsLoading,
  } = useTeams();

  const {
    data: sites,
    isError: sitesIsError,
    isLoading: sitesIsLoading,
  } = useSites();

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const { mutate: attachCurriculumToResource } =
    useAttachCurriculumToResource();
  const { mutate: detachCurriculumFromResource } =
    useDetachCurriculumFromResource();

  const updateCurriculumAssignments = (
    curriculumId: string,
    values: { name: string; id: string }[]
  ) => {
    const existingCurriculumData = data?.find((x) => x.id === curriculumId);
    const { added, removed } = findAddedAndRemovedObjects(
      existingCurriculumData?.appliesTo ?? [],
      values.map((x: any) => {
        return { id: x.value, name: x.value };
      }),
      (item) => item.id
    );

    added.map(async (x) => {
      await attachCurriculumToResource({
        curriculumId: curriculumId ?? "",
        resourceId: x.id,
      });
    });

    removed.map(async (x) => {
      await detachCurriculumFromResource({
        curriculumId: curriculumId ?? "",
        resourceId: x.id,
      });
    });
  };

  const appliesToOptions: readonly any[] = [
    {
      label: "Teams",
      options: teams?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Sites",
      options: sites?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];
  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Whoops!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center p={10}>
              <Text>
                This feature is currently in private beta. Please contact
                support@axlemobility.com for assistance.
              </Text>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Card variant={"outline"} m={5}>
        <CardBody>
          <HStack justifyContent={"space-between"} mx={5}>
            <Stack my={{ base: "4", md: "8" }}>
              <Heading as={"h3"} size={"lg"}>
                Available Training Plans
              </Heading>
              <Text>
                Below is a list of available training plans for your
                organization.
              </Text>
            </Stack>

            <Button
              variant="solid"
              colorScheme="brand.primary"
              px={6}
              onClick={onToggle}
            >
              Add New Training Plan
            </Button>
          </HStack>
          <Stack flexDir={"column"} display={"flex"} gap={5}>
            {data?.map((curriculum) => (
              <Card variant="outline" width={"100%"}>
                <CardBody>
                  <Stack direction={"row"} justify={"space-between"}>
                    <Stack>
                      <Heading as={"h4"} size={"md"}>
                        {curriculum.name}
                      </Heading>
                      <Text fontSize={"md"} mt={2}>
                        Courses Included:
                      </Text>
                    </Stack>
                    <Stack>
                      <Text color="fg.muted">Assigned To</Text>
                      <Select
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 100,
                          }),
                        }}
                        isMulti
                        options={appliesToOptions}
                        id="appliesTo"
                        menuPortalTarget={document.body}
                        value={curriculum?.appliesTo?.map((x) => {
                          return { value: x.id, label: x.name };
                        })}
                        name="appliesTo"
                        closeMenuOnSelect={true}
                        onChange={(e: any) => {
                          updateCurriculumAssignments(curriculum.id, e ?? []);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {curriculum?.courses?.map((course, index) => (
                    <Stack key={course.id} fontSize="sm" p="4" spacing="4">
                      <Stack
                        direction="row"
                        justify="space-between"
                        spacing="4"
                      >
                        <HStack spacing="3">
                          <Box>
                            <Text fontWeight="medium" color="fg.emphasized">
                              {index + 1}. {course.name}
                            </Text>
                          </Box>
                        </HStack>
                      </Stack>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                Course Description
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text color="fg.muted" whiteSpace={"pre-wrap"}>
                              {course.description}
                            </Text>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                  ))}
                </CardBody>
              </Card>
            ))}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
