import { useAuth } from "@clerk/clerk-react";

interface State<T> {
  error?: unknown;
  loading: boolean;
  data?: T | undefined;
  status: number;
}

export const useApi = () => {
  const { getToken } = useAuth();

  async function http<T>(
    path: string,
    config: RequestInit,
    secure: boolean = true,
    isFormData: boolean = false,
  ): Promise<State<T>> {
    const accessToken = secure
      ? await getToken({ template: "AxleMobility" })
      : "";

    if (secure) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    if (config.method === "patch") {
      config = {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "application/json-patch+json",
        },
      };
    }

    if ((config.method === "post" || config.method === "put") && !isFormData) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "application/json",
        },
      };
    }


    const request = new Request(path, config);
    const response = await fetch(request);
    if (!response.ok) {
      return {
        loading: false,
        error: await response.text(),
        status: response.status,
      };
    }

    let data = undefined;

    try {
      data = await response.json();
    } catch {
      //TODO
    }

    return {
      status: response.status,
      data: (data as T) ?? undefined,
      loading: false,
    };
  }

  const get = async <T>(
    path: string,
    secure?: boolean,
    config?: RequestInit,
    isFormData?: boolean
  ): Promise<State<T>> => {
    const init = { method: "get", ...config };
    return await http<T>(path, init, secure, isFormData);
  };

  const _delete = async <T>(
    path: string,
    secure?: boolean,
    config?: RequestInit,
    isFormData?: boolean
  ): Promise<State<T>> => {
    const init = { method: "delete", ...config };
    return await http<T>(path, init, secure, isFormData);
  };

  const post = async <T, U>(
    path: string,
    body: T,
    secure?: boolean,
    config?: RequestInit,
    isFormData?: boolean
  ): Promise<State<U>> => {
    const init: any = {
      method: "post",
      body: body,
      ...config
    };
    return await http<U>(path, init, secure, isFormData);
  };

  const put = async <T, U>(
    path: string,
    body: T,
    secure?: boolean,
    config?: RequestInit,
    isFormData?: boolean
  ): Promise<State<U>> => {
    const init: any = {
      method: "put",
      body: body,
      ...config,
    };
    return await http<U>(path, init, secure, isFormData);
  };

  const patch = async <T, U>(
    path: string,
    body: T,
    secure?: boolean,
    config?: RequestInit,
    isFormData?: boolean
  ): Promise<State<U>> => {
    const init: any = {
      method: "PATCH",
      body: body,
      ...config,
    };

    return await http<U>(path, init, secure);
  };

  return {
    get,
    post,
    put,
    patch,
    _delete
  };
};