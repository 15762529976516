import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Button,
  Text,
  FormControl,
  Input,
  FormLabel,
  VStack,
  Tooltip,
  Stack,
  CardHeader,
  Divider,
  Grid,
  GridItem,
  Tag,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/page-header";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import { Select } from "chakra-react-select";
import { BiPlus } from "react-icons/bi";
import { ArrowLeft, XIcon } from "lucide-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCreateGlovesOrder } from "../../api/endpoints/gloves";
import { useSites } from "../../api/endpoints/sites";

export const GloveManagementOrderPage: React.FC = () => {
  const navigate = useNavigate();
  const { pricePerGlovePair } = useFlags();
  const { mutate: createGlovesOrder } = useCreateGlovesOrder();
  const { data: sites } = useSites();

  const formik = useFormik({
    initialValues: {
      orderLines: [{ size: 8, quantity: 1, color: "black" }],
      shipToSiteId: "",
    },
    onSubmit: async (values: any) => {
      await createGlovesOrder(values);
      navigate("/tools/glove-management/orders");
    },
  });

  const gloveSizeOptions = [
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];

  const gloveColorOptions = [
    { label: "Red", value: "red" },
    { label: "Black", value: "black" },
  ];

  const glovePairQuantity = formik.values.orderLines.reduce(
    (acc: number, item: any) => acc + item.quantity,
    0
  );

  const siteOptions = sites?.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Glove Tracker"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardHeader>
            <HStack justifyContent={"space-between"}>
              <HStack>
                <IconButton
                  aria-label="go back"
                  icon={<ArrowLeft />}
                  style={{ background: "transparent" }}
                  onClick={() => navigate("/tools/glove-management/orders")}
                  color={"brand.accent"}
                />
                <Heading variant={"md"}>Order New Glove Kits</Heading>
              </HStack>
            </HStack>
            <Text variant={"sm"}>
              Each kit comes with Class 0 Rubber Gloves, Leather Protectors, and
              a carrying case.
            </Text>
          </CardHeader>
          <Divider width={"95%"} alignSelf={"center"} />
          <CardBody>
            <Grid templateColumns="3fr 1fr" gap={6} height={"100%"}>
              <GridItem w="100%" height={"100%"}>
                <FormikProvider value={formik}>
                  <Form style={{ width: "100%" }}>
                    <FieldArray
                      name="orderLines"
                      render={(arrayHelpers: FieldArrayRenderProps) => (
                        <Box width={"100%"}>
                          <VStack spacing={5} gap={5} width={"100%"}>
                            {formik.values?.orderLines.map(
                              (glove: any, index: number) => (
                                <Card
                                  variant={"outline"}
                                  width={"100%"}
                                  bg={"brand.gray.100"}
                                >
                                  <CardBody width={"100%"}>
                                    <HStack
                                      justify={"space-between"}
                                      width={"100%"}
                                    >
                                      <FormControl>
                                        <FormLabel>Size</FormLabel>
                                        <Select
                                          options={gloveSizeOptions}
                                          placeholder={"Size"}
                                          name={`orderLines.${index}.size`}
                                          onChange={(e: any) => {
                                            formik.setFieldValue(
                                              `orderLines.${index}.size`,
                                              e.value
                                            );
                                          }}
                                          value={gloveSizeOptions.find(
                                            (x) =>
                                              x.value ===
                                              formik.values.orderLines[index]
                                                .size
                                          )}
                                          closeMenuOnSelect={true}
                                          styles={{
                                            menuPortal: (provided) => ({
                                              ...provided,
                                              zIndex: 100,
                                            }),
                                          }}
                                          chakraStyles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: "brand.surface",
                                            }),
                                            dropdownIndicator: (provided) => ({
                                              ...provided,
                                              backgroundColor: "brand.surface",
                                              border: "none",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                              ...provided,
                                              borderColor: "brand.surface",
                                            }),
                                          }}
                                          menuPortalTarget={document.body}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <FormLabel>Color</FormLabel>
                                        <Select
                                          options={gloveColorOptions}
                                          placeholder={"Color"}
                                          name={`data.${index}.color`}
                                          onChange={(e: any) => {
                                            formik.setFieldValue(
                                              `data.${index}.color`,
                                              e.value
                                            );
                                          }}
                                          value={gloveColorOptions.find(
                                            (x) =>
                                              x.value ===
                                              formik.values.orderLines[index]
                                                .color
                                          )}
                                          closeMenuOnSelect={true}
                                          styles={{
                                            menuPortal: (provided) => ({
                                              ...provided,
                                              zIndex: 100,
                                            }),
                                          }}
                                          chakraStyles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              backgroundColor: "brand.surface",
                                            }),
                                            dropdownIndicator: (provided) => ({
                                              ...provided,
                                              backgroundColor: "brand.surface",
                                              border: "none",
                                            }),
                                            indicatorSeparator: (provided) => ({
                                              ...provided,
                                              borderColor: "brand.surface",
                                            }),
                                          }}
                                          menuPortalTarget={document.body}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <FormLabel>
                                          Number of glove pairs
                                        </FormLabel>
                                        <Input
                                          placeholder="Quantity"
                                          size={"lg"}
                                          name={`data.${index}.quantity`}
                                          type="number"
                                          value={
                                            formik.values.orderLines[index]
                                              .quantity
                                          }
                                          onChange={formik.handleChange}
                                          backgroundColor={"white"}
                                        />
                                      </FormControl>
                                      <IconButton
                                        alignSelf={"end"}
                                        bg={"white"}
                                        aria-label="Remove Order Line"
                                        icon={<XIcon />}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      />
                                    </HStack>
                                  </CardBody>
                                </Card>
                              )
                            )}
                          </VStack>
                          <Button
                            leftIcon={<BiPlus />}
                            variant={"outline"}
                            bg={"white"}
                            mt={2}
                            onClick={() => {
                              arrayHelpers.push({
                                size: 8,
                                quantity: 1,
                                color: "black",
                              });
                            }}
                          >
                            Add More Glove Kits
                          </Button>
                        </Box>
                      )}
                    />
                  </Form>
                </FormikProvider>
              </GridItem>
              <GridItem w="100%" height={"100%"}>
                <HStack height={"100%"}>
                  <Divider orientation={"vertical"} mr={2} />
                  <Stack>
                    <Heading as={"h3"} fontWeight={"bold"} size={"md"}>
                      Cart ({glovePairQuantity})
                    </Heading>
                    <VStack width={"100%"} my={5}>
                      {formik.values.orderLines.map(
                        (glove: any, index: number) => (
                          <Card variant={"outline"} width={"100%"} key={index}>
                            <CardBody>
                              <HStack>
                                <Box width={"60%"}>
                                  <Tag mx={0.5} size={"sm"}>
                                    QTY: {glove.quantity}
                                  </Tag>
                                  <Tag mx={0.5} size={"sm"}>
                                    SIZE: {glove.size}
                                  </Tag>
                                  <Tag mx={0.5} size={"sm"}>
                                    COLOR: {glove.color.toUpperCase()}
                                  </Tag>
                                </Box>
                                <Text variant={"md"} mt={2}>
                                  Glove Kit
                                </Text>
                              </HStack>
                            </CardBody>
                          </Card>
                        )
                      )}
                    </VStack>
                    <Heading as={"h3"} fontWeight={"bold"} size={"md"}>
                      Total Price: ${pricePerGlovePair * glovePairQuantity} USD
                    </Heading>
                    <Select
                      options={siteOptions}
                      placeholder={"Ship To Site"}
                      closeMenuOnSelect={true}
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      onChange={(e: any) => {
                        formik.setFieldValue("shipToSiteId", e.value);
                      }}
                      chakraStyles={{
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: "brand.surface",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          backgroundColor: "brand.surface",
                          border: "none",
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          borderColor: "brand.surface",
                        }),
                      }}
                    />
                    <Button
                      variant="inverted"
                      mt={2}
                      onClick={formik.submitForm}
                    >
                      Place Order
                    </Button>
                  </Stack>
                </HStack>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
