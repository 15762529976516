import { Avatar, AvatarGroup } from "@chakra-ui/avatar";
import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import {
  StackProps,
  Stack,
  Heading,
  HStack,
  Text,
  Flex,
  Box,
  Center,
} from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { SignIn, SignUp } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const SignUpPage: React.FC = () => {
  return (
    <SignUp
      appearance={{
        elements: {
          socialButtonsBlockButton: {
            background: "#fff",
          },
          formButtonPrimary: {
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#204B36",
            "&:hover, &:focus, &:active": {
              backgroundColor: "#204B36",
            },
          },
          footerActionLink: {
            color: "#204B36",
          },
          card: {
            boxShadow: "none",
            background: "none",
          },
          cardBox: {
            boxShadow: "none",
            background: "none",
          },
        },
      }}
    />
  );
};
