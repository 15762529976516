export interface CarbComplianceReport {
  vehicles: VehicleComplianceDescriptor[];
  organization: OrganizationComplianceDescriptor;
}

export interface VehicleComplianceDescriptor {
  vin: string;
  vehicleId: string;
  complianceResults: ComplianceResult[];
  applicableRules: string[];
}

export interface OrganizationComplianceDescriptor {
  organizationId: string;
  complianceResults: ComplianceResult[];
}

export interface ComplianceResult {
  ruleName: string | null;
  isCompliant: boolean;
  details: string | null;
  requiredActions: string[];
}

export interface AdvancedCleanFleetBreakdownResponse {
  yearlyBreakdowns: YearBreakdown[];
}

export interface YearBreakdown {
  year: number;
  group1: GroupBreakdown;
  group2: GroupBreakdown;
  group3: GroupBreakdown;
  totalRequiredZEVs: number;
  totalCurrentZEVs: number;
  totalNonZEVs: number;
}

export interface GroupBreakdown {
  requiredZEVs: number;
  currentZEVs: number;
  nonZEVs: number;
}


export type SystemOfRecord = {
  name: string;
  description: string;
  url: string;
  lastRegisteredAt: string | null;
}

export type ComplianceData = {
  fees: ComplianceFee[];
  inspections: ComplianceInspection[];
  violations: ComplianceViolation[];
  emissionsRecords: EmissionsRecord[];
  systemsOfRecord: SystemOfRecord[];
  tasks: ComplianceTask[];
}

export type ComplianceTask = {
  id: string;
  ruleName: string;
  description: string;
  dueDate: Date;
  completedDate?: Date;
}

export type ComplianceFee = {
  id: string;
  ruleName: string;
  amount: number;
  dueDate: string;
  paidDate: string;
  description: string;
}

export type ComplianceInspection = {
  id: string;
  ruleName: string;
  dueDate: string | null;
  inspectorName: string;
  notes: string;
  isCompleted: boolean;
  attempts: ComplianceInspectionAttempt[];
}

export type ComplianceInspectionAttempt = {
  id: string;
  attemptDate: string;
  status: ComplianceInspectionStatus;
  notes: string;
}

export enum ComplianceInspectionStatus {
  Pending = "Pending",
  Completed = "Completed",
  Failed = "Failed"
}

export type ComplianceViolation = {
  id: string;
  ruleName: string;
  violationDate: string;
  description: string;
  fine: number;
  resolutionDate?: string;
}

export type EmissionsRecord = {
  id: string;
  recordDate: string;
  coEmissions: number;
  nOxEmissions: number;
  pmEmissions: number;
  coMeasurementUnit: string;
  nOxMeasurementUnit: string;
  pmMeasurementUnit: string;
  notes: string;
  passed: boolean;
}