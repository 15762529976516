import {
  Box,
  Button,
  Center,
  Image,
  ScaleFade,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoAddCircle, IoClose } from "react-icons/io5";

const FileUploadButton = ({
  onUpdateFile,
}: {
  onUpdateFile: (file: File) => void;
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    //@ts-ignore
    setUploadedFile(file);
    onUpdateFile(file);
  };

  return (
    <Box
      as={chakra.label}
      htmlFor="file"
      bg="whiteAlpha.500"
      cursor="pointer"
      overflow="hidden"
      width={"100%"}
      position="relative"
    >
      <Button
        variant="inverted"
        size="lg"
        width={"100%"}
        p={5}
        rightIcon={uploadedFile ? <IoClose /> : <IoAddCircle />}
        onClick={() => document.getElementById("file")?.click()}
      >
        {
          //@ts-ignore
          uploadedFile ? uploadedFile.name : "Browse For File"
        }
      </Button>

      <chakra.input
        required
        style={{ display: "none" }}
        type="file"
        id="file"
        name="file"
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default FileUploadButton;
