import {
  KeyboardSensor,
  MouseSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  Card,
  CardBody,
  Input,
  Stack,
  Text,
  Badge,
  HStack,
  VStack,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import { DndContext, rectIntersection } from "@dnd-kit/core";
import { useState } from "react";
import { Calendar } from "lucide-react";
import { Site } from ".";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

interface KanbanLaneProps {
  title: string;
  id: string;
  items: Site[];
  onCardClick: (site: Site) => void;
}
const KanbanCard = ({
  site,
  index,
  parent,
  onCardClick,
}: {
  site: Site;
  index: number;
  parent: string;
  onCardClick: (site: Site) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: site.id,
    data: {
      ...site,
      index,
      parent,
    },
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  const getConstructionStatusColor = (status: string) => {
    switch (status) {
      case "onSchedule":
        return "green";
      case "atRisk":
        return "yellow";
      case "behindSchedule":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <Card
      variant={"outline"}
      transform={style.transform}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      mb="2"
      onClick={() => {
        onCardClick(site);
      }}
    >
      <CardBody>
        <VStack align="stretch" spacing={0}>
          <HStack justify="space-between">
            <Text fontWeight="bold">{site.name}</Text>
            <Badge
              colorScheme={getConstructionStatusColor(site.constructionStatus)}
            >
              {site.constructionStatus === "onSchedule"
                ? "On Schedule"
                : site.constructionStatus === "atRisk"
                ? "At Risk"
                : "Behind Schedule"}
            </Badge>
          </HStack>
          <Text fontSize="xs" color={"gray.400"}>
            {site.address}
          </Text>

          <HStack justify="space-between" mt={5}>
            <Text fontSize="xs">
              Type:{" "}
              {site.type === "heavyDuty"
                ? "Heavy Duty"
                : site.type === "mediumDuty"
                ? "Medium Duty"
                : "Light Duty"}
            </Text>
            <HStack>
              <Calendar size={14} />
              <Text fontSize="xs">
                Grand Opening:{" "}
                {new Date(site.grandOpeningDate).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </Text>
            </HStack>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

function KanbanLane({ title, id, items, onCardClick }: KanbanLaneProps) {
  const { setNodeRef } = useDroppable({
    id: id,
  });
  return (
    <Box width="33%" minWidth="33%" flexGrow={1} height={"100%"}>
      <Text fontWeight="bold">{title}</Text>
      <Card
        ref={setNodeRef}
        backgroundColor="gray.200"
        borderRadius="8"
        flex="1"
        padding="2"
        flexDirection="column"
        height="100%"
      >
        <CardBody p={0}>
          {items.map((item, key) => (
            <KanbanCard
              site={item}
              key={item.id}
              index={key}
              parent={title}
              onCardClick={onCardClick}
            />
          ))}
        </CardBody>
      </Card>
    </Box>
  );
}

export default function KanbanBoard({
  sites,
  onChange,
}: {
  sites: Site[];
  onChange: (sites: Site[]) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);
  console.log(sites);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Site Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Site Name</FormLabel>
              <Input
                placeholder="Enter site name"
                value={selectedSite?.name ?? ""}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Address</FormLabel>
              <Textarea
                value={selectedSite?.address}
                placeholder="Enter site location"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Due Date</FormLabel>
              <Input type="date" value={selectedSite?.grandOpeningDate} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Construction Status</FormLabel>
              <Input
                value={
                  selectedSite?.constructionStatus === "atRisk"
                    ? "At Risk"
                    : selectedSite?.constructionStatus === "onSchedule"
                    ? "On Schedule"
                    : selectedSite?.constructionStatus === "behindSchedule"
                    ? "Behind Schedule"
                    : selectedSite?.constructionStatus || ""
                }
                placeholder="Enter construction status"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Type</FormLabel>
              <Input
                value={
                  selectedSite?.type === "lightDuty"
                    ? "Light Duty"
                    : selectedSite?.type === "mediumDuty"
                    ? "Medium Duty"
                    : selectedSite?.type === "heavyDuty"
                    ? "Heavy Duty"
                    : selectedSite?.type || ""
                }
                placeholder="Enter type"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Construction Schedule</FormLabel>
              {selectedSite && selectedSite.constructionTasks.length > 0 && (
                <Gantt
                  tasks={selectedSite.constructionTasks}
                  viewMode={ViewMode.Month}
                />
              )}
              {selectedSite && selectedSite.constructionTasks.length === 0 && (
                <Text>Project completed!</Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose} colorScheme="red">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={(e) => {
          const container = e.over?.id;

          const newSites = [...sites];
          const siteIndex = newSites.findIndex(
            (site) => site.id === e.active.id
          );
          if (siteIndex !== -1) {
            newSites[siteIndex].constructionPhase = container as
              | "planned"
              | "inDesign"
              | "inPermitting"
              | "underConstruction"
              | "openToPublic";
          }
          onChange(newSites);
        }}
      >
        <Box mt={20} height={"100%"}>
          <Stack direction={"row"} gap={4} m={4} height={"100%"}>
            <KanbanLane
              title="Planned"
              id="planned"
              items={sites.filter(
                (site) => site.constructionPhase === "planned"
              )}
              onCardClick={(site) => {
                setSelectedSite(site);
                onOpen();
              }}
            />
            <KanbanLane
              title="In Design"
              id="inDesign"
              items={sites.filter(
                (site) => site.constructionPhase === "inDesign"
              )}
              onCardClick={(site) => {
                setSelectedSite(site);
                onOpen();
              }}
            />
            <KanbanLane
              title="In Permitting"
              id="inPermitting"
              items={sites.filter(
                (site) => site.constructionPhase === "inPermitting"
              )}
              onCardClick={(site) => {
                setSelectedSite(site);
                onOpen();
              }}
            />
            <KanbanLane
              title="Under Construction"
              id="underConstruction"
              items={sites.filter(
                (site) => site.constructionPhase === "underConstruction"
              )}
              onCardClick={(site) => {
                setSelectedSite(site);
                onOpen();
              }}
            />
            <KanbanLane
              title="Open to Public"
              id="openToPublic"
              items={sites.filter(
                (site) => site.constructionPhase === "openToPublic"
              )}
              onCardClick={(site) => {
                setSelectedSite(site);
                onOpen();
              }}
            />
          </Stack>
        </Box>
      </DndContext>
    </>
  );
}
