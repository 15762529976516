import { useAuth } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";

export const HomePage: React.FC = () => {
  const { isSignedIn, sessionId, userId, getToken } = useAuth();
  const [token, setToken] = useState<string | null>("");

  const getTokenAsync = async () => {
    const fetchedToken = await getToken({ template: "AxleMobility" });

    setToken(fetchedToken);
  };

  useEffect(() => {
    getTokenAsync();
  }, []);

  return <>{token}</>;
};
