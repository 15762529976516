import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Card,
  CardBody,
  Stack,
  Icon,
  Grid,
  GridItem,
  Tag,
  Badge,
} from "@chakra-ui/react";
import { PageHeader } from "../../components/page-header";
import Map, { Marker, Popup, useMap } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { SettingsContext } from "../..";
import { ArrowRight, BatteryCharging } from "lucide-react";
import "@caldwell619/react-kanban/dist/styles.css"; // import here for "builtin" styles
import KanbanBoard from "./kanban";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/table";
import { ReactComponent as CCS } from "../../icons/ccs.svg";
import { ReactComponent as NACS } from "../../icons/nacs.svg";
import { FaChargingStation } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { useFlags } from "launchdarkly-react-client-sdk";

type Location = {
  longitude: number;
  latitude: number;
};

type Milestone = {
  id: number;
  title: string;
  dueDate: string;
  status: string;
};

type ConstructionPhase = {
  startDate: string;
  endDate: string;
  milestones: Milestone[];
};

type Charger = {
  id: number;
  name: string;
  location: Location;
  type: string;
  status: "Available" | "In Use" | "Out of Service";
};

export type Site = {
  id: number;
  connectorTypes: string[];
  constructionStatus: "onSchedule" | "atRisk" | "behindSchedule";
  name: string;
  type: "heavyDuty" | "mediumDuty" | "lightDuty";
  address: string;
  location: Location;
  cpoPartner: string;
  currentLivePower: string;
  connectorsInUse: number;
  constructionPhase:
    | "planned"
    | "inDesign"
    | "inPermitting"
    | "underConstruction"
    | "openToPublic";
  constructionTasks: {
    start: Date;
    end: Date;
    title: string;
    id: string;
    type: any;
    name: string;
    progress: number;
  }[];
  grandOpeningDate: string;
  chargers: Charger[];
};

const EVSEManagementPage: React.FC = () => {
  const [view, setView] = useState<"map" | "project">("map");
  const { mapBoxAccessToken } = useContext(SettingsContext);
  const { demoEvseSites } = useFlags();
  const [currentSites, setCurrentSites] = useState<Site[]>([]);
  const [viewState, setViewState] = React.useState({
    longitude: -98.5795,
    latitude: 39.8283,
    zoom: 3,
  });

  const [selectedCharger, setSelectedCharger] = useState<number | null>(null);
  const columnHelper = createColumnHelper<Site>();
  const { evseMap } = useMap();

  useEffect(() => {
    if (demoEvseSites) {
      setCurrentSites(
        demoEvseSites.map((site: any) => ({
          ...site,
          constructionTasks: site.constructionTasks.map((task: any) => ({
            ...task,
            start: new Date(task.start),
            end: new Date(task.end),
          })),
        }))
      );
    }
  }, [demoEvseSites]);

  const columns = [
    columnHelper.accessor((row) => `${row.name} ${row.address}`, {
      id: "name-address",
      cell: ({ row: { original } }) => (
        <Box p={0}>
          <Stack p={0} spacing={3}>
            <Stack spacing={0}>
              <HStack justify={"space-between"}>
                <Heading>{original.name}</Heading>
                {original.constructionPhase === "openToPublic" &&
                  (original.chargers.some(
                    (charger) => charger.status === "Out of Service"
                  ) ? (
                    <Tag colorScheme="orange" size={"sm"}>
                      Issued Detected
                    </Tag>
                  ) : (
                    <Tag colorScheme={"green"} size={"sm"}>
                      OK
                    </Tag>
                  ))}
              </HStack>
              <Text variant={"xs"} fontWeight={"light"} color={"gray.500"}>
                {original.address}
              </Text>
            </Stack>
            {original.constructionPhase !== "openToPublic" && (
              <Badge
                colorScheme={"orange"}
                alignSelf={"justify"}
                alignContent={"center"}
                sx={{
                  background:
                    "repeating-linear-gradient(45deg, #FFD180, #FFD180 10px, #FFF8E1 10px, #FFF8E1 20px)",
                  color: "#333333",
                  fontWeight: "bold",
                }}
              >
                {original.constructionPhase === "planned"
                  ? "Planned"
                  : original.constructionPhase === "inDesign"
                  ? "In Design"
                  : original.constructionPhase === "inPermitting"
                  ? "In Permitting"
                  : original.constructionPhase === "underConstruction"
                  ? "Under Construction"
                  : "Open to Public"}
              </Badge>
            )}
            <HStack justify={"space-between"}>
              <VStack gap={0}>
                <Text fontWeight={"700"}>{original.currentLivePower}</Text>
                <Text variant={"xs"} fontWeight={"light"} color={"gray.500"}>
                  Live Power
                </Text>
              </VStack>
              <VStack gap={0}>
                <Text fontWeight={"700"}>{original.connectorsInUse}</Text>
                <Text variant={"xs"} fontWeight={"light"} color={"gray.500"}>
                  Connectors In Use
                </Text>
              </VStack>
              <VStack gap={0}>
                <Text fontWeight={"700"}>{original.chargers.length}</Text>
                <Text variant={"xs"} fontWeight={"light"} color={"gray.500"}>
                  Total Chargers
                </Text>
              </VStack>
            </HStack>
            <HStack justify={"space-between"}>
              <HStack p={0} spacing={4}>
                <Text fontSize={9}>Connector Types:</Text>
                <HStack>
                  {original.connectorTypes.map((type) => (
                    <Stack align={"center"} spacing={0}>
                      {type === "CCS1" ? (
                        <CCS width={24} height={24} />
                      ) : (
                        <NACS width={24} height={24} />
                      )}
                      <Text fontSize={8}>{type}</Text>
                    </Stack>
                  ))}
                </HStack>
              </HStack>
              <HStack p={0} spacing={1}>
                <Text fontSize={9}>CPO Partner:</Text>
                <Text fontSize={10} fontWeight={"bold"}>
                  {original.cpoPartner}
                </Text>
              </HStack>
            </HStack>
            <Button
              variant={"outline"}
              size={"xs"}
              rightIcon={<ArrowRight size={16} />}
            >
              View Site
            </Button>
          </Stack>
        </Box>
      ),
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="EVSE Management"></PageHeader>
      <Card variant={"outline"} m={5} mb={10} height={"100%"} width={"100%"}>
        <CardBody p={0} position="relative" overflowY={"scroll"}>
          <Button
            position="absolute"
            top={4}
            left={4}
            zIndex={1000}
            colorScheme="blue"
            variant={"inverted"}
            onClick={() => {
              // Add logic to switch to another view
              view === "map" ? setView("project") : setView("map");
            }}
          >
            {view === "map" ? "Project View" : "Map View"}
          </Button>
          {view === "map" ? (
            <Grid h="100%" templateColumns="repeat(6, 1fr)" gap={0}>
              <GridItem colSpan={2} p={3} pt={"15%"}>
                <DataTable
                  columns={columns}
                  data={currentSites}
                  suppressHeaderRow={true}
                  suppressPaginationFooter={true}
                  suppressTooltip={true}
                  onRowClick={(row, columnId) => {
                    evseMap?.flyTo({
                      center: [
                        currentSites[row].location.longitude,
                        currentSites[row].location.latitude,
                      ],
                      zoom: 20,
                    });
                  }}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Map
                  {...viewState}
                  id="evseMap"
                  mapboxAccessToken={mapBoxAccessToken}
                  mapStyle="mapbox://styles/mapbox/navigation-day-v1"
                  style={{ width: "100%", height: "100%" }}
                  onMove={(evt) => {
                    setViewState(evt.viewState);
                  }}
                >
                  {selectedCharger !== null && (
                    <Popup
                      longitude={
                        currentSites[0].chargers[selectedCharger].location
                          .longitude
                      }
                      latitude={
                        currentSites[0].chargers[selectedCharger].location
                          .latitude
                      }
                      onClose={() => {
                        setSelectedCharger(null);
                      }}
                      closeOnClick={false}
                    >
                      <Text fontWeight="bold">
                        {currentSites[0].chargers[selectedCharger].name}
                      </Text>
                      <Stack>
                        <Text>
                          Type: {currentSites[0].chargers[selectedCharger].type}
                        </Text>
                        <HStack>
                          <Text>Status:</Text>
                          <Box
                            w={3}
                            h={3}
                            borderRadius="full"
                            bg={
                              currentSites[0].chargers[selectedCharger]
                                .status === "Available"
                                ? "green.500"
                                : "red.500"
                            }
                          />
                          <Text>
                            {currentSites[0].chargers[selectedCharger].status}
                          </Text>
                        </HStack>
                      </Stack>
                    </Popup>
                  )}

                  {currentSites.map((site, index) =>
                    site.chargers.map((charger, index) => (
                      <Marker
                        key={index}
                        longitude={charger.location.longitude}
                        latitude={charger.location.latitude}
                        onClick={() => {
                          setSelectedCharger(index);
                        }}
                      >
                        <Box
                          bg={
                            charger.status === "Available"
                              ? "green.500"
                              : charger.status === "In Use"
                              ? "orange.500"
                              : "red.500"
                          }
                          borderRadius="full"
                          p={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Icon
                            as={
                              charger.status === "Available"
                                ? FaChargingStation
                                : charger.status === "In Use"
                                ? BatteryCharging
                                : IoWarning
                            }
                            boxSize={6}
                            color="white"
                          />
                        </Box>
                      </Marker>
                    ))
                  )}
                </Map>
              </GridItem>
            </Grid>
          ) : (
            <KanbanBoard
              sites={currentSites}
              onChange={(sites) => {
                setCurrentSites(sites);
              }}
            />
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default EVSEManagementPage;
