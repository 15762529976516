import {
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Helmet } from "react-helmet";

export const CourseInPersonSchedulingTab: React.FC = () => {
  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Helmet>
        <script
          src="//assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
        />
      </Helmet>
      <Stack
        alignItems={"center"}
        display={"flex"}
        flexDir={"column"}
        mx={5}
        mt={2}
      >
        <Alert status="success" variant="left-accent">
          <AlertIcon />
          Each in-person training session is tailored to your organization.
          Schedule an initial planning conversation with the Axle team to get
          started. There is no obligation. NOTE: This is not scheduling the
          actual in-person training.
        </Alert>
        <Box
          p={0}
          className="calendly-inline-widget"
          data-url="https://calendly.com/maryam-axle/15-minute?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=204b36"
          width={"100%"}
          height={"700px"}
        ></Box>
      </Stack>
    </Box>
  );
};
