import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GlovePair, GlovePairInspectionOrder, GlovePairOrder } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useGlovePairs = () => {
    const { getToken } = useAuth();
    return useQuery<GlovePair[], ApiServiceErr>({
      queryKey: ["gloves"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/v2/gloves`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      }
    });
}

export const useGlovePairOrders = () => {
  const { getToken } = useAuth();
  return useQuery<GlovePairOrder[], ApiServiceErr>({
    queryKey: ["gloves-orders"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/v2/gloves/orders`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useGlovePairInspectionOrders = () => {
  const { getToken } = useAuth();
  return useQuery<GlovePairInspectionOrder[], ApiServiceErr>({
    queryKey: ["gloves-inspections"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/v2/gloves/inspections`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useCreateGlovePairInspectionOrder = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (data: {glovePairId: string}[]) => {
      const response = await axios.post(
        `/api/v2/gloves/inspections`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
     
      return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['gloves-inspections'] });
      toast.success(`Created gloves inspection order`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating gloves inspection order`)
      console.log(error);
    },
  });
}

export const useCreateGlovesOrder = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (data: {color: string, size: number, quantity: number}[]) => {
      const response = await axios.post(
        `/api/v2/gloves/orders`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
     
      return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['gloves-orders'] });
      toast.success(`Created gloves order`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating gloves order`)
      console.log(error);
    },
  });
}

export const useAssignGloves = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({externalId, size, color, assignedTo, assignedToSite}: {externalId: string, size: number, color: string, assignedTo: string, assignedToSite: string}) => {
      const response = await axios.post(
        `/api/v2/gloves/${externalId}/assign`,
        {size, color, assignedToMemberId: assignedTo, assignedToSiteId: assignedToSite},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
     
      return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['gloves'] });
      toast.success(`Assigned gloves: ${variables.externalId}`)
    },
    onError: (error, variables, context) => {
      //@ts-ignore
      toast.error(`Error assigning gloves: ${variables.externalId}. ${error.response.data}`, {autoClose: false})
      console.log(error);
    },
  });
}