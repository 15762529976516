import {
  Box,
  Stack,
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  Image,
  HStack,
  Progress,
  Tooltip,
  CardHeader,
  Center,
  Tag,
  VStack,
  TagLabel,
  SkeletonText,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import {
  useMyCertificates,
  useMyCourses,
  useMyRefresherSummaries,
} from "../../../api/endpoints/my";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteCertificate,
  useUploadCertificate,
} from "../../../api/endpoints/certificates";
import { BiDownload, BiLock } from "react-icons/bi";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactComponent as OnlineTestIllustration } from "../../../icons/online-test.svg";
import { BsQuestionOctagonFill } from "react-icons/bs";
import { useRef } from "react";
import { ReactComponent as ChecklistIllustration } from "../../../icons/checklist.svg";
import { AwardIcon } from "lucide-react";
import { MyCertificate } from "../../../types";
import * as Yup from "yup";
import { Form, Field, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { Select } from "chakra-react-select";
import { DeleteButton } from "../../../components/delete-button";

export const TrainingTasksTab: React.FC = () => {
  const { data: courses } = useMyCourses();
  const { data: certificates, isLoading: certificatesLoading } =
    useMyCertificates();
  const { data: refresherSummaries, isLoading: refresherSummariesLoading } =
    useMyRefresherSummaries();

  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isTrial, trialEligibleCourses } = useFlags();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { mutate: uploadCertificate, isError: uploadCertificateError } =
    useUploadCertificate();

  const { mutate: deleteCertificate, isError: deleteCertificateError } =
    useDeleteCertificate();

  const schema = Yup.object().shape({
    issuedBy: Yup.object().required("Issued By is required."),
    identifier: Yup.string().min(3).required("Identifier is required."),
    validFrom: Yup.date().required("Valid from date is required."),
    validTo: Yup.date(),
    file: Yup.mixed().test(
      "fileSize",
      "The certificate file is too large",
      (value: any) => {
        if (!value) return false;
        return value.size <= 5242880;
      }
    ),
  });

  const initialValues: {
    name?: string;
    issuedBy?: string;
    identifier?: string;
    validFrom?: string;
    validTo?: string;
    file?: File;
  } = {
    name: undefined,
    issuedBy: undefined,
    identifier: undefined,
    validFrom: undefined,
    validTo: undefined,
    file: undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      console.log(values);
      await uploadCertificate({
        data: {
          identifier: values.identifier,
          name: values.issuedBy.value,
          issuedBy: values.issuedBy.issuer,
          validFrom: values.validFrom,
          validTo: values.validTo,
          file: values.file,
        },
      });
      onToggle();
    },
  });

  const certificateOptions: readonly any[] = [
    {
      label: "ASE",
      options: [
        { label: "xEV Level 1", value: "xEV Level 1", issuer: "ASE" },
        { label: "xEV Level 2", value: "xEV Level 2", issuer: "ASE" },
        { label: "Other", value: "other", issuer: "ASE" },
      ],
    },
  ];

  const hiddenCertificateFileInput = useRef(null);

  const handleCertificateFileClick = (event: any) => {
    //@ts-ignore
    hiddenCertificateFileInput.current.click();
  };

  const handleCertificateFileChange = (event: any) => {
    if (event?.target?.files?.length === 0) return;

    const file = event.target.files[0];

    if (file.size > 5242880) {
      toast.error("Certificate file is larger than 5MB.");
    } else {
      formik.setFieldValue("file", file);
    }
  };

  const incompleteCourses =
    courses?.filter((course) => course.status.toLowerCase() !== "completed") ??
    [];

  const completedCourses =
    courses?.filter((course) => course.status.toLowerCase() === "completed") ??
    [];

  return (
    <Box height={"100vh"} overflow={"auto"}>
      {searchParams.get("finishedCourse") === "true" && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Upload Existing Certificate</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.identifier === true &&
                      !!formik?.touched?.identifier === true
                    }
                  >
                    <FormLabel>Certificate ID</FormLabel>
                    <Field
                      as={Input}
                      id="identifier"
                      name={"identifier"}
                      type="text"
                      placeholder="The unique ID of the certificate"
                      value={formik.values.identifier}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.identifier?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="issuedBy"
                    isInvalid={
                      !!formik?.errors?.issuedBy === true &&
                      !!formik?.touched?.issuedBy === true
                    }
                  >
                    <FormLabel>Type</FormLabel>
                    <Select
                      options={certificateOptions}
                      id="issuedBy"
                      placeholder={"What type of certificate is this?"}
                      value={formik.values.issuedBy}
                      name="issuedBy"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("issuedBy", e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.issuedBy?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validFrom === true &&
                      !!formik?.touched?.validFrom === true
                    }
                  >
                    <FormLabel>Valid From</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validFrom"
                      name={"validFrom"}
                      value={formik.values.validFrom}
                      onChange={(e: any) => {
                        formik.setFieldValue("validFrom", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validFrom?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validTo === true &&
                      !!formik?.touched?.validTo === true
                    }
                  >
                    <FormLabel>Valid To</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validTo"
                      name={"validTo"}
                      value={formik.values.validTo}
                      onChange={(e: any) => {
                        formik.setFieldValue("validTo", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validTo?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.file === true &&
                      !!formik?.touched?.file === true
                    }
                  >
                    <HStack display={"flex"} mb={2}>
                      <FormLabel mb={0} mr={1}>
                        Certificate File
                      </FormLabel>
                      <Text color={"fg.muted"} fontSize={"xs"}>
                        (5MB Max File Size)
                      </Text>
                    </HStack>
                    <HStack>
                      {!formik.values.file && (
                        <Button
                          variant="solid"
                          colorScheme="brand.primary"
                          onClick={handleCertificateFileClick}
                        >
                          Attach Certificate
                        </Button>
                      )}
                      {formik.values.file !== undefined && (
                        <>
                          <Button
                            className="button-upload"
                            background={"red.500"}
                            color={"white"}
                            width={"40%"}
                            _hover={{ background: "red.500" }}
                            onClick={() => {
                              formik.setFieldValue("file", undefined);
                            }}
                          >
                            Remove Certificate
                          </Button>
                          <Text
                            width={"60%"}
                            fontSize={"xs"}
                            textOverflow={"ellipsis"}
                          >
                            {formik.values.file.name}
                          </Text>
                        </>
                      )}
                    </HStack>
                    <Input
                      type="file"
                      onChange={handleCertificateFileChange}
                      ref={hiddenCertificateFileInput}
                      style={{ display: "none" }}
                    ></Input>
                    <FormErrorMessage>
                      {formik?.errors?.file?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <Text color={"fg.muted"}>
                    By submitting this certificate you agree that the
                    certificate submitted is accurate to the best of your
                    knowledge.
                  </Text>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>

      <Stack>
        <Card variant={"outline"}>
          <CardHeader>
            <Heading variant={"md"}>Tasks</Heading>
            <Text variant={"sm"}>
              Here you can view your current courses and complete them.
            </Text>
          </CardHeader>
          <CardBody p={{ base: 2, sm: 5 }}>
            <Tabs position="relative" variant="unstyled">
              <TabList>
                <Tab>
                  <Text variant={"md"}>
                    Assigned Courses ({incompleteCourses?.length})
                  </Text>
                </Tab>
                <Tab>
                  <Text variant={"md"}>
                    Completed Courses ({completedCourses?.length})
                  </Text>
                </Tab>
                <Tab>
                  <Text variant={"md"}>
                    Refresher Quizzes ({refresherSummaries?.length})
                  </Text>
                </Tab>
                <Tab>
                  <Text variant={"md"}>
                    Certificates ({certificates?.length})
                  </Text>
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2.5px"
                height="2px"
                bg="brand.primary.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  {courses &&
                    incompleteCourses?.map((course, i) => {
                      return (
                        <Card key={i} my={5} variant={"outline"}>
                          <CardBody>
                            <HStack
                              flexDir={{ base: "column", sm: "row" }}
                              justify={"space-between"}
                              display={"flex"}
                              width={"100%"}
                            >
                              <HStack width={"100%"}>
                                <Image
                                  src={course.featuredImageUrl}
                                  boxSize={{ base: "50px", md: "100px" }}
                                  objectFit="cover"
                                  borderRadius={10}
                                />
                                <VStack
                                  align="start"
                                  spacing={{ base: 1, md: 4 }}
                                  flex="1"
                                  width={"100%"}
                                >
                                  <Text
                                    fontWeight="bold"
                                    fontSize={{ base: "sm", md: "md" }}
                                  >
                                    {course.name}
                                  </Text>

                                  <HStack width={"100%"}>
                                    <Progress
                                      colorScheme="brand.primary"
                                      size="sm"
                                      value={course.currentProgress}
                                      width={{ base: "100%", sm: "33%" }}
                                    />
                                    <Text fontSize="sm">
                                      {course.currentProgress}%
                                    </Text>
                                  </HStack>
                                </VStack>
                              </HStack>
                              {(!isTrial ||
                                (isTrial &&
                                  trialEligibleCourses.includes(
                                    course.id
                                  ))) && (
                                <Box
                                  width={{
                                    base: "100%",
                                    sm: "20%",
                                    "2xl": "10%",
                                  }}
                                >
                                  {course.status === "NotStarted" && (
                                    <Button
                                      variant="solid"
                                      colorScheme="brand.primary"
                                      size={{ base: "xs", md: "md" }}
                                      width={{ base: "100%", sm: "100%" }}
                                      onClick={() => {
                                        navigate(`/my/courses/${course.id}`);
                                      }}
                                    >
                                      Start Course
                                    </Button>
                                  )}
                                  {course.status === "InProgress" && (
                                    <Button
                                      variant="solid"
                                      colorScheme="brand.primary"
                                      size={{ base: "xs", md: "md" }}
                                      width={{ base: "100%", sm: "100%" }}
                                      onClick={() => {
                                        navigate(`/my/courses/${course.id}`);
                                      }}
                                    >
                                      Continue Course
                                    </Button>
                                  )}
                                  {course.status === "Expired" ||
                                    (course.status === "Completed" && (
                                      <Button
                                        size={{ base: "xs", md: "md" }}
                                        variant="solid"
                                        width={{ base: "100%", md: "100%" }}
                                        colorScheme="brand.primary"
                                        onClick={() => {
                                          navigate(
                                            `/my/courses/${course.id}?retake=true`
                                          );
                                        }}
                                      >
                                        Revisit Course
                                      </Button>
                                    ))}
                                </Box>
                              )}
                              {isTrial &&
                                !trialEligibleCourses.includes(course.id) && (
                                  <Box
                                    width={{
                                      base: "100%",
                                      sm: "20%",
                                      "2xl": "10%",
                                    }}
                                  >
                                    <Tooltip
                                      label="To access this course, you'll need to upgrade your membership. Contact us at support@axlemobility.com to upgrade."
                                      placement="left"
                                    >
                                      <Button
                                        width={{ base: "100%", md: "100%" }}
                                        variant="solid"
                                        size={{ base: "xs", md: "md" }}
                                        colorScheme="brand.primary"
                                        isDisabled={true}
                                        rightIcon={<BiLock />}
                                      >
                                        Get Access
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                )}
                            </HStack>
                          </CardBody>
                        </Card>
                      );
                    })}

                  {incompleteCourses?.length === 0 && (
                    <Center flexDirection={"column"} height={"50vh"}>
                      <ChecklistIllustration height={"50%"} width={"50%"} />

                      <Text textAlign={"center"} mt={10}>
                        You don't have any training assigned currently. We'll
                        notify you when new training is assigned.
                      </Text>
                    </Center>
                  )}
                </TabPanel>
                <TabPanel>
                  {completedCourses?.map((course, i) => {
                    return (
                      <Card key={i} my={5} variant={"outline"}>
                        <CardBody>
                          <HStack
                            flexDir={{ base: "column", sm: "row" }}
                            justify={"space-between"}
                            display={"flex"}
                            width={"100%"}
                          >
                            <HStack width={"100%"}>
                              <Image
                                src={course.featuredImageUrl}
                                boxSize={{ base: "50px", md: "100px" }}
                                objectFit="cover"
                                borderRadius={10}
                              />
                              <VStack
                                align="start"
                                spacing={{ base: 1, md: 4 }}
                                flex="1"
                                width={"100%"}
                              >
                                <Text
                                  fontWeight="bold"
                                  fontSize={{ base: "sm", md: "md" }}
                                >
                                  {course.name}
                                </Text>

                                <HStack width={"100%"}>
                                  <Progress
                                    colorScheme="brand.primary"
                                    size="sm"
                                    value={course.currentProgress}
                                    width={{ base: "100%", sm: "33%" }}
                                  />
                                  <Text fontSize="sm">
                                    {course.currentProgress}%
                                  </Text>
                                </HStack>
                              </VStack>
                            </HStack>
                            {(!isTrial ||
                              (isTrial &&
                                trialEligibleCourses.includes(course.id))) && (
                              <Box
                                width={{
                                  base: "100%",
                                  sm: "20%",
                                  "2xl": "10%",
                                }}
                              >
                                {course.status === "NotStarted" && (
                                  <Button
                                    variant="solid"
                                    colorScheme="brand.primary"
                                    size={{ base: "xs", md: "md" }}
                                    width={{ base: "100%", sm: "100%" }}
                                    onClick={() => {
                                      navigate(`/my/courses/${course.id}`);
                                    }}
                                  >
                                    Start Course
                                  </Button>
                                )}
                                {course.status === "InProgress" && (
                                  <Button
                                    variant="solid"
                                    colorScheme="brand.primary"
                                    size={{ base: "xs", md: "md" }}
                                    width={{ base: "100%", sm: "100%" }}
                                    onClick={() => {
                                      navigate(`/my/courses/${course.id}`);
                                    }}
                                  >
                                    Continue Course
                                  </Button>
                                )}
                                {course.status === "Expired" ||
                                  (course.status === "Completed" && (
                                    <Button
                                      size={{ base: "xs", md: "md" }}
                                      variant="solid"
                                      width={{ base: "100%", md: "100%" }}
                                      colorScheme="brand.primary"
                                      onClick={() => {
                                        navigate(
                                          `/my/courses/${course.id}?retake=true`
                                        );
                                      }}
                                    >
                                      Revisit Course
                                    </Button>
                                  ))}
                              </Box>
                            )}
                            {isTrial &&
                              !trialEligibleCourses.includes(course.id) && (
                                <Box
                                  width={{
                                    base: "100%",
                                    sm: "20%",
                                    "2xl": "10%",
                                  }}
                                >
                                  <Tooltip
                                    label="To access this course, you'll need to upgrade your membership. Contact us at support@axlemobility.com to upgrade."
                                    placement="left"
                                  >
                                    <Button
                                      width={{ base: "100%", md: "100%" }}
                                      variant="solid"
                                      size={{ base: "xs", md: "md" }}
                                      colorScheme="brand.primary"
                                      isDisabled={true}
                                      rightIcon={<BiLock />}
                                    >
                                      Get Access
                                    </Button>
                                  </Tooltip>
                                </Box>
                              )}
                          </HStack>
                        </CardBody>
                      </Card>
                    );
                  })}
                  {completedCourses?.length === 0 && (
                    <Center flexDirection={"column"} height={"50vh"}>
                      <ChecklistIllustration height={"50%"} width={"50%"} />

                      <Text textAlign={"center"} mt={10}>
                        You don't have any training assigned currently. We'll
                        notify you when new training is assigned.
                      </Text>
                    </Center>
                  )}
                </TabPanel>
                <TabPanel>
                  <SkeletonText
                    width="100%"
                    skeletonHeight={20}
                    noOfLines={3}
                    isLoaded={!refresherSummariesLoading}
                  ></SkeletonText>
                  {!refresherSummariesLoading &&
                    refresherSummaries?.map((refresher, i) => {
                      return (
                        <Card key={i} variant={"outline"} my={5}>
                          <CardBody>
                            <HStack
                              flexDir={{ base: "column", sm: "row" }}
                              justify={"space-between"}
                              display={"flex"}
                              width={"100%"}
                            >
                              <HStack
                                width={{
                                  base: "100%",
                                  sm: "80%",
                                  "2xl": "90%",
                                }}
                              >
                                <VStack
                                  align="start"
                                  spacing={{ base: 1, md: 4 }}
                                  flex="1"
                                >
                                  <HStack>
                                    <BsQuestionOctagonFill />
                                    <Text
                                      fontWeight="bold"
                                      fontSize={{ base: "sm", md: "md" }}
                                    >
                                      {i + 1}. {refresher.course.name}
                                    </Text>
                                  </HStack>
                                  <Tag
                                    size={"sm"}
                                    variant="outline"
                                    colorScheme="brand.primary"
                                    borderRadius={"full"}
                                  >
                                    <TagLabel>
                                      Due on:{" "}
                                      {
                                        new Date(refresher.dueAt)
                                          .toISOString()
                                          .split("T")[0]
                                      }
                                    </TagLabel>
                                  </Tag>
                                </VStack>
                              </HStack>

                              <Box
                                width={{
                                  base: "100%",
                                  sm: "20%",
                                  "2xl": "10%",
                                }}
                              >
                                <Button
                                  variant="solid"
                                  colorScheme="brand.primary"
                                  onClick={() => {
                                    navigate(`/my/refreshers/${refresher.id}`);
                                  }}
                                  width={"100%"}
                                >
                                  Start Refresher
                                </Button>
                              </Box>
                            </HStack>
                          </CardBody>
                        </Card>
                      );
                    })}
                  {refresherSummaries?.length === 0 && (
                    <Center flexDirection={"column"} height={"50vh"}>
                      <OnlineTestIllustration height={"50%"} width={"50%"} />
                      <Text textAlign={"center"} mt={10}>
                        You don't have any refresher quizzes assigned currently.
                        We'll notify you when new refresher quizzes are
                        assigned.
                      </Text>
                    </Center>
                  )}
                </TabPanel>
                <TabPanel>
                  <HStack>
                    <Button
                      variant={"solid"}
                      colorScheme={"brand.primary"}
                      alignSelf={"start"}
                      onClick={() => onToggle()}
                    >
                      Add External Certificate
                    </Button>
                  </HStack>
                  <SkeletonText
                    width="100%"
                    skeletonHeight={20}
                    noOfLines={3}
                    isLoaded={!certificatesLoading}
                  ></SkeletonText>
                  {certificates?.map(
                    (certificate: MyCertificate, index: number) => {
                      return (
                        <Card my={6} key={index} variant={"outline"}>
                          <CardBody>
                            <Stack>
                              <HStack justify={"space-between"} mb={0} pb={0}>
                                <Heading
                                  size={"sm"}
                                  display={"flex"}
                                  flexDir={"row"}
                                  alignItems={"center"}
                                >
                                  <AwardIcon />
                                  <Text ml={2}>
                                    {index + 1}. {certificate.name}
                                  </Text>
                                </Heading>
                                <HStack>
                                  <IconButton
                                    icon={<BiDownload />}
                                    aria-label="download-certificate"
                                    color={"brand.accent"}
                                    onClick={() => {
                                      window.open(certificate.url, "_blank");
                                    }}
                                  />
                                  {certificate.issuedBy !== "Axle Mobility" && (
                                    <DeleteButton
                                      deleteKey={"DELETE"}
                                      name={certificate.name}
                                      aria-label="delete certificate"
                                      onDelete={() =>
                                        deleteCertificate(certificate.id ?? "")
                                      }
                                    />
                                  )}
                                </HStack>
                              </HStack>
                              <HStack
                                mt={0}
                                pt={0}
                                flexDirection={{ base: "column", md: "row" }}
                                width={{ base: "100%", md: "initial" }}
                              >
                                <Tag
                                  size={"sm"}
                                  width={{ base: "100%", md: "initial" }}
                                  variant="outline"
                                  colorScheme="brand.primary"
                                  borderRadius={"full"}
                                >
                                  <TagLabel>
                                    Issued By: {certificate.issuedBy}
                                  </TagLabel>
                                </Tag>

                                <Tag
                                  size={"sm"}
                                  width={{ base: "100%", md: "initial" }}
                                  variant="outline"
                                  colorScheme="brand.primary"
                                  borderRadius={"full"}
                                >
                                  <TagLabel>
                                    Issued On:{" "}
                                    {
                                      new Date(certificate.validFrom)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                  </TagLabel>
                                </Tag>
                                <Tag
                                  size={"sm"}
                                  width={{ base: "100%", md: "initial" }}
                                  variant="outline"
                                  colorScheme="brand.primary"
                                  borderRadius={"full"}
                                >
                                  <TagLabel>
                                    Expires On:{" "}
                                    {certificate.validTo &&
                                      new Date(certificate.validTo)
                                        .toISOString()
                                        .split("T")[0]}
                                    {!certificate.validTo && "N/A"}
                                  </TagLabel>
                                </Tag>
                              </HStack>
                            </Stack>
                          </CardBody>
                        </Card>
                      );
                    }
                  )}
                  {certificates?.length === 0 && (
                    <Center flexDirection={"column"} height={"50vh"}>
                      <ChecklistIllustration height={"50%"} width={"50%"} />
                      <Text textAlign={"center"} mt={10}>
                        You don't have any course certificates right now.
                        Complete courses to obtain certificates or upload
                        certificates you've earned from other organizations.
                      </Text>
                    </Center>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
