import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Stack,
  useDisclosure,
  Button,
  Text,
  FormControl,
  Input,
  Table,
  Tr,
  Td,
  TableContainer,
  Tbody,
  Th,
  Thead,
  CardHeader,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Tag,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import { Select } from "chakra-react-select";
import type { OrganizationMembershipResource } from "@clerk/types";
import { useOrganization } from "@clerk/clerk-react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { useAssignGloves } from "../../api/endpoints/gloves";
import { ArrowLeft, XIcon } from "lucide-react";
import { useSites } from "../../api/endpoints/sites";

type GloveToAssign = {
  externalId: string;
  size: number | null;
  color: string | null;
  assignedTo: string | null;
  assignedToSite: string | null;
};

export const GloveManagementAssignmentPage: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [glovesToAssign, setGlovesToAssign] = useState<GloveToAssign[]>([]);

  const { mutate: assignGloves } = useAssignGloves();
  const { data: sites } = useSites();
  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const formik = useFormik({
    initialValues: {
      gloveCode: "",
    },
    onSubmit: async (values: any) => {
      if (values.gloveCode.length === 0) {
        toast.error("Glove code is required.");
        return;
      }

      const isUnique = !glovesToAssign.some(
        (glove) => glove.externalId === values.gloveCode
      );
      if (isUnique) {
        setGlovesToAssign([
          ...glovesToAssign,
          {
            externalId: values.gloveCode,
            size: null,
            color: null,
            assignedTo: null,
            assignedToSite: null,
          },
        ]);
      } else {
        toast.error("Glove pair already scanned.");
      }

      formik.resetForm();
    },
  });

  const gloveSizeOptions = [
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];

  const gloveColorOptions = [
    { label: "Red", value: "red" },
    { label: "Black", value: "black" },
  ];

  const siteOptions = sites?.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const appliesToOptions = memberships?.data?.map(
    (x: OrganizationMembershipResource) => {
      return {
        label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
        value: x.id,
      };
    }
  );

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Glove Tracker"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardHeader>
            <HStack justifyContent={"space-between"}>
              <HStack>
                <IconButton
                  aria-label="go back"
                  icon={<ArrowLeft />}
                  style={{ background: "transparent" }}
                  onClick={() => navigate("/tools/glove-management")}
                  color={"brand.accent"}
                />
                <Heading variant={"md"}>Assign Gloves</Heading>
              </HStack>
            </HStack>
          </CardHeader>
          <Divider width={"95%"} alignSelf={"center"} />
          <CardBody px={"2rem"}>
            <Stack>
              <Card variant={"outline"}>
                <CardBody py={".75rem"} px={"2rem"}>
                  <HStack justify={"space-between"}>
                    <Text>
                      1. Scan or enter the code on the gloves and add
                      information to register. Keep scanning to add more glove
                      pairs.
                    </Text>{" "}
                    <FormikProvider value={formik}>
                      <Form>
                        <FormControl>
                          <Input
                            placeholder="Glove Code"
                            size={"lg"}
                            name="gloveCode"
                            value={formik.values.gloveCode}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </Form>
                    </FormikProvider>
                  </HStack>
                </CardBody>
              </Card>
              {glovesToAssign.map((x) => (
                <Card variant={"outline"} width={"100%"} bg={"brand.gray.100"}>
                  <CardBody width={"100%"}>
                    <TableContainer
                      borderRadius={"md"}
                      backgroundColor={"brand.gray.100"}
                    >
                      <Table
                        variant={"simple"}
                        backgroundColor={"brand.gray.100"}
                      >
                        <Thead>
                          <Tr>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Glove Code
                            </Th>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Size
                            </Th>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Color
                            </Th>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Assigned To
                            </Th>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Assigned To Site
                            </Th>
                            <Th
                              backgroundColor={"brand.gray.100"}
                              borderTop={"none"}
                              borderColor={"gray.200"}
                            >
                              Actions
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td borderColor={"gray.200"} fontWeight={"600"}>
                              {x.externalId}
                            </Td>
                            <Td borderColor={"gray.200"}>
                              <Select
                                options={gloveSizeOptions}
                                placeholder={"Size"}
                                closeMenuOnSelect={true}
                                onChange={(e: any) => {
                                  setGlovesToAssign(
                                    glovesToAssign.map((y) =>
                                      y.externalId === x.externalId
                                        ? { ...y, size: e.value }
                                        : y
                                    )
                                  );
                                }}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 100,
                                  }),
                                }}
                                chakraStyles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                    border: "none",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    borderColor: "brand.surface",
                                  }),
                                }}
                                menuPortalTarget={document.body}
                              />
                            </Td>
                            <Td borderColor={"gray.200"}>
                              <FormControl>
                                <Select
                                  options={gloveColorOptions}
                                  placeholder={"Color"}
                                  onChange={(e: any) => {
                                    setGlovesToAssign(
                                      glovesToAssign.map((y) =>
                                        y.externalId === x.externalId
                                          ? { ...y, color: e.value }
                                          : y
                                      )
                                    );
                                  }}
                                  closeMenuOnSelect={true}
                                  styles={{
                                    menuPortal: (provided) => ({
                                      ...provided,
                                      zIndex: 100,
                                    }),
                                  }}
                                  chakraStyles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: "brand.surface",
                                    }),
                                    dropdownIndicator: (provided) => ({
                                      ...provided,
                                      backgroundColor: "brand.surface",
                                      border: "none",
                                    }),
                                    indicatorSeparator: (provided) => ({
                                      ...provided,
                                      borderColor: "brand.surface",
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                />
                              </FormControl>
                            </Td>
                            <Td borderColor={"gray.200"}>
                              <Select
                                options={appliesToOptions}
                                placeholder={"Assigned To"}
                                closeMenuOnSelect={true}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 100,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                onChange={(e: any) => {
                                  setGlovesToAssign(
                                    glovesToAssign.map((y) =>
                                      y.externalId === x.externalId
                                        ? { ...y, assignedTo: e.value }
                                        : y
                                    )
                                  );
                                }}
                                chakraStyles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                    border: "none",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    borderColor: "brand.surface",
                                  }),
                                }}
                              />
                            </Td>
                            <Td borderColor={"gray.200"}>
                              <Select
                                options={siteOptions}
                                placeholder={"Assigned To Site"}
                                closeMenuOnSelect={true}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 100,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                onChange={(e: any) => {
                                  setGlovesToAssign(
                                    glovesToAssign.map((y) =>
                                      y.externalId === x.externalId
                                        ? { ...y, assignedToSite: e.value }
                                        : y
                                    )
                                  );
                                }}
                                chakraStyles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    backgroundColor: "brand.surface",
                                    border: "none",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    borderColor: "brand.surface",
                                  }),
                                }}
                              />
                            </Td>
                            <Td borderColor={"gray.200"}>
                              <IconButton
                                backgroundColor={"brand.gray.100"}
                                color={"red.500"}
                                icon={<BiTrash />}
                                aria-label={"Remove"}
                                onClick={() => {
                                  setGlovesToAssign(
                                    glovesToAssign.filter(
                                      (y) => y.externalId !== x.externalId
                                    )
                                  );
                                }}
                              ></IconButton>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </CardBody>
                </Card>
              ))}
              {glovesToAssign.length > 0 && (
                <Card variant={"outline"}>
                  <CardBody py={".75rem"} px={"2rem"}>
                    <HStack justify={"space-between"}>
                      <Text>
                        2. Confirm to send alert to technicians to pick up their
                        gloves.
                      </Text>{" "}
                      <FormikProvider value={formik}>
                        <Form>
                          <FormControl>
                            <Button
                              variant="inverted"
                              onClick={() => {
                                glovesToAssign.forEach(async (glove) => {
                                  if (
                                    glove.size &&
                                    glove.assignedTo &&
                                    glove.color &&
                                    glove.assignedToSite
                                  ) {
                                    await assignGloves({
                                      externalId: glove.externalId,
                                      size: glove.size,
                                      color: glove.color,
                                      assignedTo: glove.assignedTo,
                                      assignedToSite: glove.assignedToSite,
                                    });
                                  }
                                });
                                navigate("/tools/glove-management");
                              }}
                            >
                              Confirm
                            </Button>
                          </FormControl>
                        </Form>
                      </FormikProvider>
                    </HStack>
                  </CardBody>
                </Card>
              )}
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
