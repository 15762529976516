import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Course, CurriculaProgressReport, Curriculum, Refresher, RefresherSummary } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useCurricula = () => {
  const { getToken } = useAuth();
  return useQuery<Curriculum[], ApiServiceErr>({
    queryKey: ["curricula"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/v2/curricula`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
      return response.data;
    }
  });
}

export const useCurriculaAdminReport = () => {
  const { getToken } = useAuth();
  return useQuery<CurriculaProgressReport[], ApiServiceErr>({
    queryKey: ["curricula-report"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/v2/curricula/report`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
      return response.data;
    }
  });
}

export const useAttachCurriculumToResource = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({curriculumId, resourceId}: {curriculumId: string, resourceId: string}) => {
      const response = await axios.post(
        `/api/v2/curricula/${curriculumId}/resources/attach/${resourceId}`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['curricula'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDetachCurriculumFromResource = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({curriculumId, resourceId}: {curriculumId: string, resourceId: string}) => {
      const response = await axios.post(
        `/api/v2/curricula/${curriculumId}/resources/detach/${resourceId}`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['curricula'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useCourse = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Course, ApiServiceErr>({
    queryKey: ["courses"],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/v2/courses/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useCreateCourseAttempt = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, data}: {id: string, data: any}) => {
      const result = await axios.post(
        `/api/v2/courses/${id}/attempt`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['course-attempts'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useUpdateCourseAttempt = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, data}: {id: string, data: any}) => {
      const result = await axios.put(
        `/api/v2/courses/${id}/attempt`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['my-courses'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useAcknowledgeProtocolAction = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, version}: {id: string, version: number}) => {
      const result = await axios.post(
        `/api/protocols/${id}/acknowledge/${version}`,
        undefined,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}