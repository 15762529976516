import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { Select } from "chakra-react-select";
import { Vehicle } from "../../types";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";

ChartJS.register(ArcElement, ChartTooltip, Legend);

export const VehiclePurchaseAndRegistrationTab = ({
  vehicle,
}: {
  vehicle?: Vehicle;
}) => {
  const schema = Yup.object().shape({
    plate: Yup.string().required("License plate is required"),
    registrationState: Yup.string().required("Registration state is required"),
    registrationExpirationDate: Yup.date().required(
      "Registration expiration is required"
    ),
    purchaseDate: Yup.date().required("Purchase date is required"),
  });

  const { data: updatedVehicle, mutate: updateVehicle } = useUpdateVehicle();

  const formik = useFormik({
    initialValues: {
      ...vehicle,
      registrationExpirationDate: vehicle?.registrationExpirationDate
        ? new Date(vehicle.registrationExpirationDate)
            .toISOString()
            .split("T")[0]
        : "",
      purchaseDate: vehicle?.purchaseDate
        ? new Date(vehicle.purchaseDate).toISOString().split("T")[0]
        : "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      await updateVehicle({ id: vehicle?.id ?? "", body: values });
    },
  });

  const stateOptions = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  return (
    <Box height={"100vh"}>
      <FormikProvider value={formik}>
        <Form>
          <SimpleGrid columns={[1, 2]} gap={"10px"}>
            <FormControl
              isRequired
              isInvalid={
                !!formik?.errors?.plate === true &&
                !!formik?.touched?.plate === true
              }
              mb={5}
            >
              <FormLabel>License Plate</FormLabel>
              <Field
                as={Input}
                id="plate"
                name={"plate"}
                type="text"
                placeholder="License Plate"
                value={formik?.values?.plate}
              />
              <FormErrorMessage>
                {formik?.errors?.plate?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                !!formik?.errors?.registrationState === true &&
                !!formik?.touched?.registrationState === true
              }
              mb={5}
            >
              <FormLabel>Registration State</FormLabel>
              <Select
                options={stateOptions}
                placeholder={"Select State"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("registrationState", e.value);
                }}
                id="registrationState"
                name={"registrationState"}
                value={stateOptions.find(
                  (x) => x.value === formik.values?.registrationState
                )}
              />
              <FormErrorMessage>
                {formik?.errors?.registrationState?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                !!formik?.errors?.registrationExpirationDate === true &&
                !!formik?.touched?.registrationExpirationDate === true
              }
              mb={5}
            >
              <FormLabel>Registration Expiration</FormLabel>
              <Field
                as={Input}
                id="registrationExpirationDate"
                name={"registrationExpirationDate"}
                type="date"
                value={
                  formik?.values?.registrationExpirationDate?.split("T")[0]
                }
                placeholder={formik?.values?.registrationExpirationDate}
                onChange={(e: any) =>
                  formik.setFieldValue(
                    "registrationExpirationDate",
                    e.target.value
                  )
                }
              />
              <FormErrorMessage>
                {formik?.errors?.registrationExpirationDate?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                !!formik?.errors?.purchaseDate === true &&
                !!formik?.touched?.purchaseDate === true
              }
              mb={5}
            >
              <FormLabel>Purchase Date</FormLabel>
              <Field
                as={Input}
                id="purchaseDate"
                name={"purchaseDate"}
                type="date"
                value={formik?.values?.purchaseDate?.split("T")[0]}
                placeholder={formik?.values?.purchaseDate}
                onChange={(e: any) =>
                  formik.setFieldValue("purchaseDate", e.target.value)
                }
              />
              <FormErrorMessage>
                {formik?.errors?.purchaseDate?.toString()}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <Box justifyContent={"right"} display={"flex"}>
            <Button
              onClick={formik.submitForm}
              variant="solid"
              colorScheme="brand.primary"
              isDisabled={!formik.isValid || formik.isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};
