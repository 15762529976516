import {
  Box,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const OrgManagementPage: React.FC = () => {
  const tabs = [
    {
      name: "Members",
      component: <></>,
    },
    {
      name: "Teams",
      component: <></>,
    },
    {
      name: "Sites",
      component: <></>,
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("members")) {
      setTabIndex(0);
    } else if (location.pathname.includes("teams")) {
      setTabIndex(1);
    } else if (location.pathname.includes("sites")) {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  return (
    <Box height={"100vh"} overflowY={"scroll"}>
      <PageHeader title="Organization Management" />
      <Stack>
        <Tabs
          index={tabIndex}
          size={"lg"}
          variant="soft-rounded"
          onChange={(index) => {
            setTabIndex(index);
            navigate(
              `/org-management/${tabs[index].name
                .toLowerCase()
                .replace(" ", "-")}`
            );
          }}
        >
          <TabList
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab key={index} borderRadius={"md"}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />
          <TabPanels>
            {tabs.map((tab, index) => {
              return (
                <TabPanel p={0} key={index}>
                  <Outlet />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
