import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useOrganizationMetadata,
  useUpdateOrganizationMetadata,
} from "../../api/endpoints/organizations";
import { OrganizationMetadata } from "../../types-new";
import { Select } from "chakra-react-select";
import { useCurricula } from "../../api/endpoints/courses";

export const OrganizationTrainingSection: React.FC = () => {
  const orgnizationDetailsEditSchema = Yup.object().shape({
    isDrayageFleet: Yup.boolean(),
    isStateGovernment: Yup.boolean(),
    isLocalGovernment: Yup.boolean(),
    isFederalGovernment: Yup.boolean(),
    isHighPriorityFleet: Yup.boolean(),
    businessStates: Yup.array().of(Yup.string()),
    revenueInMillions: Yup.number().min(0, "Revenue must be non-negative"),
  });

  const { mutate: updateOrganizationMetadata } =
    useUpdateOrganizationMetadata();
  const { data: curricula } = useCurricula();

  const { data: organizationMetadata, isLoading: organizationMetadataLoading } =
    useOrganizationMetadata();

  const initialValues = {
    isDrayageFleet: false,
    isStateGovernment: false,
    isLocalGovernment: false,
    isFederalGovernment: false,
    isHighPriorityFleet: false,
    businessStates: [],
    isFleet: false,
    defaultTrainingPlans: [],
    revenueInMillions: 0,
  };

  const curriculaOptions = curricula?.map((curriculum) => ({
    label: curriculum.name,
    value: curriculum.id,
  }));

  const onSubmit = async (values: any) => {
    await updateOrganizationMetadata({
      isDrayageFleet: values.isDrayageFleet,
      isStateGovernment: values.isStateGovernment,
      isLocalGovernment: values.isLocalGovernment,
      isFederalGovernment: values.isFederalGovernment,
      isHighPriorityFleet: values.isHighPriorityFleet,
      businessStates: values.businessStates,
      isFleet: values.isFleet,
      defaultTrainingPlans: values.defaultTrainingPlans,
      revenueInMillions: values.revenueInMillions,
    } as OrganizationMetadata);
  };

  return (
    <Card variant="outline">
      <Stack width={"100%"}>
        <Formik
          initialValues={organizationMetadata ?? initialValues}
          validationSchema={orgnizationDetailsEditSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, handleChange, touched, setFieldValue }) => (
            <Form name="organization-training-defaults-form">
              <CardBody>
                <Heading size="md">Training</Heading>
                <Stack spacing="1" py={{ base: "2", md: "3" }}>
                  <Stack color="brand.text">
                    <FormControl
                      isInvalid={
                        !!errors.defaultTrainingPlans &&
                        !!touched.defaultTrainingPlans
                      }
                      mt={5}
                    >
                      <FormLabel>Default Training Plans</FormLabel>
                      <Select
                        options={curriculaOptions}
                        placeholder={"Default Training Plans"}
                        isMulti
                        name="defaultTrainingPlans"
                        chakraStyles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "50%",
                          }),
                        }}
                        value={values.defaultTrainingPlans?.map(
                          (trainingPlan) =>
                            curriculaOptions?.find(
                              (x) => x.value === trainingPlan
                            )
                        )}
                        onChange={(e: any) => {
                          setFieldValue(
                            "defaultTrainingPlans",
                            e.map((x: any) => x.value)
                          );
                        }}
                      />
                      <FormHelperText>
                        Choose training plans to be automatically assigned to
                        new members upon joining your organization. These plans
                        will help ensure consistent onboarding and skill
                        development.
                      </FormHelperText>
                      <FormErrorMessage>
                        <ErrorMessage name="defaultTrainingPlans" />
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </CardBody>
              <HStack justifyContent={"right"} p={5} pt={0}>
                <Button
                  variant="outline"
                  bg="brand.primary.500"
                  color={"brand.textLight"}
                  _hover={{ backgroundColor: "brand.primary.500" }}
                  type="submit"
                >
                  Save
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Card>
  );
};
