import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Action, Protocol } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useActions = () => {
    const { getToken } = useAuth();
    return useQuery<Action[], ApiServiceErr>({
      queryKey: ["actions"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/actions`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      }
    });
}

export const useAction = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Action, ApiServiceErr>({
    queryKey: ["action", id],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/actions/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useCreateAction = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({body}: {body: any}) => {
      const result = await axios.post(
        `/api/actions`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['actions'] });
      toast.success("Action created successfully");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error creating action. Please try again.");
    },
  });
}


export const useUpdateAction = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, body}: {id: string, body: any}) => {
      const result = await axios.put(
        `/api/actions/${id}`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['actions'] });
      toast.success("Action updated successfully");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error updating action. Please try again.");
    },
  });
}

export const useResolveAction = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      const result = await axios.post(
        `/api/actions/${id}/resolve`,
        undefined,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['actions'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}