import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  IconButton,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Tag,
  Text,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { useState } from "react";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { Vendor } from "../../types";
import { ChevronsRightIcon, PlusIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useVendors } from "../../api/endpoints/vendors";

export const VendorsListPage: React.FC = () => {
  const { data: vendors } = useVendors();

  const tabs = [
    {
      name: "All Vendors",
      filtered: vendors,
    },
    {
      name: "Battery Recycling",
      filtered: vendors?.filter(
        (vendor) => vendor.type === "battery_recycling"
      ),
    },
    {
      name: "Glove Testing",
      filtered: vendors?.filter((vendor) => vendor.type === "glove_testing"),
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const columnHelper = createColumnHelper<Vendor>();
  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue() ?? "N/A",
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.contactPersonName, {
      id: "contactPersonName",
      header: () => <span>Contact</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.contactPersonEmail, {
      id: "contactPersonEmail",
      header: () => <span>Email</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.contactPersonPhone, {
      id: "contactPersonPhone",
      header: () => <span>Phone</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      header: () => <span>Type</span>,
      cell: (info) => {
        const type = info.getValue();
        if (type === "battery_recycling") {
          return <Badge variant={"green"}>Battery Recycling</Badge>;
        } else if (type === "glove_testing") {
          return <Badge variant={"purple"}>Glove Testing</Badge>;
        }
        return <Badge variant={"gray"}>Other</Badge>;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.address, {
      id: "address",
      header: () => <span>Address</span>,
      cell: (info) => (
        <Stack gap={0}>
          <Text>{info.row.original?.address}</Text>
          <Text>
            {info.row.original?.city}, {info.row.original?.state}{" "}
            {info.row.original?.zip}
          </Text>
        </Stack>
      ),
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (info) => (
        <IconButton
          fontSize={"10px"}
          icon={<ChevronsRightIcon />}
          color={"brand.accent"}
          onClick={() => {
            navigate(`/vendors/${info.row.original.id}`);
          }}
          aria-label="Edit Vendor"
        ></IconButton>
      ),
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Vendors"></PageHeader>
      <Card variant={"outline"} m={5}>
        <CardBody>
          <Stack>
            <Tabs variant="unstyled" onChange={setTabIndex}>
              <TabList>
                {tabs.map(({ name, filtered }, index) => {
                  return (
                    <Tab key={index}>
                      <Text
                        fontWeight={"500"}
                        color={
                          tabIndex === index
                            ? "brand.primary.500"
                            : "brand.text"
                        }
                      >
                        {name}
                      </Text>
                      <Tag size="sm" ml={1}>
                        {filtered?.length ?? 0}
                      </Tag>
                    </Tab>
                  );
                })}
              </TabList>
              <TabIndicator height="2px" bg="brand.primary.500" />
              {vendors && (
                <Card variant={"outline"}>
                  <CardBody>
                    <DataTable
                      columns={columns}
                      data={tabs[tabIndex].filtered ?? []}
                      actionChildren={
                        <Button
                          variant={"outline"}
                          leftIcon={<PlusIcon size={16} />}
                          onClick={() => {
                            navigate("/vendors/new");
                          }}
                        >
                          Add Vendor
                        </Button>
                      }
                    ></DataTable>
                  </CardBody>
                </Card>
              )}
            </Tabs>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
