import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Action, Protocol } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";
import { VMRSDecodeOrder } from "../../types-new";

export const useVMRSDecodeOrder = (id: string, enabled: boolean) => {
  const { getToken } = useAuth();
  return useQuery<VMRSDecodeOrder, ApiServiceErr>({
    queryKey: ["vmrs", "decode-orders", id],
    queryFn:  async () => {
      if(id.length === 0) return;
      const response = await axios.get(
        `/api/v2/vmrs/decode-orders/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useCreateVMRSDecodeOrder = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({values, requestType}: {values: {description: string, externalId: string}[], requestType: "Parts" | "RepairNotes"}) => {
      const response = await fetch(`/api/v2/vmrs/decode-orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken()}`
        },
        body: JSON.stringify({ 
          requestType: requestType,
          lineItems: values.map((value) => ({
            description: value.description,
            externalId: value.externalId
          }))
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const locationHeader = response.headers.get('Location');
      
      if (!locationHeader) {
        throw new Error('Location header not found in response');
      }
      
      return locationHeader;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['decode-orders'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error getting VMRS code recommendations. Please try again.");
    },
  });
}