import {
  Box,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Heading, HStack } from "@chakra-ui/react";
import { PageHeader } from "../../../components/page-header";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table";
import { Team } from "../../../types-new";
import {
  useCreateTeam,
  useDeleteTeam,
  useTeams,
} from "../../../api/endpoints/teams";
import { BsPencil } from "react-icons/bs";
import { IoDuplicateOutline } from "react-icons/io5";
import { DeleteButton } from "../../../components/delete-button";
import { Form, Field, FormikProvider, useFormik } from "formik";
import { PlusIcon } from "lucide-react";

export const TeamsListPage: React.FC = () => {
  const {
    data: teams,
    isError: isTeamsError,
    isLoading: isTeamsLoading,
  } = useTeams();

  const columnHelper = createColumnHelper<Team>();
  const navigate = useNavigate();
  const { data: createdTeamId, mutate: createTeam } = useCreateTeam();

  const { mutate: deleteTeam } = useDeleteTeam();
  const {
    isOpen: isEditTeamModalOpen,
    onToggle: onEditTeamModalToggle,
    onClose: onEditTeamModalClose,
  } = useDisclosure();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "members",
      header: () => <span>Members</span>,
      cell: (info) => info.row.original.members.length,
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack color={"brand.accent"} gap={0}>
            <DeleteButton
              title="Delete Team"
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete team"
              onDelete={() => deleteTeam({ id: props.row.original.id })}
            />
            <IconButton
              color={"inherit"}
              aria-label="edit team"
              onClick={() =>
                navigate(`/org-management/teams/${props.row.original.id}`)
              }
              icon={<BsPencil />}
            />
          </HStack>
        );
      },
    }),
  ];

  const editTeamSettingsFormik = useFormik({
    initialValues: {} as Team,
    onSubmit: async (values: any) => {
      await createTeam({ team: values });
    },
  });

  useEffect(() => {
    if (createdTeamId) {
      navigate(`/org-management/teams/${createdTeamId}`);
    }
  }, [createdTeamId]);

  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Modal
        isOpen={isEditTeamModalOpen}
        onClose={onEditTeamModalClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={editTeamSettingsFormik}>
            <Form>
              <Card variant={"outline"}>
                <CardBody>
                  <FormControl
                    isInvalid={
                      !!editTeamSettingsFormik?.errors?.name === true &&
                      !!editTeamSettingsFormik?.touched?.name === true
                    }
                    mb={5}
                  >
                    <FormLabel>Team Name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name={"name"}
                      type="text"
                      placeholder="Example Name"
                      value={editTeamSettingsFormik?.values?.name}
                    />
                    <FormErrorMessage>
                      {editTeamSettingsFormik?.errors?.name?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </CardBody>
                <CardFooter justifyContent={"end"} display={"flex"}>
                  <Button
                    colorScheme="brand.primary"
                    px={5}
                    onClick={() => editTeamSettingsFormik.handleSubmit()}
                  >
                    Create
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={teams ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onEditTeamModalToggle}
                >
                  Add
                </Button>
              }
              title="Teams"
              subtitle="Teams represent groups of people across your organization and can be used to assign training and other tasks in bulk."
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
