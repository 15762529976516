import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "./edit.css";
import {
  useInspection,
  useUpdateInspection,
} from "../../api/endpoints/inspections";
import { Survey } from "survey-react-ui";
import { Model, AfterRenderQuestionEvent } from "survey-core";
import { FormikProvider, Field, useFormik, Form } from "formik";
import * as Yup from "yup";
import { useOrganization } from "@clerk/clerk-react";
import { useSites } from "../../api/endpoints/sites";
import { useTeams } from "../../api/endpoints/teams";
import type { OrganizationMembershipResource } from "@clerk/types";
import { Select } from "chakra-react-select";
import initPassFailQuestionType from "../doc-library/inspections-templates/passFailQuestion";
import { PageHeader } from "../../components/page-header";
import { create } from "lodash";

export const InspectionsViewPage: React.FC = () => {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(
    undefined
  );
  const [inspection, setInspection] = useState<Model | undefined>();
  const navigate = useNavigate();
  const {
    mutate: updateInspection,
    isError: updateInspectionError,
    data: updateInspectionData,
  } = useUpdateInspection();

  const {
    data: existingInspectionData,
    isError: existingInspectionIsError,
    isLoading: existingInspectionIsLoading,
  } = useInspection(id ?? "");

  // Initialize pass fail question type once
  useEffect(() => {
    initPassFailQuestionType();
  }, []);

  const inspectionComplete = useCallback((sender: any) => {
    console.log(sender.data);
    updateInspection({ id: id ?? "", body: { results: sender.data } });
    navigate(`/my/inspections`);
  }, []);

  useEffect(() => {
    if (existingInspectionData?.form) {
      var inspectionForm = new Model(existingInspectionData.form);
      inspectionForm.onComplete.add(inspectionComplete);
      inspectionForm.onAfterRenderQuestion.add(updateStringComponents);
      inspectionForm.onValueChanged.add((sender: any, options: any) => {
        updateButtonState(options.name, sender.data);
        // setInspection(inspection);
      });
      // inspectionForm.showPreviewBeforeComplete = "showAnsweredQuestions";
      // inspectionForm.previewText = "Review and Submit";

      setInspection(inspectionForm);
    }
  }, [existingInspectionData]);

  const updateStringComponents = (
    _: any,
    options: AfterRenderQuestionEvent
  ) => {
    // Skip the outermost question for pass-fail questions.
    // Instead, the button is attached to each nested question instead.
    // TODO: Clean this up so that it's only attached to the outermost question.
    if (options.question.getType() !== "passfail") {
      const div = document.createElement("div");
      div.className = "action-bar";
      const button = document.createElement("button");
      button.textContent = "+ Create Action";
      button.className = "action-button create-action";
      button.setAttribute("data-question-id", options.question.getValueName());
      button.onclick = () => {
        const name = options.question.getValueName();
        if (name) {
          createAction(name);
        }
      };

      div.appendChild(button);

      options.htmlElement.appendChild(div);
    }
  };

  const createAction = (questionId: string) => {
    formik.resetForm();
    formik.setFieldValue("id", questionId);
    onOpen();
  };

  const removeAction = (questionId: string | undefined) => {
    inspection?.clearValue(`${questionId}__axle-action`);
  };

  // useEffect(() => {
  //   if (data) {
  //     let definition: any | undefined = undefined;
  //     if (data.form && data.form !== "null") {
  //       definition = data.form;
  //     } else {
  //       definition = {
  //         title: data.name,
  //         description: data.description,
  //       };
  //     }

  //     const inspection = new Model(definition);
  //     inspection.onComplete.add(inspectionComplete);
  //     inspection.onAfterRenderQuestion.add(updateStringComponents);
  //     inspection.onValueChanged.add((sender: any, options: any) => {
  //       // setInspection(inspection);
  //     });
  //     inspection.showPreviewBeforeComplete = "showAnsweredQuestions";
  //     inspection.previewText = "Review and Submit";

  //     // setInspection(inspection);
  //     inspection.    }
  // }, [data]);

  // useEffect(() => {
  //   console.log(inspection?.getAllValues());
  // }, [inspection.clear]);

  const updateButtonState = (name: string, currentValues: any) => {
    const questionId = name.split("__")[0];
    const button = document.querySelector(
      `.action-button[data-question-id="${questionId}"]`
    ) as HTMLButtonElement;

    if (name.includes("action") && button) {
      if (button.classList.contains("remove-action")) {
        button.classList.remove("remove-action");
        button.classList.add("create-action");
        button.textContent = "+ Create Action";
        button.onclick = () => {
          createAction(name);
          button.classList.remove("create-action");
          button.classList.add("remove-action");
          button.textContent = "- Remove Action";
        };
      } else if (button.classList.contains("create-action")) {
        button.classList.add("remove-action");
        button.classList.remove("create-action");
        button.textContent = "- Remove Action";
        button.onclick = () => {
          removeAction(questionId);
          button.classList.remove("remove-action");
          button.classList.add("create-action");
          button.textContent = "+ Create Action";
        };
      }
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Action name is required."),
    description: Yup.string()
      .min(3)
      .required("Action description is required."),
    priority: Yup.string()
      .oneOf(["high", "medium", "low"])
      .required("Action priority is required."),
    dueDate: Yup.date().required("Action due date is required."),
  });
  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      assignedTo: [],
      priority: "",
      description: "",
      dueDate: undefined,
    },
    onSubmit: async (values: any) => {
      const formData = {
        inspectionId: id ?? "",
        name: values.name,
        priority: values.priority.value,
        description: values.description,
        dueDate: values.dueDate,
        assignedTo: values.assignedTo.map((x: any) => x.value),
      };

      inspection?.setValue(`${values.id}__axle-action`, formData);
      onClose();
    },
  });

  const {
    data: teams,
    isError: teamsIsError,
    isLoading: teamsIsLoading,
  } = useTeams();

  const {
    data: sites,
    isError: sitesIsError,
    isLoading: sitesIsLoading,
  } = useSites();

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const assignedToOptions: readonly any[] = [
    {
      label: "Teams",
      options: teams?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Sites",
      options: sites?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  const priorityOptions: readonly any[] = [
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  return (
    <Box height={"100vh"} overflowY={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Create New Action</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.name === true &&
                      !!formik?.touched?.name === true
                    }
                  >
                    <FormLabel>Name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name={"name"}
                      type="text"
                      placeholder="Example Name"
                      value={formik.values.name}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.name?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.description === true &&
                      !!formik?.touched?.description === true
                    }
                  >
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Textarea}
                      id="description"
                      name={"description"}
                      type="text"
                      placeholder="Example Description"
                      value={formik.values.description}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.description?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="priority"
                    isInvalid={
                      !!formik?.errors?.priority === true &&
                      !!formik?.touched?.priority === true
                    }
                  >
                    <FormLabel>Priority</FormLabel>
                    <Select
                      options={priorityOptions}
                      id="priority"
                      placeholder={"High, Medium, or Low"}
                      value={formik.values.priority}
                      name="priority"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("priority", e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.priority?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="assignedTo"
                    isInvalid={
                      !!formik?.errors?.assignedTo === true &&
                      !!formik?.touched?.assignedTo === true
                    }
                  >
                    <FormLabel>Assigned To*</FormLabel>
                    <Select
                      isMulti
                      options={assignedToOptions}
                      id="assignedTo"
                      placeholder={"Team, Site, or Member."}
                      value={formik.values.assignedTo}
                      name="assignedTo"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("assignedTo", e);
                      }}
                    />
                    <FormHelperText>
                      The teams, sites, and members selected will be assigned to
                      resolve the action.
                    </FormHelperText>
                    <FormErrorMessage>
                      {formik?.errors?.assignedTo?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.dueDate === true &&
                      !!formik?.touched?.dueDate === true
                    }
                  >
                    <FormLabel>Due Date</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="dueDate"
                      name={"dueDate"}
                      value={formik.values.dueDate}
                      onChange={(e: any) => {
                        formik.setFieldValue("dueDate", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.dueDate?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  // onClick={onClose}
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <PageHeader title={"Inspection"} />
      <Stack p={5}>
        <Card direction={{ base: "row", sm: "row" }} variant="outline">
          <CardBody>{inspection && <Survey model={inspection} />}</CardBody>
          <CardFooter />
        </Card>
      </Stack>
    </Box>
  );
};
