import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Spinner,
  useToast,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  VStack,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag,
  HStack,
  Heading,
  Textarea,
  AlertIcon,
  Alert,
  AlertDescription,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  FormLabel,
  FormControl,
  FormErrorMessage,
  useBreakpointValue,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  useDocuments,
  useUploadDocument,
  useDeleteDocument,
  useSearchDocument,
} from "../../api/endpoints/documents";
import { Document, DocumentSearchResults } from "../../types-new";
import {
  DownloadIcon,
  UploadIcon,
  SearchIcon,
  XCircleIcon,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";
import { PageHeader } from "../../components/page-header";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/table";
import { DeleteButton } from "../../components/delete-button";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import remarkGfm from "remark-gfm";
import FileUploadButton from "../../components/file-upload-button";
import { Form, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { Select } from "chakra-react-select";
import {
  BiDotsHorizontal,
  BiDotsVertical,
  BiDotsVerticalRounded,
  BiSearch,
} from "react-icons/bi";

const DocumentsPage: React.FC = () => {
  const { data: documents, isLoading, isError } = useDocuments();
  const uploadDocument = useUploadDocument();
  const { mutate: deleteDocument } = useDeleteDocument();
  const {
    mutate: searchDocument,
    data: rawSearchResults,
    isPending: isSearchLoading,
  } = useSearchDocument();
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSecondDrawerOpen, setIsSecondDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] =
    useState<DocumentSearchResults | null>(null);

  useEffect(() => {
    if (rawSearchResults) {
      setSearchResults(rawSearchResults.data);
    }
  }, [rawSearchResults]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      toast({
        title: "Invalid file type",
        description: "Only PDF files are allowed.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const columnHelper = createColumnHelper<Document>();

  const columns = [
    columnHelper.accessor("name", {
      header: "File Name",
      cell: (info) => <Box p={2}>{info.getValue()}</Box>,
    }),
    columnHelper.accessor("category", {
      header: "Category",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("make", {
      header: "Make",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("model", {
      header: "Model",
      cell: (info) => info.getValue(),
    }),
  ];

  const desktopColumns = [
    columnHelper.accessor("sizeInBytes", {
      header: "Size",
      cell: (info) => `${(info.getValue() / 1024 / 1024).toFixed(2)} MB`,
    }),
  ];

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const commonLanguages = [
    { language: "English", flag: "🇬🇧" },
    { language: "Mandarin Chinese", flag: "🇨🇳" },
    { language: "Hindi", flag: "🇮🇳" },
    { language: "Spanish", flag: "🇲🇽" },
    { language: "French", flag: "🇫🇷" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(commonLanguages[0]);

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    make: Yup.string().required("Make is required"),
    model: Yup.string().required("Model is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      category: "",
      make: "",
      model: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!selectedFile) {
        toast({
          title: "No file selected",
          description: "Please select a file to upload.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("category", values.category);
      formData.append("make", values.make);
      formData.append("model", values.model);
      formData.append("description", values.description);
      try {
        await uploadDocument.mutate({ formData });
        toast({
          title: "Document uploaded",
          description: "Your document has been uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsUploadModalOpen(false);
        formik.resetForm();
        setSelectedFile(null);
      } catch (error) {
        toast({
          title: "Upload failed",
          description: "There was an error uploading your document.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  const categories = [
    { label: "Training", value: "training" },
    { label: "Equipment", value: "equipment" },
    { label: "Other", value: "other" },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });

  const CategorySelect = () => (
    <FormControl
      isInvalid={
        formik.touched.category && formik.errors.category !== undefined
      }
    >
      <FormLabel>Category</FormLabel>
      <Select
        placeholder="Select category"
        {...formik.getFieldProps("category")}
        options={categories}
        onChange={(e) => formik.setFieldValue("category", e?.value)}
        value={categories.find((c) => c.value === formik.values.category)}
      />

      <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
    </FormControl>
  );

  const [isUnqualifiedModalOpen, setIsUnqualifiedModalOpen] = useState(false);
  const [prevSearchResults, setPrevSearchResults] =
    useState<DocumentSearchResults | null>(null);
  useEffect(() => {
    if (searchResults && !Object.is(searchResults, prevSearchResults)) {
      setIsUnqualifiedModalOpen(!searchResults.isQualified);
      setPrevSearchResults(searchResults);
    }
  }, [searchResults]);

  const UnqualifiedModal = () => (
    <Modal
      isOpen={isUnqualifiedModalOpen}
      onClose={() => setIsUnqualifiedModalOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>DANGER</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Our records show that you are not qualified to complete the work
            associated with this search. Confirm with your supervisor before
            proceeding.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor={"red"}
            variant={"inverted"}
            mr={3}
            onClick={() => setIsUnqualifiedModalOpen(false)}
          >
            I understand
          </Button>
          <Button
            variant="ghost"
            onClick={() => setIsUnqualifiedModalOpen(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Documents"></PageHeader>
      <UnqualifiedModal />
      <Card variant={"outline"} m={5} mb={10}>
        <CardBody>
          <DataTable
            columns={isMobile ? columns : [...columns, ...desktopColumns]}
            data={documents || []}
            actionChildren={
              <>
                <Button
                  leftIcon={<UploadIcon />}
                  variant={"outline"}
                  onClick={() => setIsUploadModalOpen(true)}
                >
                  Upload Document
                </Button>
              </>
            }
            onRowClick={(row) => {
              setSelectedDocument((documents ?? [])[row]);
              setIsDrawerOpen(true);
            }}
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <VStack spacing={4}>
                  <FileUploadButton onUpdateFile={setSelectedFile} />
                  <CategorySelect />
                  <FormControl
                    isInvalid={
                      formik.touched.make && formik.errors.make !== undefined
                    }
                  >
                    <FormLabel>Make</FormLabel>
                    <Input
                      {...formik.getFieldProps("make")}
                      placeholder="Enter make"
                    />
                    <FormErrorMessage>{formik.errors.make}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.touched.model && formik.errors.model !== undefined
                    }
                  >
                    <FormLabel>Model</FormLabel>
                    <Input
                      {...formik.getFieldProps("model")}
                      placeholder="Enter model"
                    />
                    <FormErrorMessage>{formik.errors.model}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      formik.touched.description &&
                      formik.errors.description !== undefined
                    }
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      {...formik.getFieldProps("description")}
                      placeholder="Enter description"
                    />
                    <FormErrorMessage>
                      {formik.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </Form>
            </FormikProvider>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="inverted"
              mr={3}
              onClick={() => formik.handleSubmit()}
              isDisabled={!selectedFile || !formik.isValid}
            >
              Upload
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsUploadModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => setIsDrawerOpen(false)}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={{ base: 0, md: 5 }}>
            {
              <>
                <Drawer
                  isOpen={isSecondDrawerOpen}
                  placement={isMobile ? "bottom" : "right"}
                  onClose={() => setIsSecondDrawerOpen(false)}
                  size="sm"
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                      <HStack justifyContent={"space-between"} pr={4}>
                        <Heading size={"md"}>Search Document</Heading>
                        <Menu colorScheme="red">
                          <MenuButton
                            as={Button}
                            aria-label="Options"
                            variant="outline"
                          >
                            {selectedLanguage.flag} {selectedLanguage.language}
                          </MenuButton>
                          <MenuList
                            shadow="lg"
                            py="4"
                            color={useColorModeValue("gray.600", "gray.200")}
                            px="3"
                          >
                            {commonLanguages.map((language) => (
                              <MenuItem
                                key={language.language}
                                onClick={() => setSelectedLanguage(language)}
                              >
                                {language.flag} {language.language}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      </HStack>
                    </DrawerHeader>
                    <DrawerBody maxH={{ base: "50vh", md: "100%" }}>
                      <VStack spacing={4} align="stretch">
                        <Textarea
                          placeholder="Enter question. e.g. 'what does the red led mean?' or 'how to replace the charging cable?'"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          value={searchQuery}
                        />
                        {selectedDocument && (
                          <Button
                            variant={"inverted"}
                            onClick={() => {
                              setSearchResults(null);
                              searchDocument({
                                id: selectedDocument.id,
                                query:
                                  searchQuery +
                                  ` the source is in english but return your entire response in ${selectedLanguage.language}. reference the page numbers in english.`,
                              });
                            }}
                            isDisabled={
                              searchQuery === "" ||
                              selectedDocument === null ||
                              isSearchLoading
                            }
                            _hover={{
                              backgroundColor: "brand.primary.200",
                            }}
                          >
                            {searchQuery !== "" &&
                            selectedDocument !== null &&
                            isSearchLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              "Search"
                            )}
                          </Button>
                        )}
                      </VStack>

                      {!isSearchLoading && searchResults && searchQuery && (
                        <Stack mt={4}>
                          {!searchResults.isQualified ? (
                            <Alert status="error">
                              <AlertIcon />
                              <AlertDescription>
                                You are not qualified to perform this work.
                                Please confirm with your manager before
                                proceeding.
                              </AlertDescription>
                            </Alert>
                          ) : (
                            <Alert status="success">
                              <AlertIcon />
                              <AlertDescription>
                                You are qualified to perform this work. Proceed
                                with caution.
                              </AlertDescription>
                            </Alert>
                          )}

                          <Alert status="warning">
                            <AlertIcon />
                            <AlertDescription>
                              Always reference the full manual for complete and
                              accurate information. Do not rely solely on this
                              summary.
                            </AlertDescription>
                          </Alert>
                          {searchResults.references &&
                            searchResults.references.length > 0 && (
                              <VStack mt={4} align="stretch" spacing={2}>
                                <Text fontWeight="bold">Jump To Page:</Text>
                                {searchResults.references.map(
                                  (reference, index) => (
                                    <Button
                                      key={index}
                                      size="sm"
                                      variant="outline"
                                      justifyContent="flex-start"
                                      rightIcon={<ChevronRight />}
                                      onClick={() => {
                                        pageNavigationPluginInstance.jumpToPage(
                                          reference.pageNumber - 1
                                        );
                                      }}
                                    >
                                      Page {reference.pageNumber}
                                    </Button>
                                  )
                                )}
                              </VStack>
                            )}
                          <Text fontWeight="bold">Summary:</Text>
                          <Box
                            borderWidth={1}
                            borderRadius="md"
                            p={4}
                            maxHeight="400px"
                            overflowY="auto"
                          >
                            <ReactMarkdown
                              components={ChakraUIRenderer()}
                              children={searchResults.content}
                              skipHtml
                              remarkPlugins={[remarkGfm]}
                            />
                          </Box>
                        </Stack>
                      )}
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>

                <SimpleGrid
                  mt={{ base: 10, md: 0 }}
                  pt={{ base: 10, md: 0 }}
                  columns={3}
                  mb={2}
                  width={"100%"}
                  spacing={2}
                  mx={2}
                >
                  <Button
                    variant={"outline"}
                    rightIcon={<ChevronLeft />}
                    onClick={() => {
                      setIsDrawerOpen(false);
                      setIsSecondDrawerOpen(false);
                      setSelectedDocument(null);
                      setSearchResults(null);
                      setSearchQuery("");
                    }}
                  >
                    Back
                  </Button>
                  <Menu matchWidth>
                    <MenuButton
                      as={Button}
                      variant={"outline"}
                      justifyContent="center"
                      flexDirection={"column"}
                    >
                      <HStack alignItems={"center"}>
                        <Text p={0} m={"0 !important"}>
                          Actions
                        </Text>
                        <BiDotsVerticalRounded />
                      </HStack>
                    </MenuButton>
                    <MenuList zIndex={9999} p={2} gap={5}>
                      <Button
                        width={"100%"}
                        variant={"outline"}
                        onClick={() => {
                          window.open(selectedDocument?.blobUrl, "_blank");
                        }}
                        leftIcon={<DownloadIcon />}
                        mb={2}
                      >
                        Download
                      </Button>
                      <DeleteButton
                        onDelete={() => {
                          deleteDocument({ id: selectedDocument?.id ?? "" });
                          setIsDrawerOpen(false);
                          setSelectedDocument(null);
                          setSearchResults(null);
                          setSearchQuery("");
                          setIsSecondDrawerOpen(false);
                        }}
                        width={"100%"}
                        deleteButtonText="Delete Document"
                        backgroundColor={"red.500"}
                        color={"white"}
                        deleteKey="delete"
                        aria-label="delete document"
                        modalHeader={`Delete Document`}
                        modalText={`Are you sure you want to delete this document? This action cannot be undone.`}
                        name={"Delete"}
                        variant={"outline"}
                      />
                    </MenuList>
                  </Menu>
                  <Button
                    variant={"outline"}
                    rightIcon={<BiSearch />}
                    onClick={() => setIsSecondDrawerOpen(true)}
                  >
                    Search
                  </Button>
                </SimpleGrid>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                  <Viewer
                    fileUrl={selectedDocument?.blobUrl ?? ""}
                    plugins={[
                      defaultLayoutPluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                  />
                </Worker>
              </>
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default DocumentsPage;
