import {
  Box,
  Stack,
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  HStack,
  CardHeader,
  Tag,
  TagLabel,
  SkeletonText,
  Center,
  IconButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as ChecklistIllustration } from "../../icons/checklist.svg";
import { useMyCertificates } from "../../api/endpoints/my";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { BiDownload, BiTrash } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { MyCertificate } from "../../types";
import { FormikProvider, Field, useFormik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useDeleteCertificate,
  useUploadCertificate,
} from "../../api/endpoints/certificates";
import { Select } from "chakra-react-select";
import { AwardIcon } from "lucide-react";

export const MyCertificatesListPage: React.FC = () => {
  const { data: certificates, isLoading: certificatesLoading } =
    useMyCertificates();
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { mutate: uploadCertificate, isError: uploadCertificateError } =
    useUploadCertificate();

  const { mutate: deleteCertificate, isError: deleteCertificateError } =
    useDeleteCertificate();

  const schema = Yup.object().shape({
    issuedBy: Yup.object().required("Issued By is required."),
    identifier: Yup.string().min(3).required("Identifier is required."),
    validFrom: Yup.date().required("Valid from date is required."),
    validTo: Yup.date(),
    file: Yup.mixed().test(
      "fileSize",
      "The certificate file is too large",
      (value: any) => {
        if (!value) return false;
        return value.size <= 5242880;
      }
    ),
  });

  const initialValues: {
    name?: string;
    issuedBy?: string;
    identifier?: string;
    validFrom?: string;
    validTo?: string;
    file?: File;
  } = {
    name: undefined,
    issuedBy: undefined,
    identifier: undefined,
    validFrom: undefined,
    validTo: undefined,
    file: undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      console.log(values);
      await uploadCertificate({
        data: {
          identifier: values.identifier,
          name: values.issuedBy.value,
          issuedBy: values.issuedBy.issuer,
          validFrom: values.validFrom,
          validTo: values.validTo,
          file: values.file,
        },
      });
      onToggle();
    },
  });

  const certificateOptions: readonly any[] = [
    {
      label: "ASE",
      options: [
        { label: "xEV Level 1", value: "xEV Level 1", issuer: "ASE" },
        { label: "xEV Level 2", value: "xEV Level 2", issuer: "ASE" },
        { label: "Other", value: "other", issuer: "ASE" },
      ],
    },
  ];

  const hiddenCertificateFileInput = useRef(null);

  const handleCertificateFileClick = (event: any) => {
    //@ts-ignore
    hiddenCertificateFileInput.current.click();
  };

  const handleCertificateFileChange = (event: any) => {
    if (event?.target?.files?.length === 0) return;

    const file = event.target.files[0];

    if (file.size > 5242880) {
      toast.error("Certificate file is larger than 5MB.");
    } else {
      formik.setFieldValue("file", file);
    }
  };

  return (
    <Box height={"100%"} overflow={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Upload Existing Certificate</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.identifier === true &&
                      !!formik?.touched?.identifier === true
                    }
                  >
                    <FormLabel>Certificate ID</FormLabel>
                    <Field
                      as={Input}
                      id="identifier"
                      name={"identifier"}
                      type="text"
                      placeholder="The unique ID of the certificate"
                      value={formik.values.identifier}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.identifier?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="issuedBy"
                    isInvalid={
                      !!formik?.errors?.issuedBy === true &&
                      !!formik?.touched?.issuedBy === true
                    }
                  >
                    <FormLabel>Type</FormLabel>
                    <Select
                      options={certificateOptions}
                      id="issuedBy"
                      placeholder={"What type of certificate is this?"}
                      value={formik.values.issuedBy}
                      name="issuedBy"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("issuedBy", e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.issuedBy?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validFrom === true &&
                      !!formik?.touched?.validFrom === true
                    }
                  >
                    <FormLabel>Valid From</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validFrom"
                      name={"validFrom"}
                      value={formik.values.validFrom}
                      onChange={(e: any) => {
                        formik.setFieldValue("validFrom", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validFrom?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.validTo === true &&
                      !!formik?.touched?.validTo === true
                    }
                  >
                    <FormLabel>Valid To</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="validTo"
                      name={"validTo"}
                      value={formik.values.validTo}
                      onChange={(e: any) => {
                        formik.setFieldValue("validTo", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.validTo?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.file === true &&
                      !!formik?.touched?.file === true
                    }
                  >
                    <HStack display={"flex"} mb={2}>
                      <FormLabel mb={0} mr={1}>
                        Certificate File
                      </FormLabel>
                      <Text color={"fg.muted"} fontSize={"xs"}>
                        (5MB Max File Size)
                      </Text>
                    </HStack>
                    <HStack>
                      {!formik.values.file && (
                        <Button
                          variant="solid"
                          colorScheme="brand.primary"
                          onClick={handleCertificateFileClick}
                        >
                          Attach Certificate
                        </Button>
                      )}
                      {formik.values.file !== undefined && (
                        <>
                          <Button
                            className="button-upload"
                            background={"red.500"}
                            color={"white"}
                            width={"40%"}
                            _hover={{ background: "red.500" }}
                            onClick={() => {
                              formik.setFieldValue("file", undefined);
                            }}
                          >
                            Remove Certificate
                          </Button>
                          <Text
                            width={"60%"}
                            fontSize={"xs"}
                            textOverflow={"ellipsis"}
                          >
                            {formik.values.file.name}
                          </Text>
                        </>
                      )}
                    </HStack>
                    <Input
                      type="file"
                      onChange={handleCertificateFileChange}
                      ref={hiddenCertificateFileInput}
                      style={{ display: "none" }}
                    ></Input>
                    <FormErrorMessage>
                      {formik?.errors?.file?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <Text color={"fg.muted"}>
                    By submitting this certificate you agree that the
                    certificate submitted is accurate to the best of your
                    knowledge.
                  </Text>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Stack m={{ base: 1, sm: 5 }} p={{ base: 1, sm: 5 }}>
        <Card variant={"outline"} minH={"100vh"}>
          <CardHeader pb={0}>
            <Button
              leftIcon={<BsArrowLeft />}
              variant={"ghost"}
              p={0}
              mb={4}
              alignSelf={"start"}
              onClick={() => navigate("/my/courses")}
            >
              Back
            </Button>
            <Heading as="h3" size="md">
              My Certificates
            </Heading>
          </CardHeader>
          <CardBody height={"100%"}>
            <HStack>
              <Button
                variant={"solid"}
                colorScheme={"brand.primary"}
                alignSelf={"start"}
                onClick={() => onToggle()}
              >
                Add External Certificate
              </Button>
            </HStack>
            <SkeletonText
              width="100%"
              skeletonHeight={20}
              noOfLines={3}
              isLoaded={!certificatesLoading}
            ></SkeletonText>
            {certificates?.map((certificate: MyCertificate, index: number) => {
              return (
                <Card my={6} key={index} variant={"outline"}>
                  <CardBody>
                    <Stack>
                      <HStack justify={"space-between"} mb={0} pb={0}>
                        <Heading
                          size={"sm"}
                          display={"flex"}
                          flexDir={"row"}
                          alignItems={"center"}
                        >
                          <AwardIcon />
                          <Text ml={2}>
                            {index + 1}. {certificate.name}
                          </Text>
                        </Heading>
                        <HStack>
                          <IconButton
                            icon={<BiDownload />}
                            background={"brand.primary.500"}
                            aria-label="download-certificate"
                            color={"white"}
                            onClick={() => {
                              window.open(certificate.url, "_blank");
                            }}
                          />
                          {certificate.issuedBy !== "Axle Mobility" && (
                            <IconButton
                              background={"red.500"}
                              color={"white"}
                              icon={<BiTrash />}
                              aria-label="delete-certificate"
                              onClick={() => {
                                deleteCertificate(certificate.id ?? "");
                              }}
                            />
                          )}
                        </HStack>
                      </HStack>
                      <HStack
                        mt={0}
                        pt={0}
                        flexDirection={{ base: "column", md: "row" }}
                        width={{ base: "100%", md: "initial" }}
                      >
                        <Tag
                          size={"sm"}
                          width={{ base: "100%", md: "initial" }}
                          variant="outline"
                          colorScheme="brand.primary"
                          borderRadius={"full"}
                        >
                          <TagLabel>Issued By: {certificate.issuedBy}</TagLabel>
                        </Tag>

                        <Tag
                          size={"sm"}
                          width={{ base: "100%", md: "initial" }}
                          variant="outline"
                          colorScheme="brand.primary"
                          borderRadius={"full"}
                        >
                          <TagLabel>
                            Issued On:{" "}
                            {
                              new Date(certificate.validFrom)
                                .toISOString()
                                .split("T")[0]
                            }
                          </TagLabel>
                        </Tag>
                        <Tag
                          size={"sm"}
                          width={{ base: "100%", md: "initial" }}
                          variant="outline"
                          colorScheme="brand.primary"
                          borderRadius={"full"}
                        >
                          <TagLabel>
                            Expires On:{" "}
                            {certificate.validTo &&
                              new Date(certificate.validTo)
                                .toISOString()
                                .split("T")[0]}
                            {!certificate.validTo && "N/A"}
                          </TagLabel>
                        </Tag>
                      </HStack>
                    </Stack>
                  </CardBody>
                </Card>
              );
            })}
            {certificates?.length === 0 && (
              <Center flexDirection={"column"} height={"50vh"}>
                <ChecklistIllustration height={"50%"} width={"50%"} />
                <Text textAlign={"center"} mt={10}>
                  You don't have any course certificates right now. Complete
                  courses to obtain certificates or upload certificates you've
                  earned from other organizations.
                </Text>
              </Center>
            )}
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
