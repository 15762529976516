import {
  Box,
  HStack,
  Card,
  CardBody,
  Heading,
  CardFooter,
  Text,
  Stack,
  Button,
  VStack,
  Center,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState, useEffect } from "react";

import { Document, Page } from "react-pdf";
import { Select } from "chakra-react-select";
import QRCode from "react-qr-code";

import AcuraILXHEV20132014ERG from "./guides/Acura-ILX-HEV-2013-2014-ERG.pdf";
import x201420RLXSportHybrid from "./guides/2014-20-RLX-Sport-Hybrid.pdf";
import x201720MDXSportHybrid from "./guides/2017-20-MDX-Sport-Hybrid.pdf";
import x201722NSX from "./guides/2017-22-NSX.pdf";
import AudiQ5HEV20132016ERG from "./guides/Audi-Q5-HEV-2013-2016-ERG.pdf";
import AudiA3etronPHEV20142020ISOformat from "./guides/Audi-A3-e-tron-PHEV-2014-2020---ISO-format.pdf";
import AudiA3SportbacketronPHEV20162018ERG from "./guides/Audi-A3-Sportback-etron-PHEV-2016-2018-ERG.pdf";
import AudiA7PHEVFrom2019 from "./guides/Audi_A7_PHEV_From-2019.pdf";
import Audi_A8_PHEVFrom2019 from "./guides/Audi_A8_PHEV_From-2019.pdf";
import AudietronSUVEV2019 from "./guides/AudietronSUVEV2019.pdf";
import AudietronEVSUV2019French from "./guides/Audi-etron-EV-SUV-2019-French.pdf";
import Audi_Q5_PHEVFrom2019 from "./guides/Audi_Q5_PHEV_From-2019.pdf";
import Audi_etronGTFrom2020 from "./guides/Audi_e-tron-GT_From-2020.pdf";
import AudietronSportbackfrom from "./guides/Audietron--SportbackFrom-2020.pdf";
import x2022AudiQ4etron from "./guides/2022-AudiQ4e-tron.pdf";
import AudiQ8SQ8etron2023 from "./guides/AudiQ8-SQ8etron-2023.pdf";
import Audietronemergencyresponseguide from "./guides/Audi-e-tron-emergency-response-guide.pdf";
import AudiEmergencyResponseGuideISOformat from "./guides/Audi-Emergency-Response-Guide---ISO-format.pdf";
import x2022AutocarEACTTFirstRespondersManualAWE00424Rev001 from "./guides/2022-Autocar-E-ACTT-First-Responders-Manual-AWE-00424-Rev-001.pdf";
import V2_ERG_Battista_FinaHM011223 from "./guides/V2_ERG_Battista_FinalPDF-HM-011223.pdf";
import BentleyBentayga from "./guides/Bentley-Bentayga.pdf";
import BentleyEmergencyResponseGuide from "./guides/Bentley-Emergency-Response-Guide.pdf";
import FRGBBCV_EV from "./guides/FRG-BBCV_EV.pdf";
import BentleyFlyingSpurHybrid2022 from "./guides/Bentley-Flying-Spur-Hybrid-2022.pdf";
import FRGT3RE_EV from "./guides/FRG-T3RE_EV.pdf";
import x2022BrightDropEV600_EmergencyResponseGuide_english_US from "./guides/2022-BrightDrop-EV600_Emergency-Response-Guide_english_US.pdf";
import x2022BrightDropEV600_RescueSheet_english_US from "./guides/2022-BrightDrop-EV600_Rescue-Sheet_english_US.pdf";
import BrightdropZevo6002022EmergencyResponseGuide from "./guides/Brightdrop-Zevo-600-2022----Emergency-Response-Guide.pdf";
import BrightdropZevo6002023RescueSheeten_US from "./guides/Brightdrop-Zevo-600-2023---Rescue-Sheet-en_US.pdf";
import BuickLaCrossandRegalHEV20122016ERG from "./guides/Buick-LaCross-and-Regal-HEV-2012-2016-ERG.pdf";
import BuickLaCrossandRegalHEV20122016QRG from "./guides/Buick-LaCross-and-Regal-HEV-2012-2016-QRG.pdf";
import BuickLaCrossHEV20182019QRG from "./guides/Buick-LaCross-HEV-2018-2019-QRG.pdf";
import BYDK9MSeriesBusEVERG from "./guides/BYD-K9M-Series-Bus-EV-ERG.pdf";
import CadillacEscaladeHEV20082013QRG from "./guides/Cadillac-Escalade-HEV-2008-2013-QRG.pdf";
import CadillacELREREV20142016ERG from "./guides/Cadillac-ELR-EREV-2014-2016-ERG.pdf";
import CadillacELREREV20142016QRG from "./guides/Cadillac-ELR-EREV-2014-2016-QRG.pdf";
import CadillacCT6PHEV20172018QRG from "./guides/Cadillac-CT6-PHEV-2017-2018-QRG.pdf";
import x2023CadillacLYRIQ_EmergencyResponseGuide_english from "./guides/2023-Cadillac-LYRIQ_Emergency-Response-Guide_english.pdf";
import x2023CadillacLYRIQ_RescueSheet_english from "./guides/2023-Cadillac-LYRIQ_Rescue-Sheet_english.pdf";
import CadillacLyriqHatchback2023EmergencyResponseGuide from "./guides/Cadillac-Lyriq-Hatchback-2023---Emergency-Response-Guide.pdf";
import CadillacLyriq2023EmergencyResponseGuide_FC from "./guides/Cadillac-Lyriq-2023---Emergency-Response-Guide_FC.pdf";
import CadillacLyriqHatchback2023RescueSheeten_US from "./guides/Cadillac-Lyriq-Hatchback-2023---Rescue-Sheet-en_US.pdf";
import CadillacLyriq2023RescueSheet_FC from "./guides/Cadillac-Lyriq-2023---Rescue-Sheet_FC.pdf";
import ChevroletExpressCNG20112019QRG from "./guides/Chevrolet-Express-CNG-2011-2019-QRG.pdf";
import ChevroletSilveradoBiFuelandExpressCNGandLPGERG from "./guides/Chevrolet-Silverado-Bi-Fuel-and-Express-CNG-and-LPG-ERG.pdf";
import ChevroletTahoeHEV20082013ERG from "./guides/Chevrolet-Tahoe-HEV-2008-2013-ERG.pdf";
import ChevroletTahoeHEV20082013QRG from "./guides/Chevrolet-Tahoe-HEV-2008-2013-QRG.pdf";
import ChevroletSilveradoHEV20092013QRG from "./guides/Chevrolet-Silverado-HEV-2009-2013-QRG.pdf";
import ChevroletSilveradoHEV20092013ERG from "./guides/Chevrolet-Silverado-HEV-2009-2013-ERG.pdf";
import ChevroletVoltEREV20112015ERG from "./guides/Chevrolet-Volt-EREV-2011-2015-ERG.pdf";
import ChevroletVoltEREV20112015QRG from "./guides/Chevrolet-Volt-EREV-2011-2015-QRG.pdf";
import ChevroletSparkEV20142016ERG from "./guides/Chevrolet-Spark-EV-2014-2016-ERG.pdf";
import ChevroletSparkEV20142016QRG from "./guides/Chevrolet-Spark-EV-2014-2016-QRG.pdf";
import ChevroletImpalaCNG20152016QRG from "./guides/Chevrolet-Impala-CNG-2015-2016-QRG.pdf";
import ChevroletSilveradoHEV20162018QRG from "./guides/Chevrolet-Silverado-HEV-2016-2018-QRG.pdf";
import ChevroletVoltEREV20162019 from "./guides/Chevrolet-Volt-EREV-2016-2019.pdf";
import ChevroletBoltEV20172019QRG from "./guides/Chevrolet-Bolt-EV-2017-2019-QRG.pdf";
import x2022ChevroletBoltEUV_EmergencyResponseGuide_English from "./guides/2022-Chevrolet-Bolt-EUV_Emergency-Response-Guide_English.pdf";
import ChevroletBoltEUV_RescueSheet2022 from "./guides/Chevrolet-Bolt-EUV_Rescue-Sheet-2022.pdf";
import x2022ChevroletBoltEV_EmergencyResponseGuide_English from "./guides/2022-Chevrolet-Bolt-EV_Emergency-Response-Guide_English.pdf";
import ChevroletBoltEV_RescueSheet_2022 from "./guides/Chevrolet-Bolt-EV_Rescue-Sheet_2022.pdf";
import ChevroletBlazerEV2024EmergencyResponseGuideen_US from "./guides/Chevrolet-Blazer-EV-2024---Emergency-Response-Guide-en_US.pdf";
import ChevroletBlazerEV2024EmergencyResponseGuidefr_CA from "./guides/Chevrolet-Blazer-EV-2024---Emergency-Response-Guide-fr_CA.pdf";
import ChevroletBlazerEV2024RescueSheeten_US from "./guides/Chevrolet-Blazer-EV-2024---Rescue-Sheet-en_US.pdf";
import ChevroletBlazerEV2024RescueSheetfr_CA from "./guides/Chevrolet-Blazer-EV-2024---Rescue-Sheet-fr_CA.pdf";
import ChevroletCorvetteERayCabrio20242dHybridElectricEN1G123102 from "./guides/ChevroletCorvetteERayCabrio20242dHybrid-ElectricEN1G123102.pdf";
import ChevroletCorvetteERay20242dHybridElectricFCERG1G1231011G123102 from "./guides/ChevroletCorvetteERay20242dHybrid-ElectricFCERG1G1231011G123102.pdf";
import ChevroletCorvetteERay20242dHybridElectricENERG1G1231011G123102 from "./guides/ChevroletCorvetteERay20242dHybrid-ElectricENERG1G1231011G123102.pdf";
import ChevroletCorvetteERayCoupe20242dHybridElectricFC1G123101 from "./guides/ChevroletCorvetteERayCoupe20242dHybrid-ElectricFC1G123101.pdf";
import ChevroletCorvetteERayCoupe20242dHybridElectricEN1G123101 from "./guides/ChevroletCorvetteERayCoupe20242dHybrid-ElectricEN1G123101.pdf";
import ChevroletCorvetteERayCabrio20242dHybridElectricFC1G123102 from "./guides/ChevroletCorvetteERayCabrio20242dHybrid-ElectricFC1G123102.pdf";
import Chevrolet_EquinoxEV__SUV_2024_5d_Electric_EN_ERG_3GN23105 from "./guides/Chevrolet_Equinox-EV__SUV_2024_5d_Electric_EN_ERG_3GN-23105.pdf";
import Chevrolet_EquinoxEV__SUV_2024_5d_Electric_FC_ERG_3GN23105 from "./guides/Chevrolet_Equinox-EV__SUV_2024_5d_Electric_FC_ERG_3GN-23105.pdf";
import Chevrolet_EquinoxEV__SUV_2024_5d_Electric_EN_3GN23105 from "./guides/Chevrolet_Equinox-EV__SUV_2024_5d_Electric_FC_ERG_3GN-23105.pdf";
import ChevroletEquinoxFCV20072008ERG from "./guides/Chevrolet-Equinox-FCV-2007-2008-ERG.pdf";
import ChevroletEquinoxFCV20072008QRG from "./guides/Chevrolet-Equinox-FCV-2007-2008-QRG.pdf";
import ChevroletMalibuHEV20132015ERG from "./guides/Chevrolet-Malibu-HEV-2013-2015-ERG.pdf";
import ChevroletMalibuHEV20132015QRG from "./guides/Chevrolet-Malibu-HEV-2013-2015-QRG.pdf";
import ChevroletMalibuHEV20072009ERG from "./guides/Chevrolet-Malibu-HEV-2007-2009-ERG.pdf";
import ChevroletMalibuHEV20072009QRG from "./guides/Chevrolet-Malibu-HEV-2007-2009-QRG.pdf";
import ChevroletMalibuHEV20162019QRG from "./guides/Chevrolet-Malibu-HEV-2016-2019-QRG.pdf";
import ChevroletSilveradoHEV20042007ERG from "./guides/Chevrolet-Silverado-HEV-2004-2007-ERG.pdf";
import ChryslerAspenHEV2009ERG from "./guides/Chrysler-Aspen-HEV-2009-ERG.pdf";
import x20172021ChryslerPacificaRUERGISO17840Final from "./guides/2017-2021-Chrysler-Pacifica-RU-ERG-ISO-17840-Final.pdf";
import ChryslerPacificaPHEV20172018ERG from "./guides/Chrysler-Pacifica-PHEV-2017-2018-ERG.pdf";
import x2022ChryslerPacificaPHEVERG from "./guides/2022-Chrysler-Pacifica-PHEV-ERG.pdf";
import DodgeDurangoHEV2009ERG from "./guides/Dodge-Durango-HEV-2009-ERG.pdf";
import FIAT500eEV20132018ERG from "./guides/FIAT-500e-EV-2013-2018-ERG.pdf";
import FiskerKarmaPHEV20112012ERG from "./guides/Fisker-Karma-PHEV-2011-2012-ERG.pdf";
import { pdfjs } from "react-pdf";
import { PageHeader } from "../../components/page-header";

export const EmergencyGuidesViewPage: React.FC = () => {
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   "pdfjs-dist/build/pdf.worker.min.mjs",
  //   import.meta.url
  // ).toString();

  const data: Record<
    string,
    {
      title: string;
      link?: string;
      fallback?: string;
      fallbackReason?: string;
    }[]
  > = {
    Acura: [
      {
        title: "Acura 2013 ILX Hybrid Guide",
        link: AcuraILXHEV20132014ERG,
      },
      {
        title: "Acura 2014 2016 2020 RLX Sport Hybrid Guide",
        link: x201420RLXSportHybrid,
      },
      {
        title: "Acura 2017 2020 MDX Sport Hybrid Guide",
        link: x201720MDXSportHybrid,
      },
      {
        title: "Acura 2017 2022 NSX Hybrid Guide",
        link: x201722NSX,
      },
    ],
    Audi: [
      {
        title: "Audi 2013 2016 Q5 Hybrid Quattro Guide",
        link: AudiQ5HEV20132016ERG,
      },
      {
        title: "Audi 2014 2020 A3 E Tron Sportback Guide",
        link: AudiA3etronPHEV20142020ISOformat,
      },
      {
        title: "Audi 2014 A3 E tron Sportback Guide",
        link: AudiA3SportbacketronPHEV20162018ERG,
      },
      {
        title: "Audi 2019 A7 TFSI e Sedan Guide",
        link: AudiA7PHEVFrom2019,
      },
      {
        title: "Audi 2019 A8 TFSI e Sedan Guide",
        link: Audi_A8_PHEVFrom2019,
      },
      {
        title: "Audi 2019 E tron SUV Guide English",
        link: AudietronSUVEV2019,
      },
      {
        title: "Audi 2019 E tron SUV Guide French",
        link: AudietronEVSUV2019French,
      },
      {
        title: "Audi 2019 Q5 TFSI e SUV and Sportback Guide",
        link: Audi_Q5_PHEVFrom2019,
      },
      {
        title: "Audi 2020 E tron GT 5 Door Model Guide",
        link: Audi_etronGTFrom2020,
      },
      {
        title: "Audi 2020 E tron SUV and E tron Sportback Guide",
        link: AudietronSportbackfrom,
      },
      {
        title: "Audi 2021 Q4 E tron SUV and E tron Sportback Guide",
        link: x2022AudiQ4etron,
      },
      {
        title: "Audi 2023 Q8/SQ8 etron",
        link: AudiQ8SQ8etron2023,
      },
      {
        title: "Audi E tron Emergency Response Guide",
        link: Audietronemergencyresponseguide,
      },
      {
        title: "Audi Emergency Response Guide ISO Format",
        link: AudiEmergencyResponseGuideISOformat,
      },
    ],
    Autocar: [
      {
        title: "Autocar EACTT™ 2022",
        link: x2022AutocarEACTTFirstRespondersManualAWE00424Rev001,
      },
    ],
    Automobili: [
      {
        title: "Automobili Pininfarina Battista 2022",
        link: V2_ERG_Battista_FinaHM011223,
      },
    ],
    Bentley: [
      {
        title: "Bentley 2021 Bentayga Hybrid SUV Guide",
        link: BentleyBentayga,
      },
      {
        title: "Bentley Emergency Response Guide",
        link: BentleyEmergencyResponseGuide,
      },
      {
        title: "Bentley Emergency Response Guide",
        link: FRGBBCV_EV,
      },
      {
        title: "Bentley Flying Spur Hybrid 2022",
        link: BentleyFlyingSpurHybrid2022,
      },
    ],
    Blue: [
      {
        title: "Blue Bird T3RE Emergency Response Guide 2019",
        link: FRGT3RE_EV,
      },
    ],
    BMW: [
      {
        title: "BMW Emergency Response Guides",
        link: undefined,
        fallback: "https://aos.bmwgroup.com/web/oss/service/rescueinfo",
        fallbackReason:
          "BMW maintains their own resuce information. Navigate to BMW's website directly by following this link.",
      },
    ],
    BrightDrop: [
      {
        title: "BrightDrop 2022 EV600 Truck/Van Guide",
        link: x2022BrightDropEV600_EmergencyResponseGuide_english_US,
      },
      {
        title: "BrightDrop 2022 EV600 Truck/Van Rescue Sheet",
        link: x2022BrightDropEV600_RescueSheet_english_US,
      },
      {
        title: "BrightDrop Emergency Response Guide Zevo 600 2022 ",
        link: BrightdropZevo6002022EmergencyResponseGuide,
      },
      {
        title: "BrightDrop Rescue sheet Zevo 600 2023",
        link: BrightdropZevo6002023RescueSheeten_US,
      },
    ],
    Buick: [
      {
        title:
          "Buick 2012 15 LaCrosse eAssist Buick 2012 15 Regal eAssist Guide",
        link: BuickLaCrossandRegalHEV20122016ERG,
      },
      {
        title: "Buick 201215 LaCrosse Guide",
        link: BuickLaCrossandRegalHEV20122016QRG,
      },
      {
        title: "Buick 2018 LaCrosse eAssist (4 Door Sedan) Guide",
        link: BuickLaCrossHEV20182019QRG,
      },
    ],
    BYD: [
      {
        title: "BYD K9M Series Electric Bus Guide",
        link: BYDK9MSeriesBusEVERG,
      },
    ],
    Cadillac: [
      {
        title: "Cadillac 20082013 Escalade HEV Two mode Quick Reference Sheet",
        link: CadillacEscaladeHEV20082013QRG,
      },
      {
        title: "Cadillac 2014 ELR Guide",
        link: CadillacELREREV20142016ERG,
      },
      {
        title: "Cadillac 201416 ELR Guide",
        link: CadillacELREREV20142016QRG,
      },
      {
        title:
          "Cadillac 2016 CT6 Plug in Hybrid Electric Vehicle (4 Door Sedan) Guide",
        link: CadillacCT6PHEV20172018QRG,
      },
      {
        title:
          "Cadillac 2023 LYRIQ Passenger Car/Crossover Li ion Battery Guide",
        link: x2023CadillacLYRIQ_EmergencyResponseGuide_english,
      },
      {
        title: "Cadillac 2023 LYRIQ Rescue Sheet",
        link: x2023CadillacLYRIQ_RescueSheet_english,
      },
      {
        title: "Cadillac 2023 LYRIQ Rescue Sheet",
        link: x2023CadillacLYRIQ_RescueSheet_english,
      },
      {
        title: "Cadillac LYRIQ 5Door Hatchback Emergency Response Guide 2023",
        link: CadillacLyriqHatchback2023EmergencyResponseGuide,
      },
      {
        title:
          "Cadillac LYRIQ 5Door Hatchback Emergency Response Guide 2023 (French)",
        link: CadillacLyriq2023EmergencyResponseGuide_FC,
      },
      {
        title: "Cadillac LYRIQ 5Door Hatchback Rescue Sheet 2023",
        link: CadillacLyriqHatchback2023RescueSheeten_US,
      },
      {
        title: "Cadillac LYRIQ 5Door Hatchback Rescue Sheet 2023 French",
        link: CadillacLyriq2023RescueSheet_FC,
      },
    ],
    Chevrolet: [
      {
        title: "Chevrolet Express 2011 2019 CNG Quick Reference Guide",
        link: ChevroletExpressCNG20112019QRG,
      },
      {
        title: "Chevrolet Express 2014 CNG and LPG Guide",
        link: ChevroletSilveradoBiFuelandExpressCNGandLPGERG,
      },
      {
        title: "Chevrolet 2008 2013 Tahoe Hybrid and Silverado Guide",
        link: ChevroletTahoeHEV20082013ERG,
      },
      {
        title:
          "Chevrolet 2008 2013 Tahoe Hybrid and Silverado Quick Response Guide",
        link: ChevroletTahoeHEV20082013QRG,
      },
      {
        title: "Chevrolet 2009 2013 Silverado Quick Reference Guide",
        link: ChevroletSilveradoHEV20092013QRG,
      },
      {
        title: "Chevrolet 2009 2013 Tahoe Hybrid and Silverado Guide",
        link: ChevroletSilveradoHEV20092013ERG,
      },
      {
        title: "Chevrolet 2011 2015 Volt Guide",
        link: ChevroletVoltEREV20112015ERG,
      },
      {
        title: "Chevrolet 2011 2015 Volt Quick Response Guide",
        link: ChevroletVoltEREV20112015QRG,
      },
      {
        title: "Chevrolet 2014 2016 Spark EV Guide",
        link: ChevroletSparkEV20142016ERG,
      },
      {
        title: "Chevrolet 2014 2016 Spark EV Quick Response Guide",
        link: ChevroletSparkEV20142016QRG,
      },
      {
        title: "Chevrolet 2015 2016 Impala CNG Guide",
        link: ChevroletImpalaCNG20152016QRG,
      },
      {
        title: "Chevrolet 2016 2018 Silverado Quick Reference Guide",
        link: ChevroletSilveradoHEV20162018QRG,
      },
      {
        title: "Chevrolet 20162019 Volt Extended Range Guide",
        link: ChevroletVoltEREV20162019,
      },
      {
        title: "Chevrolet 2017 2021 Bolt Quick Reference Guide",
        link: ChevroletBoltEV20172019QRG,
      },
      {
        title: "Chevrolet 2022 Bolt EUV Guide",
        link: x2022ChevroletBoltEUV_EmergencyResponseGuide_English,
      },
      {
        title: "Chevrolet 2022 Bolt EUV Quick Reference Guide",
        link: ChevroletBoltEUV_RescueSheet2022,
      },
      {
        title: "Chevrolet 2022 Bolt EV Passenger Car Guide",
        link: x2022ChevroletBoltEV_EmergencyResponseGuide_English,
      },
      {
        title: "Chevrolet 2022 Bolt Guide",
        link: ChevroletBoltEV_RescueSheet_2022,
      },
      {
        title: "Chevrolet Blazer EV Emergency Response Guide 2024",
        link: ChevroletBlazerEV2024EmergencyResponseGuideen_US,
      },
      {
        title: "Chevrolet Blazer EV Emergency Response Guide 2024  French",
        link: ChevroletBlazerEV2024EmergencyResponseGuidefr_CA,
      },
      {
        title: "Chevrolet Blazer EV Quick Reference Guide 2024",
        link: ChevroletBlazerEV2024RescueSheeten_US,
      },
      {
        title: "Chevrolet Blazer EV Quick Reference Guide 2024  French",
        link: ChevroletBlazerEV2024RescueSheetfr_CA,
      },
      {
        title:
          "Chevrolet Corvette ERay 2Door Convertible Quick Reference Guide 2024",
        link: ChevroletCorvetteERayCabrio20242dHybridElectricEN1G123102,
      },
      {
        title:
          "Chevrolet Corvette ERay 2Door Coupe Convertible Emergency Response Guide (French) 2024",
        link: ChevroletCorvetteERay20242dHybridElectricFCERG1G1231011G123102,
      },
      {
        title:
          "Chevrolet Corvette ERay 2Door Coupe Convertible Emergency Response Guide 2024",
        link: ChevroletCorvetteERay20242dHybridElectricENERG1G1231011G123102,
      },
      {
        title:
          "Chevrolet Corvette ERay 2Door Coupe Quick Reference Guide (French) 2024",
        link: ChevroletCorvetteERayCoupe20242dHybridElectricFC1G123101,
      },
      {
        title: "Chevrolet Corvette ERay 2Door Coupe Quick Reference Guide 2024",
        link: ChevroletCorvetteERayCoupe20242dHybridElectricEN1G123101,
      },
      {
        title:
          "Chevrolet ERay 2Door Convertible Quick Reference Guide (French)",
        link: ChevroletCorvetteERayCabrio20242dHybridElectricFC1G123102,
      },
      {
        title:
          "Chevrolet Equinox EV 5Door Hatchback Emergency Response Guide 2024",
        link: Chevrolet_EquinoxEV__SUV_2024_5d_Electric_EN_ERG_3GN23105,
      },
      {
        title:
          "Chevrolet Equinox EV 5Door Hatchback Emergency Response Guide 2024 (French)",
        link: Chevrolet_EquinoxEV__SUV_2024_5d_Electric_FC_ERG_3GN23105,
      },
      {
        title:
          "Chevrolet Equinox EV 5Door Hatchback Quick Response Guide (French) 2024",
        link: Chevrolet_EquinoxEV__SUV_2024_5d_Electric_FC_ERG_3GN23105,
      },
      {
        title: "Chevrolet Equinox EV 5Door Hatchback Quick Response Guide 2024",
        link: Chevrolet_EquinoxEV__SUV_2024_5d_Electric_EN_3GN23105,
      },
      {
        title: "Chevrolet Equinox Fuel Cell 2007 2008 Guide",
        link: ChevroletEquinoxFCV20072008ERG,
      },
      {
        title: "Chevrolet Equinox Fuel Cell 2007 2008 Quick Reference Guide",
        link: ChevroletEquinoxFCV20072008QRG,
      },
      {
        title: "Chevrolet Malibu Eco 2015 2015 Guide",
        link: ChevroletMalibuHEV20132015ERG,
      },
      {
        title: "Chevrolet Malibu Eco 2015 2015 Quick Reference Guide",
        link: ChevroletMalibuHEV20132015QRG,
      },
      {
        title: "Chevrolet Malibu Hybrid 2007 2009 Guide",
        link: ChevroletMalibuHEV20072009ERG,
      },
      {
        title: "Chevrolet Malibu Hybrid 2007 2009 Quick Reference Guide",
        link: ChevroletMalibuHEV20072009QRG,
      },
      {
        title: "Chevrolet Malibu Hybrid 2016 2019 Quick Reference Guide",
        link: ChevroletMalibuHEV20162019QRG,
      },
      {
        title: "Chevrolet Silverado 2004 2007 Guide",
        link: ChevroletSilveradoHEV20042007ERG,
      },
    ],
    Chrysler: [
      {
        title: "Chrysler 2009 Aspen Guide",
        link: ChryslerAspenHEV2009ERG,
      },
      {
        title: "Chrysler 2017 2021 Pacifica Hybrid Guide",
        link: x20172021ChryslerPacificaRUERGISO17840Final,
      },
      {
        title: "Chrysler 2017 Pacifica Hybrid Guide",
        link: ChryslerPacificaPHEV20172018ERG,
      },
      {
        title: "Chrysler 2022 Pacifica Hybrid Guide",
        link: x2022ChryslerPacificaPHEVERG,
      },
      {
        title: "Chrysler Aspen Dodge Durango 2007 Hybrid Guide",
        link: DodgeDurangoHEV2009ERG,
      },
    ],
    Fiat: [
      {
        title: "Fiat 2013 AT 500e Guide",
        link: FIAT500eEV20132018ERG,
      },
    ],
    Fisker: [
      {
        title: "Fisker 2012 Karma Guide",
        link: FiskerKarmaPHEV20112012ERG,
      },
    ],
  };

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [vehicleMake, setVehicleMake] = useState<string>();
  const [guideFilePath, setGuideFilePath] = useState<string>();
  const [fallback, setFallback] = useState<string>();
  const [fallbackReason, setFallbackReason] = useState<string>();

  const [vehicleGuideSelectedIndex, setVehicleGuideSelectedIndex] = useState<
    number | undefined
  >();

  const [vehicleMakeGuideOptions, setVehicleMakeGuideOptions] = useState<
    {
      value: number;
      label: string;
    }[]
  >();
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const vehicleMakeOptions = Object.keys(data).map((x) => {
    return { label: x, value: x };
  });

  useEffect(() => {
    if (vehicleMake) {
      setVehicleGuideSelectedIndex(0);
      setVehicleMakeGuideOptions(
        data[vehicleMake].map((x, i) => {
          return { label: x.title, value: i };
        })
      );
    }
  }, [vehicleMake]);

  useEffect(() => {
    if (vehicleMake && vehicleGuideSelectedIndex) {
      console.log(vehicleMake);
      console.log(vehicleGuideSelectedIndex);
      if (data[vehicleMake][vehicleGuideSelectedIndex]) {
        const link = data[vehicleMake][vehicleGuideSelectedIndex].link;

        if (link) {
          setFallback(undefined);
          setFallbackReason(undefined);
          setGuideFilePath(link);
        } else {
          setFallback(data[vehicleMake][vehicleGuideSelectedIndex].fallback);
          setFallbackReason(
            data[vehicleMake][vehicleGuideSelectedIndex].fallbackReason
          );
        }
      }
    }
  }, [vehicleMake, vehicleGuideSelectedIndex]);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Emergency Guides"></PageHeader>
      <Box p={5}>
        <Card direction={{ base: "row", sm: "row" }} variant="outline">
          <CardBody>
            <Box my={5}>
              <Box>
                <HStack justifyContent={"space-between"}>
                  <Stack w={"30%"}>
                    <Select
                      options={vehicleMakeOptions}
                      placeholder={"Vehicle Make"}
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        setVehicleMake(e.value);
                      }}
                    />
                    {vehicleMake && (
                      <Box mt="1rem">
                        <Select
                          options={vehicleMakeGuideOptions}
                          placeholder={`${vehicleMake} Guides`}
                          closeMenuOnSelect={true}
                          onChange={(e: any) => {
                            setVehicleGuideSelectedIndex(e.value);
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                  <Text>
                    Page {pageNumber} of {numPages}
                  </Text>
                  <VStack>
                    <Box
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: 72,
                        width: "100%",
                      }}
                    >
                      <QRCode
                        value={`${window.location.origin}${guideFilePath}`}
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        viewBox={`0 0 256 256`}
                      />
                    </Box>
                    <Text>Direct Link To Guide</Text>
                    <HStack>
                      <Button
                        variant="solid"
                        colorScheme="brand.primary"
                        isDisabled={pageNumber == 1}
                        onClick={() => setPageNumber(pageNumber - 1)}
                      >
                        Previous Page
                      </Button>
                      <Button
                        variant="solid"
                        colorScheme="brand.primary"
                        isDisabled={pageNumber == numPages}
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        Next Page
                      </Button>
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
              <Center mt={"1rem"}>
                {guideFilePath && !fallback && (
                  <>
                    <Document
                      file={guideFilePath}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </>
                )}
                {fallback && fallbackReason && (
                  <Box>
                    <Heading>{fallbackReason}</Heading>
                  </Box>
                )}
              </Center>
            </Box>
          </CardBody>
          <CardFooter />
        </Card>
      </Box>
    </Box>
  );
};
