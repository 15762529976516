import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EditSite } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";
import { Site } from "../../types-new/organizations";

export const useSites = (enabled = true) => {
    const { getToken } = useAuth();
    return useQuery<Site[], ApiServiceErr>({
      queryKey: ["sites"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/sites`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      },
      enabled: enabled
    });
}

export const useSite = (id: string, enabled = true) => {
  const { getToken } = useAuth();
  return useQuery<Site, ApiServiceErr>({
    queryKey: ["site", id],
    queryFn:  async () => {
      if(id.length === 0) return;
      const response = await axios.get(
        `/api/sites/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useUpdateSite = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, site}: {id: string, site: EditSite}) => {
      const response = await axios.put(
        `/api/sites/${id}`,
        site,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
     
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['editsite', variables.id], variables.site);
      queryClient.invalidateQueries({ queryKey: ['editsite'] });
      toast.success(`Updated site: ${variables.site.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error updating site: ${variables.site.name}`)
      console.log(error);
    },
    
  });
}

export const useCreateSite = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({site}: {site: EditSite}) => {
      const response = await axios.post(
        `/api/sites`,
        site,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.headers["location"];
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['editsite'] });
      toast.success(`Created site: ${variables.site.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating site: ${variables.site.name}`)
      console.log(error);
    },
  });
}


export const useAddMemberToSite = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({siteId, memberId}: {siteId: string, memberId: string}) => {
      const response = await axios.post(
        `/api/sites/${siteId}/add`,
        {memberId: memberId},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['site'] });
      toast.success(`Added member(s) to site`)

    },
    onError: (error, variables, context) => {
      toast.error(`Error adding member(s) to site`)
      console.log(error);
    },
  });
}

export const useRemoveMemberFromSite = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({siteId, memberId}: {siteId: string, memberId: string}) => {
      const response = await axios.post(
        `/api/sites/${siteId}/remove`,
        {memberId: memberId},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['site'] });
      toast.success(`Removed member(s) from site`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error removing member(s) from site`)
      console.log(error);
    },
  });
}

export const useDeleteSite = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      const response = await axios.delete(
        `/api/sites/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['site'] });
      toast.success(`Deleted site: ${variables.id}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error deleting site: ${variables.id}`)
    },
  });
}
