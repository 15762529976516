import { Icon } from "@chakra-ui/icon";
import { BoxProps, Stack, HStack, Text } from "@chakra-ui/layout";
import { Box, Heading } from "@chakra-ui/react";
import {
  FiMoreVertical,
  FiArrowUpRight,
  FiArrowDownRight,
} from "react-icons/fi";

interface Props extends BoxProps {
  label: string;
  value: string;
  delta?: {
    value: string;
    isUpwardsTrend: boolean;
  };
}

export const Stat = (props: Props) => {
  const { label, value, delta, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Stack gap={1}>
        <HStack justify="space-between">
          <Text color="fg.muted" mb={2} fontWeight={"500"}>
            {label}
          </Text>
          {/* <Icon as={FiMoreVertical} boxSize="5" color="fg.muted" /> */}
        </HStack>
        <Stack spacing={1}>
          <Heading size={{ base: "sm", md: "md" }}>{value}</Heading>
          {delta && (
            <HStack spacing={1} fontWeight="medium">
              <Icon
                color={delta.isUpwardsTrend ? "success" : "error"}
                as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}
                boxSize="5"
              />
              <Text color={delta.isUpwardsTrend ? "success" : "error"}>
                {delta.value}
              </Text>
              <Text color="fg.muted">vs last quarter</Text>
            </HStack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
