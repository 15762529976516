import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Document, DocumentSearchResults } from "../../types-new";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useDocuments = () => {
    const { getToken } = useAuth();
    return useQuery<Document[], ApiServiceErr>({
      queryKey: ["documents"],
      queryFn:  async () => {
       const result =  await axios.get(
          `/api/v2/documents`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );

        return result.data;
      }
    });
}

export const useSearchDocument = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({query, id}: {query: string, id: string}) => {
      const result =  await axios.post(
        `/api/v2/documents/${id}/search`,
        {query},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

       return result;
    },
    onSuccess: (result, variables, context) => {
      return queryClient.invalidateQueries({ queryKey: ['documents'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error searching document. Please try again.");
    },
    
  });
}

export const useUploadDocument = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({formData}: {formData: FormData}) => {
      return await axios.post(
        `/api/v2/documents`,
        formData,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      toast.success("Document uploaded.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error uploading document. Please try again.");
    },
  });
}

export const useDeleteDocument = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/v2/documents/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      toast.success("Document deleted.");
    },
    onError: (error, variables, context) => {
      toast.error("Error deleting document. Please try again.");
    },
  });
}