import {
  Box,
  Heading,
  HStack,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  FormHelperText,
  CardBody,
  Card,
  CardHeader,
  IconButton,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Protocol } from "../../../types";
import "./edit.css";
import "@mdxeditor/editor/style.css";
import * as Yup from "yup";
import type { OrganizationMembershipResource } from "@clerk/types";

import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  InsertTable,
  tablePlugin,
  UndoRedo,
  frontmatterPlugin,
  linkDialogPlugin,
  linkPlugin,
  markdownShortcutPlugin,
  ListsToggle,
  MDXEditorMethods,
} from "@mdxeditor/editor";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Select } from "chakra-react-select";
import { toast } from "react-toastify";
import {
  useAttachProtocolToResource,
  useDetachProtocolFromResource,
  useProtocol,
  useUpdateProtocol,
} from "../../../api/endpoints/protocols";
import { useTeams } from "../../../api/endpoints/teams";
import { useSites } from "../../../api/endpoints/sites";
import { useOrganization } from "@clerk/clerk-react";
import { PageHeader } from "../../../components/page-header";
import { ArrowLeft } from "lucide-react";

interface FormValues {
  name: string;
  description: string;
  referenceCode: string;
  appliesTo: string[];
  content: string;
  expiresOn: string | undefined;
  category: string;
}

const findAddedAndRemovedObjects = (
  originalArray: any[],
  newArray: any[],
  keySelector: (item: any) => any
): { added: any[]; removed: any[] } => {
  const originalKeys = originalArray.map(keySelector);
  const newKeys = newArray.map(keySelector);

  const added = newArray.filter(
    (item) => !originalKeys.includes(keySelector(item))
  );
  const removed = originalArray.filter(
    (item) => !newKeys.includes(keySelector(item))
  );

  return { added, removed };
};

const formatDate = (input: string): string => {
  const date = new Date(input);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-based in JS
  const day = date.getUTCDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const ProtocolsEditPage: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const {
    data: protocol,
    isError: protocolIsError,
    isLoading: protocolIsLoading,
  } = useProtocol(id ?? "");

  const {
    data: teams,
    isError: teamsIsError,
    isLoading: teamsIsLoading,
  } = useTeams();

  const {
    data: sites,
    isError: sitesIsError,
    isLoading: sitesIsLoading,
  } = useSites();

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const { mutate: updateProtocol } = useUpdateProtocol();
  const { mutate: attachProtocolToResource } = useAttachProtocolToResource();
  const { mutate: detachProtocolFromResource } =
    useDetachProtocolFromResource();

  const appliesToOptions: readonly any[] = [
    {
      label: "Teams",
      options: teams?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Sites",
      options: sites?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  let key: keyof Protocol;

  useEffect(() => {
    if (protocol) {
      for (key in protocol) {
        if (key === "content") {
          ref.current?.setMarkdown(protocol[key] ?? "");
        }
        if (key === "expiresOn") {
          const test = protocol[key];
          if ((protocol[key]?.toString() ?? "").length > 0) {
            formik.setFieldValue(
              key,
              formatDate(protocol[key]?.toString() ?? "")
            );
          }
          continue;
        }
        if (key === "appliesTo") {
          formik.setFieldValue(
            key,
            (protocol[key] ?? []).map((x) => {
              return { value: x.id, label: x.name };
            })
          );
          continue;
        }
        formik.setFieldValue(key, protocol[key], true);
      }
    }
  }, [protocolIsLoading]);

  const ref = useRef<MDXEditorMethods>(null);

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Protocol name is required."),
    description: Yup.string()
      .min(3)
      .required("Protocol description is required."),
    referenceCode: Yup.string().min(3),
    content: Yup.string().min(3).required("Protocol content is required."),
    category: Yup.string().nullable().notRequired(),
    expiresOn: Yup.date().nullable().notRequired().default(null),
  });

  const initialValues: FormValues = {
    name: protocol?.name ?? "",
    description: protocol?.description ?? "",
    referenceCode: protocol?.referenceCode ?? "",
    appliesTo: [],
    expiresOn: undefined,
    category: "",
    content: protocol?.content ?? "Insert protocol content here.",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      await updateProtocol({
        id: id ?? "",
        protocol: {
          id: values.id,
          name: values.name,
          content: values.content,
          description: values.description,
          category: values.category,
          referenceCode: values.referenceCode,
          expiresOn: values.expiresOn,
        },
      });

      const { added, removed } = findAddedAndRemovedObjects(
        protocol?.appliesTo ?? [],
        values.appliesTo.map((x: any) => {
          return { id: x.value, name: x.value };
        }),
        (item) => item.id
      );

      added.map(async (x) => {
        await attachProtocolToResource({
          protocolId: id ?? "",
          resourceId: x.id,
        });
      });

      removed.map(async (x) => {
        await detachProtocolFromResource({
          protocolId: id ?? "",
          resourceId: x.id,
        });
      });

      toast("Successfully updated protocol.");
    },
  });

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Stack p={5}>
        <FormikProvider value={formik}>
          <Form>
            <Card variant={"outline"}>
              <CardBody>
                <HStack justifyContent={"space-between"}>
                  <HStack>
                    <IconButton
                      aria-label="edit evidence"
                      icon={<ArrowLeft />}
                      style={{ background: "transparent" }}
                      onClick={() => navigate("/doc-library/protocols")}
                      color={"brand.accent"}
                    />
                    <Heading variant={"md"}>
                      Protocol Version: {protocol?.version}
                    </Heading>
                  </HStack>
                  <Button
                    variant="solid"
                    colorScheme="brand.primary"
                    type="submit"
                  >
                    Save Protocol
                  </Button>
                </HStack>

                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.name === true &&
                        !!formik?.touched?.name === true
                      }
                    >
                      <FormLabel>Name*</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name={"name"}
                        type="text"
                        placeholder="Example Name"
                        value={formik.values.name}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.referenceCode === true &&
                        !!formik?.touched?.referenceCode === true
                      }
                    >
                      <FormLabel>Reference Code</FormLabel>
                      <Field
                        as={Input}
                        id="referenceCode"
                        name={"referenceCode"}
                        type="text"
                        placeholder="Reference Code"
                        value={formik.values?.referenceCode}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.referenceCode?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>

                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.expiresOn === true &&
                        !!formik?.touched?.expiresOn === true
                      }
                    >
                      <FormLabel>Expires On</FormLabel>
                      <Field
                        as={Input}
                        id="expiresOn"
                        name={"expiresOn"}
                        type="date"
                        placeholder="mm/dd/yyyy"
                        value={formik.values.expiresOn}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.expiresOn?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.category === true &&
                        !!formik?.touched?.category === true
                      }
                    >
                      <FormLabel>Category</FormLabel>
                      <Field
                        as={Input}
                        id="category"
                        name={"category"}
                        type="text"
                        value={formik.values.category}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.category?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl
                      id="description"
                      isInvalid={
                        !!formik?.errors?.description === true &&
                        !!formik?.touched?.description === true
                      }
                    >
                      <FormLabel>Description*</FormLabel>
                      <Field
                        as={Textarea}
                        id="description"
                        name={"description"}
                        type="text"
                        placeholder="Example Description"
                        value={formik.values.description}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.description?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="appliesTo"
                      isInvalid={
                        !!formik?.errors?.appliesTo === true &&
                        !!formik?.touched?.appliesTo === true
                      }
                    >
                      <FormLabel>Applies To*</FormLabel>
                      <Select
                        isMulti
                        options={appliesToOptions}
                        id="appliesTo"
                        placeholder={
                          "Select the teams this policy is applicable to."
                        }
                        value={formik.values.appliesTo}
                        name="appliesTo"
                        closeMenuOnSelect={true}
                        onChange={(e: any) => {
                          formik.setFieldValue("appliesTo", e);
                        }}
                      />
                      <FormHelperText>
                        The teams, sites, and members selected will be required
                        to review and agree to this policy.
                      </FormHelperText>
                      <FormErrorMessage>
                        {formik?.errors?.appliesTo?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <FormControl
                    id="content"
                    isInvalid={
                      !!formik?.errors?.content === true &&
                      !!formik?.touched?.content === true
                    }
                  >
                    <FormLabel>Content*</FormLabel>
                    <MDXEditor
                      contentEditableClassName="prose"
                      markdown={formik.values.content}
                      ref={ref}
                      plugins={[
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              <UndoRedo />
                              <BoldItalicUnderlineToggles />
                              <BlockTypeSelect />
                              <CreateLink />
                              <InsertTable />
                              <ListsToggle />
                            </>
                          ),
                        }),
                        headingsPlugin(),
                        listsPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        listsPlugin(),
                        quotePlugin(),
                        thematicBreakPlugin(),
                        tablePlugin(),
                        markdownShortcutPlugin(),
                        frontmatterPlugin(),
                      ]}
                      onChange={(markdown: string) => {
                        formik.setFieldValue("content", markdown);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.content?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </CardBody>
            </Card>
          </Form>
        </FormikProvider>
      </Stack>
    </Box>
  );
};
