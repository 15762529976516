import { Box, Card, CardBody, CardFooter } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerBlocks } from "./blocks";
import { useMyRefresher, useUpdateMyRefresher } from "../../api/endpoints/my";
export const MyRefresherViewerPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: myRefresher,
    isError: myRefresherError,
    isLoading: myRefresherIsLoading,
  } = useMyRefresher(id as string);

  const { mutate: updateMyRefresher } = useUpdateMyRefresher();

  useEffect(() => {
    registerBlocks();
  }, []);

  const [blocks, setBlocks] = useState<any[]>([]);
  const [formReady, setFormReady] = useState(false);

  useEffect(() => {
    if (!myRefresher) {
      return;
    }

    const formData = [];
    for (const quiz of myRefresher.quizzes) {
      for (const question of quiz.questions) {
        formData.push({
          id: quiz.id,
          name: question.type,
          attributes: {
            required: question.isRequired,
            label: question.title,
            correctAnswer: question.correctAnswer,
            randomize: false,
            choices: question.options.map((y) => {
              return { label: y.text, value: y.value };
            }),
          },
        });
      }

      setFormReady(true);
    }

    setBlocks(formData);
  }, [myRefresher]);

  return (
    <Box p={{ base: "3", md: "4" }}>
      <Card direction={{ base: "row", sm: "row" }} variant="outline">
        <CardBody>
          <Box my={5}>
            <Box
              style={{
                width: "100%",
                height: "100vh",
                display: formReady ? "block" : "none",
              }}
            >
              {formReady && blocks && blocks.length > 0 && (
                <Form
                  formId={1}
                  applyLogic={false}
                  beforeGoingNext={async ({
                    setIsFieldValid,
                    currentBlockId,
                    answers,
                    setFieldValidationErr,
                    setIsCurrentBlockSafeToSwipe,
                    goNext,
                  }) => {
                    const blockIndex = blocks.findIndex(
                      (x) => x.id === currentBlockId
                    );
                    if (
                      blocks[blockIndex] &&
                      blocks[blockIndex].name === "multiple-choice" &&
                      blocks[blockIndex].attributes.correctAnswer !==
                        (answers[currentBlockId] as any).value[0]
                    ) {
                      setIsFieldValid(currentBlockId, false);
                      setFieldValidationErr(
                        currentBlockId,
                        "That's not quite right, please try again."
                      );
                      setIsCurrentBlockSafeToSwipe(false);
                    } else {
                      setIsCurrentBlockSafeToSwipe(true);
                      goNext();
                    }
                  }}
                  formObj={{
                    theme: {
                      buttonsBgColor: "#204B36",
                      answersColor: "#204B36",
                    },
                    blocks: blocks,
                    messages: {
                      "block.defaultThankYouScreen.label": `You've completed the ${myRefresher?.course.name} course refresher!`,
                      "label.button.ok": "OK",
                      "label.hintText.enter": "press <strong>Enter ↵</strong>",
                      "label.hintText.multipleSelection":
                        "Choose as many as you like",
                      "block.dropdown.placeholder": "Type or select an option",
                      "block.dropdown.noSuggestions": "No Suggestions!",
                      "block.shortText.placeholder": "Type your answer here",
                      "block.longText.placeholder": "Type your answer here",
                      "block.longText.hint":
                        "<strong>Shift ⇧ + Enter ↵</strong> to make a line break",
                      "block.longText.touchHint":
                        "<strong> Enter ↵</strong> to make a line break",
                      "block.number.placeholder": "Type your answer here",
                      "block.email.placeholder": "Type your email here",
                      "label.correct": "Correct",
                      "label.incorrect": "Incorrect",
                      "label.yourAnswer": "Your answer",
                      "label.answersExplanation": "Answers explanation",
                      "label.hintText.key": "Key",
                      "label.yes.default": "Yes",
                      "label.no.default": "No",
                      "label.progress.percent":
                        "{{progress:percent}}% completed",
                      "label.errorAlert.required": "This field is required!",
                      "label.errorAlert.date": "Invalid date!",
                      "label.errorAlert.number": "Numbers only!",
                      "label.errorAlert.selectionRequired":
                        "Please make at least one selection!",
                      "label.errorAlert.email": "Invalid email!",
                      "label.errorAlert.url": "Invalid url!",
                      "label.errorAlert.range":
                        "Please enter a number between {{attribute:min}} and {{attribute:max}}",
                      "label.errorAlert.minNum":
                        "Please enter a number greater than {{attribute:min}}",
                      "label.errorAlert.maxNum":
                        "Please enter a number lower than {{attribute:max}}",
                      "label.errorAlert.maxCharacters":
                        "Maximum characters reached!",
                      "label.errorAlert.minCharacters":
                        "Please insert more than {{attribute:minCharacters}} characters!",
                      "label.errorAlert.minChoices":
                        "Please select at least {{attribute:min}} choices!",
                      "label.errorAlert.maxChoices":
                        "Maximum choices to select is {{attribute:max}}!",
                      "label.submitBtn": "Submit",
                      "label.errorAlert.noConnection":
                        "Can't connect to the server right now!",
                      "label.errorAlert.serverError": "Server error!",
                      "block.file.uploadLabel":
                        "<strong> Choose file </strong> or <strong>Drag here</strong>",
                      "label.hint.maxFileUploadSize":
                        "Maximum file size is {{attribute:maxFileSize}}MB",
                      "label.errorAlert.phone": "Invalid phone number!",
                    },

                    // notifications: [],
                    settings: {
                      disableProgressBar: false,
                      disableWheelSwiping: false,
                      disableNavigationArrows: false,
                      animationDirection: "vertical",
                      showLettersOnAnswers: false,
                      showQuestionsNumbers: false,
                      // displayBranding: false,
                    },
                  }}
                  onSubmit={(
                    data,
                    {
                      completeForm,
                      setIsSubmitting,
                      goToBlock,
                      setSubmissionErr,
                    }
                  ) => {
                    setTimeout(() => {
                      updateMyRefresher({
                        id: myRefresher?.id as string,
                        data: { completed: true },
                      });
                      setIsSubmitting(false);
                      completeForm();
                      navigate("/my/courses");
                    }, 500);
                  }}
                />
              )}
            </Box>
          </Box>
        </CardBody>
        <CardFooter />
      </Card>
    </Box>
  );
};
