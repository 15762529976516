import {
  Box,
  BoxProps,
  Button,
  CardBody,
  Card,
  HStack,
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Tag,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Flex,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import { PageHeader } from "../../components/page-header";
import { Stat as AxleStat } from "../../components/stat";
import { useVehicles } from "../../api/endpoints/vehicles";
import { AreaChart, BarChart, LineChart } from "@saas-ui/charts";
import {
  useCarbAdvancedCleanFleetBreakdown,
  useCarbReport,
} from "../../api/endpoints/carb";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { Vehicle } from "../../types";

// Add this type definition for the vehicle status data
type VehicleStatusData = {
  id: string;
  vin: string;
  make: string;
  model: string;
  nextFeeDue: string;
  isCompliant: boolean;
  openViolations: number;
  nextInspection: string;
  applicableRules: string[];
  requiredActions: string[];
};

export const CARBReportPage: React.FC = () => {
  const { data: vehicles } = useVehicles();
  const { data: carbComplianceReport } = useCarbReport();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [projectionYear, setProjectionYear] = useState<number>(
    new Date().getFullYear()
  );
  const [additionalGroup1ZEVs, setAdditionalGroup1ZEVs] = useState<number>(0);
  const [additionalGroup2ZEVs, setAdditionalGroup2ZEVs] = useState<number>(0);
  const [additionalGroup3ZEVs, setAdditionalGroup3ZEVs] = useState<number>(0);
  const [additionalGroup1NonZEVs, setAdditionalGroup1NonZEVs] =
    useState<number>(0);
  const [additionalGroup2NonZEVs, setAdditionalGroup2NonZEVs] =
    useState<number>(0);
  const [additionalGroup3NonZEVs, setAdditionalGroup3NonZEVs] =
    useState<number>(0);

  const [acfChartData, setAcfChartData] = useState<any[]>([]);

  const vehiclesOutOfCompliance = useMemo(() => {
    return (carbComplianceReport?.vehicles ?? []).filter(
      (vehicle) => !vehicle.complianceResults.every((r) => r.isCompliant)
    );
  }, [carbComplianceReport]);

  const openViolations = useMemo(() => {
    return (vehicles ?? []).filter((vehicle) =>
      vehicle.complianceData.violations.some(
        (v) => v.resolutionDate == null || v.resolutionDate === ""
      )
    );
  }, [vehicles]);

  const totalFeesDue = useMemo(() => {
    return (vehicles ?? []).reduce((acc, vehicle) => {
      return (
        acc +
        vehicle.complianceData.fees.reduce((feeAcc, fee) => {
          return feeAcc + (fee.paidDate === null ? fee.amount : 0);
        }, 0)
      );
    }, 0);
  }, [vehicles]);

  const furthestDueDate = useMemo(() => {
    const allDueDates = (vehicles ?? []).flatMap((vehicle) =>
      vehicle.complianceData.fees
        .filter((fee) => fee.paidDate === null)
        .map((fee) => new Date(fee.dueDate))
    );

    return allDueDates.length > 0
      ? new Date(Math.max(...allDueDates.map((date) => date.getTime())))
      : null;
  }, [vehicles]);

  const inspectionStatusPerMonth = useMemo(() => {
    const inspectionStatus: Record<
      string,
      { due: number; completed: number; notCompleted: number }
    > = {};

    (vehicles ?? []).forEach((vehicle) => {
      vehicle.complianceData.inspections.forEach((inspection) => {
        if (inspection.dueDate) {
          const date = new Date(inspection.dueDate);
          const monthYear = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;

          if (!inspectionStatus[monthYear]) {
            inspectionStatus[monthYear] = {
              due: 0,
              completed: 0,
              notCompleted: 0,
            };
          }

          inspectionStatus[monthYear].due += 1;

          if (inspection.isCompleted) {
            inspectionStatus[monthYear].completed += 1;
          } else {
            inspectionStatus[monthYear].notCompleted += 1;
          }
        }
      });
    });

    return Object.entries(inspectionStatus)
      .sort(([a], [b]) => {
        const [monthA, yearA] = a.split("/");
        const [monthB, yearB] = b.split("/");
        return (
          new Date(Number(yearA), Number(monthA) - 1).getTime() -
          new Date(Number(yearB), Number(monthB) - 1).getTime()
        );
      })
      .map(([date, status]) => ({
        date,
        "Inspections Due": status.due,
        "Inspections Completed": status.completed,
        "Inspections Not Completed": status.notCompleted,
      }));
  }, [vehicles]);

  // Add this new useMemo hook to prepare the data for the DataTable
  const vehicleStatusData = useMemo(() => {
    return (vehicles ?? []).map((vehicle): VehicleStatusData => {
      const nextFee = vehicle.complianceData.fees.find(
        (f) => f.paidDate === null
      );
      const nextInspection = vehicle.complianceData.inspections.find(
        (i) => !i.isCompleted
      );
      const openViolations = vehicle.complianceData.violations.filter(
        (v) => v.resolutionDate === null
      );

      const vehicleCompliance = carbComplianceReport?.vehicles.find(
        (v) => v.vehicleId === vehicle.id
      );

      return {
        id: vehicle.id,
        vin: vehicle.vin,
        isCompliant:
          vehicleCompliance?.complianceResults.every((r) => r.isCompliant) ??
          false,
        make: vehicle.make,
        model: vehicle.model,
        nextFeeDue: nextFee?.dueDate
          ? new Date(nextFee.dueDate).toString()
          : "N/A",
        openViolations: openViolations.length,
        nextInspection:
          nextInspection?.dueDate && nextInspection.dueDate.trim() !== ""
            ? new Date(nextInspection.dueDate).toString()
            : "N/A",
        applicableRules: vehicleCompliance?.applicableRules ?? [],
        requiredActions:
          vehicleCompliance?.complianceResults.flatMap(
            (r) => r.requiredActions
          ) ?? [],
      };
    });
  }, [vehicles, carbComplianceReport]);

  // Define the columns for the DataTable
  const columns: ColumnDef<VehicleStatusData>[] = [
    {
      accessorKey: "vin",
      header: "VIN",
    },
    {
      accessorKey: "make",
      header: "Make",
    },
    {
      accessorKey: "model",
      header: "Model",
    },
    {
      accessorFn: (row) => `${row.isCompliant == true ? "Yes" : "No"}`,
      header: "Compliant",
      cell: ({ row }) => {
        return <Text>{row.original.isCompliant === true ? "Yes" : "No"}</Text>;
      },
    },
    {
      accessorKey: "applicableRules",
      header: "Applicable Rules",
      cell: ({ row }) => {
        return (
          <Flex flexWrap="wrap" gap={1}>
            {row.original.applicableRules.map((rule) => (
              <Tag
                size="sm"
                bg={"brand.primary.500"}
                color={"white"}
                rounded={"full"}
              >
                {rule}
              </Tag>
            ))}

            {row.original.applicableRules.length === 0 && (
              <Tag size="sm" bg="gray.300" color="gray.800" rounded="full">
                N/A
              </Tag>
            )}
          </Flex>
        );
      },
    },
    {
      accessorKey: "nextFeeDue",
      header: "Next Fee Due",
      cell: ({ row }) => {
        const nextFeeDue = row.original.nextFeeDue;
        if (nextFeeDue === "N/A") {
          return "N/A";
        }

        const date = new Date(nextFeeDue);
        return isNaN(date.getTime())
          ? "Invalid Date"
          : date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
      },
    },
    {
      accessorKey: "nextInspection",
      header: "Next Inspection Due",
      cell: ({ row }) => {
        const nextInspection = row.original.nextInspection;

        if (nextInspection === "N/A") {
          return "N/A";
        }

        const date = new Date(nextInspection);
        return isNaN(date.getTime())
          ? "Invalid Date"
          : date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
      },
    },
    {
      accessorKey: "openViolations",
      header: "Open Violations",
    },
  ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const chartData = [];

    for (let year = currentYear; year <= 2042; year++) {
      const filteredVehicles =
        vehicles?.filter(
          (v) =>
            v.gvwr === "Class2G" ||
            v.gvwr === "Class2H" ||
            v.gvwr === "Class3" ||
            v.gvwr === "Class4" ||
            v.gvwr === "Class5" ||
            v.gvwr === "Class6" ||
            v.gvwr === "Class7" ||
            v.gvwr === "Class8" ||
            (v.isPackageDeliveryVehicle &&
              (v.gvwr === "Class1" ||
                v.gvwr === "Class1A" ||
                v.gvwr === "Class1B" ||
                v.gvwr === "Class1C" ||
                v.gvwr === "Class1D" ||
                v.gvwr === "Class2" ||
                v.gvwr === "Class2E" ||
                v.gvwr === "Class2F"))
        ) ?? [];

      const group1Vehicles = filteredVehicles.filter(
        (v) =>
          v.isBoxTruck ||
          (v.type === "Bus" && v.numberOfAxles === 2) ||
          v.isYardTractor ||
          (v.isPackageDeliveryVehicle &&
            (v.gvwr === "Class1" ||
              v.gvwr === "Class1A" ||
              v.gvwr === "Class1B" ||
              v.gvwr === "Class1C" ||
              v.gvwr === "Class1D" ||
              v.gvwr === "Class2" ||
              v.gvwr === "Class2E" ||
              v.gvwr === "Class2F"))
      );
      const group2Vehicles = filteredVehicles.filter(
        (v) =>
          v.type === "Pickup" ||
          (v.type === "Bus" && v.numberOfAxles === 3) ||
          v.type === "Cargo Van" ||
          (v.type === "Truck" && !v.isYardTractor) ||
          v.isWorkTruck
      );
      const group3Vehicles = filteredVehicles.filter(
        (v) =>
          v.isSpecialtyVehicle ||
          v.hasSleeperCompartment ||
          v.type === "Truck-Tractor"
      );

      const calculateGroupRequirement = (
        year: number,
        milestoneYears: number[]
      ): number => {
        const percentages = [10, 25, 50, 75, 100];
        for (let i = 0; i < milestoneYears.length; i++) {
          if (year >= milestoneYears[i]) {
            return percentages[i];
          }
        }
        return 0;
      };

      const requirements = {
        group1: calculateGroupRequirement(year, [2025, 2028, 2031, 2033, 2035]),
        group2: calculateGroupRequirement(year, [2027, 2030, 2033, 2036, 2039]),
        group3: calculateGroupRequirement(year, [2030, 2033, 2036, 2039, 2042]),
      };

      const calculateGroupBreakdown = (
        groupVehicles: Vehicle[],
        requiredPercentage: number
      ) => {
        const totalVehicles = groupVehicles.length;
        const requiredZEVs = Math.ceil(
          (totalVehicles * requiredPercentage) / 100
        );
        const currentZEVs = groupVehicles.filter(
          (v) => v.fuelType === "Electric"
        ).length;

        return {
          requiredZEVs,
          currentZEVs,
          nonZEVs: totalVehicles - currentZEVs,
        };
      };

      const group1Breakdown = calculateGroupBreakdown(
        group1Vehicles,
        requirements.group1
      );
      const group2Breakdown = calculateGroupBreakdown(
        group2Vehicles,
        requirements.group2
      );
      const group3Breakdown = calculateGroupBreakdown(
        group3Vehicles,
        requirements.group3
      );

      const group1ZEVs =
        (year >= projectionYear ? additionalGroup1ZEVs : 0) +
        group1Breakdown.currentZEVs;
      const group2ZEVs =
        (year >= projectionYear ? additionalGroup2ZEVs : 0) +
        group2Breakdown.currentZEVs;
      const group3ZEVs =
        (year >= projectionYear ? additionalGroup3ZEVs : 0) +
        group3Breakdown.currentZEVs;

      const group1Total =
        group1ZEVs +
        (year >= projectionYear ? additionalGroup1NonZEVs : 0) +
        group1Vehicles.length;
      const group2Total =
        group2ZEVs +
        (year >= projectionYear ? additionalGroup2NonZEVs : 0) +
        group2Vehicles.length;
      const group3Total =
        group3ZEVs +
        (year >= projectionYear ? additionalGroup3NonZEVs : 0) +
        group3Vehicles.length;

      const group1Required = Math.ceil(
        (group1Total * requirements.group1) / 100
      );
      const group2Required = Math.ceil(
        (group2Total * requirements.group2) / 100
      );
      const group3Required = Math.ceil(
        (group3Total * requirements.group3) / 100
      );

      chartData.push({
        year,
        "Required ZEVs": group1Required + group2Required + group3Required,
        "Current ZEVs": filteredVehicles.filter(
          (v) => v.fuelType === "Electric"
        ).length,
        "Projected ZEVs": group1ZEVs + group2ZEVs + group3ZEVs,
        complianceStatus:
          group1ZEVs >= group1Required &&
          group2ZEVs >= group2Required &&
          group3ZEVs >= group3Required
            ? "Compliant"
            : "Non-Compliant",
      });
    }

    setAcfChartData(chartData);
  }, [
    vehicles,
    projectionYear,
    additionalGroup1ZEVs,
    additionalGroup2ZEVs,
    additionalGroup3ZEVs,
    additionalGroup1NonZEVs,
    additionalGroup2NonZEVs,
    additionalGroup3NonZEVs,
  ]);

  const handleProjectionYearChange = (value: string) => {
    setProjectionYear(Number(value));
  };

  const handleAdditionalVehiclesChange = (
    group: number,
    isZEV: boolean,
    value: string
  ) => {
    const numValue = Number(value);
    switch (group) {
      case 1:
        isZEV
          ? setAdditionalGroup1ZEVs(numValue)
          : setAdditionalGroup1NonZEVs(numValue);
        break;
      case 2:
        isZEV
          ? setAdditionalGroup2ZEVs(numValue)
          : setAdditionalGroup2NonZEVs(numValue);
        break;
      case 3:
        isZEV
          ? setAdditionalGroup3ZEVs(numValue)
          : setAdditionalGroup3NonZEVs(numValue);
        break;
    }
  };

  const feePaymentsDueNext30Days = useMemo(() => {
    const today = new Date();
    const thirtyDaysFromNow = new Date(
      today.getTime() + 30 * 24 * 60 * 60 * 1000
    );

    return (vehicles ?? []).reduce((total, vehicle) => {
      return (
        total +
        vehicle.complianceData.fees.filter((fee) => {
          const dueDate = new Date(fee.dueDate);
          return (
            fee.paidDate === null &&
            dueDate >= today &&
            dueDate <= thirtyDaysFromNow
          );
        }).length
      );
    }, 0);
  }, [vehicles]);

  const inspectionsDueNext30Days = useMemo(() => {
    const today = new Date();
    const thirtyDaysFromNow = new Date(
      today.getTime() + 30 * 24 * 60 * 60 * 1000
    );

    return (vehicles ?? []).reduce((total, vehicle) => {
      return (
        total +
        vehicle.complianceData.inspections.filter((inspection) => {
          const dueDate = new Date(inspection.dueDate || "");
          return (
            !inspection.isCompleted &&
            dueDate >= today &&
            dueDate <= thirtyDaysFromNow
          );
        }).length
      );
    }, 0);
  }, [vehicles]);

  const tasksDueNext30Days = useMemo(() => {
    const today = new Date();
    const thirtyDaysFromNow = new Date(
      today.getTime() + 30 * 24 * 60 * 60 * 1000
    );

    return (vehicles ?? []).reduce((total, vehicle) => {
      const pendingTasks = vehicle.complianceData.tasks.filter((task) => {
        const dueDate = new Date(task.dueDate);
        return (
          (task.completedDate === undefined || task.completedDate === null) &&
          dueDate >= today &&
          dueDate <= thirtyDaysFromNow
        );
      }).length;

      return total + pendingTasks;
    }, 0);
  }, [vehicles]);

  return (
    <Box>
      <PageHeader title="CARB Management" />
      <Card variant={"outline"} m={5}>
        <CardBody>
          <Stack spacing={6}>
            {/* Critical Issues Section */}
            <Box>
              <Heading size="lg" mb={4}>
                Overview
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Total Fleet Assets"
                      value={vehicles?.length?.toString() ?? "0"}
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Fleet Assets Out of Compliance"
                      value={vehiclesOutOfCompliance?.length?.toString() ?? "0"}
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Open Violations"
                      value={openViolations?.length?.toString() ?? "0"}
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AxleStat
                      label={
                        furthestDueDate
                          ? `Total Fees Due Through ${furthestDueDate.toLocaleString(
                              "default",
                              { month: "long", year: "numeric" }
                            )}`
                          : "Total Fees Due"
                      }
                      value={`$${totalFeesDue?.toLocaleString()}`}
                    />
                  </CardBody>
                </Card>
              </SimpleGrid>
            </Box>

            {/* Upcoming Deadlines Section */}
            <Box>
              <Heading size="md" mb={4}>
                Upcoming Deadlines
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Fee Payments Due (Next 30 Days)"
                      value={feePaymentsDueNext30Days.toString()}
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Inspections Due (Next 30 Days)"
                      value={inspectionsDueNext30Days.toString()}
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <AxleStat
                      label="Tasks Due (Next 30 Days)"
                      value={tasksDueNext30Days.toString()}
                    />
                  </CardBody>
                </Card>
              </SimpleGrid>
            </Box>

            {/* Compliance Overview Chart */}
            <Stack>
              <Heading size="md" mb={4}>
                Compliance Overview
              </Heading>
              <Card>
                <CardBody>
                  <HStack justify="space-between">
                    <Text color="fg.muted" mb={2} fontWeight={"500"}>
                      Clean Truck Check Inspections Overview
                    </Text>
                  </HStack>
                  <BarChart
                    data={inspectionStatusPerMonth}
                    categories={[
                      "Inspections Due",
                      "Inspections Completed",
                      // "Inspections Not Completed",
                    ]}
                    variant="solid"
                    colors={[
                      "#0A3B1C", // Darker green
                      // "#4A6D5C", // Medium green
                      "#D0DDD6", // Light green with more contrast
                    ]}
                    height="300px" // Increased height for better readability
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading size="sm" mb={4}>
                    Advanced Clean Fleet (ACF) ZEV Requirements Calculator
                  </Heading>
                  <Box mt={8}>
                    <LineChart
                      data={acfChartData}
                      categories={[
                        "Required ZEVs",
                        "Current ZEVs",
                        "Projected ZEVs",
                      ]}
                      index="year"
                      colors={["#FF99A8", "#99FF99", "#99CCFF"]}
                      height="300px"
                    />
                  </Box>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>Projection Year</FormLabel>
                      <NumberInput
                        min={new Date().getFullYear()}
                        max={2042}
                        value={projectionYear}
                        onChange={handleProjectionYearChange}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                    <SimpleGrid columns={3} spacing={4}>
                      {[1, 2, 3].map((group) => (
                        <Box key={group}>
                          <Text fontWeight="bold">Group {group}</Text>
                          <FormControl>
                            <FormLabel>Additional ZEVs</FormLabel>
                            <NumberInput
                              min={0}
                              value={
                                group === 1
                                  ? additionalGroup1ZEVs
                                  : group === 2
                                  ? additionalGroup2ZEVs
                                  : additionalGroup3ZEVs
                              }
                              onChange={(value) =>
                                handleAdditionalVehiclesChange(
                                  group,
                                  true,
                                  value
                                )
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                          <FormControl mt={2}>
                            <FormLabel>Additional Non-ZEVs</FormLabel>
                            <NumberInput
                              min={0}
                              value={
                                group === 1
                                  ? additionalGroup1NonZEVs
                                  : group === 2
                                  ? additionalGroup2NonZEVs
                                  : additionalGroup3NonZEVs
                              }
                              onChange={(value) =>
                                handleAdditionalVehiclesChange(
                                  group,
                                  false,
                                  value
                                )
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Stack>
                </CardBody>
              </Card>
            </Stack>

            {/* Vehicle Status Table */}
            <Box>
              <Heading size="md" mb={4}>
                Vehicle Details
              </Heading>
              <Card>
                <CardBody>
                  <DataTable
                    data={vehicleStatusData}
                    columns={columns}
                    isSearchEnabled={true}
                    renderRowSubComponent={({ row }) => {
                      const hasActions = row.requiredActions.length > 0;

                      if (!hasActions) {
                        return null;
                      }

                      return (
                        <Stack>
                          {row.requiredActions.map((action) => (
                            <HStack>
                              <Icon as={BsDot} />
                              <Text>{action}</Text>
                            </HStack>
                          ))}
                        </Stack>
                      );
                    }}
                    onRowClick={(rowIndex, columnIndex, row) => {
                      navigate(`/vehicles/${row.id}`);
                    }}
                  />
                </CardBody>
              </Card>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
