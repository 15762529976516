import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useOrganizationMetadata,
  useUpdateOrganizationMetadata,
} from "../../api/endpoints/organizations";
import { OrganizationMetadata } from "../../types-new";
import { Select } from "chakra-react-select";
import { useCurricula } from "../../api/endpoints/courses";
import {
  FaCheck,
  FaTruck,
  FaFlag,
  FaLandmark,
  FaCity,
  FaExclamationTriangle,
  FaCar,
} from "react-icons/fa";
import { RadioTowerIcon } from "lucide-react";

export const OrganizationComplianceSection: React.FC = () => {
  const orgnizationDetailsEditSchema = Yup.object().shape({
    isDrayageFleet: Yup.boolean(),
    isStateGovernment: Yup.boolean(),
    isLocalGovernment: Yup.boolean(),
    isFederalGovernment: Yup.boolean(),
    isHighPriorityFleet: Yup.boolean(),
    businessStates: Yup.array().of(Yup.string()),
    isInLowPopulationCounty: Yup.boolean(),
    revenueInMillions: Yup.number().min(0, "Revenue must be non-negative"),
  });

  const { mutate: updateOrganizationMetadata } =
    useUpdateOrganizationMetadata();
  const {
    data: curricula,
    isError,
    isLoading: curriculaLoading,
  } = useCurricula();

  const { data: organizationMetadata, isLoading: organizationMetadataLoading } =
    useOrganizationMetadata();

  const initialValues = {
    isDrayageFleet: false,
    isStateGovernment: false,
    isLocalGovernment: false,
    isFederalGovernment: false,
    isHighPriorityFleet: false,
    businessStates: [],
    isFleet: false,
    isInLowPopulationCounty: false,
    defaultTrainingPlans: [],
    revenueInMillions: 0,
  };

  const curriculaOptions = curricula?.map((curriculum) => ({
    label: curriculum.name,
    value: curriculum.id,
  }));

  const onSubmit = async (values: any) => {
    await updateOrganizationMetadata({
      isDrayageFleet: values.isDrayageFleet,
      isStateGovernment: values.isStateGovernment,
      isLocalGovernment: values.isLocalGovernment,
      isFederalGovernment: values.isFederalGovernment,
      isHighPriorityFleet: values.isHighPriorityFleet,
      businessStates: values.businessStates,
      isFleet: values.isFleet,
      defaultTrainingPlans: values.defaultTrainingPlans,
      revenueInMillions: values.revenueInMillions,
      isInLowPopulationCounty: values.isInLowPopulationCounty,
    } as OrganizationMetadata);
  };

  const usStateOptions = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  return (
    <Card variant="outline">
      <Stack width={"100%"}>
        <Formik
          initialValues={organizationMetadata ?? initialValues}
          validationSchema={orgnizationDetailsEditSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, errors, handleChange, touched, setFieldValue }) => (
            <Form name="organization-compliance-form">
              <CardBody>
                <Heading size="md">Compliance Information</Heading>
                <Text color={"fg.muted"}>
                  The details you provide in this section are crucial for
                  assessing and ensuring your fleet's compliance with relevant
                  regulations. Your input helps us tailor our services to meet
                  your specific compliance needs.
                </Text>
                <Stack spacing="1" py={{ base: "2", md: "3" }}>
                  <Stack color="brand.text" spacing={10}>
                    <FormControl>
                      <FormLabel>
                        Do you operate a motor vehicle fleet?
                      </FormLabel>
                      <Text fontSize="sm" color="gray.600" mb={3}>
                        Select this option if your organization manages a fleet
                        of vehicles.
                      </Text>
                      <Button
                        variant={values.isFleet ? "solid" : "outline"}
                        colorScheme={values.isFleet ? "green" : "gray"}
                        onClick={() => {
                          const newIsFleet = !values.isFleet;
                          setFieldValue("isFleet", newIsFleet);
                          if (newIsFleet) {
                            setFieldValue("isDrayageFleet", false);
                            setFieldValue("isStateGovernment", false);
                            setFieldValue("isLocalGovernment", false);
                            setFieldValue("isFederalGovernment", false);
                            setFieldValue("isHighPriorityFleet", false);
                          }
                        }}
                        leftIcon={
                          <Icon
                            as={values.isFleet ? FaCheck : FaCar}
                            color={values.isFleet ? "white" : "gray.500"}
                          />
                        }
                        _hover={{
                          bg: values.isFleet ? "green.600" : "gray.100",
                        }}
                      >
                        I Operate a Motor Vehicle Fleet
                      </Button>
                      <FormErrorMessage>
                        <ErrorMessage name="isFleet" />
                      </FormErrorMessage>
                    </FormControl>
                    {values.isFleet && (
                      <>
                        <FormControl>
                          <FormLabel>Select your fleet type:</FormLabel>
                          <Text fontSize="sm" color="gray.600" mb={3}>
                            Choose all that apply to your organization. This
                            helps us tailor compliance requirements.
                          </Text>
                          <SimpleGrid columns={2} spacing={4}>
                            <Button
                              variant={
                                values.isDrayageFleet ? "solid" : "outline"
                              }
                              colorScheme={
                                values.isDrayageFleet ? "green" : "gray"
                              }
                              onClick={() =>
                                setFieldValue(
                                  "isDrayageFleet",
                                  !values.isDrayageFleet
                                )
                              }
                              leftIcon={
                                <Icon
                                  as={values.isDrayageFleet ? FaCheck : FaTruck}
                                  color={
                                    values.isDrayageFleet ? "white" : "gray.500"
                                  }
                                />
                              }
                              _hover={{
                                bg: values.isDrayageFleet
                                  ? "green.600"
                                  : "gray.100",
                              }}
                            >
                              Drayage Fleet
                            </Button>
                            <Button
                              variant={
                                values.isFederalGovernment ? "solid" : "outline"
                              }
                              colorScheme={
                                values.isFederalGovernment ? "green" : "gray"
                              }
                              onClick={() =>
                                setFieldValue(
                                  "isFederalGovernment",
                                  !values.isFederalGovernment
                                )
                              }
                              leftIcon={
                                <Icon
                                  as={
                                    values.isFederalGovernment
                                      ? FaCheck
                                      : FaFlag
                                  }
                                  color={
                                    values.isFederalGovernment
                                      ? "white"
                                      : "gray.500"
                                  }
                                />
                              }
                              _hover={{
                                bg: values.isFederalGovernment
                                  ? "green.600"
                                  : "gray.100",
                              }}
                            >
                              Federal Government
                            </Button>
                            <Button
                              variant={
                                values.isStateGovernment ? "solid" : "outline"
                              }
                              colorScheme={
                                values.isStateGovernment ? "green" : "gray"
                              }
                              onClick={() =>
                                setFieldValue(
                                  "isStateGovernment",
                                  !values.isStateGovernment
                                )
                              }
                              leftIcon={
                                <Icon
                                  as={
                                    values.isStateGovernment
                                      ? FaCheck
                                      : FaLandmark
                                  }
                                  color={
                                    values.isStateGovernment
                                      ? "white"
                                      : "gray.500"
                                  }
                                />
                              }
                              _hover={{
                                bg: values.isStateGovernment
                                  ? "green.600"
                                  : "gray.100",
                              }}
                            >
                              State Government
                            </Button>
                            <Button
                              variant={
                                values.isLocalGovernment ? "solid" : "outline"
                              }
                              colorScheme={
                                values.isLocalGovernment ? "green" : "gray"
                              }
                              onClick={() =>
                                setFieldValue(
                                  "isLocalGovernment",
                                  !values.isLocalGovernment
                                )
                              }
                              leftIcon={
                                <Icon
                                  as={
                                    values.isLocalGovernment ? FaCheck : FaCity
                                  }
                                  color={
                                    values.isLocalGovernment
                                      ? "white"
                                      : "gray.500"
                                  }
                                />
                              }
                              _hover={{
                                bg: values.isLocalGovernment
                                  ? "green.600"
                                  : "gray.100",
                              }}
                            >
                              Local Government
                            </Button>
                            <Button
                              variant={
                                values.isHighPriorityFleet ? "solid" : "outline"
                              }
                              colorScheme={
                                values.isHighPriorityFleet ? "green" : "gray"
                              }
                              onClick={() =>
                                setFieldValue(
                                  "isHighPriorityFleet",
                                  !values.isHighPriorityFleet
                                )
                              }
                              leftIcon={
                                <Icon
                                  as={
                                    values.isHighPriorityFleet
                                      ? FaCheck
                                      : FaExclamationTriangle
                                  }
                                  color={
                                    values.isHighPriorityFleet
                                      ? "white"
                                      : "gray.500"
                                  }
                                />
                              }
                              _hover={{
                                bg: values.isHighPriorityFleet
                                  ? "green.600"
                                  : "gray.100",
                              }}
                            >
                              High Priority Fleet
                            </Button>
                          </SimpleGrid>
                        </FormControl>
                        {values.isFleet &&
                          (values.isLocalGovernment ||
                            values.isStateGovernment) && (
                            <>
                              <FormControl>
                                <FormLabel>
                                  State & Local Government Situations:
                                </FormLabel>
                                <Text fontSize="sm" color="gray.600" mb={3}>
                                  Choose all that apply to your organization.
                                  This helps us tailor compliance requirements.
                                </Text>
                                <SimpleGrid columns={2} spacing={4}>
                                  <Button
                                    variant={
                                      values.isInLowPopulationCounty
                                        ? "solid"
                                        : "outline"
                                    }
                                    colorScheme={
                                      values.isInLowPopulationCounty
                                        ? "green"
                                        : "gray"
                                    }
                                    onClick={() =>
                                      setFieldValue(
                                        "isInLowPopulationCounty",
                                        !values.isInLowPopulationCounty
                                      )
                                    }
                                    leftIcon={
                                      <Icon
                                        as={
                                          values.isInLowPopulationCounty
                                            ? FaCheck
                                            : FaCity
                                        }
                                        color={
                                          values.isInLowPopulationCounty
                                            ? "white"
                                            : "gray.500"
                                        }
                                      />
                                    }
                                    _hover={{
                                      bg: values.isInLowPopulationCounty
                                        ? "green.600"
                                        : "gray.100",
                                    }}
                                  >
                                    Serves 90+% Low Population County
                                  </Button>
                                </SimpleGrid>
                              </FormControl>
                            </>
                          )}
                        <FormControl mt={5}>
                          <FormLabel>
                            Annual Revenue (in millions of dollars)
                          </FormLabel>
                          <Text fontSize="sm" color="gray.600" mb={3}>
                            Slide to indicate your organization's annual
                            revenue. This helps us understand the scale of your
                            operations.
                          </Text>
                          <Stack>
                            <Box width={"50%"} ml={10}>
                              <Slider
                                aria-label="revenue-slider"
                                defaultValue={values.revenueInMillions}
                                min={10}
                                max={50}
                                step={10}
                                onChange={(val) =>
                                  setFieldValue("revenueInMillions", val)
                                }
                              >
                                <SliderMark
                                  value={10}
                                  mt="1"
                                  ml="-2.5"
                                  fontSize="sm"
                                >
                                  ≤ 10M
                                </SliderMark>
                                <SliderMark
                                  value={30}
                                  mt="1"
                                  ml="-2.5"
                                  fontSize="sm"
                                >
                                  30M
                                </SliderMark>
                                <SliderMark
                                  value={50}
                                  mt="1"
                                  ml="-2.5"
                                  fontSize="sm"
                                >
                                  ≥ 50M
                                </SliderMark>
                                <SliderMark
                                  value={values.revenueInMillions}
                                  textAlign="center"
                                  bg="blue.500"
                                  color="white"
                                  mt="-10"
                                  ml="-5"
                                  w="12"
                                  borderRadius="md"
                                >
                                  {values.revenueInMillions}M
                                </SliderMark>
                                <SliderTrack>
                                  <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                              </Slider>
                            </Box>
                          </Stack>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.businessStates && !!touched.businessStates
                          }
                          mt={5}
                        >
                          <FormLabel>States of Business</FormLabel>
                          <Select
                            options={usStateOptions}
                            placeholder={"States of Business"}
                            isMulti
                            name="businessStates"
                            chakraStyles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "50%",
                              }),
                            }}
                            value={values.businessStates.map((state) =>
                              usStateOptions.find((x) => x.value === state)
                            )}
                            onChange={(e: any) => {
                              setFieldValue(
                                "businessStates",
                                e.map((x: any) => x.value)
                              );
                            }}
                          />
                          <FormHelperText>
                            Select all the states where your fleet operates.
                          </FormHelperText>
                          <FormErrorMessage>
                            <ErrorMessage name="businessStates" />
                          </FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                  </Stack>
                </Stack>
              </CardBody>
              <HStack justifyContent={"right"} p={5} pt={0}>
                <Button
                  variant="outline"
                  bg="brand.primary.500"
                  color={"brand.textLight"}
                  _hover={{ backgroundColor: "brand.primary.500" }}
                  type="submit"
                >
                  Save
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Card>
  );
};
