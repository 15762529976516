import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useOrganization } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { SummaryTab } from "./summary-tab";
import { ControlsTab } from "./controls-tab";
import { ProtocolsTab } from "./protocols-tab";
import { EvidenceTab } from "./evidence-tab";
import { useParams } from "react-router-dom";
import { useEvidences } from "../../api/endpoints/evidence";
import { useProtocols } from "../../api/endpoints/protocols";
import { useProject } from "../../api/endpoints/projects";
import { useInspectionTemplates } from "../../api/endpoints/inspection-templates";
import { PageHeader } from "../../components/page-header";
import { useFlags } from "launchdarkly-react-client-sdk";

export const ProjectsEditPage: React.FC = () => {
  let { id } = useParams();
  const { isDemoMode } = useFlags();

  const {
    data: evidences,
    isError: isEvidencesError,
    isLoading: isEvidencesLoading,
  } = useEvidences();

  const {
    data: protocols,
    isError: isProtocolsError,
    isLoading: isProtocolsLoading,
  } = useProtocols();

  const {
    data: inspectionTemplates,
    isError: isInspectionTemplatesError,
    isLoading: isInspectionTemplatesLoading,
  } = useInspectionTemplates();

  const {
    data: project,
    isError: isProjectError,
    isLoading: isProjectLoading,
  } = useProject(id ?? "");

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const tabs = [
    { name: "Summary", component: <SummaryTab project={project} /> },
    {
      name: "Controls",
      component: (
        <ControlsTab
          project={project}
          evidences={evidences}
          protocols={protocols}
          inspectionTemplates={inspectionTemplates}
          memberships={memberships?.data}
        />
      ),
    },
    {
      name: "Protocols",
      component: <ProtocolsTab project={project} policies={protocols} />,
    },
    {
      name: "Evidence",
      component: <EvidenceTab project={project} evidences={evidences} />,
    },
    // { name: "Scratch Pad", component: <ScratchPadTab /> },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box height={"100%"} overflowY={"scroll"}>
      {isDemoMode && (
        <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px" pb={5}>
              Begin a fire safety audit
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing={5}>
                <Text>We will perform the following process:</Text>
                <OrderedList>
                  <ListItem>
                    Prepare a downloadable report based on this playbook.
                  </ListItem>
                  <ListItem>
                    Communicate with your local fire authority and provide
                    report access.
                  </ListItem>
                  <ListItem>
                    Facilitate on-site visits with site administrators.
                  </ListItem>
                </OrderedList>

                <Divider />
                <Checkbox
                // isChecked={batteryRecyclingConsent}
                // onChange={(e) => setBatteryRecyclingConsent(e.target.checked)}
                >
                  Check if you understand and authorize Axle Mobility to share
                  this playbook's data with your local fire authority (Glenwood
                  Farms Fire Station 7).
                </Checkbox>
                <Button
                  colorScheme="brand.primary"
                  // isDisabled={!batteryRecyclingConsent}
                  // onClick={onRecyclingSubmission}
                  onClick={onClose}
                >
                  Submit Request
                </Button>
                <Button
                  onClick={onClose}
                  colorScheme="brand.primary"
                  variant={"outline"}
                >
                  Cancel
                </Button>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      <PageHeader title="Site Safety Plan">
        {isDemoMode && (
          <Button variant={"outline"} onClick={onOpen}>
            Request Fire Safety Audit
          </Button>
        )}
      </PageHeader>
      <Stack>
        <Tabs size={"lg"} variant="soft-rounded">
          <TabList
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab key={index} borderRadius={"md"}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />

          <TabPanels style={{ overflow: "unset !important" }}>
            {tabs.map((tab, index) => {
              return (
                <TabPanel p={0} key={index}>
                  {tab.component}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
