import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Stack,
  useDisclosure,
  Button,
  Text,
  FormControl,
  Input,
  CardHeader,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header";
import { Form, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import {
  useCreateGlovePairInspectionOrder,
  useGlovePairs,
} from "../../api/endpoints/gloves";
import { ArrowLeft } from "lucide-react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/table";

type GloveToReinspect = {
  externalId: string;
  expiresAt: string;
  size: number | null;
  color: string | null;
  assignedTo: string | null;
  isScanned: boolean;
};

export const GloveManagementSendForTestingPage: React.FC = () => {
  const columnHelper = createColumnHelper<GloveToReinspect>();

  const [glovesToReinspect, setGlovesToReinspect] = useState<
    GloveToReinspect[]
  >([]);

  const navigate = useNavigate();
  const { data, isLoading } = useGlovePairs();
  const { mutate: createGlovePairInspectionOrder } =
    useCreateGlovePairInspectionOrder();

  useEffect(() => {
    const today = new Date();

    const expiringGloves =
      data?.filter((glove) => {
        const expirationDate = new Date(glove.expiresAt);
        const timeDiff = expirationDate.getTime() - today.getTime();
        const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysUntilExpiration < 30;
      }) ?? [];

    const mappedGloves = expiringGloves
      .filter((glove) => glove.status === "Assigned")
      .map(
        (glove) =>
          ({
            externalId: glove.externalId,
            size: glove.size,
            color: glove.color,
            assignedTo: glove.assignedTo.name,
            isScanned: false,
            expiresAt: glove.expiresAt,
          } as GloveToReinspect)
      );

    setGlovesToReinspect(mappedGloves);
  }, [data]);

  const formik = useFormik({
    initialValues: {
      gloveCode: "",
    },
    onSubmit: async (values: any) => {
      if (values.gloveCode.length === 0) {
        toast.error("Glove code is required.");
        return;
      }

      const gloveIndex = glovesToReinspect.findIndex(
        (glove) => glove.externalId === values.gloveCode
      );

      if (gloveIndex > -1) {
        const updatedGloves = [...glovesToReinspect];
        updatedGloves[gloveIndex] = {
          ...updatedGloves[gloveIndex],
          isScanned: true,
        };
        setGlovesToReinspect(updatedGloves);
      } else {
        toast.error("Glove pair not found.");
      }

      formik.resetForm();
    },
  });

  const columns = [
    columnHelper.accessor((row) => row.externalId, {
      id: "Glove Code",
      header: () => <span>Glove Code</span>,
      cell: (info) => (
        <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.assignedTo, {
      id: "Assigned To",
      header: () => <span>Assigned To</span>,
      cell: (info) => (
        <Text variant={"sm"} color={"brand.text"}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.expiresAt, {
      id: "Expiration Date",
      header: () => <span>Expiration Date</span>,
      cell: (info) => {
        const currentDate = new Date();
        const expirationDate = new Date(info.getValue());
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return (
          <HStack>
            <Text variant={"sm"} color={"brand.text"}>
              {expirationDate.toLocaleString("default", {
                month: "short",
              })}
              {expirationDate.getDate()}, {expirationDate.getFullYear()}
            </Text>
            {daysUntilExpiration < 30 && daysUntilExpiration > 0 && (
              <Badge variant="orange">{"< 30 days"}</Badge>
            )}
            {daysUntilExpiration <= 0 && (
              <Badge variant="red">{"Expired"}</Badge>
            )}
          </HStack>
        );
      },
    }),
    columnHelper.accessor((row) => row.isScanned, {
      id: "status",
      header: () => <span>Scan Status</span>,
      cell: (info) => {
        if (info.getValue() === true) {
          return <Badge variant="green">{"Scanned"}</Badge>;
        }
        if (info.getValue() === false) {
          return <Badge variant="gray">{"Not Scanned"}</Badge>;
        }
      },
    }),
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Glove Tracker"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardHeader>
            <HStack justifyContent={"space-between"}>
              <HStack>
                <IconButton
                  aria-label="go back"
                  icon={<ArrowLeft />}
                  style={{ background: "transparent" }}
                  onClick={() =>
                    navigate("/tools/glove-management/inspections")
                  }
                  color={"brand.accent"}
                />
                <Heading variant={"md"}>Send Gloves for Testing</Heading>
              </HStack>
            </HStack>
          </CardHeader>
          <Divider width={"95%"} alignSelf={"center"} />
          <CardBody px={"2rem"}>
            <Stack>
              <Card variant={"outline"}>
                <CardBody py={".75rem"} px={"2rem"}>
                  <HStack justify={"space-between"}>
                    <Text>
                      1. Scan each glove pair below to register them for a
                      testing batch.
                    </Text>
                    <FormikProvider value={formik}>
                      <Form>
                        <FormControl>
                          <Input
                            placeholder="Glove Code"
                            size={"lg"}
                            name="gloveCode"
                            value={formik.values.gloveCode}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </Form>
                    </FormikProvider>
                  </HStack>
                </CardBody>
              </Card>
              <Card variant={"outline"}>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={glovesToReinspect}
                    isSearchEnabled={false}
                  />
                </CardBody>
              </Card>
              <Card variant={"outline"}>
                <CardBody py={".75rem"} px={"2rem"}>
                  <HStack justify={"space-between"}>
                    <Text>
                      2. Confirm and put all scanned gloves into the shipping
                      box. We will prepare a shipping label for you and email it
                      to you shortly.
                    </Text>{" "}
                    <FormikProvider value={formik}>
                      <Form>
                        <FormControl>
                          <Button
                            variant="inverted"
                            onClick={() => {
                              const glovesToSend = glovesToReinspect.filter(
                                (x) => x.isScanned
                              );
                              createGlovePairInspectionOrder(
                                glovesToSend.map((x) => ({
                                  glovePairId: x.externalId,
                                }))
                              );
                              navigate("/tools/glove-management");
                            }}
                            isDisabled={
                              glovesToReinspect?.filter((x) => x.isScanned)
                                ?.length === 0
                            }
                          >
                            Confirm
                          </Button>
                        </FormControl>
                      </Form>
                    </FormikProvider>
                  </HStack>
                </CardBody>
              </Card>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
