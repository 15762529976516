import {
  Badge,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  Progress,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsPencil } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { Action, Project } from "../../types";
import { useActions } from "../../api/endpoints/actions";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { DataTable } from "../../components/table";
import { Link, useNavigate } from "react-router-dom";
import { ChartData } from "chart.js";
import { MatrixElement } from "chartjs-chart-matrix";
import {
  Chart as ChartJS,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import _ from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";

ChartJS.register(
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  MatrixElement
);

export const members = [
  {
    id: "1",
    name: "Leader One",
    handle: "@christian",
    avatarUrl: "https://bit.ly/code-beast",
    status: "active",
    message: "Some message",
    lastSeen: "Site Leader",
  },
  {
    id: "2",
    name: "Leader Two",
    handle: "@kent",
    avatarUrl: "https://bit.ly/kent-c-dodds",
    status: "active",
    message: "Some message",
    lastSeen: "Backup Site Leader",
  },
];

interface StatProps extends BoxProps {
  label: string;
  value: {
    value: string;
    color: string;
  };
  delta: {
    value: string;
  };
  badge?: {
    value: string;
    backgroundColor: string;
    color: string;
  };
}
export const Stat = (props: StatProps) => {
  const { label, value, delta, badge, ...boxProps } = props;
  return (
    <Card variant={"outline"}>
      <CardBody
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
        {...boxProps}
      >
        <Stack>
          <HStack justify="space-between">
            <Text textStyle="md" fontWeight={"medium"}>
              {label}
            </Text>
            <Icon as={FiMoreVertical} boxSize="5" color="fg.muted" />
          </HStack>
          <Stack spacing="4">
            <HStack>
              <Heading color={value.color} variant={"2xl"}>
                {value.value}
              </Heading>
              {badge && (
                <Badge bg={badge.backgroundColor} color={badge.color}>
                  {badge?.value}
                </Badge>
              )}
            </HStack>
            <HStack spacing="1" fontWeight="medium">
              {/* <Text color={delta.isUpwardsTrend ? "success" : "error"}>
              {delta.value}
            </Text> */}
              <Text color="fg.muted">{delta.value}</Text>
            </HStack>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
};

export const SummaryTab: React.FC<{
  project?: Project;
}> = ({ project }) => {
  const { data } = useActions();
  const navigate = useNavigate();
  const { demoTasks } = useFlags();

  const columnHelper = createColumnHelper<Action>();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Action</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Category</span>,
      cell: (info) => _.sample(["PPE", "Facilities", "Energy Control"]),
    }),

    columnHelper.accessor((row) => row.priority, {
      id: "priority",
      cell: (info) => {
        switch (info.getValue()) {
          case "High":
            return <Badge variant="red">{info.getValue()}</Badge>;
          case "Medium":
            return <Badge variant="yellow">{info.getValue()}</Badge>;
          default:
            return <Badge variant="blue">{info.getValue()}</Badge>;
        }
      },
      header: () => <span>Risk Level</span>,
    }),
    columnHelper.accessor((row) => row.assignedTo, {
      id: "assignedTo",
      cell: (info) => {
        return info.getValue()?.map((x, i) => {
          return (
            <Text whiteSpace={"normal"} key={i}>
              {x.name}
            </Text>
          );
        });
      },
      header: () => <span>Assigned To</span>,
    }),
    columnHelper.accessor((row) => row.dueDate, {
      id: "dueDate",
      cell: (info) => {
        const parsedDate = dayjs(info.getValue());

        return (
          // <Tooltip label={info.getValue()}>
          <Text whiteSpace={"normal"}>{parsedDate.format("MM/DD/YYYY")}</Text>
          // </Tooltip>
        );
      },
      header: () => <span>Due On</span>,
    }),
    columnHelper.accessor((row) => row.priority, {
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <Link to="/" style={{ fontWeight: "bolder" }}>
              Send Reminder
            </Link>
            {props.row.original.id === "demo-gloves" && (
              <IconButton
                aria-label="address action"
                onClick={() => navigate(`/tools/glove-management`)}
                icon={<BsPencil />}
                style={{ background: "transparent" }}
              />
            )}
            {props.row.original.id === "demo-training" && (
              <IconButton
                aria-label="address action"
                onClick={() => navigate(`/tools/training-tracker`)}
                icon={<BsPencil />}
                style={{ background: "transparent" }}
              />
            )}
            {props.row.original.id !== "demo-gloves" &&
              props.row.original.id !== "demo-training" && (
                <IconButton
                  aria-label="address action"
                  onClick={() => navigate(`${props.row.original.id}`)}
                  icon={<BsPencil />}
                  style={{ background: "transparent" }}
                />
              )}
          </HStack>
        );
      },
    }),
  ];

  return (
    <Box py={5} px={5}>
      <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "6" }}>
        <Stat
          label={"Current Health"}
          value={{ value: "Critically At Risk", color: "red.500" }}
          delta={{
            value: "Improving vs last week",
          }}
        ></Stat>
        <Stat
          label={"High Risk Items"}
          value={{ value: "2", color: "black" }}
          delta={{
            value: "+2 vs last week",
          }}
          badge={{
            value: "Warning",
            backgroundColor: "red.500",
            color: "white",
          }}
        ></Stat>
        <Stat
          label={"Open Items"}
          value={{ value: "17", color: "black" }}
          delta={{
            value: "+17 vs last week",
          }}
        ></Stat>
      </SimpleGrid>
      <Heading size={"sm"} mt={5}>
        Risk Mitigation Progress
      </Heading>
      <SimpleGrid mt={5} columns={1} gap={{ base: "5", md: "6" }}>
        <Card variant={"outline"}>
          <CardBody>
            <HStack justifyContent={"space-between"} display={"flex"}>
              <Text>Documentation</Text>
              <Progress value={0} width={"70%"} colorScheme="green" />
            </HStack>
            <HStack justifyContent={"space-between"} display={"flex"}>
              <Text>WECP</Text>
              <Progress value={0} width={"70%"} colorScheme="green" />
            </HStack>
            <HStack justifyContent={"space-between"} display={"flex"}>
              <Text>Facilities</Text>
              <Progress value={0} width={"70%"} colorScheme="green" />
            </HStack>
            <HStack justifyContent={"space-between"} display={"flex"}>
              <Text>PPE</Text>
              <Progress value={0} width={"70%"} colorScheme="green" />
            </HStack>
            <HStack justifyContent={"space-between"} display={"flex"}>
              <Text>Training</Text>
              <Progress value={0} width={"70%"} colorScheme="green" />
            </HStack>
          </CardBody>
        </Card>
      </SimpleGrid>
      <Heading size={"sm"} mt={5}>
        Action Items
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        gap={{ base: "5", md: "6" }}
        mt={2}
        mb={5}
      >
        {/* {stats.map((stat, id) => (
          <Stat key={id} {...stat} />
        ))} */}
        <Stat
          label={"Controls"}
          value={{ value: "0/17", color: "red.500" }}
          delta={{
            value: "Need review",
          }}
        ></Stat>
        <Stat
          label={"Protocols"}
          value={{ value: "0/9", color: "red.500" }}
          delta={{
            value: "Need Updating",
          }}
          badge={{
            value: "Warning",
            backgroundColor: "red.500",
            color: "white",
          }}
        ></Stat>
        <Stat
          label={"Evidence"}
          value={{ value: "0/3", color: "red.500" }}
          delta={{
            value: "Need sign off",
          }}
        ></Stat>
      </SimpleGrid>
      <Card variant={"outline"}>
        <CardBody>
          <DataTable columns={columns} data={data?.concat(demoTasks) ?? []} />
        </CardBody>
      </Card>
      <Heading size={"sm"} mt={5}>
        Stats
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        gap={{ base: "5", md: "6" }}
        mt={2}
      >
        <Stat
          label={"Employees"}
          value={{ value: "8", color: "orange.500" }}
          delta={{
            value: "Need review",
          }}
        ></Stat>
        <Stat
          label={"Electric Vehicles"}
          value={{ value: "18", color: "black" }}
          delta={{
            value: "Need Updating",
          }}
          badge={{
            value: "Warning",
            backgroundColor: "orange.500",
            color: "white",
          }}
        ></Stat>
        <Stat
          label={"Electric Vehicle Supply Equipment"}
          value={{ value: "3", color: "black" }}
          delta={{
            value: "Need sign off",
          }}
        ></Stat>
      </SimpleGrid>
    </Box>
  );
};
