import { Card, CardBody, HStack, Heading, Text } from "@chakra-ui/react";
import { DeleteButton } from "../../components/delete-button";
import { FiLogOut } from "react-icons/fi";

export const OrganizationDangerZoneSection: React.FC = () => {
  return (
    <>
      <Card direction={{ base: "row", sm: "row" }} variant="outline">
        <HStack width={"100%"}>
          <CardBody>
            <Heading size="md">Leave Organization</Heading>
            <Text py="2">
              Once you leave an organization, you must be re-invited by an admin
              to join it again.
            </Text>
          </CardBody>

          <HStack justifyContent={"right"} p={5}>
            <DeleteButton
              deleteKey="LEAVE"
              deleteButtonText="Leave Organization"
              modalHeader="Leave Organization"
              modalText="Are you sure you want to leave this organization? You must be re-invited by an admin to join it again."
              name="this organization"
              onDelete={() => {
                // Implement leave organization logic here
                console.log("Left organization");
              }}
              leftIcon={<FiLogOut />}
              variant="inverted"
              bg="red.500"
              color={"white"}
              p={2}
            />
          </HStack>
        </HStack>
      </Card>
      <Card direction={{ base: "row", sm: "row" }} variant="outline">
        <HStack width={"100%"}>
          <CardBody>
            <Heading size="md">Delete Organization</Heading>
            <Text py="2">
              Once you delete an organization, there is no going back. Please be
              certain.
            </Text>
          </CardBody>
          <HStack justifyContent={"right"} p={5}>
            <DeleteButton
              deleteKey="DELETE"
              deleteButtonText="Delete Organization"
              modalHeader="Delete Organization"
              modalText="Are you sure you want to delete this organization? This action cannot be undone."
              name="this organization"
              onDelete={() => {
                // Implement delete organization logic here
                console.log("Organization deleted");
              }}
              color={"white"}
              bg="red.500"
              p={2}
            />
          </HStack>
        </HStack>
      </Card>
    </>
  );
};
