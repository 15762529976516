import { Box, Button, Center, Heading, HStack, Icon, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { ProviderIntegrationSummary } from "../../types-new";

import { useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { LuUnplug } from "react-icons/lu";
import { DataTable } from "../../components/table";


export const GenericTab: React.FC<{ description: string, demoProviders: ProviderIntegrationSummary[], header: string }> = ({ description, demoProviders, header }) => {
    const [selectedProviderDetails, setSelectedProviderDetails] = useState<ProviderIntegrationSummary>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const openModal = (provider: ProviderIntegrationSummary) => {
        setSelectedProviderDetails({ ...provider });
        onOpen();
    }

    const getProviderIcon = (provider: ProviderIntegrationSummary) => (
        provider.thumbnailImageName || provider.thumbnailImageUrl ?
            <Image
                maxWidth={20}
                src={provider.notImplemented ? provider.thumbnailImageUrl : `/assets/providers/${provider.thumbnailImageName}`}
                alt={`${provider.thumbnailImageName}`}
            />
            :
            <Center width={20} height={20}><Icon as={LuUnplug} boxSize={10} /></Center>
    );

    const columnHelper = createColumnHelper<ProviderIntegrationSummary>();
    const columns = [
        columnHelper.display({
            id: "icon",
            cell: ({ row: { original: provider } }) => getProviderIcon(provider),
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => `${row.name} ${row.description}`, {
            id: "name-desc",
            cell: ({ row: { original: provider } }) => (
                <Box>
                    <HStack gap={".25rem"}>
                        {provider.isConnected && <Icon as={HiCheckCircle} color="green.500" boxSize={5} />}
                        <Heading>{provider.name}</Heading>
                    </HStack>
                    <Text color={"brand.accent"}>{provider.description}</Text>
                </Box>
            ),
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => row.isConnected, {
            id: "connectCta",
            cell: ({ row: { original: provider } }) => (
                <Button width={"100%"} variant="solid" colorScheme="brand.primary" onClick={() => openModal(provider)}>
                    Connect
                </Button>
            ),
            footer: (props) => props.column.id,
        }),
    ];

    const getModalContent = () =>
        selectedProviderDetails &&
        (
            <ModalContent>
                <ModalHeader>Whoops!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center p={10}>
                        <Text>
                            This feature is currently in private beta. Please contact
                            support@axlemobility.com for assistance.
                        </Text>
                    </Center>
                </ModalBody>
            </ModalContent>
        );

    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
                <ModalOverlay />
                {getModalContent()}
            </Modal>
            <Heading fontSize={"lg"} mb={2}>
                {header}
            </Heading>
            <Text variant="md" color={"brand.accent"} fontWeight={400}>
                {description}
            </Text>
            <DataTable
                columns={columns}
                data={demoProviders ?? []}
                suppressHeaderRow={true}
                suppressPaginationFooter={true}
            />
        </Box>
    );
}