import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Heading,
  Stack,
  StackDivider,
  Switch,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "lucide-react";
import { ReactNode } from "react";

interface PageHeaderProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  // breadcrumbs?: { name: string; link?: string }[];
}

export const PageHeader: React.FC<PageHeaderProps> = (
  props: PageHeaderProps
) => {
  const { title, subtitle, children } = props;

  return (
    <Box
      bg="bg.surface"
      p={{ base: "4", md: "6" }}
      borderLeft={"1px solid"}
      borderColor={"brand.border"}
    >
      <Stack spacing="5" flexDirection="row" justify="space-between">
        {(title || subtitle) && (
          <Stack spacing="1">
            {title && <Heading variant="2xl">{title}</Heading>}
            {subtitle && (
              <Text textStyle="sm" color="fg.muted" lineHeight={1}>
                {subtitle}
              </Text>
            )}
          </Stack>
        )}
        {children && (
          <Stack direction="row" spacing="4">
            {children}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
