import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr } from ".";
import { AdvancedCleanFleetBreakdownResponse, CarbComplianceReport } from "../../types-new/carb";

export const useCarbReport = () => {
  const { getToken } = useAuth();
  return useQuery<CarbComplianceReport, ApiServiceErr>({
    queryKey: ["carb-report"],
    queryFn: async () => {
      const response = await axios.get(`/api/v2/compliance/carb-report`, { headers: { Authorization: `Bearer ${await getToken({ template: "AxleMobility" })}` } });
      return response.data;
    }
  });
};
  
export const useCarbAdvancedCleanFleetBreakdown = () => {
  const { getToken } = useAuth();
  return useQuery<AdvancedCleanFleetBreakdownResponse, ApiServiceErr>({
    queryKey: ["carb-advanced-clean-fleet-breakdown"],
    queryFn: async () => {
      const response = await axios.get(`/api/v2/compliance/carb-report/acf-breakdown`, { headers: { Authorization: `Bearer ${await getToken({ template: "AxleMobility" })}` } });
      return response.data;
    }
  });
};
