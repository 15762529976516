import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  RadioGroup,
  Radio,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import * as Yup from "yup";

import { Field, Form, FormikProvider, useFormik } from "formik";
import { PageHeader } from "../../components/page-header";
import { useOrganization } from "@clerk/clerk-react";
import { ArrowLeftIcon } from "lucide-react";
import {
  useCreateVendor,
  useDeleteVendor,
  useUpdateVendor,
  useVendor,
} from "../../api/endpoints/vendors";
import { Select } from "chakra-react-select";
import { DeleteButton } from "../../components/delete-button";
const { GetCountries, GetState, GetCity } = require("react-country-state-city");

export const VendorsEditPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [countriesList, setCountriesList] = useState<
    { label: string; value: string; code: string }[]
  >([]);
  const [stateList, setStateList] = useState<
    { label: string; value: string; code: string }[]
  >([]);
  const [cityList, setCityList] = useState<
    { label: string; value: string; code: string }[]
  >([]);
  const isEditMode = id !== undefined;

  const { data: existingVendor } = useVendor(id ?? "", isEditMode);

  useEffect(() => {
    if (existingVendor) {
      GetCountries().then((result: any) => {
        setCountriesList(
          result.map((x: any) => {
            return { label: x.name, value: x.id, code: x.iso3 };
          })
        );

        const countryId = result.find(
          (x: any) => x.iso3 === existingVendor.country
        )?.id;

        GetState(countryId).then((result: any) => {
          setStateList(
            result.map((x: any) => {
              return { label: x.name, value: x.id, code: x.state_code };
            })
          );

          const stateId = result.find(
            (x: any) => x.state_code === existingVendor.state
          )?.id;

          GetCity(countryId, stateId).then((result: any) => {
            setCityList(
              result.map((x: any) => {
                return { label: x.name, value: x.id, code: x.name };
              })
            );

            vendorFormik.setValues(existingVendor);
          });
        });
      });
    }
  }, [existingVendor]);

  const { data: createdVendor, mutate: createVendor } = useCreateVendor();
  const { data: updatedVendor, mutate: updateVendor } = useUpdateVendor();
  const { mutate: deleteVendor } = useDeleteVendor();

  const vendorSchema = Yup.object().shape({
    name: Yup.string().min(1).required("Name is required."),
    website: Yup.string().url("Invalid URL."),
    address: Yup.string().required("Address is required."),
    addressLine2: Yup.string(),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    country: Yup.string().required("Country is required."),
    notes: Yup.string(),
    contactPersonName: Yup.string().required(
      "Contact person name is required."
    ),
    contactPersonEmail: Yup.string()
      .email("Invalid email address.")
      .required("Contact person email is required."),
    contactPersonPhone: Yup.string().required(
      "Contact person phone is required."
    ),
    zip: Yup.string()
      .required("Zip code is required.")
      .matches(/^\d{5}$/, "Invalid zip code."),
    type: Yup.string().required("Type is required."),
  });

  const vendorFormik = useFormik({
    initialValues: {
      name: "",
      website: "",
      address: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",
      notes: "",
      type: "other",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
    },
    validationSchema: vendorSchema,
    onSubmit: async (values: any) => {
      if (!isEditMode) {
        await createVendor(values);
      } else {
        await updateVendor({ id: id, body: values });
      }
    },
  });

  useEffect(() => {
    GetCountries().then((result: any) => {
      setCountriesList(
        result.map((x: any) => {
          return { label: x.name, value: x.id, code: x.iso3 };
        })
      );

      const countryId = result.find((x: any) => x.iso3 === "USA")?.id;

      GetState(countryId).then((result: any) => {
        setStateList(
          result.map((x: any) => {
            return { label: x.name, value: x.id, code: x.state_code };
          })
        );
      });
    });
  }, []);

  useEffect(() => {
    const countryId = countriesList.find(
      (x) => x.code === vendorFormik.values.country
    )?.value;

    GetState(countryId).then((result: any) => {
      setStateList(
        result.map((x: any) => {
          return { label: x.name, value: x.id, code: x.state_code };
        })
      );
    });
  }, [vendorFormik?.values?.country]);

  useEffect(() => {
    const countryId = countriesList.find(
      (x) => x.code === vendorFormik.values.country
    )?.value;

    const stateId = stateList.find(
      (x) => x.code === vendorFormik.values.state
    )?.value;

    GetCity(countryId, stateId).then((result: any) => {
      setCityList(
        result.map((x: any) => {
          return { label: x.name, value: x.id, code: x.name };
        })
      );
    });
  }, [vendorFormik?.values?.state]);

  useEffect(() => {
    if (createdVendor) {
      navigate(`/vendors/${createdVendor.id}`);
    }
  }, [createdVendor]);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box height={"100vh"}>
      <PageHeader title={"Vendors"}>
        {isEditMode && (
          <HStack justify={"flex-end"} width={"100%"}>
            <DeleteButton
              color="red.500"
              variant={"outline"}
              onDelete={async () => {
                await deleteVendor({ id: existingVendor?.id ?? "" });
                navigate("/vendors");
              }}
              name={existingVendor?.name}
              deleteKey={existingVendor?.name}
            >
              Delete Vendor
            </DeleteButton>
          </HStack>
        )}
      </PageHeader>
      <Stack p={5}>
        <Card overflow="hidden" variant="outline" position={"relative"}>
          <CardHeader>
            <HStack justify={"space-between"}>
              <HStack>
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onClick={() => {
                    navigate("/vendors");
                  }}
                  aria-label={"Back"}
                />
                <Heading size={"md"}>Edit Vendor</Heading>
              </HStack>
              <HStack>
                <Button variant={"outline"}>Cancel</Button>
                <Button variant={"inverted"} onClick={vendorFormik.submitForm}>
                  Save
                </Button>
              </HStack>
            </HStack>
          </CardHeader>
          <Divider w={"95%"} alignSelf={"center"} />
          <CardBody>
            <FormikProvider value={vendorFormik}>
              <Form>
                <Text
                  variant={"sm"}
                  color={"brand.text"}
                  fontWeight={"600"}
                  mb={5}
                >
                  Company Information
                </Text>
                <Stack spacing={5}>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.name === true &&
                      !!vendorFormik?.touched?.name === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Company Name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name={"name"}
                      type="text"
                      placeholder="Company Name"
                      value={vendorFormik?.values?.name}
                    />
                    <FormErrorMessage>
                      {vendorFormik?.errors?.name?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.website === true &&
                      !!vendorFormik?.touched?.website === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Website</FormLabel>
                    <Field
                      as={Input}
                      id="website"
                      name={"website"}
                      type="text"
                      placeholder="Website"
                      value={vendorFormik?.values?.website}
                    />
                    <FormErrorMessage>
                      {vendorFormik?.errors?.website?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.address === true &&
                      !!vendorFormik?.touched?.address === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Address</FormLabel>
                    <Field
                      as={Input}
                      id="address"
                      name={"address"}
                      type="text"
                      placeholder="Address"
                      value={vendorFormik?.values?.address}
                    />
                    <FormHelperText>
                      Street address, P.O Box, etc.
                    </FormHelperText>
                    <FormErrorMessage>
                      {vendorFormik?.errors?.address?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.addressLine2 === true &&
                      !!vendorFormik?.touched?.addressLine2 === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Address Line 2</FormLabel>
                    <Field
                      as={Input}
                      id="addressLine2"
                      name={"addressLine2"}
                      type="text"
                      placeholder="Address Line 2"
                      value={vendorFormik?.values?.addressLine2}
                    />
                    <FormHelperText>Apt, Suite, etc.</FormHelperText>
                    <FormErrorMessage>
                      {vendorFormik?.errors?.addressLine2?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <SimpleGrid columns={2} spacing={5}>
                    <FormControl
                      isInvalid={
                        !!vendorFormik?.errors?.state === true &&
                        !!vendorFormik?.touched?.state === true
                      }
                    >
                      <FormLabel fontSize={"sm"}>State</FormLabel>
                      <Select
                        options={stateList}
                        placeholder={"State"}
                        value={stateList?.find(
                          (x) => x.code === vendorFormik.values.state
                        )}
                        closeMenuOnSelect={true}
                        onChange={(e: any) => {
                          vendorFormik.setFieldValue("state", e.code);
                        }}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 100,
                          }),
                        }}
                        chakraStyles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: "brand.surface",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            backgroundColor: "brand.surface",
                            border: "none",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            borderColor: "brand.surface",
                          }),
                        }}
                        menuPortalTarget={document.body}
                      />
                      <FormErrorMessage>
                        {vendorFormik?.errors?.state?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!vendorFormik?.errors?.city === true &&
                        !!vendorFormik?.touched?.city === true
                      }
                    >
                      <FormLabel fontSize={"sm"}>City</FormLabel>
                      <Select
                        options={cityList}
                        placeholder={"City"}
                        value={cityList?.find(
                          (x) => x.code === vendorFormik.values.city
                        )}
                        closeMenuOnSelect={true}
                        onChange={(e: any) => {
                          vendorFormik.setFieldValue("city", e.code);
                        }}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 100,
                          }),
                        }}
                        chakraStyles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: "brand.surface",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            backgroundColor: "brand.surface",
                            border: "none",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            borderColor: "brand.surface",
                          }),
                        }}
                        menuPortalTarget={document.body}
                      />
                      <FormErrorMessage>
                        {vendorFormik?.errors?.city?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!vendorFormik?.errors?.zip === true &&
                        !!vendorFormik?.touched?.zip === true
                      }
                    >
                      <FormLabel fontSize={"sm"}>Zip Code</FormLabel>
                      <Field
                        as={Input}
                        id="zip"
                        name={"zip"}
                        type="text"
                        placeholder="Zip Code"
                        value={vendorFormik?.values?.zip}
                      />
                      <FormErrorMessage>
                        {vendorFormik?.errors?.zip?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    {countriesList.length > 0 && (
                      <FormControl
                        isInvalid={
                          !!vendorFormik?.errors?.country === true &&
                          !!vendorFormik?.touched?.country === true
                        }
                      >
                        <FormLabel fontSize={"sm"}>Country</FormLabel>
                        <Select
                          options={countriesList}
                          placeholder={"Country"}
                          value={countriesList?.find(
                            (x) => x.code === vendorFormik.values.country
                          )}
                          // value={vendorFormik?.values?.country}
                          closeMenuOnSelect={true}
                          onChange={(e: any) => {
                            vendorFormik.setFieldValue("country", e.code);
                          }}
                          styles={{
                            menuPortal: (provided) => ({
                              ...provided,
                              zIndex: 100,
                            }),
                          }}
                          chakraStyles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "brand.surface",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              backgroundColor: "brand.surface",
                              border: "none",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              borderColor: "brand.surface",
                            }),
                          }}
                          menuPortalTarget={document.body}
                        />
                        <FormErrorMessage>
                          {vendorFormik?.errors?.country?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </SimpleGrid>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.notes === true &&
                      !!vendorFormik?.touched?.notes === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Notes</FormLabel>
                    <Field
                      as={Textarea}
                      id="notes"
                      name={"notes"}
                      type="text"
                      placeholder="Notes"
                      value={vendorFormik?.values?.notes}
                    />
                    <FormHelperText>
                      Any additional information about the vendor.
                    </FormHelperText>
                    <FormErrorMessage>
                      {vendorFormik?.errors?.notes?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Divider my={10} w={"95%"} alignSelf={"center"} />
                <Text
                  variant={"sm"}
                  color={"brand.text"}
                  fontWeight={"600"}
                  mb={5}
                >
                  Contact Person
                </Text>
                <Stack spacing={5}>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.name === true &&
                      !!vendorFormik?.touched?.name === true
                    }
                  >
                    <FormLabel fontSize={"sm"}>Contact Name</FormLabel>
                    <Field
                      as={Input}
                      id="contactPersonName"
                      name={"contactPersonName"}
                      type="text"
                      placeholder="Contact Name"
                      value={vendorFormik?.values?.contactPersonName}
                    />
                    <FormErrorMessage>
                      {vendorFormik?.errors?.contactPersonName?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <SimpleGrid columns={2} spacing={5}>
                    <FormControl
                      isInvalid={
                        !!vendorFormik?.errors?.contactPersonPhone === true &&
                        !!vendorFormik?.touched?.contactPersonPhone === true
                      }
                    >
                      <FormLabel fontSize={"sm"}>Contact Phone</FormLabel>
                      <Field
                        as={Input}
                        id="contactPersonPhone"
                        name={"contactPersonPhone"}
                        type="text"
                        placeholder="Contact Phone"
                        value={vendorFormik?.values?.contactPersonPhone}
                      />
                      <FormErrorMessage>
                        {vendorFormik?.errors?.contactPersonPhone?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!vendorFormik?.errors?.contactPersonEmail === true &&
                        !!vendorFormik?.touched?.contactPersonEmail === true
                      }
                    >
                      <FormLabel fontSize={"sm"}>Contact Email</FormLabel>
                      <Field
                        as={Input}
                        id="contactPersonEmail"
                        name={"contactPersonEmail"}
                        type="email"
                        placeholder="Contact Email"
                        value={vendorFormik?.values?.contactPersonEmail}
                      />
                      <FormErrorMessage>
                        {vendorFormik?.errors?.contactPersonEmail?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                </Stack>
                <Divider my={10} w={"95%"} alignSelf={"center"} />
                <Text
                  variant={"sm"}
                  color={"brand.text"}
                  fontWeight={"600"}
                  mb={5}
                >
                  Type
                </Text>
                <Stack spacing={5}>
                  <FormControl
                    isInvalid={
                      !!vendorFormik?.errors?.type === true &&
                      !!vendorFormik?.touched?.type === true
                    }
                  >
                    <RadioGroup
                      id="type"
                      name={"type"}
                      onChange={(e: any) => {
                        vendorFormik.setFieldValue("type", e);
                      }}
                      value={vendorFormik.values.type}
                    >
                      <Stack spacing={6}>
                        <Stack>
                          <Radio
                            colorScheme="green"
                            value="battery_recycling"
                            size={"lg"}
                          >
                            Battery Recycling
                          </Radio>
                          <FormHelperText mt={0}>
                            This type allows this vendor to provide battery
                            recycling services to you.
                          </FormHelperText>
                        </Stack>
                        <Stack>
                          <Radio
                            colorScheme="green"
                            size={"lg"}
                            value="glove_testing"
                          >
                            Glove Testing
                          </Radio>
                          <FormHelperText mt={0}>
                            This type allows this vendor to provide glove
                            testing services to you.
                          </FormHelperText>
                        </Stack>
                        <Stack>
                          <Radio colorScheme="green" value="other" size={"lg"}>
                            Other
                          </Radio>
                          <FormHelperText mt={0}>
                            This type allows this vendor to provide any other
                            services to you.
                          </FormHelperText>
                        </Stack>
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {vendorFormik?.errors?.type?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </Form>
            </FormikProvider>
          </CardBody>
          <CardFooter />
        </Card>
      </Stack>
    </Box>
  );
};
