import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Button,
  ModalFooter,
  useDisclosure,
  Badge,
  Text,
  IconButton,
  Tooltip,
  FormHelperText,
  Textarea,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useFormik, FormikProvider, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { BsPencil } from "react-icons/bs";
import { createColumnHelper } from "@tanstack/react-table";
import { Action } from "../../types";
import { DataTable } from "../../components/table";
import { useActions, useCreateAction } from "../../api/endpoints/actions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import type { OrganizationMembershipResource } from "@clerk/types";
import { useOrganization } from "@clerk/clerk-react";
import { useSites } from "../../api/endpoints/sites";
import { useTeams } from "../../api/endpoints/teams";
import { Select } from "chakra-react-select";
import { PageHeader } from "../../components/page-header";
import { PlusIcon } from "lucide-react";
import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

dayjs.extend(relativeTime);

export const MyActionsListPage: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const columnHelper = createColumnHelper<Action>();
  const { demoTasks } = useFlags();

  const { data, isError, isLoading } = useActions();
  const { mutate: createAction } = useCreateAction();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      cell: (info) => {
        return <Text whiteSpace={"normal"}>{info.getValue()}</Text>;
      },
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor((row) => row.dueDate, {
      id: "dueDate",
      cell: (info) => {
        const parsedDate = dayjs(info.getValue());

        return (
          <Tooltip label={info.getValue()}>
            <Text whiteSpace={"normal"}>{parsedDate.format("MM/DD/YYYY")}</Text>
          </Tooltip>
        );
      },
      header: () => <span>Due On</span>,
    }),
    columnHelper.accessor((row) => row.priority, {
      id: "priority",
      cell: (info) => {
        switch (info.getValue()) {
          case "High":
            return <Badge variant="red">{info.getValue()}</Badge>;
          case "Medium":
            return <Badge variant="yellow">{info.getValue()}</Badge>;
          default:
            return <Badge variant="blue">{info.getValue()}</Badge>;
        }
      },
      header: () => <span>Priority</span>,
    }),
    columnHelper.accessor((row) => row.assignedTo, {
      id: "assignedTo",
      cell: (info) => {
        return info.getValue()?.map((x) => {
          return <Text whiteSpace={"normal"}>{x.name}</Text>;
        });
      },
      header: () => <span>Assigned To</span>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <IconButton
              aria-label="address action"
              onClick={() => navigate(`${props.row.original.id}`)}
              icon={<BsPencil />}
              color={"brand.accent"}
              style={{ background: "transparent" }}
            />
          </HStack>
        );
      },
    }),
  ];

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Action name is required."),
    description: Yup.string()
      .min(3)
      .required("Action description is required."),
    priority: Yup.object().required("Action priority is required."),
    dueDate: Yup.date().required("Action due date is required."),
  });

  const initialValues = {
    name: undefined,
    description: undefined,
    assignedTo: [],
    dueDate: undefined,
    priority: "low",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      // values.id = selectedQuestion;
      const formData = {
        name: values.name,
        priority: values.priority.value,
        description: values.description,
        dueDate: values.dueDate,
        assignedTo: values.assignedTo.map((x: any) => x.value),
      };
      createAction({ body: formData });
      onToggle();
    },
  });

  const {
    data: teams,
    isError: teamsIsError,
    isLoading: teamsIsLoading,
  } = useTeams();

  const {
    data: sites,
    isError: sitesIsError,
    isLoading: sitesIsLoading,
  } = useSites();

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const assignedToOptions: readonly any[] = [
    {
      label: "Teams",
      options: teams?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Sites",
      options: sites?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  const priorityOptions: readonly any[] = [
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Create New Action</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.name === true &&
                      !!formik?.touched?.name === true
                    }
                  >
                    <FormLabel>Name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name={"name"}
                      type="text"
                      placeholder="Example Name"
                      value={formik.values.name}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.name?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.description === true &&
                      !!formik?.touched?.description === true
                    }
                  >
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Textarea}
                      id="description"
                      name={"description"}
                      type="text"
                      placeholder="Example Description"
                      value={formik.values.description}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.description?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="priority"
                    isInvalid={
                      !!formik?.errors?.priority === true &&
                      !!formik?.touched?.priority === true
                    }
                  >
                    <FormLabel>Priority</FormLabel>
                    <Select
                      options={priorityOptions}
                      id="priority"
                      placeholder={"High, Medium, or Low"}
                      value={formik.values.priority}
                      name="priority"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("priority", e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.priority?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="assignedTo"
                    isInvalid={
                      !!formik?.errors?.assignedTo === true &&
                      !!formik?.touched?.assignedTo === true
                    }
                  >
                    <FormLabel>Assigned To*</FormLabel>
                    <Select
                      isMulti
                      options={assignedToOptions}
                      id="assignedTo"
                      placeholder={"Team, Site, or Member."}
                      value={formik.values.assignedTo}
                      name="assignedTo"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("assignedTo", e);
                      }}
                    />
                    <FormHelperText>
                      The teams, sites, and members selected will be assigned to
                      resolve the action.
                    </FormHelperText>
                    <FormErrorMessage>
                      {formik?.errors?.assignedTo?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!formik?.errors?.dueDate === true &&
                      !!formik?.touched?.dueDate === true
                    }
                  >
                    <FormLabel>Due Date</FormLabel>
                    <Input
                      placeholder="Select Date"
                      size="md"
                      type="date"
                      id="dueDate"
                      name={"dueDate"}
                      value={formik.values.dueDate}
                      onChange={(e: any) => {
                        formik.setFieldValue("dueDate", e.target.value);
                      }}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.dueDate?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <PageHeader title="Tasks"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={data?.concat(demoTasks) ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onToggle}
                >
                  Add
                </Button>
              }
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
