import {
  Badge,
  Box,
  Card,
  CardBody,
  HStack,
  IconButton,
  Stack,
  useDisclosure,
  Button,
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Divider,
  List,
  ListIcon,
  ListItem,
  VStack,
  SimpleGrid,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { BsEye } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { PageHeader } from "../../components/page-header";
import { GlovePair } from "../../types";
import { useGlovePairs } from "../../api/endpoints/gloves";
import { CheckCircle } from "lucide-react";

export const GloveManagementListPage: React.FC = () => {
  const columnHelper = createColumnHelper<GlovePair>();
  const [selectedGlove, setSelectedGlove] = useState<GlovePair | null>(null);
  const navigate = useNavigate();
  const { data, isLoading } = useGlovePairs();

  const glovesInTesting = useMemo(() => {
    return data?.filter((glove) => glove.status === "Testing") || [];
  }, [data]);

  const expiringSoonGloves = useMemo(() => {
    const today = new Date();
    return (
      data
        ?.filter((glove) => glove.status === "Assigned")
        .filter((glove) => {
          const expirationDate = new Date(glove.expiresAt);
          const timeDiff = expirationDate.getTime() - today.getTime();
          const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
          return daysUntilExpiration < 30 && daysUntilExpiration > 0;
        }) || []
    );
  }, [data]);

  const expiredGloves = useMemo(() => {
    const today = new Date();
    return (
      data
        ?.filter((glove) => glove.status === "Assigned")
        .filter((glove) => {
          const expirationDate = new Date(glove.expiresAt);
          const timeDiff = expirationDate.getTime() - today.getTime();
          const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
          return daysUntilExpiration <= 0;
        }) || []
    );
  }, [data]);

  const columns = [
    columnHelper.accessor((row) => row.externalId, {
      id: "Glove Code",
      header: () => <span>Glove Code</span>,
      cell: (info) => (
        <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.assignedTo.name, {
      id: "Assigned To",
      header: () => <span>Assigned To</span>,
      cell: (info) => (
        <Text variant={"sm"} color={"brand.text"}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.assignedToSite?.name, {
      id: "Assigned To Site",
      header: () => <span>Assigned To Site</span>,
      cell: (info) => (
        <Text variant={"sm"} color={"brand.text"}>
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.expiresAt, {
      id: "Expiration Date",
      header: () => <span>Expiration Date</span>,
      cell: (info) => {
        const currentDate = new Date();
        const expirationDate = new Date(info.getValue());
        const timeDiff = expirationDate.getTime() - currentDate.getTime();
        const daysUntilExpiration = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return (
          <HStack>
            <Text variant={"sm"} color={"brand.text"}>
              {expirationDate.toLocaleString("default", {
                month: "short",
              })}{" "}
              {expirationDate.getDate()}, {expirationDate.getFullYear()}
            </Text>
            {daysUntilExpiration < 30 &&
              daysUntilExpiration > 0 &&
              info.row.original.status === "Assigned" && (
                <Badge variant="orange">{"< 30 days"}</Badge>
              )}
            {daysUntilExpiration < 0 &&
              info.row.original.status === "Assigned" && (
                <Badge variant="red">{"Expired"}</Badge>
              )}
          </HStack>
        );
      },
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      header: () => <span>Status</span>,
      cell: (info) => {
        if (info.getValue() === "Assigned") {
          return <Badge variant="gray">{"In Use"}</Badge>;
        }
        if (info.getValue() === "Expired") {
          return <Badge variant="red">{"Expired"}</Badge>;
        }
        if (info.getValue() === "Testing") {
          return <Badge variant="blue">{"Testing"}</Badge>;
        }
        if (info.getValue() === "Disposed") {
          return <Badge variant="black">{"Disposed"}</Badge>;
        }
      },
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <IconButton
              aria-label="view glove"
              icon={<BsEye />}
              onClick={() => {
                setSelectedGlove(props.row.original);
                onOpen();
              }}
              style={{ background: "transparent" }}
            />
          </HStack>
        );
      },
    }),
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Activity</DrawerHeader>
          <DrawerBody>
            <HStack>
              <Text fontWeight={"bold"}>Glove Code: </Text>
              <Text>{selectedGlove?.externalId}</Text>
            </HStack>
            <HStack>
              <Text fontWeight={"bold"}>Assigned To: </Text>
              <Text>{selectedGlove?.assignedTo.name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight={"bold"}>Assigned To Site: </Text>
              <Text>{selectedGlove?.assignedToSite?.name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight={"bold"}>Class: </Text>
              <Text>{selectedGlove?.class}</Text>
            </HStack>
            <HStack>
              <Text fontWeight={"bold"}>Size: </Text>
              <Text>{selectedGlove?.size}</Text>
            </HStack>

            <HStack>
              <Text fontWeight={"bold"}>Expires On: </Text>
              <Text>
                {selectedGlove?.expiresAt
                  ? new Date(selectedGlove.expiresAt).toLocaleDateString(
                      "en-US",
                      { year: "numeric", month: "long", day: "numeric" }
                    )
                  : "N/A"}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight={"bold"}>Remaining Life:</Text>
              <Text>
                {selectedGlove?.expiresAt
                  ? Math.max(
                      0,
                      Math.ceil(
                        (new Date(selectedGlove.expiresAt).getTime() -
                          new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      )
                    )
                  : "N/A"}{" "}
                days
              </Text>
            </HStack>
            <Divider my={5} />
            <List spacing={3}>
              {selectedGlove?.eventHistory.map((event, i) => (
                <ListItem key={i}>
                  <HStack align={"center"}>
                    <ListIcon as={CheckCircle} color="green.500" />
                    <VStack gap={0}>
                      <Text fontWeight={"bold"}>{event.eventName}</Text>
                      <Text color={"fg.muted"} fontSize={"xs"}>
                        {new Date(event.eventAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </Text>
                    </VStack>
                  </HStack>
                </ListItem>
              ))}
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <PageHeader title="Glove Tracker"></PageHeader>

      {expiringSoonGloves.length > 0 && (
        <Alert
          status="warning"
          variant="left-accent"
          flexDir="column"
          justifyContent={"left"}
          alignItems={"space-between"}
        >
          <HStack justifyContent={"space-between"}>
            <HStack>
              <AlertIcon />
              <Stack spacing={0}>
                <AlertTitle>Attention</AlertTitle>
                <AlertDescription>
                  {expiringSoonGloves.length} pairs of gloves are about to
                  expire
                </AlertDescription>
              </Stack>
            </HStack>
            <Button
              width={{ base: "100%", md: "auto" }}
              background={"orange.500"}
              color={"white"}
              onClick={() =>
                navigate("/tools/glove-management/inspections/new")
              }
            >
              Send for Testing
            </Button>
          </HStack>
        </Alert>
      )}
      {expiredGloves.length > 0 && (
        <Alert
          status="error"
          variant="left-accent"
          flexDir="column"
          justifyContent={"left"}
          alignItems={"space-between"}
        >
          <HStack justifyContent={"space-between"}>
            <HStack>
              <AlertIcon />
              <Stack spacing={0}>
                <AlertTitle>Attention</AlertTitle>
                <AlertDescription>
                  {expiredGloves.length} pairs of gloves expired! They should be
                  removed from the field immediately.
                </AlertDescription>
              </Stack>
            </HStack>
            <Button
              width={{ base: "100%", md: "auto" }}
              background={"red.500"}
              color={"white"}
              onClick={() =>
                navigate("/tools/glove-management/inspections/new")
              }
            >
              Send for Testing
            </Button>
          </HStack>
        </Alert>
      )}
      <Card variant={"outline"} m={5} p={5}>
        <CardBody>
          <SimpleGrid columns={{ base: 1, md: 5 }} spacing={5} mb={5}>
            <Card
              variant={"outline"}
              bg={expiredGloves.length > 0 ? "red.500" : "white"}
            >
              <CardBody p={3}>
                <Text
                  variant={"xs"}
                  color={expiredGloves.length > 0 ? "white" : "gray.400"}
                >
                  Gloves expired
                </Text>
                <Center p={5}>
                  <Text
                    variant={"3xl"}
                    color={expiredGloves.length > 0 ? "white" : "initial"}
                  >
                    {expiredGloves.length}
                  </Text>
                </Center>
              </CardBody>
            </Card>
            <Card
              variant={"outline"}
              bg={expiringSoonGloves.length > 0 ? "orange.500" : "white"}
            >
              <CardBody p={3}>
                <Text
                  variant={"xs"}
                  color={expiringSoonGloves.length > 0 ? "white" : "gray.400"}
                >
                  Gloves expiring
                </Text>
                <Center p={5}>
                  <Text
                    variant={"3xl"}
                    color={expiringSoonGloves.length > 0 ? "white" : "initial"}
                  >
                    {expiringSoonGloves.length}
                  </Text>
                </Center>
              </CardBody>
            </Card>
            <Card
              variant={"outline"}
              bg={glovesInTesting.length > 0 ? "blue.500" : "white"}
            >
              <CardBody p={3}>
                <Text
                  variant={"xs"}
                  color={glovesInTesting.length > 0 ? "white" : "gray.400"}
                >
                  Gloves in testing
                </Text>
                <Center p={5}>
                  <Text
                    variant={"3xl"}
                    color={glovesInTesting.length > 0 ? "white" : "initial"}
                  >
                    {glovesInTesting.length}
                  </Text>
                </Center>
              </CardBody>
            </Card>
            <Card variant={"outline"}>
              <CardBody p={3}>
                <Text variant={"xs"} color={"gray.400"}>
                  Gloves in use
                </Text>
                <Center p={5}>
                  <Text variant={"3xl"}>
                    {
                      data?.filter((glove) => glove.status === "Assigned")
                        .length
                    }
                  </Text>
                </Center>
              </CardBody>
            </Card>
            <Card variant={"outline"}>
              <CardBody p={3}>
                <Text variant={"xs"} color={"gray.400"}>
                  Total gloves
                </Text>
                <Center p={5}>
                  <Text variant={"3xl"}>{data?.length}</Text>
                </Center>
              </CardBody>
            </Card>
          </SimpleGrid>
          <Card variant={"outline"}>
            <CardBody>
              <DataTable
                columns={columns}
                data={data || []}
                actionChildren={
                  <>
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      variant="outline"
                      onClick={() =>
                        navigate("/tools/glove-management/inspections")
                      }
                    >
                      Send for Testing
                    </Button>
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      variant="outline"
                      onClick={() => navigate("/tools/glove-management/orders")}
                    >
                      Order Gloves
                    </Button>
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      variant="outline"
                      onClick={() => navigate("/tools/glove-management/assign")}
                    >
                      Assign Gloves
                    </Button>
                  </>
                }
              />
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Box>
  );
};
