import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Protocol } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useProtocols = () => {
    const { getToken } = useAuth();
    return useQuery<Protocol[], ApiServiceErr>({
      queryKey: ["protocols"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/protocols`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      }
    });
}

export const useProtocol = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Protocol, ApiServiceErr>({
    queryKey: ["protocol", id],
    queryFn:  async () => {
      const response = await axios.get(
        `/api/protocols/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useCreateProtocol = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({protocol}: {protocol: Protocol}) => {
      return await axios.post(
        `/api/protocols`,
        protocol,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useUpdateProtocol = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, protocol}: {id: string, protocol: Protocol}) => {
      const response = await axios.put(
        `/api/protocols/${id}`,
        protocol,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['protocol', variables.id], variables.protocol);
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDeleteProtocol = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      const response = await axios.delete(
        `/api/protocols/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useAttachProtocolToResource = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({protocolId, resourceId}: {protocolId: string, resourceId: string}) => {
      const response = await axios.post(
        `/api/protocols/${protocolId}/attach/${resourceId}`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDetachProtocolFromResource = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({protocolId, resourceId}: {protocolId: string, resourceId: string}) => {
      const response = await axios.post(
        `/api/protocols/${protocolId}/detach/${resourceId}`,
        null,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['protocols'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}