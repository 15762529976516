import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";
import { Team } from "../../types-new";

export const useTeams = () => {
    const { getToken } = useAuth();
    return useQuery<Team[], ApiServiceErr>({
      queryKey: ["teams"],
      queryFn:  async () => {
        const response = await axios.get(
          `/api/teams`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );
        return response.data;
      }
    });
}

export const useTeam = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Team, ApiServiceErr>({
    queryKey: ["team", id],
    queryFn:  async () => {
      if(id.length === 0) return;
      const response = await axios.get(
        `/api/teams/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    }
  });
}

export const useUpdateTeam = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, team}: {id: string, team: Team}) => {
      const response = await axios.put(
        `/api/teams/${id}`,
        team,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
     
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['team', variables.id], variables.team);
      queryClient.invalidateQueries({ queryKey: ['team'] });
      toast.success(`Updated team: ${variables.team.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error updating team: ${variables.team.name}`)
      console.log(error);
    },
    
  });
}

export const useCreateTeam = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({team}: {team: Team}) => {
      const response = await axios.post(
        `/api/teams`,
        team,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.headers["location"];
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['team'] });
      toast.success(`Created team: ${variables.team.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating team: ${variables.team.name}`)
      console.log(error);
    },
  });
}


export const useAddMemberToTeam = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({teamId, memberId}: {teamId: string, memberId: string}) => {
      const response = await axios.post(
        `/api/teams/${teamId}/add`,
        {memberId: memberId},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['team'] });
      toast.success(`Added member(s) to team`)

    },
    onError: (error, variables, context) => {
      toast.error(`Error adding member(s) to team`)
      console.log(error);
    },
  });
}

export const useRemoveMemberFromTeam = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({teamId, memberId}: {teamId: string, memberId: string}) => {
      const response = await axios.post(
        `/api/teams/${teamId}/remove`,
        {memberId: memberId},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['team'] });
      toast.success(`Removed member(s) from team`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error removing member(s) from team`)
      console.log(error);
    },
  });
}

export const useDeleteTeam = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      const response = await axios.delete(
        `/api/teams/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['team'] });
      toast.success(`Deleted team: ${variables.id}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error deleting team: ${variables.id}`)
    },
  });
}
