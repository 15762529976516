import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InspectionTemplate } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useInspectionTemplates = () => {
  const { getToken } = useAuth();
  return useQuery<InspectionTemplate[], ApiServiceErr>({
    queryKey: ["inspection-templates"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/inspection-templates`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useInspectionTemplate = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<InspectionTemplate, ApiServiceErr>({
    queryKey: ["inspection-templates", id],
    queryFn:  async () => {
      const result = await axios.get(
        `/api/inspection-templates/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useCreateInspectionTemplate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {name: string, description: string, formDefinition?: any  }) => {
      const result = await axios.post(
        `/api/inspection-templates`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-templates'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useUpdateInspectionTemplate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, body}: {id: string, body: {name?: string, description?: string, form?: any  }}) => {
      const result = await axios.put(
        `/api/inspection-templates/${id}`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      toast.success("Inspection template updated successfully.");

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-templates'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("An error occurred while updating the inspection template. Please try again.");
    },
  });
}

export const useDeleteInspectionTemplate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/inspection-templates/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['inspection-templates'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}