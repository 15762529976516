import {
  Card,
  CardBody,
  CardFooter,
  Editable,
  EditablePreview,
  EditableTextarea,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useOrganization } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import type { OrganizationMembershipResource } from "@clerk/types";

import { useNavigate, useParams } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table";
import { Member, Team } from "../../../types-new";
import * as Yup from "yup";

import { Field, Form, FormikProvider, useFormik } from "formik";
import {
  useAddMemberToTeam,
  useCreateTeam,
  useRemoveMemberFromTeam,
  useTeam,
  useUpdateTeam,
} from "../../../api/endpoints/teams";
import { Select } from "chakra-react-select";
import { DeleteButton } from "../../../components/delete-button";
import { ArrowLeft, PlusIcon } from "lucide-react";
export const TeamEditMembersTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: team,
    isError: isTeamError,
    isLoading: isTeamLoading,
  } = useTeam(id ?? "");

  const columnHelper = createColumnHelper<Member>();

  const {
    isOpen: isAddMemberModalOpen,
    onToggle: onAddMemberModalToggle,
    onClose: onAddMemberModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditTeamModalOpen,
    onToggle: onEditTeamModalToggle,
    onClose: onEditTeamModalClose,
  } = useDisclosure();

  const { mutate: addMemberToTeam } = useAddMemberToTeam();
  const { mutate: removeMemberFromTeam } = useRemoveMemberFromTeam();
  const { mutate: updateTeam } = useUpdateTeam();
  const { data: createdTeamId, mutate: createTeam } = useCreateTeam();

  const columns = [
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      header: () => <span>First Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      header: () => <span>Last Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),

    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <DeleteButton
              deleteKey={props.row.original.emailAddress}
              name={props.row.original.emailAddress}
              aria-label="delete team member"
              onDelete={() =>
                removeMemberFromTeam({
                  teamId: id ?? "",
                  memberId: props.row.original.id,
                })
              }
            />
          </HStack>
        );
      },
    }),
  ];

  const schema = Yup.object().shape({
    members: Yup.array().min(1, "At least one member is required."),
  });

  const initialValues = {
    members: [],
  };

  const addMemberToTeamFormik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      if (id === undefined) return;
      await values.members.forEach(async (member: any) => {
        await addMemberToTeam({ teamId: id, memberId: member.value });
      });

      onAddMemberModalClose();
    },
  });

  const editTeamSettingsFormik = useFormik({
    initialValues: team ?? ({} as Team),
    validationSchema: schema,
    onSubmit: async (values: any) => {
      if (id !== undefined) {
        await updateTeam({ id: id, team: values });
      } else {
        await createTeam({ team: values });
      }
    },
  });

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const membersOptions: readonly any[] = [
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  return (
    <Stack p={5}>
      <Modal
        isOpen={isAddMemberModalOpen}
        onClose={onAddMemberModalClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={addMemberToTeamFormik}>
            <Form>
              <ModalHeader>Add Members to {team?.name} Team</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    id="members"
                    isInvalid={
                      !!addMemberToTeamFormik?.errors?.members === true &&
                      !!addMemberToTeamFormik?.touched?.members === true
                    }
                  >
                    <FormLabel>New Members</FormLabel>
                    <Select
                      isMulti
                      options={membersOptions}
                      id="members"
                      placeholder={"Members"}
                      value={addMemberToTeamFormik.values.members}
                      name="members"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        addMemberToTeamFormik.setFieldValue("members", e);
                      }}
                    />
                    <FormHelperText>
                      The members listed above will be assigned to {team?.name}{" "}
                      Team.
                    </FormHelperText>
                    <FormErrorMessage>
                      {addMemberToTeamFormik?.errors?.members?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onAddMemberModalClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!addMemberToTeamFormik.isValid}
                  type="submit"
                >
                  Add
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isEditTeamModalOpen}
        onClose={onEditTeamModalClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={editTeamSettingsFormik}>
            <Form>
              <Card variant={"outline"}>
                <CardBody>
                  <FormControl
                    isInvalid={
                      !!editTeamSettingsFormik?.errors?.name === true &&
                      !!editTeamSettingsFormik?.touched?.name === true
                    }
                    mb={5}
                  >
                    <FormLabel>Team Name</FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name={"name"}
                      type="text"
                      placeholder="Example Name"
                      value={editTeamSettingsFormik?.values?.name}
                    />
                    <FormErrorMessage>
                      {editTeamSettingsFormik?.errors?.name?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </CardBody>
                <CardFooter justifyContent={"end"} display={"flex"}>
                  <Button
                    colorScheme="brand.primary"
                    px={5}
                    onClick={() => editTeamSettingsFormik.handleSubmit()}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Card variant={"outline"}>
        <CardBody>
          <DataTable
            columns={columns}
            data={team?.members ?? []}
            actionChildren={
              <>
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onAddMemberModalToggle}
                >
                  Add Member
                </Button>
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onEditTeamModalToggle}
                >
                  Edit Team
                </Button>
              </>
            }
            title={team?.name}
            onBackClick={() => navigate("/org-management/teams")}
          />
        </CardBody>
      </Card>
    </Stack>
  );
};
