import { useState, useMemo } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Select,
  Text,
  Stack,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { Vehicle } from "../../types";
import { ComplianceTask } from "../../types-new";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../vehicles/date-picker.css";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { Edit } from "lucide-react";

export const VehicleTasksTab = ({ vehicle }: { vehicle?: Vehicle }) => {
  const { mutate: updateVehicle } = useUpdateVehicle();
  const [tasks, setTasks] = useState<ComplianceTask[]>(
    vehicle?.complianceData.tasks || []
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTask, setSelectedTask] = useState<ComplianceTask | null>(null);

  const columns: ColumnDef<ComplianceTask>[] = useMemo(
    () => [
      {
        header: "Due Date",
        accessorKey: "dueDate",
        cell: ({ getValue }) => {
          const date = getValue<string>();
          return new Date(date).toLocaleDateString();
        },
      },
      {
        header: "Type",
        accessorKey: "ruleName",
      },
      {
        header: "Description",
        accessorKey: "description",
      },
      {
        header: "Status",
        accessorKey: "completedDate",
        cell: ({ getValue }) => {
          const completed = getValue<string>();
          return (
            <Badge
              colorScheme={completed && completed !== "" ? "green" : "red"}
            >
              {completed && completed !== "" ? "Completed" : "Pending"}
            </Badge>
          );
        },
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <IconButton
            p={0}
            m={0}
            aria-label="Edit task"
            icon={<Edit size={16} />}
            size="16px"
            onClick={() => {
              setSelectedTask(row.original);
              onOpen();
            }}
          />
        ),
      },
    ],
    []
  );

  const handleSaveTask = async () => {
    if (selectedTask) {
      const updatedTasks = tasks.map((t) =>
        t.id === selectedTask.id ? { ...t, ...selectedTask } : t
      );
      setTasks(updatedTasks);
      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            tasks: updatedTasks,
          },
        };
        console.log("updatedVehicle", updatedVehicle);
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }
    }
    setSelectedTask(null);
    onClose();
  };

  return (
    <Box>
      <DataTable
        data={tasks}
        columns={columns}
        suppressPaginationFooter={true}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Task</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} justifyContent="flex-start">
              {selectedTask && (
                <Stack width="100%">
                  <Text>
                    Due Date:{" "}
                    {new Date(selectedTask.dueDate).toLocaleDateString()}
                  </Text>
                  <Text>Type: {selectedTask.ruleName}</Text>
                  <Text>Description: {selectedTask.description}</Text>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      value={
                        selectedTask.completedDate ? "Completed" : "Pending"
                      }
                      onChange={(e) =>
                        setSelectedTask({
                          ...selectedTask,
                          completedDate:
                            e.target.value === "Completed"
                              ? new Date()
                              : undefined,
                        })
                      }
                    >
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={"inverted"} mr={3} onClick={handleSaveTask}>
              Save Changes
            </Button>
            <Button variant={"outline"} color="red.500" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
