import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  StackDivider,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  ProjectControl,
  Evidence,
  Protocol,
  Project,
  InspectionTemplate,
} from "../../types";
import { DataTable } from "../../components/table";
import { createColumnHelper } from "@tanstack/react-table";
import { Select } from "chakra-react-select";
import { useFormik, Form, FormikProvider } from "formik";
import { toast } from "react-toastify";
import type { OrganizationMembershipResource } from "@clerk/types";
import { useUpdateProjectControlStatus } from "../../api/endpoints/projects";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useFlags } from "launchdarkly-react-client-sdk";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
type BarChartData = {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
};

interface FormValues {
  isApplicable: boolean;
  ownerId: string | undefined;
  evidence: string[];
  protocols: string[];
  inspectionTemplates: string[];
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Control Compliance History",
    },
  },
};

export const ControlsTab: React.FC<{
  project?: Project;
  evidences?: Evidence[];
  protocols?: Protocol[];
  inspectionTemplates?: InspectionTemplate[];
  memberships?: OrganizationMembershipResource[];
  setProject?: React.Dispatch<React.SetStateAction<Project | undefined>>;
}> = ({
  project,
  evidences,
  protocols,
  inspectionTemplates,
  memberships,
  setProject,
}) => {
  const columnHelper = createColumnHelper<ProjectControl>();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [selectedControl, setSelectedControl] = useState<
    ProjectControl | undefined
  >();
  const [controlComplianceHistory, setControlComplianceHistory] = useState<
    BarChartData | undefined
  >();
  const {
    mutate: updateProjectControlStatus,
    isError: updateProjectControlStatusError,
  } = useUpdateProjectControlStatus();

  const { demoControls } = useFlags();

  const evidenceOptions = evidences?.map((x) => {
    return { label: x.name, value: x.id };
  });

  const protocolsOptions = protocols?.map((x) => {
    return { label: x.name, value: x.id };
  });

  const inspectionTemplatesOptions = inspectionTemplates?.map((x) => {
    return { label: x.name, value: x.id };
  });

  const ownerOptions = memberships?.map((x) => {
    return {
      label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
      value: x.publicUserData.userId,
    };
  });

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "referenceCode",
      header: () => <span>Reference Code</span>,
      cell: (info) => {
        return <Badge>{info.row.original?.referenceCode} </Badge>;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => {
        const value = info.getValue();
        return value;
      },
      header: () => <span>Name</span>,
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row, {
      id: "controls",
      cell: (info) => {
        switch (info.row.original.status) {
          case "Mitigated":
            return <Badge variant={"green"}>Mitigated</Badge>;
          case "NotApplicable":
            return <Badge variant="gray">Not Applicable</Badge>;
          case "NotMitigated":
          default:
            return <Badge variant="red">Not Mitigated</Badge>;
        }
      },
      header: () => <span>Status</span>,
      footer: (props) => props.column.id,
    }),
  ];

  const onRowClick = (rowIndex: number, columnIndex: number) => {
    setSelectedControl(project?.controls.concat(demoControls ?? [])[rowIndex]);
    onToggle();
  };

  useEffect(() => {
    formik.resetForm();

    let key: keyof ProjectControl;

    if (!selectedControl) return;

    const compliantCounts = Array(12).fill(0);
    const nonCompliantCounts = Array(12).fill(0);

    // Group data by month and count compliant and non-compliant entries
    selectedControl.protocolAutomatedCheckResults.forEach((entry) => {
      const date = new Date(entry.checkedAt);
      const month = date.getMonth();

      if (entry.isCompliant) {
        compliantCounts[month]++;
      } else {
        nonCompliantCounts[month]++;
      }
    });

    setControlComplianceHistory({
      labels: months,
      datasets: [
        {
          label: "Compliant",
          data: compliantCounts,
          backgroundColor: "rgba(32, 75, 54, 0.8)",
        },
        {
          label: "Non-Compliant",
          data: nonCompliantCounts,
          backgroundColor: "rgba(116,1,18, 0.8)",
        },
      ],
    });

    for (key in selectedControl) {
      if (
        key === "evidence" ||
        key === "protocols" ||
        key === "inspectionTemplates"
      ) {
        formik.setFieldValue(
          key,
          selectedControl[key].map((x) => {
            return x.id;
          }),
          true
        );
      } else if (key === "owner") {
        formik.setFieldValue("ownerId", selectedControl[key]?.id, true);
      } else {
        formik.setFieldValue(key, selectedControl[key], true);
      }
    }
  }, [selectedControl]);

  const initialValues: FormValues = {
    isApplicable: true,
    ownerId: undefined,
    protocols: [],
    evidence: [],
    inspectionTemplates: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      await updateProjectControlStatus({
        body: values,
        projectId: project?.id ?? "",
        controlId: selectedControl?.id ?? "",
      });

      if (!updateProjectControlStatusError) {
        toast("Updated control successfully.");
        onToggle();
        setSelectedControl(undefined);
      } else {
        toast.error(
          `Error when updating control: ${updateProjectControlStatusError}`
        );
      }
    },
  });

  return (
    <Box py={5} px={10}>
      <Drawer isOpen={isOpen} placement="right" size={"lg"} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent overflow={"scroll"}>
          <FormikProvider value={formik}>
            <Form>
              <DrawerCloseButton />
              <DrawerHeader>
                <Heading>Control Details</Heading>
              </DrawerHeader>
              <Divider />
              <DrawerBody>
                <Stack divider={<StackDivider />}>
                  <Box>
                    <Heading size={"md"}>Completion Status</Heading>
                    <Text p={5}>{selectedControl?.statusHint}</Text>
                    {(selectedControl?.nonCompliantResources.length ?? 0) >
                      0 && <Text fontWeight={"bold"}>Affected Resources:</Text>}
                    {selectedControl?.nonCompliantResources.map((x, i) => {
                      return (
                        <Card
                          key={i}
                          variant={"outline"}
                          my={2}
                          borderColor={"red.500"}
                        >
                          <CardBody>{x.name}</CardBody>
                        </Card>
                      );
                    })}
                    {controlComplianceHistory &&
                      (selectedControl?.protocolAutomatedCheckResults.length ??
                        0) > 0 && (
                        <Bar
                          options={options}
                          data={controlComplianceHistory}
                        />
                      )}
                  </Box>
                  <Box>
                    <Heading size={"md"}>Name</Heading>
                    <Text p={5}>{selectedControl?.name}</Text>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Guidance</Heading>
                    <Text p={5}>{selectedControl?.guidance}</Text>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Evidence</Heading>
                    <Box p={5}>
                      <FormControl
                        id="evidence"
                        isInvalid={
                          !!formik?.errors?.evidence === true &&
                          !!formik?.touched?.evidence === true
                        }
                      >
                        <Select
                          isMulti
                          options={evidenceOptions}
                          id="evidence"
                          placeholder={
                            "Select the evidence to mitigate this control."
                          }
                          name="evidence"
                          closeMenuOnSelect={true}
                          value={evidenceOptions?.filter((option) =>
                            formik.values.evidence.some(
                              (evidenceId: any) => option.value === evidenceId
                            )
                          )}
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "evidence",
                              e.map((x: any) => x.value)
                            );
                          }}
                        />

                        <FormErrorMessage>
                          {formik?.errors?.evidence?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Protocols</Heading>
                    <Box p={5}>
                      <FormControl
                        id="protocols"
                        isInvalid={
                          !!formik?.errors?.protocols === true &&
                          !!formik?.touched?.protocols === true
                        }
                      >
                        <Select
                          isMulti
                          options={protocolsOptions}
                          id="protocols"
                          placeholder={
                            "Select the evidence to mitigate this control."
                          }
                          name="protocols"
                          closeMenuOnSelect={true}
                          value={protocolsOptions?.filter((option) =>
                            formik.values.protocols.some(
                              (protocolId: string) =>
                                option.value === protocolId
                            )
                          )}
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "protocols",
                              e.map((x: any) => x.value)
                            );
                          }}
                        />

                        <FormErrorMessage>
                          {formik?.errors?.protocols?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Inspection Templates</Heading>
                    <Box p={5}>
                      <FormControl
                        id="inspectionTemplates"
                        isInvalid={
                          !!formik?.errors?.inspectionTemplates === true &&
                          !!formik?.touched?.inspectionTemplates === true
                        }
                      >
                        <Select
                          isMulti
                          options={inspectionTemplatesOptions}
                          id="inspectionTemplates"
                          placeholder={
                            "Select the inspection template related to this control."
                          }
                          name="inspectionTemplates"
                          closeMenuOnSelect={true}
                          value={inspectionTemplatesOptions?.filter((option) =>
                            formik.values.inspectionTemplates.some(
                              (inspectionTemplateId: any) =>
                                option.value === inspectionTemplateId
                            )
                          )}
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "inspectionTemplates",
                              e.map((x: any) => x.value)
                            );
                          }}
                        />

                        <FormErrorMessage>
                          {formik?.errors?.inspectionTemplates?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Is Applicable</Heading>
                    <Box p={5}>
                      <FormControl
                        isInvalid={
                          !!formik?.errors?.isApplicable === true &&
                          !!formik?.touched?.isApplicable === true
                        }
                      >
                        <Switch
                          id="isApplicable"
                          name="isApplicable"
                          isChecked={formik.values.isApplicable}
                          checked={formik.values.isApplicable}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "isApplicable",
                              e.target.checked
                            )
                          }
                        />
                        <FormErrorMessage>
                          {formik?.errors?.isApplicable?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box>
                    <Heading size={"md"}>Owner</Heading>
                    <Box p={5}>
                      <FormControl
                        id="ownerId"
                        isInvalid={
                          !!formik?.errors?.ownerId === true &&
                          !!formik?.touched?.ownerId === true
                        }
                      >
                        <Select
                          options={ownerOptions}
                          id="ownerId"
                          placeholder={"Select the owner to own this control."}
                          name="ownerId"
                          closeMenuOnSelect={true}
                          value={ownerOptions?.find(
                            (option) => option.value === formik.values?.ownerId
                          )}
                          onChange={(e: any) => {
                            formik.setFieldValue("ownerId", e.value);
                          }}
                        />

                        <FormErrorMessage>
                          {formik?.errors?.ownerId?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>
                </Stack>
              </DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="brand.primary" type="submit">
                  Save
                </Button>
              </DrawerFooter>
            </Form>
          </FormikProvider>
        </DrawerContent>
      </Drawer>
      <Card variant={"outline"}>
        <CardBody>
          <DataTable
            onRowClick={onRowClick}
            columns={columns}
            data={project?.controls.concat(demoControls ?? []) ?? []}
          />
        </CardBody>
      </Card>
    </Box>
  );
};
