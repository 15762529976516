import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Action, Protocol } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useZoomSignature = () => {
  const { getToken } = useAuth();
  
  return useMutation({
    mutationFn: async ({ meetingNumber }: { meetingNumber: string }) => {
      const token = await getToken({ template: "AxleMobility" });
      const response = await axios.post<{ signature: string }>(
        "/api/v2/zoom/jwt",
        {
          meetingId: meetingNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.signature;
    },
    onError: (error) => {
      console.error("Error getting Zoom signature:", error);
      toast.error("Error getting Zoom signature. Please try again.");
    },
  });
};