import { theme as proTheme } from "@chakra-ui/pro-theme";
import {
  theme as baseTheme,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";

export const axleTheme = extendTheme(proTheme, {
    fonts: {
      ...baseTheme.fonts,
      heading: `"Inter", sans-serif`,
      body: `"Inter", sans-serif`,
    },
    colors: {
      ...baseTheme.colors,
      brand: {
        primary: {
          100: "#799386",
          200: "#638172",
          300: "#4d6f5e",
          400: "#365d4a",
          500: "#204B36",
          600: "#1d4431",
          700: "#1a3c2b",
          800: "#163526",
          900: "#132d20",
        },
        gray: {
          100: "#F2F7F4",
          200: "#EAF0ED",
          300: "#D0DDD6",
        },
        tintGray: {
          100: "#EDF2F7",
          200: "#F7FAFC",
        },
        secondary: {
          500: "#F7F9E8",
        },
        warning: {
          500: "#F79009",
        },
        success: {
          500: "#12B76A",
        },
        surface: "#FFF",
        border: "#E2E8F0",
        text: "#2D3748",
        accent: "#718096",
        background: "#f3f4f6",
      },
    },
    components: {
      ...baseTheme.components,
      Text: {
        baseStyle: {
          color: "brand.text",
        },
        variants: {
          xs: {
            lineHeight: "1rem",
            fontSize: "0.75rem",
            fontWeight: "600",
          },
          sm: {
            lineHeight: "1.25rem",
            fontSize: "0.875rem",
            fontWeight: "500",
            color: "brand.accent",
          },
          md: {
            lineHeight: "1.5rem",
            fontSize: "1rem",
            fontWeight: "500",
          },
          "3xl": {
            lineHeight: "2rem",
            fontSize: "2.25rem",
            fontWeight: "700",
          },
        },
      },
      Badge: {
        baseStyle: {
          "border-radius": "1rem",
          "font-family": "Inter",
          "font-size": "0.75rem",
          "font-style": "normal",
          "font-weight": "700",
          "line-height": "1rem",
          "text-transform": "initial",
        },
        variants: {
          green: {
            bg: "green.500",
            color: "green.50",
          },
          red: {
            bg: "red.500",
            color: "red.50",
          },
          yellow: {
            bg: "yellow.500",
            color: "yellow.50",
          },
          orange: {
            bg: "orange.500",
            color: "orange.50",
          },
          gray: {
            bg: "#F7FAFC",
            color: "#718096",
          },
          blue: {
            bg: "blue.500",
            color: "blue.50",
          },
          black: {
            bg: "black",
            color: "white",
          },
          purple: {
            bg: "purple.500",
            color: "purple.50",
          },
        },
      },
      Button: {
        defaultProps: {
          variant: "ghost",
        },
        baseStyle: {
          color: "brand.text",
        },
        variants: {
          outline: {
            borderRadius: "0.375rem",
            borderWidth: "1px",
            borderColor: "brand.border",
            padding: ".75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            lineHeight: "1.25rem",
            color: "brand.primary.500",
          },
          inverted: {
            borderRadius: "0.375rem",
            borderWidth: "1px",
            borderColor: "brand.border",
            padding: ".75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            lineHeight: "1.25rem",
            color: "brand.surface",
            backgroundColor: "brand.primary.500",
          },
        },
      },
      Card: {
        baseStyle: {
          container: {
            backgroundColor: "white",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "hidden",
          },
          header: {
            padding: "6",
            borderBottomWidth: "1px",
          },
          body: {
            padding: "6",
          },
          footer: {
            padding: "6",
            borderTopWidth: "1px",
          },
        },
        variants: {
          outline: {
            container: {
              borderWidth: "1px",
              boxShadow: "none",
            },
          },
          filled: {
            container: {
              backgroundColor: "gray.100",
            },
          },
          elevated: {
            container: {
              boxShadow: "md",
            },
          },
        },
        defaultProps: {
          variant: "outline",
        },
      },

      IconButton: {
        baseStyle: {
          color: "brand.text",
        },
      },
      Tabs: {
        variants: {
          "soft-rounded": {
            tab: {
              _selected: {
                bg: "brand.gray.200",
                color: "brand.primary.500",
              },
            },
          },
        },
      },
      Heading: {
        baseStyle: {
          color: "brand.text",
        },
        variants: {
          "4xl": {
            lineHeight: "normal",
            fontSize: "2.75rem",
            fontWeight: "700",
          },
          "2xl": {
            lineHeight: "2rem",
            fontSize: "1.5rem",
            fontWeight: "600",
          },
          md: {
            lineHeight: "1.5rem",
            fontSize: "1rem",
            fontWeight: "600",
          },
          sm: {
            lineHeight: "1.5rem",
            fontSize: "0.875rem",
            fontWeight: "600",
          },
        },
        defaultProps: {
          variant: "md",
        },
      },
    },
  });