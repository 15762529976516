import { FormControl, FormLabel } from "@chakra-ui/form-control";
import {
    Box,
    Button,
    Card,
    CardBody,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Text,
    VStack
} from "@chakra-ui/react";
import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useCreateProviderSecret } from "../../api/endpoints";
import { useImportVehiclesFromProvider } from "../../api/endpoints/vehicles";
import { ProviderIntegrationSummary } from "../../types-new";


export const ConnectProviderModal: React.FC<{ onClose: () => void, provider: ProviderIntegrationSummary }> = ({ onClose, provider: { connectionInstructionsHtml, id, name, } }) => {
    const [showInput, setShowInput] = useState(false)
    const [connectionInput, setConnectionInput] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const { mutate: connectProvider, isPending: connectionIsPending, isSuccess: connectionIsSuccessful } = useCreateProviderSecret();
    const { mutate: importVehiclesFromProvider } = useImportVehiclesFromProvider();
    const navigate = useNavigate();

    const getConnectModalContent = () => (
        <ModalContent>
            <ModalHeader>Connect {name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <VStack align={"flex-start"} gap={6}>
                    <VStack width={"100%"} align={"flex-start"}>
                        <Heading>
                            Copy & Paste API Token
                        </Heading>
                        <FormControl isRequired>
                            <FormLabel>API Token</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showInput ? 'text' : 'password'}
                                    placeholder='Token'
                                    autoComplete="one-time-code"
                                    onChange={(e: any) => setConnectionInput(e.target.value)}
                                />
                                <InputRightElement>
                                    <Button leftIcon={showInput ? <IoMdEye /> : <IoMdEyeOff />} size='sm' onClick={() => setShowInput(!showInput)} />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    </VStack>
                    <Card variant={"outline"} width={"100%"} bg={"brand.gray.100"} borderColor={"brand.gray.300"}>
                        <CardBody>
                            <Box
                                dangerouslySetInnerHTML={{ __html: connectionInstructionsHtml }}
                                width={"100%"}
                            />
                        </CardBody>
                    </Card>
                </VStack>
            </ModalBody>
            <ModalFooter>
                <Button color="brand.primary.500" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isDisabled={connectionInput?.length < 10}
                    isLoading={connectionIsPending}
                    variant={"solid"} colorScheme="brand.primary"
                    onClick={
                        () => connectProvider({
                            providerId: id,
                            providerName: name,
                            secretValue: connectionInput
                        })
                    }
                >
                    Connect
                </Button>
            </ModalFooter>
        </ModalContent>
    );

    const getSuccessModalContent = () => (
        <ModalContent>
            <ModalHeader>{name} is now a connected provider!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <VStack align={"flex-start"} gap={6} pb={4}>
                    <Text color={"brand.accent"}>
                        Your vehicles are now importing. Please check back in a few minutes to see updated information on the vehicles page.
                    </Text>
                    <Text color={"brand.accent"}>
                        Vehicle information from {name} will be automatically kept up to date as long as {name} is a connected provider.
                    </Text>
                    <Button
                        rightIcon={<ChevronRight />}
                        colorScheme="brand.primary"
                        variant="solid"
                        onClick={() => navigate("/vehicles")}
                        fontWeight={"bold"}
                        fontSize={"medium"}
                    >
                        Manage Vehicles
                    </Button>
                </VStack>
            </ModalBody>
        </ModalContent>
    );

    useEffect(() => {
        if (connectionIsSuccessful) {
            setShowSuccess(true);
            importVehiclesFromProvider({ providerName: name, updatedAfter: "" })
        }
    }, [connectionIsSuccessful])

    return showSuccess ? getSuccessModalContent() : getConnectModalContent();
}