import {
  Box,
  Button,
  Heading,
  Badge,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { Vehicle } from "../../types";
import { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";

export const VehicleFeesAndPaymentsTab = ({
  vehicle,
}: {
  vehicle?: Vehicle;
}) => {
  const { data: updatedVehicle, mutate: updateVehicle } = useUpdateVehicle();
  const [fees, setFees] = useState(vehicle?.complianceData?.fees || []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFeeId, setSelectedFeeId] = useState<string | null>(null);
  const [paymentDate, setPaymentDate] = useState<Date | null>(new Date());

  const handlePayFee = async () => {
    if (selectedFeeId && paymentDate) {
      const updatedFees = fees.map((fee) =>
        fee.id === selectedFeeId
          ? { ...fee, paidDate: paymentDate.toISOString() }
          : fee
      );
      setFees(updatedFees);

      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: { ...vehicle.complianceData, fees: updatedFees },
        };
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }
      onClose();
    }
  };

  const openPaymentModal = (feeId: string) => {
    setSelectedFeeId(feeId);
    setPaymentDate(new Date());
    onOpen();
  };

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: "Fee Name",
        accessorKey: "description",
      },
      {
        header: "Amount",
        accessorKey: "amount",
        cell: ({ getValue }) => `$${getValue<number>().toFixed(2)}`,
      },
      {
        header: "Due Date",
        accessorKey: "dueDate",
        cell: ({ getValue }) =>
          new Date(getValue<string>()).toLocaleDateString(),
      },
      {
        header: "Status",
        accessorKey: "paidDate",
        cell: ({ getValue }) => (
          <Badge colorScheme={getValue() ? "green" : "red"}>
            {getValue() ? "Paid" : "Unpaid"}
          </Badge>
        ),
      },
      {
        header: "Action",
        accessorKey: "id",
        cell: ({ row }) => (
          <>
            {!row.original.paidDate && (
              <Button
                size="sm"
                variant={"inverted"}
                onClick={() => openPaymentModal(row.original.id)}
              >
                Mark as Paid
              </Button>
            )}
            {row.original.paidDate && (
              <Text fontSize="sm">
                Paid on: {new Date(row.original.paidDate).toLocaleDateString()}
              </Text>
            )}
          </>
        ),
      },
    ],
    []
  );

  return (
    <Box>
      <DataTable
        data={fees}
        columns={columns}
        suppressPaginationFooter={true}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Payment Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DatePicker
              selected={paymentDate}
              onChange={(date) => setPaymentDate(date)}
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="scroll"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              customInput={
                <Input
                  placeholder="Select payment date"
                  width={"100%"}
                  _focus={{
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 1px blue.500",
                  }}
                />
              }
              wrapperClassName="datePicker"
              popperClassName="datepicker-popper"
              calendarClassName="custom-calendar"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant={"inverted"} mr={3} onClick={handlePayFee}>
              Confirm Payment
            </Button>
            <Button variant={"outline"} color={"red.500"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
