import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Text,
  SimpleGrid,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  OrderedList,
  Checkbox,
  Stack,
  Divider,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { Vehicle, VehicleBattery } from "../../types";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { Select, createFilter } from "chakra-react-select";
import {
  useCreateBatteryDisposalOrder,
  useUpdateVehicle,
  useUpdateVehicleBattery,
} from "../../api/endpoints/vehicles";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import React from "react";

export const BatteryInformationTab = ({
  vehicle,
  battery,
}: {
  vehicle?: Vehicle;
  battery?: VehicleBattery;
}) => {
  const chemistryOptions = [
    { label: "Lead Acid/Lead", value: "Lead Acid/Lead" },
    { label: "Nickel-Metal-Hydride/NiMH", value: "Nickel-Metal-Hydride/NiMH" },
    { label: "Lithium-Ion/Li-Ion", value: "Lithium-Ion/Li-Ion" },
    {
      label: "Cobalt Dioxide/Cobalt",
      value: "Cobalt Dioxide/Cobalt",
    },
    {
      label: "Nickle-Cobalt-Aluminum/NCA",
      value: "Nickle-Cobalt-Aluminum/NCA",
    },
    {
      label: "Manganese Oxide Spinel/MnO",
      value: "Manganese Oxide Spinel/MnO",
    },
    {
      label: "Iron Phosphate/FePo",
      value: "Iron Phosphate/FePo",
    },
    { label: "Silicon", value: "Silicon" },
  ];

  const chargePortOptions = [
    { label: "J1772", value: "J1772" },
    { label: "J3400", value: "J3400" },
    { label: "CCS", value: "CCS" },
    { label: "CHAdeMO", value: "CHAdeMO" },
  ];

  const { data: updatedVehicle, mutate: updateVehicleBattery } =
    useUpdateVehicleBattery();
  const { mutate: createBatteryDisposalOrder } =
    useCreateBatteryDisposalOrder();

  const schema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      chemistry: battery?.chemistry,
      capacity: battery?.capacity,
      range: battery?.range,
      chargePort: battery?.chargePort,
      manufactureDate: battery?.manufactureDate
        ? new Date(battery.manufactureDate).toISOString().split("T")[0]
        : null,
      serialNumber: battery?.serialNumber,
    } as VehicleBattery,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      await updateVehicleBattery({
        vehicleId: vehicle?.id!,
        batteryId: battery?.id!,
        vin: vehicle?.vin!,
        payload: values,
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batteryRecyclingConsent, setBatteryRecyclingConsent] = useState(false);
  const [isHazardousDisposal, setIsHazordousDisposal] = React.useState("yes");
  const onRecyclingSubmission = async () => {
    if (batteryRecyclingConsent) {
      createBatteryDisposalOrder({
        vehicleId: vehicle?.id ?? "",
        batteryId: battery?.id ?? "",
        isHazardousDisposal: isHazardousDisposal !== "no",
      });
    }
  };

  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            Begin a battery disposal process
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={5}>
              <Text>We will perform the following process:</Text>
              <OrderedList>
                <ListItem>Notify the battery recycling teams.</ListItem>
                <ListItem>Notify site recycling leads.</ListItem>
                <ListItem>Notify certified removal technician.</ListItem>
                <ListItem>Provide packaging labels & storage boxes</ListItem>
              </OrderedList>
              <Divider />
              <Stack>
                <Text>Is the battery damaged, defective, or recalled?</Text>
                <RadioGroup
                  onChange={setIsHazordousDisposal}
                  value={isHazardousDisposal}
                >
                  <Stack direction="column">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                    <Radio value="unknown">Not Sure</Radio>
                  </Stack>
                </RadioGroup>
              </Stack>
              <Divider />
              <Checkbox
                isChecked={batteryRecyclingConsent}
                onChange={(e) => setBatteryRecyclingConsent(e.target.checked)}
              >
                Check if you understand and accept the requirements for
                hazardous battery diposal.
              </Checkbox>
              <Button
                colorScheme="brand.primary"
                isDisabled={!batteryRecyclingConsent}
                onClick={onRecyclingSubmission}
              >
                Submit Request
              </Button>
              <Button
                onClick={onClose}
                colorScheme="brand.primary"
                variant={"outline"}
              >
                Cancel
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Card
        direction={{ base: "row", sm: "row" }}
        variant="outline"
        my={{ base: "4", md: "8" }}
      >
        <HStack width={"100%"}>
          <CardBody>
            <Heading size="md">Action Zone</Heading>
            <Text py="2">
              We'll arange for your vehicle's battery to be recycled through an
              easy step by step process.
            </Text>
          </CardBody>

          <CardFooter justifyContent={"right"}>
            <Button
              variant="outline"
              colorScheme="green"
              onClick={onOpen}
              isDisabled={battery?.isDisposalRequested}
            >
              Recycle Battery
            </Button>
          </CardFooter>
        </HStack>
      </Card>
      <FormikProvider value={formik}>
        <Form>
          <FormControl
            isInvalid={
              !!formik?.errors?.serialNumber === true &&
              !!formik?.touched?.serialNumber === true
            }
            mb={5}
          >
            <FormLabel>Serial Number</FormLabel>
            <Field
              as={Input}
              id="serialNumber"
              name={"serialNumber"}
              type="text"
              placeholder="Serial Number"
              value={formik?.values?.serialNumber}
            />
            <FormHelperText>The serial number of this battery.</FormHelperText>
            <FormErrorMessage>
              {formik?.errors?.serialNumber?.toString()}
            </FormErrorMessage>
          </FormControl>
          <SimpleGrid columns={[1, 2]} gap={"10px"}>
            <FormControl
              isInvalid={
                //@ts-ignore
                !!formik?.errors?.chemistry === true &&
                !!formik?.touched?.chemistry === true
              }
              mb={5}
            >
              <FormLabel>Battery Type</FormLabel>
              <Select
                options={chemistryOptions}
                placeholder={"Battery Type"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("chemistry", e.value);
                }}
                id="chemistry"
                name={"chemistry"}
                value={chemistryOptions.find(
                  (x) => x.value === formik.values?.chemistry
                )}
              />

              <FormErrorMessage>
                {formik?.errors?.chemistry?.toString()}
              </FormErrorMessage>
            </FormControl>{" "}
            <FormControl
              isInvalid={
                !!formik?.errors?.capacity === true &&
                !!formik?.touched?.capacity === true
              }
              mb={5}
            >
              <FormLabel>Capacity (kWh)</FormLabel>
              <Field
                as={Input}
                id="capacity"
                name={"capacity"}
                type="number"
                placeholder="Capacity"
                value={formik?.values?.capacity}
              />

              <FormErrorMessage>
                {formik?.errors?.capacity?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.range === true &&
                !!formik?.touched?.range === true
              }
              mb={5}
            >
              <FormLabel>Range (Miles)</FormLabel>
              <Field
                as={Input}
                id="range"
                name={"range"}
                type="number"
                placeholder="Range"
                value={formik?.values?.range}
              />

              <FormErrorMessage>
                {formik?.errors?.range?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.chargePort === true &&
                !!formik?.touched?.chargePort === true
              }
              mb={5}
            >
              <FormLabel>Charge Port</FormLabel>
              <Select
                options={chargePortOptions}
                placeholder={"Charge Port"}
                closeMenuOnSelect={true}
                onChange={(e: any) => {
                  formik.setFieldValue("chargePort", e.value);
                }}
                id="chargePort"
                name={"chargePort"}
                value={chargePortOptions.find(
                  (x) => x.value === formik.values.chargePort
                )}
              />

              <FormErrorMessage>
                {formik?.errors?.chargePort?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik?.errors?.manufactureDate === true &&
                !!formik?.touched?.manufactureDate === true
              }
              mb={5}
            >
              <FormLabel>Manufacture Date</FormLabel>
              <Field
                as={Input}
                id="manufactureDate"
                name={"manufactureDate"}
                type="date"
                placeholder="Manufacture Date"
                value={formik?.values?.manufactureDate}
              />
              <FormErrorMessage>
                {formik?.errors?.manufactureDate?.toString()}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>

          <Box justifyContent={"right"} display={"flex"}>
            <Button
              onClick={formik.submitForm}
              variant="solid"
              colorScheme="brand.primary"
            >
              Save
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};
