import {
  HStack,
  Button,
  Box,
  Badge,
  IconButton,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  FormErrorMessage,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Alert,
  AlertIcon,
  CardBody,
  Card,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsPencil } from "react-icons/bs";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/table";
import { useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select";
import { useApi } from "../../hooks/use-api";
import * as Yup from "yup";
import { Field, FormikProvider, useFormik, Form } from "formik";
import { Project } from "../../types";
import {
  useCreateProject,
  useDeleteProject,
  useProjects,
} from "../../api/endpoints/projects";
import { useFrameworks } from "../../api/endpoints/frameworks";
import { CheckboxCard, CheckboxCardGroup } from "./CheckboxCardGroup";
import { PageHeader } from "../../components/page-header";
import { DeleteButton } from "../../components/delete-button";
import { PlusIcon } from "lucide-react";

export const ProjectsListPage: React.FC = () => {
  const columnHelper = createColumnHelper<Project>();
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { post } = useApi();

  const {
    data: projects,
    isError: isProjectsError,
    isLoading: isProjectsLoading,
  } = useProjects();
  const { mutate: deleteProject } = useDeleteProject();
  const {
    data: frameworks,
    isError: isFrameworksError,
    isLoading: isFrameworksLoading,
  } = useFrameworks();

  const {
    mutate: createProject,
    isError: createProjectError,
    data: createProjectData,
  } = useCreateProject();

  const frameworkOptions = frameworks?.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.basedOnFramework.name, {
      id: "basedOnFrameworkName",
      cell: (info) => {
        const value = info.getValue();
        return <Badge variant={"green"}>{value}</Badge>;
      },
      header: () => <span>Framework</span>,
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.controls, {
      id: "controls",
      cell: (info) => info.getValue().length,
      header: () => <span>Controls</span>,
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <DeleteButton
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete project"
              onDelete={() => deleteProject({ id: props.row.original.id })}
            />
            <IconButton
              aria-label="edit project"
              color={"brand.accent"}
              onClick={() => navigate(`${props.row.original.id}`)}
              icon={<BsPencil />}
              style={{ background: "transparent" }}
            />
          </HStack>
        );
      },
    }),
  ];

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Project name is required."),
    basedOnFrameworkId: Yup.string()
      .min(3)
      .oneOf((frameworks ?? []).map((x) => x.id))
      .required("Project framework is required."),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      basedOnFrameworkId: "",
    },
    validationSchema: schema,
    onSubmit: async (values: any) => {
      await createProject(values);
    },
  });

  const steps = [
    {
      title: "General Information",
      description: "A general survey of your organization",
    },
    {
      title: "Your Vehicles",
      description: "Tell us more about the vehicles you have.",
    },
    {
      title: "Regulatory Requirements",
      description: "We'll find any regulations that apply.",
    },
    {
      title: "Create Your Playbook",
      description: "Finalize your selections and create your playbook.",
    },
  ];

  const assessment = [
    [
      { id: "1a", name: "Have chargers onsite" },
      { id: "2a", name: "Have chargers onsite within 12 months" },
      { id: "3a", name: "Use electric vehicles today" },
      { id: "4a", name: "Use electric vehicles within 12 months" },
      { id: "5a", name: "Maintenance is done onsite" },
      { id: "6a", name: "Employees maintain chargers or vehicles" },
      { id: "7a", name: "Vendors maintain chargers or vehicles" },
      { id: "8a", name: "Maintenance is done offsite" },
    ],
    [
      { id: "1b", name: "Class 1 (e.g. compact cars)" },
      { id: "2b", name: "Class 2 (e.g. sedans)" },
      { id: "3b", name: "Class 3 (e.g. SUVs)" },
      { id: "4b", name: "Class 4 (e.g. light duty vehicles)" },
      { id: "5b", name: "Class 5 (e.g. medium duty trucks)" },
      { id: "6b", name: "Class 6 (e.g. step vans)" },
      { id: "7b", name: "Class 7 (e.g. delivery trucks)" },
      { id: "8b", name: "Class 8 (e.g. heavy duty trucks)" },
    ],
    [
      { id: "1c", name: "Fleet is used for drayage" },
      { id: "2c", name: "Fleet is owned by state/local government" },
      { id: "3c", name: "Fleet is owned by federal government" },
      { id: "4c", name: "Fleet is considered High Priority" },
      { id: "5c", name: "Fleet operates in the State of California" },
    ],
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (frameworks && frameworks.length > 0) {
      formik.setFieldValue("basedOnFrameworkId", frameworks[0].id);
    }
  }, [frameworks]);

  useEffect(() => {
    if (createProjectData) navigate(`/projects/${createProjectData.data}`);
  }, [createProjectData]);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"6xl"}>
        <ModalOverlay />
        <FormikProvider value={formik}>
          <Form>
            <ModalContent>
              <ModalHeader>Create New Compliance Playbook</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stepper size="xs" index={activeStep} colorScheme="green">
                  {steps.map((step, index) => (
                    <Step key={index} onClick={() => setActiveStep(index)}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>

                      <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                      </Box>

                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
                <Box mt={"3rem"}>
                  {(activeStep === 0 ||
                    activeStep === 1 ||
                    activeStep === 2) && (
                    <CheckboxCardGroup spacing="3">
                      {assessment[activeStep].map((option) => (
                        <CheckboxCard key={option.id} value={option.id}>
                          <Text
                            color="fg.emphasized"
                            fontWeight="medium"
                            fontSize="xl"
                          >
                            {option.name}
                          </Text>
                        </CheckboxCard>
                      ))}
                    </CheckboxCardGroup>
                  )}
                  {activeStep === 3 && (
                    <Stack
                      spacing="5"
                      px={{ base: "4", md: "6" }}
                      py={{ base: "5", md: "6" }}
                    >
                      <Alert status="success">
                        <AlertIcon />
                        Based on your responses, we recommend the frameworks
                        below. Feel free to adjust the selections.
                      </Alert>

                      <Stack
                        spacing="6"
                        direction={{ base: "column", md: "row" }}
                      >
                        <FormControl
                          isInvalid={
                            !!formik?.errors?.name === true &&
                            !!formik?.touched?.name === true
                          }
                        >
                          <FormLabel>Name</FormLabel>
                          <Field
                            as={Input}
                            id="name"
                            name={"name"}
                            type="text"
                            placeholder="Example Name"
                            value={formik.values.name}
                          />
                          <FormErrorMessage>
                            {formik?.errors?.name?.toString()}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                      <FormControl id="street">
                        <FormLabel>Framework(s)</FormLabel>
                        <Select
                          colorScheme="purple"
                          options={frameworkOptions}
                          id="basedOnFrameworkId"
                          placeholder={"Select Framework"}
                          name="basedOnFrameworkId"
                          closeMenuOnSelect={true}
                          value={frameworkOptions?.find(
                            (framework: any) =>
                              framework.value ===
                              formik.values.basedOnFrameworkId
                          )}
                          onChange={(e: any) => {
                            formik.setFieldValue("basedOnFrameworkId", e.value);
                          }}
                        />
                        <FormErrorMessage>
                          {formik?.errors?.basedOnFrameworkId?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>
                  )}
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  onClick={() => setActiveStep(activeStep - 1)}
                  isDisabled={activeStep === 0}
                  mr={2}
                >
                  Previous
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  onClick={() => setActiveStep(activeStep + 1)}
                  isDisabled={activeStep === 3}
                  mr={2}
                >
                  Next
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  // onClick={onClose}
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </Form>
        </FormikProvider>
      </Modal>
      <PageHeader title="Playbooks"></PageHeader>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={projects ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onToggle}
                >
                  Create
                </Button>
              }
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
