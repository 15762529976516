import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Framework } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useFrameworks = () => {
    const { getToken } = useAuth();
    return useQuery<Framework[], ApiServiceErr>({
      queryKey: ["frameworks"],
      queryFn:  async () => {
       const result =  await axios.get(
          `/api/frameworks`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );

        return result.data;
      }
    });
}

export const useFramework = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Framework, ApiServiceErr>({
    queryKey: ["frameworks", id],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/frameworks/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}