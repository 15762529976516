import {
  Box,
  Button,
  Heading,
  Badge,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  VStack,
  HStack,
  Card,
  CardBody,
  Select,
  useToast,
  IconButton,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { Vehicle } from "../../types";
import { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import { FaCircle, FaEdit, FaTrash } from "react-icons/fa";
import { DeleteButton } from "../../components/delete-button";

export const VehicleSystemsOfRecordTab = ({
  vehicle,
}: {
  vehicle?: Vehicle;
}) => {
  const { mutate: updateVehicle } = useUpdateVehicle();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const [newSystem, setNewSystem] = useState("");
  const [registeredAt, setRegisteredAt] = useState<Date | null>(null);
  const [selectedSystem, setSelectedSystem] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleAddSystem = () => {
    if (vehicle && newSystem && registeredAt && isConfirmed) {
      const updatedSystems = [
        ...(vehicle.complianceData.systemsOfRecord || []),
        {
          name: newSystem,
          lastRegisteredAt: registeredAt.toISOString(),
          url: "",
          description: "",
        },
      ];
      updateVehicle({
        id: vehicle.id,
        body: {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            systemsOfRecord: updatedSystems,
          },
        },
      });
      onAddClose();
      setIsConfirmed(false);
    }
  };

  const handleUpdateRegisteredAt = () => {
    if (vehicle && selectedSystem && registeredAt && isConfirmed) {
      const updatedSystems = vehicle.complianceData.systemsOfRecord.map((s) =>
        s.name === selectedSystem
          ? { ...s, lastRegisteredAt: registeredAt.toISOString() }
          : s
      );
      updateVehicle({
        id: vehicle.id,
        body: {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            systemsOfRecord: updatedSystems,
          },
        },
      });
      onUpdateClose();
      setIsConfirmed(false);
    }
  };

  const handleDeleteSystem = (systemName: string) => {
    if (vehicle) {
      const updatedSystems = vehicle.complianceData.systemsOfRecord.filter(
        (s) => s.name !== systemName
      );
      updateVehicle({
        id: vehicle.id,
        body: {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            systemsOfRecord: updatedSystems,
          },
        },
      });
    }
  };

  const openUpdateModal = (systemName: string) => {
    setSelectedSystem(systemName);
    const system = vehicle?.complianceData.systemsOfRecord.find(
      (s) => s.name === systemName
    );
    if (system) {
      setRegisteredAt(new Date(system.lastRegisteredAt || ""));
    }
    onUpdateOpen();
  };

  return (
    <Box>
      <HStack justifyContent={"end"}>
        <Button onClick={onAddOpen} mb={4} variant={"inverted"}>
          Add to System of Record
        </Button>
      </HStack>

      {vehicle?.complianceData.systemsOfRecord &&
      vehicle.complianceData.systemsOfRecord.length > 0 ? (
        <VStack spacing={4} align="stretch">
          {vehicle.complianceData.systemsOfRecord.map((system) => (
            <Card key={system.name}>
              <CardBody>
                <HStack justifyContent="space-between">
                  <VStack align="start">
                    <Heading size="md">{system.name}</Heading>
                    <Badge colorScheme="green">Connected</Badge>
                    <HStack>
                      <FaCircle
                        color="green"
                        size="10"
                        style={{ animation: "pulse 2s infinite" }}
                      />
                      <Text>
                        Last Updated:{" "}
                        {new Date(
                          system?.lastRegisteredAt || ""
                        ).toLocaleDateString()}
                      </Text>
                    </HStack>
                  </VStack>
                  <HStack>
                    <IconButton
                      icon={<FaEdit />}
                      color={"gray.500"}
                      onClick={() => openUpdateModal(system.name)}
                      aria-label="Update Registration"
                    />
                    <DeleteButton
                      onDelete={() => handleDeleteSystem(system.name)}
                      aria-label="Delete System"
                      modalText={`Confirm you have removed the vehivle from ${system.name}`}
                      deleteKey="CONFIRM"
                    />
                  </HStack>
                </HStack>
              </CardBody>
            </Card>
          ))}
        </VStack>
      ) : (
        <Box textAlign="center" py={10}>
          <Heading size="md">Get started with your first system</Heading>
          <Text mt={2}>Click "Add to System of Record" to begin</Text>
        </Box>
      )}

      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add to System of Record</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Select system</FormLabel>
              <Select
                placeholder="Select system"
                value={newSystem}
                onChange={(e) => setNewSystem(e.target.value)}
                mb={4}
              >
                <option value="Clean Truck Check-Vehicle Inspection System (CTC-VIS)">
                  Clean Truck Check-Vehicle Inspection System (CTC-VIS)
                </option>
                <option value="Truck Regulations Upload, Compliance and Reporting System (TRUCRS)">
                  Truck Regulations Upload, Compliance and Reporting System
                  (TRUCRS)
                </option>
                <option value="Air Resources Board Equipment Registration System (ARBER)">
                  Air Resources Board Equipment Registration System (ARBER)
                </option>
                <option value="Off-Road Diesel (DOORS)">
                  Off-Road Diesel (DOORS)
                </option>
              </Select>
              <FormHelperText>
                Please select the system of record you want to add.
              </FormHelperText>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Registration Date</FormLabel>
              <DatePicker
                selected={registeredAt}
                onChange={(date: Date | null) => {
                  if (date) {
                    setRegisteredAt(date);
                  }
                }}
                customInput={<Input />}
              />
              <FormHelperText>
                Select the date when the vehicle was registered in the system.
              </FormHelperText>
            </FormControl>
            <Checkbox
              mt={4}
              isChecked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
            >
              I confirm that I have updated the vehicle information in{" "}
              {newSystem}
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"inverted"}
              mr={3}
              onClick={handleAddSystem}
              isDisabled={!isConfirmed}
            >
              Save
            </Button>
            <Button variant="outline" color={"red.500"} onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isUpdateOpen} onClose={onUpdateClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Registration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Updating registration for: {selectedSystem}</Text>
            <FormControl>
              <FormLabel>Registration Date</FormLabel>
              <DatePicker
                selected={registeredAt}
                onChange={(date: Date | null) => {
                  if (date) {
                    setRegisteredAt(date);
                  }
                }}
                customInput={<Input />}
              />
              <FormHelperText>
                Select the new registration date for the vehicle in the system.
              </FormHelperText>
            </FormControl>
            <Checkbox
              mt={4}
              isChecked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
            >
              I confirm that I have updated the vehicle information in{" "}
              {selectedSystem}
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleUpdateRegisteredAt}
              isDisabled={!isConfirmed}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onUpdateClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
