import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { ManualInspectionCreate, ManualInspectionUpdate } from '../../types';

export const useCreateManualInspection = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (inspection: ManualInspectionCreate) => {
      const token = await getToken({ template: "AxleMobility" });
      const result = await axios.post('/api/manual-inspections', inspection, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['manual-inspections'] });
    },
    onError: (error: any) => {
      console.error('Error creating manual inspection:', error.response?.data || error.message);
    },
  });
};

export const useUpdateManualInspection = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (inspection: ManualInspectionUpdate) => {
      const token = await getToken({ template: "AxleMobility" });
      const result = await axios.put(`/api/manual-inspections/${inspection.id}`, inspection, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['manual-inspections'] });
    },
    onError: (error: any) => {
      console.error('Error updating manual inspection:', error.response?.data || error.message);
    },
  });
};

export const useDeleteManualInspection = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const token = await getToken({ template: "AxleMobility" });
      await axios.delete(`/api/manual-inspections/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['manual-inspections'] });
    },
    onError: (error: any) => {
      console.error('Error deleting manual inspection:', error.response?.data || error.message);
    },
  });
};
