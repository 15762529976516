import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  Textarea,
  Switch,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { ArrowLeftIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  useCreateVMRSDecodeOrder,
  useVMRSDecodeOrder,
} from "../../api/endpoints/vmrs";
import "./index.css";
import { useEffect, useMemo, useState } from "react";
import { CSVImporter } from "csv-import-react";
import { ReactComponent as VMRSPartsPostTableIcon } from "../../icons/vmrs-parts-post-table.svg";
import { ReactComponent as VMRSPartsPreTableIcon } from "../../icons/vmrs-parts-pre-table.svg";
import { ReactComponent as VMRSRepairNotesPreTableIcon } from "../../icons/vmrs-repair-post-table.svg";
import { ReactComponent as VMRSRepairNotesPostTableIcon } from "../../icons/vmrs-repair-pre-table.svg";
import { ReactComponent as LeftIcon } from "../../icons/left-icon.svg";
import { ConversionResultsTable } from "./conversion-results-table";

type VMRSPartConversionResult = {
  partName: string;
  externalId: string;
  vmrsPartName: string;
  vmrsPartId: string;
  isFetching: boolean;
  errors: string[];
};

import { useLocation } from "react-router-dom";

export const VMRSIndexPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const modeFromQuery = queryParams.get("mode") as
    | "repair-notes-bulk"
    | "parts-bulk"
    | "repair-notes-single"
    | null;

  const {
    mutate: createVMRSDecodeOrder,
    isSuccess: isCreateVMRSDecodeOrderSuccess,
    data: decodeOrderId,
  } = useCreateVMRSDecodeOrder();

  const { data: vmrsDecodeOrder, refetch: refetchVMRSDecodeOrder } =
    useVMRSDecodeOrder(decodeOrderId ?? "", isCreateVMRSDecodeOrderSuccess);

  const [currentStep, setCurrentStep] = useState(0);
  const [processingMode, setProcessingMode] = useState<
    "repair-notes-bulk" | "parts-bulk" | "repair-notes-single"
  >(modeFromQuery ?? "repair-notes-bulk");
  const [singleModeInput, setSingleModeInput] = useState("");
  const [conversionResults, setConversionResults] = useState<
    VMRSPartConversionResult[]
  >([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      isCreateVMRSDecodeOrderSuccess &&
      processingMode !== "repair-notes-single"
    ) {
      navigate(`/vmrs/${decodeOrderId}`);
    }
  }, [isCreateVMRSDecodeOrderSuccess, decodeOrderId, navigate, processingMode]);

  useEffect(() => {
    if (
      isCreateVMRSDecodeOrderSuccess &&
      processingMode === "repair-notes-single"
    ) {
      refetchVMRSDecodeOrder();
    }
  }, [isCreateVMRSDecodeOrderSuccess, refetchVMRSDecodeOrder, processingMode]);

  const createDecodeOrder = async (data: any) => {
    const values = data.rows.map((row: any) => {
      return {
        description: row.values.description,
        externalId: row.values.id,
      };
    });
    await createVMRSDecodeOrder({
      values,
      requestType: processingMode.includes("repair-notes")
        ? "RepairNotes"
        : "Parts",
    });
  };

  const handleSingleModeSubmit = async () => {
    const values = [{ description: singleModeInput, externalId: "N/A" }];
    await createVMRSDecodeOrder({
      values,
      requestType: "RepairNotes",
    });
  };

  const csvImporter = useMemo(() => {
    return (
      <Box>
        <CSVImporter
          isModal={false}
          onComplete={(data) => createDecodeOrder(data)}
          template={{
            columns:
              processingMode === "repair-notes-bulk"
                ? [
                    {
                      name: "Repair Notes",
                      key: "description",
                      required: true,
                      description:
                        "The repair notes or part names to convert to VMRS Code(s).",
                      suggested_mappings: [
                        "Part",
                        "Repair",
                        "Notes",
                        "Description",
                        "Repair Note",
                      ],
                    },
                    {
                      name: "Task ID",
                      key: "id",
                      required: true,
                      description:
                        "The task ID you use to reference this RO note.",
                      suggested_mappings: ["Id"],
                    },
                  ]
                : [
                    {
                      name: "Part Name",
                      key: "description",
                      required: true,
                      description:
                        "The repair notes or part names to convert to VMRS Code(s).",
                      suggested_mappings: [
                        "Part",
                        "Name",
                        "Part Name",
                        "Description",
                      ],
                    },
                    {
                      name: "Part ID",
                      key: "id",
                      required: true,
                      description:
                        "The part ID you use to reference this part.",
                      suggested_mappings: ["Id"],
                    },
                  ],
          }}
          primaryColor="#204B36"
        />
      </Box>
    );
  }, [processingMode]);

  return (
    <Box height={"110vh"} overflow={"scroll"}>
      <PageHeader title="VMRS AI Assistant">
        <Switch
          fontWeight={"bold"}
          isChecked={processingMode === "repair-notes-single"}
          onChange={() => {
            setProcessingMode(
              processingMode === "repair-notes-single"
                ? "repair-notes-bulk"
                : "repair-notes-single"
            );
          }}
        >
          Single Entry Mode
        </Switch>
      </PageHeader>
      <Card variant={"outline"} m={5} height={"100%"}>
        <CardBody>
          {processingMode === "repair-notes-single" && (
            <Box>
              <Heading>VMRS AI Converter</Heading>
              <Text variant={"sm"}>
                Upload your repair notes to extract part information and capture
                associated VMRS codes.
              </Text>
              <VStack mt={5} gap={10}>
                <Textarea
                  placeholder="Enter repair/work notes"
                  value={singleModeInput}
                  onChange={(e) => setSingleModeInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSingleModeSubmit();
                    }
                  }}
                />
                <HStack justify={"flex-end"} width={"100%"}>
                  <Button
                    variant={"inverted"}
                    size={"lg"}
                    onClick={handleSingleModeSubmit}
                  >
                    Submit
                  </Button>
                </HStack>
              </VStack>
              <Box mt={10}>
                <Heading>Results</Heading>
                <ConversionResultsTable
                  id={decodeOrderId ?? ""}
                  showTableOnly
                />
              </Box>
            </Box>
          )}

          {processingMode !== "repair-notes-single" && currentStep === 0 && (
            <Box>
              <Heading>VMRS AI Converter</Heading>
              <Text variant={"sm"}>
                {processingMode === "repair-notes-bulk"
                  ? "Upload your CSV file with repair notes to extract part information and capture associated VMRS codes."
                  : "Upload your CSV file with part names and IDs to convert to VMRS codes."}
              </Text>

              <VStack mt={"5%"} gap={10}>
                <HStack>
                  <Button
                    size={"xl"}
                    variant={
                      processingMode === "repair-notes-bulk"
                        ? "inverted"
                        : "outline"
                    }
                    onClick={() => setProcessingMode("repair-notes-bulk")}
                  >
                    Repair Notes Bulk Mode
                  </Button>
                  <Button
                    size={"xl"}
                    variant={
                      processingMode === "parts-bulk" ? "inverted" : "outline"
                    }
                    onClick={() => setProcessingMode("parts-bulk")}
                  >
                    Parts Bulk Mode
                  </Button>
                </HStack>
                <HStack>
                  {processingMode === "repair-notes-bulk" && (
                    <VMRSRepairNotesPreTableIcon />
                  )}
                  {processingMode === "parts-bulk" && <VMRSPartsPreTableIcon />}
                  <LeftIcon />
                  {processingMode === "repair-notes-bulk" && (
                    <VMRSRepairNotesPostTableIcon />
                  )}
                  {processingMode === "parts-bulk" && (
                    <VMRSPartsPostTableIcon />
                  )}
                </HStack>
                <Button
                  variant={"inverted"}
                  size={"lg"}
                  onClick={() => setCurrentStep(1)}
                >
                  Get Started
                </Button>
              </VStack>
            </Box>
          )}
          {processingMode !== "repair-notes-single" && currentStep === 1 && (
            <Box>
              <HStack>
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                  aria-label={"Change Mode"}
                />
                <Heading size={"md"}>Change Mode</Heading>
              </HStack>
              {csvImporter}
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};
