import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {  CARBReportLine, Vehicle, VehicleBattery, VehicleBatteryDisposalOrder, Vendor } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";

export const useVendors = () => {
  const { getToken } = useAuth();
  return useQuery<Vendor[], ApiServiceErr>({
    queryKey: ["vendors"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/v2/vendors`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useVendor = (id: string, enabled: boolean) => {
  const { getToken } = useAuth();
  return useQuery<Vendor, ApiServiceErr>({
    queryKey: ["vendors", id],
    queryFn:  async () => {
      if(id.length === 0) return;
      const response = await axios.get(
        `/api/v2/vendors/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useCreateVendor = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: Vendor) => {
      const response = await axios.post(
        `/api/v2/vendors`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      toast.success(`Created vendor: ${variables.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating vendor: ${variables.name}`)
      console.log(error);
    },
  });
}


export const useUpdateVendor = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, body}: {id: string, body: any}) => {
      const result = await axios.put(
        `/api/v2/vendors/${id}`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      toast.success(`Updated vendor: ${variables.body.name}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error updating vendor: ${variables.body.name}`)
      console.log(error);
    },
  });
}

export const useDeleteVendor = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/v2/vendors/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      toast.success(`Deleted vendor.`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error deleting vendor.`)
      console.log(error);
    },
  });
}