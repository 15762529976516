import {
  Box,
  Button,
  Badge,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import {
  ComplianceInspectionStatus,
  ComplianceInspectionAttempt,
  ComplianceInspection,
} from "../../types-new";
import React, { useCallback, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./vehicle-inspections-tab.css";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import { PlusIcon } from "lucide-react";
import { Vehicle } from "../../types";

export const VehicleInspectionsTab = ({ vehicle }: { vehicle?: Vehicle }) => {
  const { data: updatedVehicle, mutate: updateVehicle } = useUpdateVehicle();
  const [inspections, setInspections] = useState(
    vehicle?.complianceData?.inspections || []
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedInspectionId, setSelectedInspectionId] = useState<
    string | null
  >(null);
  const [selectedAttemptId, setSelectedAttemptId] = useState<string | null>(
    null
  );
  const [inspectionDate, setInspectionDate] = useState<Date | null>(new Date());
  const [isResultsUploaded, setIsResultsUploaded] = useState(false);
  const [inspectionStatus, setInspectionStatus] =
    useState<ComplianceInspectionStatus>(ComplianceInspectionStatus.Pending);
  const [inspectionNotes, setInspectionNotes] = useState("");
  const [isNewInspectionModalOpen, setIsNewInspectionModalOpen] =
    useState(false);

  const openInspectionModal = (inspectionId: string, attemptId?: string) => {
    setSelectedInspectionId(inspectionId);
    setSelectedAttemptId(attemptId || null);
    if (attemptId) {
      const inspection = inspections.find((i) => i.id === inspectionId);
      const attempt = inspection?.attempts.find((a) => a.id === attemptId);
      if (attempt) {
        setInspectionDate(new Date(attempt.attemptDate));
        setInspectionStatus(attempt.status);
        setInspectionNotes(attempt.notes);
        setIsResultsUploaded(
          attempt.status === ComplianceInspectionStatus.Completed
        );
      }
    } else {
      setInspectionDate(new Date());
      setIsResultsUploaded(false);
      setInspectionStatus(ComplianceInspectionStatus.Pending);
      setInspectionNotes("");
    }
    onOpen();
  };

  const getInspectionStatus = (inspection: any) => {
    if (inspection.attempts && inspection.attempts.length > 0) {
      const latestAttempt = inspection.attempts[inspection.attempts.length - 1];
      return latestAttempt.status;
    }
    return ComplianceInspectionStatus.Pending;
  };

  const isOverdue = (dueDate: string, status: ComplianceInspectionStatus) => {
    return (
      new Date(dueDate) < new Date() &&
      status !== ComplianceInspectionStatus.Completed
    );
  };

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: "Inspection Type",
        accessorKey: "notes",
        cell: ({ row, getValue }) => (
          <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
            {row.getCanExpand() ? (
              <button
                onClick={row.getToggleExpandedHandler()}
                style={{ cursor: "pointer" }}
              >
                {getValue()?.toString()}
              </button>
            ) : (
              <Text>{getValue()?.toString()}</Text>
            )}
          </div>
        ),
      },
      {
        header: "Due Date",
        accessorKey: "dueDate",
        cell: ({ getValue }) =>
          getValue()
            ? new Date(getValue<string>()).toLocaleDateString()
            : "N/A",
      },
      {
        header: "Status",
        accessorFn: (row) => row.status || getInspectionStatus(row),
        cell: ({ getValue, row }) => {
          const status = getValue() as ComplianceInspectionStatus;
          const dueDate = row.original.dueDate;
          const overdue = row.depth === 0 && isOverdue(dueDate, status);
          return (
            <Badge
              colorScheme={
                overdue
                  ? "red"
                  : status === ComplianceInspectionStatus.Completed
                  ? "green"
                  : status === ComplianceInspectionStatus.Failed
                  ? "red"
                  : "yellow"
              }
            >
              {overdue ? "Overdue" : status}
            </Badge>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "id",
        cell: ({ row }) => {
          if (row.depth > 0) {
            return null;
          }
          return (
            <Button
              size="sm"
              variant={"outline"}
              onClick={() => openInspectionModal(row.original.id)}
            >
              Log Inspection
            </Button>
          );
        },
      },
    ],
    [openInspectionModal]
  );

  const getSubRows = useCallback((inspection: any) => {
    return inspection.attempts?.map(
      (attempt: any) =>
        ({
          ...attempt,
          notes: `Attempt: ${new Date().toLocaleDateString()} - ${
            attempt.status
          }`,
        } as ComplianceInspectionAttempt)
    );
  }, []);

  const handleRecordInspectionActivity = async () => {
    if (selectedInspectionId && inspectionDate) {
      const updatedInspections = inspections.map((inspection) => {
        if (inspection.id === selectedInspectionId) {
          let updatedAttempts;
          if (selectedAttemptId) {
            updatedAttempts = inspection.attempts.map((attempt) =>
              attempt.id === selectedAttemptId
                ? {
                    ...attempt,
                    attemptDate: inspectionDate.toISOString(),
                    status: inspectionStatus,
                    notes: inspectionNotes,
                  }
                : attempt
            );
          } else {
            const newAttempt = {
              id: `attempt_${Date.now()}`,
              attemptDate: inspectionDate.toISOString(),
              status: inspectionStatus,
              notes: inspectionNotes,
            };
            updatedAttempts = [...(inspection.attempts || []), newAttempt];
          }
          return {
            ...inspection,
            attempts: updatedAttempts,
          };
        }
        return inspection;
      });
      setInspections(updatedInspections);

      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            inspections: updatedInspections,
          },
        };
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }
      onClose();
    }
  };

  const openNewInspectionModal = () => {
    setIsNewInspectionModalOpen(true);
  };

  const closeNewInspectionModal = () => {
    setIsNewInspectionModalOpen(false);
  };

  const handleCreateNewInspection = () => {
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 30);

    const newInspection = {
      id: `inspection_${Date.now()}`,
      ruleName: "Clean Truck Check",
      notes: "Notice to Submit",
      dueDate: dueDate.toISOString(),
      inspectorName: "",
      isCompleted: false,
      attempts: [],
    };

    setInspections([...inspections, newInspection as ComplianceInspection]);

    if (vehicle) {
      const updatedVehicle = {
        ...vehicle,
        complianceData: {
          ...vehicle.complianceData,
          inspections: [...inspections, newInspection],
        },
      };
      updateVehicle({ id: vehicle.id, body: updatedVehicle });
    }

    closeNewInspectionModal();
  };

  return (
    <Box>
      <DataTable
        data={inspections}
        columns={columns}
        getSubRowsFn={getSubRows}
        suppressPaginationFooter={true}
        initialState={{
          expanded: true,
        }}
        actionChildren={
          <Button
            leftIcon={<PlusIcon />}
            variant={"inverted"}
            onClick={openNewInspectionModal}
            mb={4}
          >
            Received Notice to Submit to Testing
          </Button>
        }
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedAttemptId
              ? "Edit Inspection Attempt"
              : "Record Inspection Activity"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Inspection Date</FormLabel>
              <DatePicker
                selected={inspectionDate}
                onChange={(date) => setInspectionDate(date)}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="scroll"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                customInput={
                  <Input
                    placeholder="Select inspection date"
                    width={"100%"}
                    _focus={{
                      borderColor: "blue.500",
                      boxShadow: "0 0 0 1px blue.500",
                    }}
                  />
                }
                wrapperClassName="datePicker"
                popperClassName="datepicker-popper"
                calendarClassName="custom-calendar"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Select
                value={inspectionStatus}
                onChange={(e) =>
                  setInspectionStatus(
                    e.target.value as ComplianceInspectionStatus
                  )
                }
              >
                <option value={ComplianceInspectionStatus.Pending}>
                  Pending
                </option>
                <option value={ComplianceInspectionStatus.Completed}>
                  Completed
                </option>
                <option value={ComplianceInspectionStatus.Failed}>
                  Failed
                </option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Notes</FormLabel>
              <Input
                value={inspectionNotes}
                onChange={(e) => setInspectionNotes(e.target.value)}
                placeholder="Enter any notes about the inspection"
              />
            </FormControl>
            {inspectionStatus === ComplianceInspectionStatus.Completed && (
              <Checkbox
                mt={4}
                isChecked={isResultsUploaded}
                onChange={(e) => setIsResultsUploaded(e.target.checked)}
              >
                I have uploaded the results to the{" "}
                {
                  inspections.find((i) => i.id === selectedInspectionId)
                    ?.ruleName
                }{" "}
                system
              </Checkbox>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"inverted"}
              mr={3}
              onClick={handleRecordInspectionActivity}
              isDisabled={
                inspectionStatus === ComplianceInspectionStatus.Completed &&
                !isResultsUploaded
              }
            >
              {selectedAttemptId ? "Update Attempt" : "Record Activity"}
            </Button>
            <Button variant={"outline"} color={"red.500"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isNewInspectionModalOpen}
        onClose={closeNewInspectionModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Notice to Submit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Inspection Type: Notice to Submit</Text>
            <Text mt={4}>
              Due Date:{" "}
              {new Date(
                Date.now() + 30 * 24 * 60 * 60 * 1000
              ).toLocaleDateString()}
            </Text>
            <Text fontSize="sm" color="gray.500">
              (30 days from today, not changeable)
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"inverted"}
              mr={3}
              onClick={handleCreateNewInspection}
            >
              Confirm
            </Button>
            <Button variant="ghost" onClick={closeNewInspectionModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
