import {
  Box,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-header";
import { EvidenceTab } from "./evidence/list";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { InspectionTemplatesListTab } from "./inspections-templates/inspection-templates-list";
import { ProtocolsLibraryTab } from "./protocols/protocols-library-tab";

export const DocLibraryPage: React.FC = () => {
  const [tabs, setTabs] = useState<
    { name: string; component: React.ReactNode }[]
  >([
    { name: "Evidence", component: <EvidenceTab key={1} /> },
    { name: "Protocols", component: <ProtocolsLibraryTab key={2} /> },
    {
      name: "Inspection Templates",
      component: <InspectionTemplatesListTab key={2} />,
    },
    {
      name: "General",
      component: <></>,
    },
  ]);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("evidence")) {
      setTabIndex(0);
    } else if (location.pathname.includes("protocols")) {
      setTabIndex(1);
    } else if (location.pathname.includes("inspection-templates")) {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  return (
    <Box height={"100vh"} overflowY={"scroll"}>
      <PageHeader title="Doc Library" />
      <Stack>
        <Tabs
          index={tabIndex}
          size={"lg"}
          variant="soft-rounded"
          onChange={(index) => {
            setTabIndex(index);
            navigate(
              `/doc-library/${tabs[index].name.toLowerCase().replace(" ", "-")}`
            );
          }}
        >
          <TabList
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  borderRadius={"md"}
                  isDisabled={tab.name === "General" /*coming soon*/}
                >
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />
          <TabPanels>
            {tabs.map((tab, index) => {
              return (
                <TabPanel p={0} key={index}>
                  <Outlet />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
