import {
  Box,
  Stack,
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  Image,
  HStack,
  Progress,
  Tooltip,
  CardHeader,
  SimpleGrid,
  Center,
  Tag,
  VStack,
  TagLabel,
  SkeletonText,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Icon
} from "@chakra-ui/react";

import {
  useMyCertificates,
  useMyCourses,
  useMyRefresherSummaries,
} from "../../../api/endpoints/my";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteCertificate,
  useUploadCertificate,
} from "../../../api/endpoints/certificates";
import { PageHeader } from "../../../components/page-header";
import { BiDownload, BiLock, BiTrash } from "react-icons/bi";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactComponent as OnlineTestIllustration } from "../../../icons/online-test.svg";
import { BsLightning, BsQuestionOctagonFill } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ChecklistIllustration } from "../../../icons/checklist.svg";
import { AwardIcon } from "lucide-react";
import { MyCertificate } from "../../../types";
import * as Yup from "yup";
import { Form, Field, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { Select } from "chakra-react-select";
import { DeleteButton } from "../../../components/delete-button";
import { HiFire } from "react-icons/hi";
import { IoMedical } from "react-icons/io5";

export const TrainingLibraryTab: React.FC = () => {
  const { data: courses, isLoading: coursesLoading } = useMyCourses();
  const { data: certificates, isLoading: certificatesLoading } =
    useMyCertificates();
  const { data: refresherSummaries, isLoading: refresherSummariesLoading } =
    useMyRefresherSummaries();

  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isTrial, trialEligibleCourses, demoCourses } = useFlags();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { mutate: uploadCertificate, isError: uploadCertificateError } =
    useUploadCertificate();

  const { mutate: deleteCertificate, isError: deleteCertificateError } =
    useDeleteCertificate();

  const schema = Yup.object().shape({
    issuedBy: Yup.object().required("Issued By is required."),
    identifier: Yup.string().min(3).required("Identifier is required."),
    validFrom: Yup.date().required("Valid from date is required."),
    validTo: Yup.date(),
    file: Yup.mixed().test(
      "fileSize",
      "The certificate file is too large",
      (value: any) => {
        if (!value) return false;
        return value.size <= 5242880;
      }
    ),
  });

  const initialValues: {
    name?: string;
    issuedBy?: string;
    identifier?: string;
    validFrom?: string;
    validTo?: string;
    file?: File;
  } = {
    name: undefined,
    issuedBy: undefined,
    identifier: undefined,
    validFrom: undefined,
    validTo: undefined,
    file: undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      console.log(values);
      await uploadCertificate({
        data: {
          identifier: values.identifier,
          name: values.issuedBy.value,
          issuedBy: values.issuedBy.issuer,
          validFrom: values.validFrom,
          validTo: values.validTo,
          file: values.file,
        },
      });
      onToggle();
    },
  });

  const certificateOptions: readonly any[] = [
    {
      label: "ASE",
      options: [
        { label: "xEV Level 1", value: "xEV Level 1", issuer: "ASE" },
        { label: "xEV Level 2", value: "xEV Level 2", issuer: "ASE" },
        { label: "Other", value: "other", issuer: "ASE" },
      ],
    },
  ];

  const hiddenCertificateFileInput = useRef(null);

  const handleCertificateFileClick = (event: any) => {
    //@ts-ignore
    hiddenCertificateFileInput.current.click();
  };

  const handleCertificateFileChange = (event: any) => {
    if (event?.target?.files?.length === 0) return;

    const file = event.target.files[0];

    if (file.size > 5242880) {
      toast.error("Certificate file is larger than 5MB.");
    } else {
      formik.setFieldValue("file", file);
    }
  };

  const tabs = [
    {
      name: "Tasks",
      component: <></>,
    },
    // { name: "Scratch Pad", component: <ScratchPadTab /> },
  ];

  return (
    <Box height={"100%"} overflowY={"auto"}>
      {searchParams.get("finishedCourse") === "true" && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      <Stack>
        <Card variant={"outline"}>
          <CardHeader>
            <Heading variant={"md"}>Library</Heading>
            <Text variant={"sm"}>
              See below for the training courses available to you.
            </Text>
          </CardHeader>
          <CardBody p={{ base: 2, sm: 5 }}>
            <SkeletonText
              width="100%"
              skeletonHeight={20}
              noOfLines={3}
              isLoaded={!coursesLoading}
            ></SkeletonText>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={3}>
              {!coursesLoading &&
                courses &&
                courses.concat(demoCourses)?.map((course, i) => {
                  return (
                    <Card key={i} variant={"outline"} borderRadius={"lg"}>
                      <CardBody p={0}>
                        <Stack height={"100%"}>
                          <Image
                            src={course.featuredImageUrl}
                            borderTopRadius={"lg"}
                            aspectRatio={"2/1"}
                            objectFit={"cover"}
                          />
                          <Stack
                            p={"1rem"}
                            justify={"space-between"}
                            display={"flex"}
                            flexDirection={"column"}
                            height={"100%"}
                          >
                            <Text
                              variant={"sm"}
                              fontWeight={600}
                              color={"brand.text"}
                            >
                              {course.name}
                            </Text>
                            <HStack>
                              <Tag
                                size={"sm"}
                                variant="outline"
                                colorScheme="brand.primary"
                                borderRadius={"full"}
                                width={"fit-content"}
                              >
                                <TagLabel fontWeight={"700"}>
                                  {course.modules} Modules
                                </TagLabel>
                              </Tag>
                              <Tag
                                size={"sm"}
                                variant="outline"
                                color={
                                  course.icon === "fire"
                                    ? "orange.500"
                                    : course.icon === "health"
                                    ? "red.500"
                                    : "yellow.500"
                                }
                                borderColor={
                                  course.icon === "fire"
                                    ? "orange.500"
                                    : course.icon === "health"
                                    ? "red.500"
                                    : "yellow.500"
                                }
                                borderRadius={"full"}
                              >
                                <TagLabel>
                                  {course.icon === "fire" && <HiFire />}
                                  {course.icon === "health" && <IoMedical />}
                                  {(course.icon === "electricity" ||
                                    course.icon === undefined) && (
                                    <BsLightning />
                                  )}
                                </TagLabel>
                              </Tag>
                            </HStack>
                          </Stack>
                        </Stack>
                      </CardBody>
                    </Card>
                  );
                })}
            </SimpleGrid>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
