import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  FormHelperText,
  Textarea,
  HStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { FormikProvider, Field, useFormik, Form } from "formik";
import * as Yup from "yup";
import { useOrganization } from "@clerk/clerk-react";
import { useSites } from "../../api/endpoints/sites";
import { useTeams } from "../../api/endpoints/teams";
import type { OrganizationMembershipResource } from "@clerk/types";
import { Select } from "chakra-react-select";
import {
  useAction,
  useResolveAction,
  useUpdateAction,
} from "../../api/endpoints/actions";
import { Action } from "../../types";

export const MyActionEditPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    mutate: updateAction,
    isError: updateActionError,
    data: updateActionData,
  } = useUpdateAction();

  const {
    mutate: resolveAction,
    isError: resolveActionError,
    data: resolveActionData,
  } = useResolveAction();

  const formatDate = (input: string): string => {
    const date = new Date(input);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-based in JS
    const day = date.getUTCDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const { data, isError, isLoading } = useAction(id ?? "");
  // updateInspection({ id: id ?? "", body: { results: sender.data } });
  let key: keyof Action;

  useEffect(() => {
    if (data) {
      for (key in data) {
        if (key === "dueDate") {
          const test = data[key];
          if ((data[key]?.toString() ?? "").length > 0) {
            formik.setFieldValue(key, formatDate(data[key]?.toString() ?? ""));
          }
          continue;
        }
        if (key === "assignedTo") {
          formik.setFieldValue(
            key,
            (data[key] ?? []).map((x) => {
              return { value: x.id, label: x.name };
            })
          );
          continue;
        }
        if (key === "priority") {
          formik.setFieldValue(
            key,
            priorityOptions.find((x) => x.label === data[key]),
            true
          );
          continue;
        }
        formik.setFieldValue(key, data[key], true);
      }
    }
  }, [isLoading]);

  const onResolveClick = async () => {
    await resolveAction({ id: id ?? "" });
    navigate("/my/tasks");
  };

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Action name is required."),
    description: Yup.string()
      .min(3)
      .required("Action description is required."),
    priority: Yup.object().required("Action priority is required."),
    dueDate: Yup.date().required("Action due date is required."),
  });

  const initialValues = {
    name: data?.name ?? "",
    description: data?.description ?? "",
    assignedTo: [],
    dueDate: undefined,
    priority: "low",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      // values.id = selectedQuestion;
      const formData = {
        id: id,
        name: values.name,
        priority: values.priority.value,
        description: values.description,
        dueDate: values.dueDate,
        assignedTo: values.assignedTo.map((x: any) => x.value),
      };
      updateAction({ id: id ?? "", body: formData });
    },
  });

  const {
    data: teams,
    isError: teamsIsError,
    isLoading: teamsIsLoading,
  } = useTeams();

  const {
    data: sites,
    isError: sitesIsError,
    isLoading: sitesIsLoading,
  } = useSites();

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const assignedToOptions: readonly any[] = [
    {
      label: "Teams",
      options: teams?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Sites",
      options: sites?.map((x) => {
        return { label: x.name, value: x.id };
      }),
    },
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  const priorityOptions: readonly any[] = [
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  return (
    <Box p={{ base: "3", md: "4" }}>
      <Card direction={{ base: "row", sm: "row" }} variant="outline">
        <CardBody>
          <FormikProvider value={formik}>
            <Form>
              <Heading size="md" my={5}>
                Edit Action - {data?.name}
              </Heading>
              <HStack justifyContent={"right"}>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  type="submit"
                  onClick={onResolveClick}
                >
                  Resolve
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  type="submit"
                >
                  Save
                </Button>
              </HStack>
              <Stack
                spacing="5"
                px={{ base: "4", md: "6" }}
                py={{ base: "5", md: "6" }}
              >
                <FormControl
                  isInvalid={
                    !!formik?.errors?.name === true &&
                    !!formik?.touched?.name === true
                  }
                >
                  <FormLabel>Name</FormLabel>
                  <Field
                    as={Input}
                    id="name"
                    name={"name"}
                    type="text"
                    placeholder="Example Name"
                    value={formik.values.name}
                  />
                  <FormErrorMessage>
                    {formik?.errors?.name?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!formik?.errors?.description === true &&
                    !!formik?.touched?.description === true
                  }
                >
                  <FormLabel>Description</FormLabel>
                  <Field
                    as={Textarea}
                    id="description"
                    name={"description"}
                    type="text"
                    placeholder="Example Description"
                    value={formik.values.description}
                  />
                  <FormErrorMessage>
                    {formik?.errors?.description?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="priority"
                  isInvalid={
                    !!formik?.errors?.priority === true &&
                    !!formik?.touched?.priority === true
                  }
                >
                  <FormLabel>Priority</FormLabel>
                  <Select
                    options={priorityOptions}
                    id="priority"
                    placeholder={"High, Medium, or Low"}
                    value={formik.values.priority}
                    name="priority"
                    closeMenuOnSelect={true}
                    onChange={(e: any) => {
                      formik.setFieldValue("priority", e);
                    }}
                  />
                  <FormErrorMessage>
                    {formik?.errors?.priority?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="assignedTo"
                  isInvalid={
                    !!formik?.errors?.assignedTo === true &&
                    !!formik?.touched?.assignedTo === true
                  }
                >
                  <FormLabel>Assigned To*</FormLabel>
                  <Select
                    isMulti
                    options={assignedToOptions}
                    id="assignedTo"
                    placeholder={"Team, Site, or Member."}
                    value={formik.values.assignedTo}
                    name="assignedTo"
                    closeMenuOnSelect={true}
                    onChange={(e: any) => {
                      formik.setFieldValue("assignedTo", e);
                    }}
                  />
                  <FormHelperText>
                    The teams, sites, and members selected will be assigned to
                    resolve the action.
                  </FormHelperText>
                  <FormErrorMessage>
                    {formik?.errors?.assignedTo?.toString()}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!formik?.errors?.dueDate === true &&
                    !!formik?.touched?.dueDate === true
                  }
                >
                  <FormLabel>Due Date</FormLabel>
                  <Input
                    placeholder="Select Date"
                    size="md"
                    type="date"
                    id="dueDate"
                    name={"dueDate"}
                    value={formik.values.dueDate}
                    onChange={(e: any) => {
                      formik.setFieldValue("dueDate", e.target.value);
                    }}
                  />
                  <FormErrorMessage>
                    {formik?.errors?.dueDate?.toString()}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Form>
          </FormikProvider>
        </CardBody>
        <CardFooter />
      </Card>
    </Box>
  );
};
