import {
  Box,
  Card,
  Badge,
  CardBody,
  HStack,
  useDisclosure,
  Button,
  Text,
  Stack,
  Tag,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PageHeader } from "../../components/page-header";
import { useGlovePairInspectionOrders } from "../../api/endpoints/gloves";
import { PlusIcon } from "lucide-react";
import { createColumnHelper } from "@tanstack/react-table";
import { GlovePairInspectionOrder } from "../../types";
import { DataTable } from "../../components/table";
import { HiOutlineDocumentDownload } from "react-icons/hi";

export const GloveManagementInspectionOrdersListPage: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: orders } = useGlovePairInspectionOrders();
  const columnHelper = createColumnHelper<GlovePairInspectionOrder>();

  const statusColorMap: { [key: string]: string } = {
    Preparing: "gray",
    Testing: "blue",
    Returning: "purple",
    Completed: "black",
  };

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => {
        const value = info.getValue();
        return (
          <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
            {value.slice(-10).toUpperCase()}
          </Text>
        );
      },
      header: () => <span>Order Number</span>,
    }),
    columnHelper.display({
      id: "quantity",
      header: () => <span>Quantity</span>,
      cell: (info) => info.row.original.orderLines.length,
    }),

    columnHelper.display({
      id: "orderedAt",
      header: () => <span>Order Date</span>,
      cell: (info) => (
        <Text>
          {new Date(info.row.original.orderedAt).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.display({
      id: "status",
      header: () => <span>Status</span>,
      cell: (info) => (
        <Badge variant={statusColorMap[info.row.original.status]}>
          {info.row.original.status}
        </Badge>
      ),
    }),
    columnHelper.display({
      id: "report",
      header: () => <span>Report</span>,
      cell: (info) => (
        <IconButton
          aria-label="download report"
          icon={<HiOutlineDocumentDownload />}
          onClick={() =>
            window.open(info.row.original.inspectionReportUrl, "_blank")
          }
          isDisabled={
            info.row.original.status !== "Completed" ||
            !info.row.original.inspectionReportUrl ||
            info.row.original.inspectionReportUrl.trim() === ""
          }
        ></IconButton>
      ),
    }),
  ];

  const [selectedOrder, setSelectedOrder] =
    useState<GlovePairInspectionOrder | null>(null);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Glove Tracker"></PageHeader>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Inspection Details</DrawerHeader>

          <DrawerBody>
            <HStack justify={"space-between"}>
              <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
                ID: {selectedOrder?.id.slice(-10).toUpperCase()}
              </Text>
              <Badge variant={statusColorMap[selectedOrder?.status ?? ""]}>
                {selectedOrder?.status}
              </Badge>
            </HStack>
            <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
              Order Date:{" "}
              {selectedOrder?.orderedAt
                ? new Date(selectedOrder.orderedAt).toLocaleDateString()
                : "N/A"}
            </Text>
            <Stack mt={2}>
              {selectedOrder?.orderLines.map((line) => (
                <Card variant={"outline"}>
                  <CardBody>
                    <HStack alignItems={"center"}>
                      <Box width={"60%"}>
                        <Tag>Glove ID: {line.glovePairId}</Tag>
                      </Box>
                      {selectedOrder.status === "Completed" && (
                        <Text variant={"md"}>{line.disposition}</Text>
                      )}
                      {selectedOrder.status !== "Completed" && (
                        <Text variant={"md"}>N/A</Text>
                      )}
                    </HStack>
                  </CardBody>
                </Card>
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={orders ?? []}
              onBackClick={() => navigate("/tools/glove-management")}
              title="Glove Inspection Orders"
              onRowClick={(row) => {
                if (orders) {
                  setSelectedOrder(orders[row]);
                  onOpen();
                }
              }}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={() =>
                    navigate("/tools/glove-management/inspections/new")
                  }
                >
                  Send for Testing
                </Button>
              }
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
