import {
  Box,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, HStack, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table";
import { EditSite } from "../../../types";
import { Site } from "../../../types-new";
import {
  useCreateSite,
  useDeleteSite,
  useSites,
} from "../../../api/endpoints/sites";
import { BsPencil } from "react-icons/bs";
import { DeleteButton } from "../../../components/delete-button";
import { Form, FormikProvider, Field, useFormik } from "formik";
import { useOrganization } from "@clerk/clerk-react";
import * as Yup from "yup";
import type { OrganizationMembershipResource } from "@clerk/types";
import { Select } from "chakra-react-select";
import { PlusIcon } from "lucide-react";

export const SitePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    data: sites,
    isError: isSitesError,
    isLoading: isSitesLoading,
  } = useSites();

  const columnHelper = createColumnHelper<Site>();
  const navigate = useNavigate();

  const { mutate: deleteSite } = useDeleteSite();
  const {
    isOpen: isNewSiteModalOpen,
    onToggle: onNewSiteModalToggle,
    onClose: onNewSiteModalClose,
  } = useDisclosure();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor(
      ({ address }) =>
        Object.values(address)
          .filter(
            (value) => typeof value === "string" || typeof value === "number"
          )
          .join(" "),
      {
        id: "address",
        header: () => <span>Address</span>,
        cell: ({
          row: {
            original: { address },
          },
        }) => {
          return (
            <Text>
              {address.street}, {address.city}, {address.state}{" "}
              {address.postalCode} {address.country}
            </Text>
          );
        },
        enableGlobalFilter: true,
        enableColumnFilter: true,
      }
    ),
    columnHelper.accessor((row) => row.members.length, {
      id: "members",
      header: () => <span>Members</span>,
      cell: (info) => info.row.original.members.length,
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack color={"brand.accent"} gap={0}>
            <DeleteButton
              title="Delete Team"
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete site"
              onDelete={() => deleteSite({ id: props.row.original.id })}
            />
            <IconButton
              color={"inherit"}
              aria-label="edit site"
              onClick={() =>
                navigate(`/org-management/sites/${props.row.original.id}`)
              }
              icon={<BsPencil />}
            />
          </HStack>
        );
      },
    }),
  ];

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const memberOptions = memberships?.data?.map(
    (x: OrganizationMembershipResource) => {
      return {
        label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
        value: x.id,
      };
    }
  );

  const { data: createdSiteId, mutate: createSite } = useCreateSite();

  useEffect(() => {
    if (createdSiteId) {
      navigate(`/org-management/sites/${createdSiteId}`);
    }
  }, [createdSiteId]);

  const schema = Yup.object().shape({
    // name: Yup.string().min(3).required("Action name is required."),
    // description: Yup.string()
    //   .min(3)
    //   .required("Action description is required."),
    // priority: Yup.object().required("Action priority is required."),
    // dueDate: Yup.date().required("Action due date is required."),
  });

  const formik = useFormik({
    initialValues: {} as EditSite,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      await createSite({ site: values });
    },
  });

  return (
    <Box height={"100vh"} overflow={"auto"}>
      <Modal
        isOpen={isNewSiteModalOpen}
        onClose={onNewSiteModalClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <Card variant={"outline"}>
                <CardBody>
                  <Stack>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.name === true &&
                        !!formik?.touched?.name === true
                      }
                      mb={5}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name={"name"}
                        type="text"
                        placeholder="Example Name"
                        value={formik?.values?.name}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.description === true &&
                        !!formik?.touched?.description === true
                      }
                      mb={5}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field
                        as={Textarea}
                        id="description"
                        name={"description"}
                        type="text"
                        placeholder="Description"
                        value={formik?.values?.description}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.description?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.street === true &&
                        !!formik?.touched?.street === true
                      }
                    >
                      <FormLabel>Street</FormLabel>
                      <Field
                        as={Input}
                        name={"street"}
                        type="text"
                        placeholder="123 Example Street"
                        value={formik?.values?.street}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.street?.toString()}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!formik?.errors?.city === true &&
                        !!formik?.touched?.city === true
                      }
                    >
                      <FormLabel>City</FormLabel>
                      <Field
                        as={Input}
                        name={"city"}
                        type="text"
                        placeholder="City"
                        value={formik?.values?.city}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.city?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.state === true &&
                        !!formik?.touched?.state === true
                      }
                    >
                      <FormLabel>State / Province</FormLabel>
                      <Field
                        as={Input}
                        name={"state"}
                        type="text"
                        placeholder="State"
                        value={formik?.values?.state}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.state?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.postalCode === true &&
                        !!formik?.touched?.postalCode === true
                      }
                    >
                      <FormLabel>ZIP/ Postal Code</FormLabel>
                      <Field
                        as={Input}
                        name={"postalCode"}
                        type="text"
                        placeholder="Postal Code"
                        value={formik?.values?.postalCode}
                      />
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.country === true &&
                        !!formik?.touched?.country === true
                      }
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={Input}
                        name={"country"}
                        type="text"
                        placeholder="Country"
                        value={formik?.values?.country}
                      />
                    </FormControl>
                    <FormLabel>Primary Site Leader</FormLabel>
                    <Select
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                      options={memberOptions}
                      id="primarySiteLeaderId"
                      menuPortalTarget={document.body}
                      value={memberOptions?.find(
                        (x: any) =>
                          x.value === formik.values.primarySiteLeaderId
                      )}
                      name="primarySiteLeaderId"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("primarySiteLeaderId", e.value);
                      }}
                    />
                    <FormLabel>Secondary Site Leader</FormLabel>
                    <Select
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                      options={memberOptions}
                      id="secondarySiteLeaderId"
                      menuPortalTarget={document.body}
                      value={memberOptions?.find(
                        (x: any) =>
                          x.value === formik.values.secondarySiteLeaderId
                      )}
                      name="secondarySiteLeaderId"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        formik.setFieldValue("secondarySiteLeaderId", e.value);
                      }}
                    />
                  </Stack>
                </CardBody>
                <CardFooter justifyContent={"end"} display={"flex"}>
                  <Button
                    colorScheme="brand.primary"
                    px={5}
                    onClick={() => formik.handleSubmit()}
                  >
                    Create
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={sites ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onNewSiteModalToggle}
                >
                  New Site
                </Button>
              }
              title="Sites"
              subtitle="Sites represent physical locations important to your business and can be used to assign training and other tasks in bulk."
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
