import {
  Box,
  Button,
  Card,
  CardBody,
  IconButton,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";

import { PageHeader } from "../../components/page-header";
import { ChevronsRightIcon, PlusIcon } from "lucide-react";
export const CommunityPage: React.FC = () => {
  const posts = [
    {
      id: 1,
      title: "Hello from Maryam - Testing Community Functionality",
      content:
        "Hello everyone! This is Maryam, I'm testing out our new community feature. I'm excited to see how this platform will enhance our communication and knowledge sharing. Please ignore this test post, but feel free to explore the functionality!",
      author: "Maryam2",
      time: "3 days ago",
      category: "Test",
      comments: 0,
      likes: 2,
    },
  ];
  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Community"></PageHeader>
      <Card variant={"outline"} m={5} height={"100%"}>
        <CardBody>
          <Stack spacing={6}>
            <Button
              leftIcon={<PlusIcon />}
              colorScheme="brand.primary"
              variant="inverted"
              alignSelf="flex-end"
            >
              New Post
            </Button>

            <Stack spacing={4}>
              {posts.map((post) => (
                <Card key={post.id} variant="outline">
                  <CardBody>
                    <Stack spacing={3}>
                      <Text fontWeight="bold" fontSize="lg">
                        {post.title}
                      </Text>
                      <Text noOfLines={2}>{post.content}</Text>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Text fontSize="sm" color="gray.500">
                          Posted by {post.author}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          • {post.time}
                        </Text>
                        <Tag
                          size="sm"
                          variant="subtle"
                          bg="brand.primary.500"
                          color="white"
                        >
                          {post.category}
                        </Tag>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack direction="row" spacing={2}>
                          <Button size="sm" variant="ghost">
                            💬 {post.comments} Comments
                          </Button>
                          <Button size="sm" variant="ghost">
                            👍 {post.likes} Likes
                          </Button>
                        </Stack>
                        <IconButton
                          icon={<ChevronsRightIcon />}
                          aria-label="View post"
                          variant="ghost"
                          size="sm"
                        />
                      </Stack>
                    </Stack>
                  </CardBody>
                </Card>
              ))}
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
