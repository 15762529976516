import {
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useOrganization } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import type { OrganizationMembershipResource } from "@clerk/types";

import { useNavigate, useParams } from "react-router-dom";
import { Button, HStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/table";
import { EditSite } from "../../../types";
import { Member } from "../../../types-new";
import {
  useAddMemberToSite,
  useCreateSite,
  useRemoveMemberFromSite,
  useSite,
  useUpdateSite,
} from "../../../api/endpoints/sites";

import * as Yup from "yup";

import { Field, Form, FormikProvider, useFormik } from "formik";
import { Select } from "chakra-react-select";
import { DeleteButton } from "../../../components/delete-button";
import { PlusIcon } from "lucide-react";

export const SiteEditTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: site,
    isError: isSiteError,
    isLoading: isSiteLoading,
  } = useSite(id ?? "");

  const columnHelper = createColumnHelper<Member>();

  const {
    isOpen: isAddMemberToSiteOpen,
    onToggle: onAddMemberToSiteToggle,
    onClose: onAddMemberToSiteClose,
  } = useDisclosure();

  const {
    isOpen: isEditSiteModalOpen,
    onToggle: onEditSiteModalToggle,
    onClose: onEditSiteModalClose,
  } = useDisclosure();

  const { mutate: addMemberToSite } = useAddMemberToSite();
  const { mutate: removeMemberFromSite } = useRemoveMemberFromSite();

  const columns = [
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      header: () => <span>First Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      header: () => <span>Last Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),

    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <DeleteButton
              deleteKey={props.row.original.emailAddress}
              name={props.row.original.emailAddress}
              aria-label="delete site"
              onDelete={() =>
                removeMemberFromSite({
                  siteId: id ?? "",
                  memberId: props.row.original.id,
                })
              }
            />
          </HStack>
        );
      },
    }),
  ];

  const schema = Yup.object().shape({
    members: Yup.array().min(1, "At least one member is required."),
  });

  const { mutate: updateSite } = useUpdateSite();

  const { data: createdSiteId, mutate: createSite } = useCreateSite();

  const initialValues = {
    members: [],
  };

  const addMemberToSiteFormik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values: any) => {
      if (id === undefined) return;
      await values.members.forEach(async (member: any) => {
        await addMemberToSite({ siteId: id, memberId: member.value });
      });

      onAddMemberToSiteClose();
    },
  });

  const editSiteSettingsFormik = useFormik({
    initialValues: site
      ? ({
          id: site.id,
          name: site.name,
          description: site.description,
          street: site.address.street,
          city: site.address.city,
          state: site.address.state,
          postalCode: site.address.postalCode,
          country: site.address.country,
          primarySiteLeaderId: site.primarySiteLeader?.id,
          secondarySiteLeaderId: site.secondarySiteLeader?.id,
        } as EditSite)
      : ({} as EditSite),
    validationSchema: schema,
    onSubmit: async (values: any) => {
      if (id !== undefined) {
        await updateSite({ id: id ?? "", site: values });
      } else {
        await createSite({ site: values });
      }
    },
  });

  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const memberOptions: readonly any[] = [
    {
      label: "Members",
      options: memberships?.data?.map((x: OrganizationMembershipResource) => {
        return {
          label: `${x.publicUserData.firstName} ${x.publicUserData.lastName}`,
          value: x.id,
        };
      }),
    },
  ];

  useEffect(() => {
    if (site) {
      editSiteSettingsFormik.setValues({
        id: site.id,
        name: site.name,
        description: site.description,
        street: site.address.street,
        city: site.address.city,
        state: site.address.state,
        postalCode: site.address.postalCode,
        country: site.address.country,
        primarySiteLeaderId: site.primarySiteLeader?.id,
        secondarySiteLeaderId: site.secondarySiteLeader?.id,
      } as EditSite);
    }
  }, [site]);

  return (
    <Stack p={5}>
      <Modal
        isOpen={isAddMemberToSiteOpen}
        onClose={onAddMemberToSiteClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={addMemberToSiteFormik}>
            <Form>
              <ModalHeader>Add Members to {site?.name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <FormControl
                    id="members"
                    isInvalid={
                      !!addMemberToSiteFormik?.errors?.members === true &&
                      !!addMemberToSiteFormik?.touched?.members === true
                    }
                  >
                    <FormLabel>New Members</FormLabel>
                    <Select
                      isMulti
                      options={memberOptions}
                      id="members"
                      placeholder={"Members"}
                      value={addMemberToSiteFormik.values.members}
                      name="members"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        addMemberToSiteFormik.setFieldValue("members", e);
                      }}
                    />
                    <FormHelperText>
                      The members listed above will be assigned to {site?.name}.
                    </FormHelperText>
                    <FormErrorMessage>
                      {addMemberToSiteFormik?.errors?.members?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onAddMemberToSiteClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!addMemberToSiteFormik.isValid}
                  type="submit"
                >
                  Add
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isEditSiteModalOpen}
        onClose={onEditSiteModalClose}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={editSiteSettingsFormik}>
            <Form>
              <Card variant={"outline"}>
                <CardBody>
                  <Stack gap={2}>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.name === true &&
                        !!editSiteSettingsFormik?.touched?.name === true
                      }
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name={"name"}
                        type="text"
                        placeholder="Example Name"
                        value={editSiteSettingsFormik?.values?.name}
                      />
                      <FormErrorMessage>
                        {editSiteSettingsFormik?.errors?.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.description ===
                          true &&
                        !!editSiteSettingsFormik?.touched?.description === true
                      }
                    >
                      <FormLabel>Description</FormLabel>
                      <Field
                        as={Textarea}
                        id="description"
                        name={"description"}
                        type="text"
                        placeholder="Description"
                        value={editSiteSettingsFormik?.values?.description}
                      />
                      <FormErrorMessage>
                        {editSiteSettingsFormik?.errors?.description?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.street === true &&
                        !!editSiteSettingsFormik?.touched?.street === true
                      }
                    >
                      <FormLabel>Street</FormLabel>
                      <Field
                        as={Input}
                        name={"street"}
                        type="text"
                        placeholder="123 Example Street"
                        value={editSiteSettingsFormik?.values?.street}
                      />
                      <FormErrorMessage>
                        {editSiteSettingsFormik?.errors?.street?.toString()}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.city === true &&
                        !!editSiteSettingsFormik?.touched?.city === true
                      }
                    >
                      <FormLabel>City</FormLabel>
                      <Field
                        as={Input}
                        name={"city"}
                        type="text"
                        placeholder="City"
                        value={editSiteSettingsFormik?.values?.city}
                      />
                      <FormErrorMessage>
                        {editSiteSettingsFormik?.errors?.city?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.state === true &&
                        !!editSiteSettingsFormik?.touched?.state === true
                      }
                    >
                      <FormLabel>State / Province</FormLabel>
                      <Field
                        as={Input}
                        name={"state"}
                        type="text"
                        placeholder="State"
                        value={editSiteSettingsFormik?.values?.state}
                      />
                      <FormErrorMessage>
                        {editSiteSettingsFormik?.errors?.state?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.postalCode === true &&
                        !!editSiteSettingsFormik?.touched?.postalCode === true
                      }
                    >
                      <FormLabel>ZIP/ Postal Code</FormLabel>
                      <Field
                        as={Input}
                        name={"postalCode"}
                        type="text"
                        placeholder="Postal Code"
                        value={editSiteSettingsFormik?.values?.postalCode}
                      />
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!editSiteSettingsFormik?.errors?.country === true &&
                        !!editSiteSettingsFormik?.touched?.country === true
                      }
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={Input}
                        name={"country"}
                        type="text"
                        placeholder="Country"
                        value={editSiteSettingsFormik?.values?.country}
                      />
                    </FormControl>

                    <FormLabel>Primary Site Leader</FormLabel>
                    <Select
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 10000,
                        }),
                      }}
                      options={memberOptions}
                      id="primarySiteLeaderId"
                      menuPortalTarget={document.body}
                      value={memberOptions[0].options?.find(
                        (x: any) =>
                          x.value ===
                          editSiteSettingsFormik.values.primarySiteLeaderId
                      )}
                      name="primarySiteLeaderId"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        editSiteSettingsFormik.setFieldValue(
                          "primarySiteLeaderId",
                          e.value
                        );
                      }}
                    />

                    <FormLabel>Secondary Site Leader</FormLabel>
                    <Select
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 10000,
                        }),
                      }}
                      options={memberOptions}
                      id="secondarySiteLeaderId"
                      menuPortalTarget={document.body}
                      value={memberOptions[0].options?.find(
                        (x: any) =>
                          x.value ===
                          editSiteSettingsFormik.values.secondarySiteLeaderId
                      )}
                      name="secondarySiteLeaderId"
                      closeMenuOnSelect={true}
                      onChange={(e: any) => {
                        editSiteSettingsFormik.setFieldValue(
                          "secondarySiteLeaderId",
                          e.value
                        );
                      }}
                    />
                  </Stack>
                </CardBody>
                <CardFooter justifyContent={"end"} display={"flex"}>
                  <Button
                    colorScheme="brand.primary"
                    px={5}
                    onClick={() => editSiteSettingsFormik.handleSubmit()}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Card variant={"outline"}>
        <CardBody>
          <DataTable
            columns={columns}
            data={site?.members ?? []}
            actionChildren={
              <>
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onAddMemberToSiteToggle}
                >
                  Add Member
                </Button>
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onEditSiteModalToggle}
                >
                  Edit Site
                </Button>
              </>
            }
            title={site?.name}
            onBackClick={() => navigate(`/org-management/sites`)}
          />
        </CardBody>
      </Card>
    </Stack>
  );
};
