import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createContext, useEffect, useState } from "react";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
const reactPlugin = new ReactPlugin();

export const AppInsightsContext = createContext(reactPlugin);

export const AppInsightsContextProvider: React.FC<{
  children: React.ReactNode;
  settings: { applicationInsightsKey?: string };
}> = ({ children, settings }) => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const { orgRole } = useAuth();
  const [appInsightsInstance, setAppInsightsInstance] = useState<
    ApplicationInsights | undefined
  >(undefined);

  const initializeAppInsights = (instrumentationKey: string) => {
    const newAppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });
    newAppInsights.loadAppInsights();
    setAppInsightsInstance(newAppInsights);
  };

  useEffect(() => {
    if (settings?.applicationInsightsKey) {
      initializeAppInsights(settings.applicationInsightsKey);
    }
  }, [settings?.applicationInsightsKey]);

  useEffect(() => {
    if (appInsightsInstance && user?.id && organization?.id && orgRole) {
      appInsightsInstance.addTelemetryInitializer((envelope) => {
        if (envelope.baseData) {
          envelope.baseData.properties["organizationId"] = organization?.id;
          envelope.baseData.properties["userId"] = user?.id;
          envelope.baseData.properties["role"] = orgRole;
        }
      });
    }
  }, [user?.id, organization?.id, orgRole]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};
