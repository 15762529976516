import {
  Box,
  Heading,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useState } from "react";
import { CourseManagementTab } from "./course-management-tab";
import { CourseReportingTab } from "./course-reporting-tab";
import { PageHeader } from "../../components/page-header";
import { CourseInPersonSchedulingTab } from "./course-inperson-scheduling";

export const TrainingListPage: React.FC = () => {
  const [tabs, setTabs] = useState<
    { name: string; component: React.ReactNode }[]
  >([
    { name: "Reporting", component: <CourseReportingTab key={1} /> },
    {
      name: "Assign Training",
      component: <CourseManagementTab key={2} />,
    },
    {
      name: "Schedule In-Person Training",
      component: <CourseInPersonSchedulingTab key={3} />,
    },
  ]);

  return (
    <Box height={"100vh"} overflow={"scroll"} p={0}>
      <PageHeader title="Training Management"></PageHeader>
      <Stack>
        <Tabs
          size={"lg"}
          variant="soft-rounded"
          borderLeft={"1px solid"}
          borderColor={"brand.border"}
        >
          <TabList
            pt={0}
            pb={6}
            px={6}
            background={"white"}
            borderBottom={"1px"}
            borderBottomColor={"gray.100"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab key={index} borderRadius={"md"}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator />
          <TabPanels>
            {tabs.map((tab, index) => {
              return <TabPanel p={0}>{tab.component}</TabPanel>;
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
};
