import {
  Box,
  Card,
  CardBody,
  HStack,
  useDisclosure,
  Button,
  Text,
  Stack,
  Tag,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PageHeader } from "../../components/page-header";
import { useGlovePairOrders } from "../../api/endpoints/gloves";
import { PlusIcon } from "lucide-react";
import { createColumnHelper } from "@tanstack/react-table";
import { GlovePairOrder } from "../../types";
import { DataTable } from "../../components/table";

export const GloveManagementOrdersListPage: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: orders } = useGlovePairOrders();
  const columnHelper = createColumnHelper<GlovePairOrder>();

  const statusColorMap: { [key: string]: string } = {
    Preparing: "green",
    InTransit: "blue",
    Delivered: "gray",
  };

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      cell: (info) => {
        const value = info.getValue();
        return (
          <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
            {value.slice(-10).toUpperCase()}
          </Text>
        );
      },
      header: () => <span>Order Number</span>,
    }),
    columnHelper.display({
      id: "quantity",
      header: () => <span>Quantity</span>,
      cell: (info) =>
        info.row.original.orderLines.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        ),
    }),
    columnHelper.display({
      id: "total",
      header: () => <span>Total</span>,
      cell: (info) => <Text>${info.row.original.totalPrice}</Text>,
    }),
    columnHelper.display({
      id: "orderedAt",
      header: () => <span>Order Date</span>,
      cell: (info) => (
        <Text>
          {new Date(info.row.original.orderedAt).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.display({
      id: "status",
      header: () => <span>Status</span>,
      cell: (info) => (
        <Tag colorScheme={statusColorMap[info.row.original.status]}>
          {info.row.original.status}
        </Tag>
      ),
    }),
  ];

  const [selectedOrder, setSelectedOrder] = useState<GlovePairOrder | null>(
    null
  );

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Glove Tracker"></PageHeader>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Order Details</DrawerHeader>

          <DrawerBody>
            <HStack justify={"space-between"}>
              <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
                ID: {selectedOrder?.id.slice(-10).toUpperCase()}
              </Text>

              <Tag
                colorScheme={
                  selectedOrder?.status === "Preparing"
                    ? "green"
                    : selectedOrder?.status === "InTransit"
                    ? "blue"
                    : "gray"
                }
              >
                {selectedOrder?.status}
              </Tag>
            </HStack>
            <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
              Order Date:{" "}
              {selectedOrder?.orderedAt
                ? new Date(selectedOrder.orderedAt).toLocaleDateString()
                : "N/A"}
            </Text>
            <Text variant={"sm"} fontWeight={"700"} color={"brand.text"}>
              Site: {selectedOrder?.site?.name}
            </Text>
            <Stack mt={2}>
              {selectedOrder?.orderLines.map((line) => (
                <Card variant={"outline"}>
                  <CardBody>
                    <HStack>
                      <Box width={"60%"}>
                        <Tag mx={0.5} size={"sm"}>
                          QTY: {line.quantity}
                        </Tag>
                        <Tag mx={0.5} size={"sm"}>
                          SIZE: {line.size}
                        </Tag>
                        <Tag mx={0.5} size={"sm"}>
                          COLOR: {line.color.toUpperCase()}
                        </Tag>
                      </Box>
                      <Text variant={"md"} mt={2}>
                        Glove Kit
                      </Text>
                    </HStack>
                  </CardBody>
                </Card>
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={orders ?? []}
              onBackClick={() => navigate("/tools/glove-management")}
              title="Glove Kit Orders"
              onRowClick={(row) => {
                if (orders) {
                  setSelectedOrder(orders[row]);
                  onOpen();
                }
              }}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={() => navigate("/tools/glove-management/orders/new")}
                >
                  Order New Gloves
                </Button>
              }
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
