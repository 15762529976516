import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Course, CurriculaProgressReport, Curriculum, MyCertificate } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";
import { toast } from "react-toastify";


export const useUploadCertificate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({data}: {data: MyCertificate}) => {
      const result = await axios.postForm(
        `/api/v2/certificates`,
        data,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['certificates'] });
      toast.success("Successfully uploaded certificate.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error while uploading certificate. Please try again.");
    },
  });
}

export const useDeleteCertificate = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (id: string) => {
      const result = await axios.delete(
        `/api/v2/certificates/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['certificates'] });
      toast.success("Successfully deleted certificate.");
    },
    onError: (error, variables, context) => {
      console.log(error);
      toast.error("Error while deleting certificate. Please try again.");
    },
  });
}