import {
    Box,
    Card,
    CardBody,
    Center,
    Divider,
    HStack,
    Heading,
    Icon,
    Stack,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";

import { FaRegStar } from "react-icons/fa";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../components/page-header";
import { GenericTab } from "./generic-tab";
import { GpsAndTelematicsTab } from "./gps-and-telematics-tab";


export const ConnectionsPage: React.FC = () => {
    const { demoAllProviders } = useFlags();
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const { tab: tabPath } = useParams();

    useEffect(() => {
        const maybeTab = tabs.find(t => t.path === tabPath);
        if (maybeTab) {
            setTabIndex(maybeTab.index);
        }
    }, [])


    const getTabBox = (heading: string = "", subHeading: string = "") => (
        <Box>
            <Heading fontSize={"lg"} mb={2}>
                {heading}
            </Heading>
            <Text variant="md" color={"brand.accent"} fontWeight={400}>
                {subHeading}
            </Text>
        </Box>
    );

    const tabs =
        ([
            {
                name: "GPS & Telematics",
                path: "gps-telematics",
                index: 1,
                component: <GpsAndTelematicsTab demoProviders={(demoAllProviders["gps_telematics"]?.providersList ?? [])} />,
            },
            {
                name: "Battery Management Systems",
                path: "battery-management-systems",
                index: 2,
                component:
                    <GenericTab
                        description={(demoAllProviders["battery_management"]?.description ?? "Coming Soon")}
                        demoProviders={(demoAllProviders["battery_management"]?.providersList ?? [])}
                        header={(demoAllProviders["battery_management"]?.header ?? "Battery Management Systems")}
                    />
            },
            {
                name: "Charge Point Operation Software",
                path: "charge-operations-software",
                index: 3,
                component:
                    <GenericTab
                        description={(demoAllProviders["charge_point_software"]?.description ?? "Coming Soon")}
                        demoProviders={(demoAllProviders["charge_point_software"]?.providersList ?? [])}
                        header={(demoAllProviders["charge_point_software"]?.header ?? "Charge Point Operation Software")}
                    />
            },
            {
                name: "Repair Software",
                path: "repair-software",
                index: 4,
                component:
                    <GenericTab
                        description={(demoAllProviders["repair_software"]?.description ?? "Coming Soon")}
                        demoProviders={(demoAllProviders["repair_software"]?.providersList ?? [])}
                        header={(demoAllProviders["repair_software"]?.header ?? "Repair Software")}
                    />
            },
        ]);

    return (
        <Box height={"100vh"} overflow={"scroll"}>
            <PageHeader title="Connections"></PageHeader>
            <Card variant={"outline"} m={6}>
                <CardBody px={0}>
                    <Stack>
                        <Tabs
                            index={tabIndex}
                            size={"lg"} orientation={"vertical"}
                            variant="soft-rounded"
                            onChange={(index) => {
                                setTabIndex(index);
                                if (index === 0) {
                                    navigate("/connections");
                                }
                                if (index > 0) {
                                    navigate(`/connections/${tabs[index - 1].path}`);
                                }
                            }}
                        >
                            <TabList p={6} width={"35%"}>
                                <Tab key={0} fontSize={"md"} borderRadius={"sm"} justifyContent={"left"} alignContent={"left"} py={"2"}>
                                    <HStack>
                                        <Icon as={FaRegStar} />
                                        <p>How it works</p>
                                    </HStack>
                                </Tab>
                                <Text px="4" py="2" variant="sm" fontWeight={"bold"} color={"brand.accent"}>
                                    Providers
                                </Text>
                                {tabs.map(({ name, index }) => {
                                    return (
                                        <Tab key={index} fontSize={"md"} borderRadius={"md"} justifyContent={"left"} alignContent={"left"} py={"2"}>
                                            {name}
                                        </Tab>
                                    );
                                })}
                            </TabList>
                            <TabIndicator />
                            <Center height="100vh">
                                <Divider orientation="vertical" />
                            </Center>
                            <TabPanels>
                                <TabPanel key={0} pl={4} pr={0} py={0}>
                                    <CardBody>
                                        <VStack key={1} spacing={4} align={"left"}>
                                            {getTabBox(
                                                "Manage your fleet data in one place",
                                                "Our seamless integrations from trusted industry partners enable you to automate fleet operations processes and centralize your fleet data."
                                            )}
                                            {getTabBox(
                                                "1. Connect",
                                                "Select and link your provider to Axle"
                                            )}
                                            {getTabBox(
                                                "2. Sync",
                                                "Build your fleet by syncing data via providers"
                                            )}
                                            {getTabBox(
                                                "3. Capture",
                                                "Automatically collect data, enhancing fleet operations"
                                            )}
                                        </VStack>
                                    </CardBody>
                                </TabPanel>
                                {tabs.map(({ component, index }) => (
                                    <TabPanel key={index} p={0}>
                                        <CardBody>{component}</CardBody>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    );
}