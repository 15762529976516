import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Button,
  ModalFooter,
  useDisclosure,
  Text,
  IconButton,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useFormik, FormikProvider, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { BsPencil } from "react-icons/bs";
import { createColumnHelper } from "@tanstack/react-table";
import { Protocol } from "../../../types";
import { useEffect } from "react";
import { DataTable } from "../../../components/table";
import {
  useCreateProtocol,
  useDeleteProtocol,
  useProtocols,
} from "../../../api/endpoints/protocols";
import { IoDuplicateOutline } from "react-icons/io5";
import { DeleteButton } from "../../../components/delete-button";
import { PlusIcon } from "lucide-react";

export const ProtocolsLibraryTab: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const columnHelper = createColumnHelper<Protocol>();

  const { data, isError, isLoading } = useProtocols();
  const { mutate: deleteProtocol } = useDeleteProtocol();
  const { mutate: createProtocol, data: createProtocolResponse } =
    useCreateProtocol();

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Protocol name is required."),
    description: Yup.string()
      .min(3)
      .required("Protocol description is required."),
    referenceCode: Yup.string().min(3),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      referenceCode: "",
    },
    validationSchema: schema,
    onSubmit: async (values: any) => {
      await createProtocol({ protocol: values });
    },
  });

  useEffect(() => {
    if (createProtocolResponse?.data) {
      navigate(`/doc-library/protocols/${createProtocolResponse?.data}`);
    }
  }, [createProtocolResponse]);

  const columns = [
    columnHelper.accessor((row) => row.referenceCode, {
      id: "referenceCode",
      cell: (info) => {
        const value = info.getValue();
        return <Text>{value}</Text>;
      },
      header: () => <span>Reference Code</span>,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor((row) => row.description, {
      id: "description",
      cell: (info) => {
        return <Text whiteSpace={"normal"}>{info.getValue()}</Text>;
      },
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor((row) => row.version, {
      id: "version",
      cell: (info) => {
        return (
          <HStack>
            <Text>V{info.getValue()} -</Text>
            <Text color={"fg.muted"}>
              {info.row.original.membersAcknowledgedTotal} out of{" "}
              {info.row.original.appliesToMembersTotal} acknowledged.
            </Text>
          </HStack>
        );
      },
      header: () => <span>Latest Version</span>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack color={"brand.accent"} gap={0}>
            <DeleteButton
              title="Delete Evidence"
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete protocol"
              onDelete={() => deleteProtocol({ id: props.row.original.id })}
            />
            <IconButton
              color={"inherit"}
              aria-label="clone protocol"
              icon={<IoDuplicateOutline />}
            />
            <IconButton
              color={"inherit"}
              aria-label="edit protocol"
              onClick={() => navigate(`${props.row.original.id}`)}
              icon={<BsPencil />}
            />
          </HStack>
        );
      },
    }),
  ];
  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Create New Protocol</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.name === true &&
                        !!formik?.touched?.name === true
                      }
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name={"name"}
                        type="text"
                        placeholder="Example Name"
                        value={formik.values.name}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.description === true &&
                        !!formik?.touched?.description === true
                      }
                    >
                      <FormLabel>Reference Code</FormLabel>
                      <Field
                        as={Input}
                        id="referenceCode"
                        name={"referenceCode"}
                        type="text"
                        placeholder="Reference Code"
                        value={formik.values?.referenceCode}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.referenceCode?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <FormControl id="street">
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Input}
                      id="description"
                      name={"description"}
                      type="text"
                      placeholder="Example Description"
                      value={formik.values.description}
                    />
                    <FormErrorMessage>
                      {formik?.errors?.description?.toString()}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={data ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onToggle}
                >
                  Add
                </Button>
              }
              title="Protocols"
              subtitle="Add and manage your company protocols."
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
