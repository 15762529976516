import { Box, Button, Center, Heading, HStack, Icon, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { ProviderIntegrationSummary } from "../../types-new";

import { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { LuUnplug } from "react-icons/lu";
import { useDeleteProviderSecret, useProviders } from "../../api/endpoints";
import { useImportVehiclesFromProvider } from "../../api/endpoints/vehicles";
import { DeleteButton } from "../../components/delete-button";
import { DataTable } from "../../components/table";
import { ConnectProviderModal } from "./connect-provider-modal";


export const GpsAndTelematicsTab: React.FC<{ demoProviders: ProviderIntegrationSummary[] }> = ({ demoProviders }) => {
    const [selectedProviderDetails, setSelectedProviderDetails] = useState<ProviderIntegrationSummary>();
    const { data: providerSummaries } = useProviders();
    const { mutate: disconnectProvider } = useDeleteProviderSecret();
    const { isOpen: connectModalIsOpen, onOpen: connectModalOnOpen, onClose: connectModalOnClose } = useDisclosure();
    const { isOpen: syncModalIsOpen, onOpen: syncModalOnOpen, onClose: syncModalOnClose } = useDisclosure();
    const { mutate: importVehiclesFromProvider, isSuccess: importReqAccepted } = useImportVehiclesFromProvider();

    const createModalOpener = (onModalOpen: () => void) => (provider: ProviderIntegrationSummary) => {
        setSelectedProviderDetails({ ...provider });
        onModalOpen();
    };
    const openConnectModal = createModalOpener(connectModalOnOpen);
    const openSyncModal = createModalOpener(syncModalOnOpen);


    const getProviderIcon = (provider: ProviderIntegrationSummary) => (
        provider.thumbnailImageName || provider.thumbnailImageUrl ?
            <Image
                maxWidth={20}
                src={provider.notImplemented ? provider.thumbnailImageUrl : `/assets/providers/${provider.thumbnailImageName}`}
                alt={`${provider.thumbnailImageName}`}
            />
            :
            <Center width={20} height={20}><Icon as={LuUnplug} boxSize={10} /></Center>
    );

    useEffect(() => {
        if (importReqAccepted) {
            syncModalOnClose();
        }
    }, [importReqAccepted, syncModalOnClose]);

    const getConnectCta = (provider: ProviderIntegrationSummary) => (
        provider.isConnected ?
            <HStack>
                <Button variant="outline" colorScheme="brand.primary" onClick={() => openSyncModal(provider)}>
                    Sync
                </Button>
                <DeleteButton
                    aria-label="disconnect provider"
                    color={"brand.primary.500"}
                    deleteKey={provider.name}
                    deleteButtonText={"Disconnect"}
                    leftIcon={undefined}
                    modalHeader={`Disconnect ${provider.name}`}
                    modalText={`Are you sure you want to disconnect ${provider.name}? This action cannot be undone.`}
                    name={"Disconnect"}
                    onDelete={
                        () => disconnectProvider({ providerId: provider.id ?? "", secretId: provider.secretReferenceId ?? "" })
                    }
                    variant={"outline"}
                >
                    Disconnect
                </DeleteButton>
            </HStack >
            :
            <HStack justify={"flex-end"}>
                <Button width={"100%"} variant="solid" colorScheme="brand.primary" onClick={() => openConnectModal(provider)}>
                    Connect
                </Button>
            </HStack>
    );

    const columnHelper = createColumnHelper<ProviderIntegrationSummary>();
    const columns = [
        columnHelper.display({
            id: "icon",
            cell: ({ row: { original: provider } }) => getProviderIcon(provider),
            footer: (props) => props.column.id,
        }),
        columnHelper.accessor((row) => `${row.name} ${row.description}`, {
            id: "name-desc",
            cell: ({ row: { original: provider } }) => (
                <Box>
                    <HStack gap={3}>
                        <VStack align={"left"}>
                            <HStack gap={1}>
                                {provider.isConnected && <Icon as={HiCheckCircle} color="green.500" boxSize={5} />}
                                <Heading>{provider.name}</Heading>
                            </HStack>
                            <Text color={"brand.accent"} noOfLines={1}>{provider.description}</Text>
                        </VStack>
                        {getConnectCta(provider)}
                    </HStack>
                </Box>
            ),
            footer: (props) => props.column.id,
        }),
    ];

    const getModalContent = () => (
        selectedProviderDetails &&
        (selectedProviderDetails.notImplemented ?
            <ModalContent>
                <ModalHeader>Whoops!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center p={10}>
                        <Text>
                            This feature is currently in private beta. Please contact
                            support@axlemobility.com for assistance.
                        </Text>
                    </Center>
                </ModalBody>
            </ModalContent>
            :
            <ConnectProviderModal onClose={connectModalOnClose} provider={selectedProviderDetails} />
        ));

    const getSyncModalContent = () => (
        selectedProviderDetails &&
        (
            <ModalContent>
                <ModalHeader pr={8}>Axle is already importing your vehicles from {selectedProviderDetails.name}.</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align={"flex-start"} gap={6}>
                        <VStack width={"100%"} align={"flex-start"}>
                            <Text color={"brand.accent"} fontWeight={600}>
                                Vehicle information from {selectedProviderDetails.name} is automatically kept up to date.
                            </Text>
                            <Text color={"brand.accent"}>
                                If you have recently initiated a vehicle import, please wait up to 5 minutes for your vehicle information to load before trying again.
                            </Text>
                            <Text color={"brand.accent"}>
                                If you still need to manually sync data, click the button below:
                            </Text>
                        </VStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant={"solid"}
                        colorScheme="brand.primary"
                        onClick={() => {
                            const dateMinus24Hours = new Date((new Date()).getTime() - (24 * 60 * 60 * 1000));
                            const dateMinus24HoursString = dateMinus24Hours.toUTCString();

                            importVehiclesFromProvider({ providerName: selectedProviderDetails.name, updatedAfter: dateMinus24HoursString })
                        }}
                    >
                        Sync
                    </Button>
                    <Button color="brand.primary.500" onClick={syncModalOnClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        ));

    const providerData = (providerSummaries ?? []).concat(demoProviders ?? []);

    return (
        <Box>
            <Modal isOpen={connectModalIsOpen} onClose={connectModalOnClose} isCentered size={"xl"}>
                <ModalOverlay />
                {getModalContent()}
            </Modal>
            <Modal isOpen={syncModalIsOpen} onClose={syncModalOnClose} isCentered size={"xl"}>
                <ModalOverlay />
                {getSyncModalContent()}
            </Modal>
            <Heading fontSize={"lg"} mb={2}>
                GPS & Telematics
            </Heading>
            <Text variant="md" color={"brand.accent"} fontWeight={400}>
                Connect your GPS & Telematics solutions to automatically sync your vehicles and capture data,
                making it easier to keep up with preventive maintenance and respond quickly to vehicle issues.
            </Text>
            <Text variant="md" color={"brand.accent"} mt={4}>
                Providers with an established connection will automatically keep vehicle data up to date.
            </Text>
            <DataTable
                columns={columns}
                data={providerData}
                suppressHeaderRow={true}
                suppressPaginationFooter={true}
            />
        </Box>
    );
}