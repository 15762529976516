import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Project } from "../../types";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { ApiServiceErr, ParamOptions } from ".";

export const useProjects = () => {
    const { getToken } = useAuth();
    return useQuery<Project[], ApiServiceErr>({
      queryKey: ["projects"],
      queryFn:  async () => {
       const result =  await axios.get(
          `/api/projects`,
          {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
        );

        return result.data;
      }
    });
}

export const useProject = (id: string) => {
  const { getToken } = useAuth();
  return useQuery<Project, ApiServiceErr>({
    queryKey: ["projects", id],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/projects/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useCreateProject = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {name: string, basedOnFrameworkId: string }) => {
      return await axios.post(
        `/api/projects`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useDeleteProject = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/projects/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}

export const useUpdateProjectControlStatus = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({projectId, controlId, body}: {projectId: string, controlId: string, body: {isApplicable: boolean, evidence: string[], protocols: string[], ownerId: string[]}}) => {
      return await axios.put(
        `/api/projects/${projectId}/controls/${controlId}/status`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error, variables, context) => {
      console.log(error);
    },
  });
}