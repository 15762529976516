import { SetStateAction, useEffect } from "react";
import { Dispatch } from "redux";
import { Box } from "@chakra-ui/react";

interface LessonBlockProps {
  id: string;
  attributes: {
    required: boolean;
    start: number;
    end: number;
    data: string;
  };
  setIsValid: (v: boolean) => unknown;
  setIsAnswered: (v: boolean) => unknown;
  setValidationErr: (v: string) => unknown;
  showNextBtn: (v: boolean) => unknown;
  isActive: boolean;
  val: any;
  setVal: Dispatch<SetStateAction<any>>;
  next: () => void;
}

const LessonBlock = ({
  id,
  attributes,
  setIsValid,
  setIsAnswered,
  setValidationErr,
  showNextBtn,
  isActive,
  val,
  setVal,
  next,
}: LessonBlockProps) => {
  useEffect(() => {
    setVal("watched");
    setValidationErr("");
    showNextBtn(true);
    setIsAnswered(true);
  }, []); // eslint-disable-line
  return <Box className={"lesson-block"}></Box>;
};
export default LessonBlock;
