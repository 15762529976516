import {
  Box,
  Card,
  CardBody,
  HStack,
  IconButton,
  Link,
  Text,
  Stack,
  Button,
  SimpleGrid,
  BoxProps,
  Icon,
  Heading,
  Badge,
  StackDivider,
  chakra,
  Textarea,
} from "@chakra-ui/react";
import { PageHeader } from "../../components/page-header";
import {
  FiArrowDownRight,
  FiArrowUpRight,
  FiChevronRight,
  FiMoreVertical,
  FiX,
} from "react-icons/fi";
import { useContext, useEffect, useMemo, useState } from "react";
import { useOrganization, useUser } from "@clerk/clerk-react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { BatteryMedium, CrossIcon, Plug2, TruckIcon } from "lucide-react";
import { AreaChart, LineChart } from "@saas-ui/charts";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Action } from "../../types";
import { Reorder } from "framer-motion";
import { Select } from "chakra-react-select";
import { SettingsContext } from "../..";
import React from "react";
import { Stat } from "../../components/stat";

const valueFormatter = (value: number) => {
  return `${value}%`;
};

const List = chakra(Reorder.Group);
const ListItem = chakra(Reorder.Item);

const trucks = [
  {
    id: 0,
    name: "Truck 1",
    location: {
      longitude: -118.4085,
      latitude: 33.9416,
    },
    enrouteTo: "1 Manchester Boulevard, Inglewood, CA 90301",
    battery: {
      stateOfCharge: 0.2,
      stateOfHealth: 0.8,
    },
  },
  {
    id: 1,
    name: "Truck 2",
    location: {
      longitude: -118.0292,
      latitude: 34.0686,
    },
    enrouteTo: "3400 Flair Dr, El Monte, CA 91731",
    battery: {
      stateOfCharge: 0.3,
      stateOfHealth: 0.7,
    },
  },
  {
    id: 2,
    name: "Truck 3",
    enrouteTo: "6801 Hollywood Blvd, Hollywood, CA 90028",
    location: {
      longitude: -118.3617,
      latitude: 34.09,
    },
    battery: {
      stateOfCharge: 0.4,
      stateOfHealth: 0.6,
    },
  },
  {
    id: 3,
    name: "Truck 4",
    enrouteTo: "1333 2nd St, Santa Monica, CA 90401",
    location: {
      longitude: -118.4912,
      latitude: 34.0195,
    },
    battery: {
      stateOfCharge: 0.5,
      stateOfHealth: 0.5,
    },
  },
  {
    id: 4,
    name: "Truck 5",
    enrouteTo: "205 S Willowbrook Ave, Compton, CA 90220",
    location: {
      longitude: -118.23,
      latitude: 34.0034,
    },
    battery: {
      stateOfCharge: 0.6,
      stateOfHealth: 0.4,
    },
  },
];

const chargers = [
  {
    id: 0,
    name: "Downtown LA Charger",
    location: {
      longitude: -118.2437,
      latitude: 34.0522,
    },
    type: "DC Fast Charger",
    status: "Available",
  },
  {
    id: 1,
    name: "Santa Monica Pier Charger",
    location: {
      longitude: -118.4968,
      latitude: 34.0095,
    },
    type: "Level 2 Charger",
    status: "In Use",
  },
];

const NewWebinarBanner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box borderBottomWidth="1px" bg="bg.surface" width={"100%"}>
      <Box py="3">
        <HStack
          spacing={{ base: "3", md: "4" }}
          justify={{ base: "start", md: "space-between" }}
        >
          <Box boxSize="8" display={{ base: "none", md: "block" }} />
          <Text color="fg.emphasized" fontWeight="medium">
            🤝 Join our{" "}
            <Link
              href="#"
              color={"blue.500"}
              fontWeight={"bold"}
              textDecoration={"underline"}
            >
              live webinar on{" "}
              {new Date(
                (() => {
                  const today = new Date();
                  const oneMonthLater = new Date(
                    today.setMonth(today.getMonth() + 1)
                  );
                  while (
                    oneMonthLater.getDay() === 0 ||
                    oneMonthLater.getDay() === 6
                  ) {
                    oneMonthLater.setDate(oneMonthLater.getDate() + 1);
                  }
                  return oneMonthLater.getTime();
                })()
              ).toLocaleDateString()}
            </Link>{" "}
            to understand Electric Vehicle (EV) total cost of ownership.
          </Text>
          <IconButton
            icon={<FiX />}
            variant="tertiary"
            aria-label="Close banner"
            onClick={onClose}
          />
        </HStack>
      </Box>
    </Box>
  );
};

const NewRegulationsBanner: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <Box borderBottomWidth="1px" bg="orange.100" width={"100%"}>
      <Box py="3">
        <HStack
          spacing={{ base: "3", md: "4" }}
          justify={{ base: "start", md: "space-between" }}
          px={5}
        >
          <Box boxSize="8" display={{ base: "none", md: "block" }} />
          <Text color="fg.emphasized" fontWeight="medium">
            See updates to the CARB compliance policy.
          </Text>
          <Button variant={"solid"} colorScheme={"orange"} onClick={onClose}>
            Review
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

const NewDocumentBanner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box borderBottomWidth="1px" bg="orange.100" width={"100%"}>
      <Box py="3">
        <HStack
          spacing={{ base: "3", md: "4" }}
          justify={{ base: "start", md: "space-between" }}
          px={5}
        >
          <Text color="fg.emphasized" fontWeight="medium">
            New document available.
          </Text>
          <Button variant={"solid"} colorScheme={"orange"} onClick={onClose}>
            Review
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

const generateUptimeData = () => {
  const data = [];
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setMonth(startDate.getMonth() - 2);

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    let uptime = 99.9;

    // Occasionally introduce a dip to around 50%
    if (Math.random() < 0.05) {
      uptime = 50 + Math.random() * 10;
    } else {
      // Small variations around 99.9%
      uptime = 99.9 + (Math.random() - 0.5) * 0.2;
    }

    data.push({
      date: d.toLocaleString("default", { month: "2-digit", day: "2-digit" }),
      "EVSE Uptime": parseFloat(uptime.toFixed(2)),
      SLA: 95,
    });
  }

  return data;
};

const generateReportDates = () => {
  const today = new Date();

  return [
    new Date(new Date().setDate(new Date().getDate() - 2)),
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
    new Date(new Date().setMonth(new Date().getMonth() + 2)),
  ];
};

export const FoundationPage: React.FC = () => {
  const [isNewRegulationsBannerOpen, setIsNewRegulationsBannerOpen] =
    useState(true);
  const [selectedTruck, setSelectedTruck] = useState<number | null>(null);
  const [selectedCharger, setSelectedCharger] = useState<number | null>(null);
  const [isNewWebinarBannerOpen, setIsNewWebinarBannerOpen] = useState(true);
  const { demoTasks } = useFlags();

  const [truncatedTasks, setTruncatedTasks] = useState<Action[]>([]);

  const [order, setOrder] = useState<string[]>([]);

  useEffect(() => {
    setTruncatedTasks(demoTasks.slice(0, 4));
  }, [demoTasks]);

  useEffect(() => {
    setOrder(truncatedTasks.map((task) => task.id));
  }, [truncatedTasks]);

  const user = useUser();
  const { organization } = useOrganization();
  const navigate = useNavigate();

  const generateChartData = () => {
    const today = new Date();
    const twoMonthsAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 2,
      today.getDate()
    );
    const data = [];
    let currentDate = new Date(twoMonthsAgo);
    let previousPercentage = 0;
    while (currentDate <= today) {
      const dayDifference = Math.floor(
        (currentDate.getTime() - twoMonthsAgo.getTime()) / (1000 * 60 * 60 * 24)
      );
      const totalDays = Math.floor(
        (today.getTime() - twoMonthsAgo.getTime()) / (1000 * 60 * 60 * 24)
      );
      let percentage;

      if (currentDate.getTime() === today.getTime()) {
        percentage = 40;
      } else {
        percentage = Math.floor((dayDifference / totalDays) * 40);
        // Add some randomness to make the data more realistic, but ensure it never decreases
        percentage += Math.floor(Math.random() * 3);
        percentage = Math.max(previousPercentage, Math.min(40, percentage));
      }

      data.push({
        date: currentDate.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        }),
        Drivers: percentage,
        Technicians: percentage * (0.5 + Math.floor(data.length / 10) * 0.1),
      });

      previousPercentage = percentage;
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return data;
  };

  const { mapBoxAccessToken } = useContext(SettingsContext);

  const chartData = useMemo(() => generateChartData(), []);
  const generateRecentDate = () => {
    const today = new Date();
    const randomDays = Math.floor(Math.random() * 90);
    const recentDate = new Date(today.setDate(today.getDate() - randomDays));
    return recentDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const documents = [
    {
      id: "1",
      title: "Inspection of High Voltage Cables and Connectors",
      publishedAt: generateRecentDate(),
      description:
        "This comprehensive guide outlines the proper procedures for inspecting high voltage cables and connectors in electric vehicles. It covers safety protocols, necessary equipment, common issues to look for, and best practices for maintaining the integrity of these critical components. The document emphasizes the importance of regular inspections to prevent potential hazards and ensure optimal vehicle performance.",
    },
    {
      id: "2",
      title: "Personnel Management During Lock-Out / Tag-Out",
      publishedAt: generateRecentDate(),
      description:
        "This document provides detailed guidelines for managing personnel during lock-out/tag-out procedures in EV maintenance facilities. It covers the roles and responsibilities of different team members, communication protocols, safety measures, and step-by-step instructions for implementing and removing lock-out/tag-out devices. The guide also includes best practices for training staff and maintaining compliance with relevant safety regulations.",
    },
    {
      id: "3",
      title: "Fire Suppression System Testing in EV Parking Areas",
      publishedAt: generateRecentDate(),
      description:
        "This comprehensive manual outlines the procedures for testing and maintaining fire suppression systems in electric vehicle parking areas. It covers different types of fire suppression systems, testing frequencies, documentation requirements, and specific considerations for EV-related fire risks. The guide also includes troubleshooting tips, emergency response protocols, and recommendations for system upgrades to meet evolving safety standards.",
    },
  ];
  const uptimeData = useMemo(() => generateUptimeData(), []);

  const reportDates = useMemo(() => generateReportDates(), []);

  return (
    <Box height={"100vh"} overflow={"scroll"}>
      <PageHeader title="Foundation"></PageHeader>
      {isNewRegulationsBannerOpen && (
        <NewRegulationsBanner
          onClose={() => setIsNewRegulationsBannerOpen(false)}
        />
      )}
      {isNewWebinarBannerOpen && (
        <NewWebinarBanner onClose={() => setIsNewWebinarBannerOpen(false)} />
      )}
      <Stack p={5}>
        <Card direction={{ base: "row", sm: "row" }} variant="outline">
          <CardBody>
            <Stack gap={4}>
              <Card variant={"outline"}>
                <CardBody>
                  <Stack
                    gap={0}
                    direction={"row"}
                    width={"100%"}
                    justify={"space-between"}
                    align={"center"}
                  >
                    <Text fontSize={"lg"} fontWeight={"bold"}>
                      Welcome, {user.user?.firstName}!
                    </Text>
                    <Stack spacing={0}>
                      <Text fontSize={"sm"} color={"fg.muted"}>
                        Site: {organization?.name}
                      </Text>
                      <Text fontSize={"sm"} color={"fg.muted"}>
                        {new Date().toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Text>
                    </Stack>
                  </Stack>
                </CardBody>
              </Card>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <Stack>
                  <HStack justify={"space-between"}>
                    <Card variant={"outline"} width={"50%"}>
                      <CardBody>
                        <Stat
                          label="Total Electric Vehicles"
                          value="5"
                          delta={{ value: "+1", isUpwardsTrend: true }}
                        />
                      </CardBody>
                    </Card>
                    <Card variant={"outline"} width={"50%"}>
                      <CardBody>
                        <Stat
                          label="Total Electric Vehicle Supply Equipment"
                          value="2"
                          delta={{ value: "+1", isUpwardsTrend: true }}
                        />
                      </CardBody>
                    </Card>
                  </HStack>
                  <Card variant={"outline"}>
                    <CardBody>
                      <Text
                        textStyle="sm"
                        color="fg.muted"
                        mb={2}
                        fontWeight={"bold"}
                      >
                        EVSE Uptime
                      </Text>

                      <LineChart
                        data={uptimeData}
                        categories={["EVSE Uptime", "SLA"]}
                        yAxisWidth={80}
                        height="300px"
                        colors={["#204B36", "#22C55E"]}
                      />
                    </CardBody>
                  </Card>
                </Stack>
                <Card variant={"outline"}>
                  <CardBody pb={10}>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      EV/EVSE Locations
                    </Text>
                    <Map
                      initialViewState={{
                        longitude: -118.2437,
                        latitude: 34.0522,
                        zoom: 9,
                      }}
                      mapboxAccessToken={mapBoxAccessToken}
                      mapStyle="mapbox://styles/mapbox/navigation-day-v1"
                      style={{ width: "100%", height: "100%" }}
                    >
                      {selectedTruck !== null && (
                        <Popup
                          longitude={trucks[selectedTruck].location.longitude}
                          latitude={trucks[selectedTruck].location.latitude}
                          onClose={() => {
                            setSelectedTruck(null);
                            setSelectedCharger(null);
                          }}
                          closeOnClick={false}
                        >
                          <Stack>
                            <Text fontWeight={"bold"}>
                              Enroute to: {trucks[selectedTruck].enrouteTo}
                            </Text>
                            <HStack>
                              <HStack>
                                <Icon as={BatteryMedium} />
                                <Text>
                                  {trucks[selectedTruck].battery.stateOfCharge *
                                    100}
                                  %
                                </Text>
                              </HStack>
                              <HStack>
                                <Icon as={CrossIcon} />
                                <Text>
                                  {trucks[selectedTruck].battery.stateOfHealth *
                                    100}
                                  %
                                </Text>
                              </HStack>
                            </HStack>
                          </Stack>
                        </Popup>
                      )}
                      {selectedCharger !== null && (
                        <Popup
                          longitude={
                            chargers[selectedCharger].location.longitude
                          }
                          latitude={chargers[selectedCharger].location.latitude}
                          onClose={() => {
                            setSelectedTruck(null);
                            setSelectedCharger(null);
                          }}
                          closeOnClick={false}
                        >
                          <Text fontWeight="bold">
                            {chargers[selectedCharger].name}
                          </Text>
                          <Stack>
                            <Text>Type: {chargers[selectedCharger].type}</Text>
                            <HStack>
                              <Text>Status:</Text>
                              <Box
                                w={3}
                                h={3}
                                borderRadius="full"
                                bg={
                                  chargers[selectedCharger].status ===
                                  "Available"
                                    ? "green.500"
                                    : "red.500"
                                }
                              />
                              <Text>{chargers[selectedCharger].status}</Text>
                            </HStack>
                          </Stack>
                        </Popup>
                      )}
                      {trucks.map((truck, index) => (
                        <Marker
                          key={index}
                          longitude={truck.location.longitude}
                          latitude={truck.location.latitude}
                          onClick={() => {
                            setSelectedCharger(null);
                            setSelectedTruck(index);
                          }}
                        >
                          <Box
                            bg="white"
                            borderRadius="full"
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon as={TruckIcon} boxSize={6} color="black" />
                          </Box>
                        </Marker>
                      ))}
                      {chargers.map((charger, index) => (
                        <Marker
                          key={index}
                          longitude={charger.location.longitude}
                          latitude={charger.location.latitude}
                          onClick={() => {
                            setSelectedTruck(null);
                            setSelectedCharger(index);
                          }}
                        >
                          <Box
                            bg="white"
                            borderRadius="full"
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon as={Plug2} boxSize={6} color="black" />
                          </Box>
                        </Marker>
                      ))}
                    </Map>
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      Training Progress
                    </Text>

                    <AreaChart
                      data={chartData}
                      categories={["Drivers", "Technicians"]}
                      valueFormatter={valueFormatter}
                      yAxisWidth={80}
                      colors={["#204B36", "#2F6F50"]}
                      height="300px"
                    />
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      Safety & Compliance
                    </Text>
                    {/* <Card variant={"outline"} width={"fit-content"}>
                        <CardBody p={1}>
                          <HStack justify={"space-between"} mb={5}>
                            <Box
                              borderRadius={"full"}
                              borderColor={"brand.primary.400"}
                              borderWidth={2}
                              padding={1}
                              width={"fit-content"}
                            >
                              <Image
                                height={6}
                                width={6}
                                src={carbLogo}
                                alt="CARB Logo"
                                borderRadius={"full"}
                              />
                            </Box>
                            <Badge
                              variant={"outline"}
                              bg={"brand.primary.500"}
                              color={"brand.surface"}
                              size={"sm"}
                            >
                              CARB Regulations
                            </Badge>
                          </HStack>
                          <CircularProgress
                            value={60}
                            color={"brand.primary.500"}
                            size={40}
                            mx={5}
                          >
                            <CircularProgressLabel
                              fontSize={40}
                              fontWeight={"bold"}
                            >
                              60%
                            </CircularProgressLabel>
                          </CircularProgress>
                          <HStack spacing={0} gap={1} mt={5}>
                            <Box
                              bg={"brand.primary.500"}
                              borderRadius={"full"}
                              width={6}
                              height={6}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Icon
                                as={LayoutDashboard}
                                color={"brand.surface"}
                              />
                            </Box>
                            <Stack gap={0} spacing={0}>
                              <Text
                                fontSize={"sm"}
                                fontWeight={"bold"}
                                lineHeight={0.75}
                              >
                                55/91
                              </Text>
                              <Text fontSize={"xs"}>Requirements</Text>
                            </Stack>
                          </HStack>
                        </CardBody>
                      </Card> */}

                    <Card variant="outline">
                      <CardBody>
                        <Text
                          textStyle="sm"
                          color="fg.muted"
                          mb={2}
                          fontWeight="bold"
                        >
                          Inspection Reports Due
                        </Text>
                        <HStack justify="space-between" align="center">
                          <Stack
                            justify="space-between"
                            align="center"
                            width="100%"
                            divider={<StackDivider />}
                          >
                            {reportDates.map((date, index) => (
                              <HStack
                                key={index}
                                width={"100%"}
                                justify={"space-between"}
                              >
                                <Text fontSize="lg" fontWeight="medium">
                                  {date.toLocaleString("default", {
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </Text>
                                <HStack>
                                  <Badge
                                    colorScheme={
                                      new Date(date) < new Date()
                                        ? "red"
                                        : "green"
                                    }
                                  >
                                    {new Date(date) < new Date()
                                      ? "Overdue"
                                      : "Up Next"}
                                  </Badge>
                                  <Icon
                                    as={FiChevronRight}
                                    boxSize={5}
                                    color="fg.muted"
                                  />
                                </HStack>
                              </HStack>
                            ))}
                          </Stack>
                        </HStack>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      Site Operations
                    </Text>
                    <HStack width={"100%"} justify={"space-between"} mb={5}>
                      <Text color={"fg.muted"} variant={"xs"}>
                        Pending Tasks: {demoTasks.length}
                      </Text>
                      <Text color={"fg.muted"} variant={"xs"}>
                        Last Updated:{" "}
                        {new Date(
                          new Date().setMonth(new Date().getMonth() - 2)
                        ).toLocaleString("default", {
                          month: "long",
                          year: "numeric",
                        })}
                      </Text>
                    </HStack>

                    <List
                      values={order}
                      //@ts-ignore
                      onReorder={setOrder}
                      listStyleType="none"
                    >
                      <Stack spacing="3" width="full">
                        {order
                          .map((item: string) =>
                            truncatedTasks.find(
                              (demoTask: Action) => demoTask.id === item
                            )
                          )
                          //@ts-ignore
                          .map((demoTask: Action, index: number) =>
                            demoTask ? (
                              <ListItem
                                key={demoTask.id}
                                value={demoTask.id}
                                bg="bg.surface"
                                p="4"
                                borderRadius="lg"
                                border={"1px"}
                                borderColor={"brand.gray.200"}
                                cursor="grab"
                                whileTap={{ cursor: "grabbing", scale: 1.1 }}
                              >
                                <Stack shouldWrapChildren spacing="4">
                                  <Text textStyle="sm" fontWeight="medium">
                                    {demoTask.name}
                                  </Text>
                                  <HStack justify="space-between">
                                    <Badge
                                      colorScheme={
                                        demoTask.priority === "High"
                                          ? "red"
                                          : "green"
                                      }
                                      size="sm"
                                    >
                                      {demoTask.priority}
                                    </Badge>
                                    <HStack spacing="3">
                                      <Text
                                        textStyle="xs"
                                        color="fg.subtle"
                                        fontWeight="medium"
                                      >
                                        OPS-{index + 1}
                                      </Text>
                                    </HStack>
                                  </HStack>
                                </Stack>
                              </ListItem>
                            ) : null
                          )}
                      </Stack>
                    </List>
                    <Button
                      mt={5}
                      rightIcon={<FiChevronRight />}
                      variant={"inverted"}
                      size={"sm"}
                      width={"100%"}
                      onClick={() => {
                        navigate("/my/tasks");
                      }}
                    >
                      View All Tasks ({demoTasks.length})
                    </Button>
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      Document Library
                    </Text>
                    <NewDocumentBanner onClose={() => {}} />
                    <Stack height={"100%"} mt={10}>
                      <Stack divider={<StackDivider />} spacing="4">
                        {documents.map((document) => (
                          <Stack key={document.id} fontSize="sm" spacing="0.5">
                            <Box>
                              <Text fontWeight="medium" color="fg.emphasized">
                                {document.title}
                              </Text>
                              <Text color="fg.subtle">
                                Published {document.publishedAt}
                              </Text>
                            </Box>
                            <Text
                              color="fg.muted"
                              sx={{
                                "-webkit-box-orient": "vertical",
                                "-webkit-line-clamp": "2",
                                overflow: "hidden",
                                display: "-webkit-box",
                              }}
                            >
                              {document.description}
                            </Text>
                          </Stack>
                        ))}
                      </Stack>
                      <Button
                        mt={5}
                        rightIcon={<FiChevronRight />}
                        variant={"inverted"}
                        size={"sm"}
                        width={"100%"}
                        alignSelf="flex-end"
                        onClick={() => {
                          navigate("/doc-library/protocols");
                        }}
                      >
                        View All (65)
                      </Button>
                    </Stack>
                  </CardBody>
                </Card>
                <Card variant={"outline"}>
                  <CardBody>
                    <Text
                      textStyle="sm"
                      color="fg.muted"
                      mb={2}
                      fontWeight={"bold"}
                    >
                      Get Help
                    </Text>
                    <Select
                      id="help-type"
                      name="help-type"
                      options={[
                        {
                          label: "Technical Support",
                          value: "technical-support",
                        },
                        { label: "Safety Guidance", value: "safety-guidance" },
                        {
                          label: "Regulatory Compliance",
                          value: "regulatory-compliance",
                        },
                        {
                          label: "Training Resources",
                          value: "training-resources",
                        },
                      ]}
                      closeMenuOnSelect={false}
                      placeholder="Select the type of help you need"
                    />
                    <Textarea
                      mt={5}
                      placeholder="Enter your message here..."
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #E2E8F0",
                        resize: "vertical",
                      }}
                    />
                    <Button
                      mt={5}
                      variant={"inverted"}
                      size={"sm"}
                      width={"100%"}
                    >
                      Submit
                    </Button>
                  </CardBody>
                </Card>
              </SimpleGrid>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
