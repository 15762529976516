import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Progress,
  Skeleton,
  Stack,
  Tag,
  Text,
  chakra,
} from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./course-viewer.css";
import "@quillforms/renderer-core/build-style/style.css";
import { registerBlocks } from "./blocks";
import { useCourse, useUpdateCourseAttempt } from "../../api/endpoints/courses";
import { useMyCourses } from "../../api/endpoints/my";
import { BsArrowLeft } from "react-icons/bs";
import {
  HiAnnotation,
  HiArrowCircleDown,
  HiArrowCircleRight,
  HiArrowCircleUp,
  HiArrowDown,
  HiCheckCircle,
  HiInformationCircle,
  HiLockClosed,
  HiOutlineDownload,
  HiPlay,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Vimeo from "@u-wave/react-vimeo";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import { useAuth, useUser } from "@clerk/clerk-react";
import { SettingsContext } from "../..";
import { useZoomSignature } from "../../api/endpoints/zoom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { InfoIcon } from "lucide-react";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

type MeetingResponse = {
  data: Data;
  message: null;
  success: boolean;
};

type Data = {
  meeting: Meeting;
};

type Meeting = {
  meetingId: string;
  passcode: string;
};

async function initClient({
  meetingId,
  userEmail,
  userName,
  passcode,
  signature,
  sdkKey,
  setIsZoomInitialized,
}: Meeting & {
  userName: string;
  userEmail: string;
  signature: string;
  sdkKey: string;
  setIsZoomInitialized: (isZoomInitialized: boolean) => void;
}) {
  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareWebSDK();

  const element = document.getElementById("zmmtg-root");
  if (!element) {
    return;
  }
  const leaveURL = window.location.href;
  ZoomMtg.init({
    leaveUrl: leaveURL,
    patchJsMedia: true,

    success: (success: any) => {
      ZoomMtg.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: meetingId,
        userName: userName,
        userEmail: userEmail,
        passWord: passcode,
        success: (success: any) => {
          setIsZoomInitialized(true);
        },
        error: (error: any) => {
          console.log(error);
          setIsZoomInitialized(false);
        },
      });
    },
    error: (error: any) => {
      console.log(error);
      setIsZoomInitialized(false);
    },
  });
}

const TableOfContents = ({
  blocks,
  currentBlockId,
  goToBlock,
  maxIndex,
}: {
  blocks: any[];
  currentBlockId: string;
  maxIndex: number;
  goToBlock: (blockId: string) => void;
}) => {
  const condensedContents: any[] = [];

  let currentQuizGroup = null;

  const currentIndex = blocks.findIndex((x) => x.id === currentBlockId);

  for (let i = 0; i < blocks?.length; i++) {
    const currentItem = blocks[i];

    if (currentItem.name === "lesson") {
      if (currentQuizGroup) {
        condensedContents.push(currentQuizGroup);
        currentQuizGroup = null;
      }
      condensedContents.push({
        type: "lesson",
        index: i,
        indexId: currentItem.id,
        name: currentItem.label,
        additionalData: currentItem.additionalData,
      });
    } else if (currentItem.name === "multiple-choice") {
      if (!currentQuizGroup) {
        console.log(currentItem.additionalData);
        currentQuizGroup = {
          type: "quiz",
          startIndex: i,
          startIndexId: currentItem.id,
          endIndex: i,
          completed: false,
          name: currentItem.nameOverride ?? "Knowledge Check",
        };
      } else {
        currentQuizGroup.endIndex = i;
      }
    }
  }

  // Add the last quiz group if any
  if (currentQuizGroup) {
    condensedContents.push(currentQuizGroup);
  }

  return (
    <Stack gap={0} width={"100%"}>
      {condensedContents
        .filter((content) => !content?.additionalData?.isOptional)
        .map((content, i) => {
          const isLesson = content.type === "lesson";
          const isQuiz = content.type === "quiz";

          const isLessonLocked =
            currentIndex < (content?.index ?? 0) &&
            (content.index ?? 0) > maxIndex;

          const isLessonCompleted =
            currentIndex >= (content?.index ?? 0) || currentIndex < maxIndex;
          const isQuizCompleted =
            currentIndex >= (content?.startIndex ?? 0) ||
            currentIndex < maxIndex;
          const isQuizLocked =
            currentIndex < (content?.startIndex ?? 0) &&
            (content?.startIndex ?? 0) > maxIndex;

          return (
            <HStack
              p={5}
              key={i}
              bg={
                content?.indexId?.toString() === currentBlockId
                  ? "gray.100"
                  : "none"
              }
              borderRadius={"1rem"}
              justifyContent={"space-between"}
              onClick={() => {
                if (
                  content.type === "lesson" &&
                  content?.indexId &&
                  (content.index ?? 0) < maxIndex
                ) {
                  goToBlock(content?.indexId);
                }
                if (
                  content.type === "quiz" &&
                  (content.startIndex ?? 0) < maxIndex
                ) {
                  goToBlock(content?.startIndexId?.toString() ?? "");
                }
              }}
              _hover={{
                cursor:
                  (isLesson && (content.index ?? 0) > maxIndex) ||
                  (isQuiz && (content?.startIndex ?? 0) > maxIndex)
                    ? "initial"
                    : "pointer",
              }}
            >
              <HStack
                justifyContent={"space-between"}
                display={"flex"}
                width={"100%"}
              >
                {isLesson &&
                  isLessonCompleted &&
                  (content.index ?? 0) <= maxIndex && (
                    <HiPlay fill="green" size={40} />
                  )}
                {((isLesson && isLessonLocked) || (isQuiz && isQuizLocked)) &&
                  (content.index ?? 0) > maxIndex && (
                    <HiLockClosed fill="gray" size={40} />
                  )}
                {isQuiz && isQuizCompleted && (
                  <HiQuestionMarkCircle fill="green" size={40} />
                )}
                {isQuiz && isQuizLocked && (
                  <HiLockClosed fill="gray" size={40} />
                )}
                <Text
                  fontWeight="600"
                  color="fg.emphasized"
                  display={"inline-block"}
                  width={"80%"}
                  overflow={"wrap"}
                >
                  {content.name}
                </Text>
                {((isLesson &&
                  ((content.index ?? 0) < currentIndex ||
                    (content?.index ?? 0) < maxIndex)) ||
                  (isQuiz && (content.endIndex ?? 0) < maxIndex) ||
                  currentIndex > maxIndex) && (
                  <HiCheckCircle
                    fill="green"
                    size={40}
                    style={{ marginLeft: "auto", alignItems: "end" }}
                  />
                )}
              </HStack>
            </HStack>
          );
        })}
      <Accordion allowToggle>
        <AccordionItem border={"none"}>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                _expanded={{ bg: "gray.100", color: "white" }}
                border={"none"}
                justifyContent={"space-between"}
              >
                {isExpanded ? (
                  <HiArrowCircleUp fill="green" size={40} />
                ) : (
                  <HiInformationCircle fill="green" size={40} />
                )}
                <Text
                  fontWeight="600"
                  color="fg.emphasized"
                  width={"80%"}
                  textAlign={"left"}
                >
                  Additional Refresher Material (Optional)
                </Text>
              </AccordionButton>
              <AccordionPanel>
                {/* @ts-ignore */}
                {condensedContents
                  .filter((content) => content?.additionalData?.isOptional)
                  .map((content, i) => (
                    <HStack
                      py={5}
                      key={i}
                      bg={
                        content?.indexId?.toString() === currentBlockId
                          ? "gray.100"
                          : "none"
                      }
                      borderRadius={"1rem"}
                      justifyContent={"space-between"}
                      onClick={() => {
                        if (content?.indexId) {
                          goToBlock(content?.indexId);
                        }
                      }}
                      _hover={{
                        cursor: "pointer",
                      }}
                    >
                      <HiInformationCircle fill="#4d6f5e" size={40} />
                      <Text
                        fontWeight="600"
                        color="fg.emphasized"
                        display={"inline-block"}
                        width={"80%"}
                        overflow={"wrap"}
                      >
                        {content.name}
                      </Text>
                    </HStack>
                  ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

const ContentViewer = ({
  blocks,
  currentBlockId,
  setValid,
  setTouched,
}: {
  blocks: any[];
  currentBlockId: string;
  setValid: (valid: boolean) => void;
  setTouched: (touched: boolean) => void;
}) => {
  const currentBlock = blocks.find((x) => x.id === currentBlockId);

  const [selectedAnswer, setSelectedAnswer] = useState("");

  const [timeWatched, setTimeWatched] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [isZoomInitialized, setIsZoomInitialized] = useState(false);
  const { user } = useUser();
  const { zoomSdkKey } = useContext(SettingsContext);
  const { mutate: getSignature, data: signature } = useZoomSignature();
  const { lessonContent } = useFlags() as {
    lessonContent: { key: string; value: string }[];
  };

  useEffect(() => {
    return () => {
      if (isZoomInitialized) {
        ZoomMtg.leaveMeeting({
          confirm: false,
          error: (error: any) => {
            console.log(error);
          },
        });
      }
    };
  }, []);

  useEffect(() => {
    if (signature) {
      initClient({
        meetingId: currentBlock?.additionalData?.zoomMeetingId,
        passcode: currentBlock?.additionalData?.zoomToken,
        userName: user?.fullName ?? "",
        userEmail: user?.emailAddresses[0]?.emailAddress ?? "",
        signature: signature,
        sdkKey: zoomSdkKey,
        setIsZoomInitialized,
      });
    }
  }, [signature]);

  const initZoomApp = async (meetingId: string, passcode: string) => {
    await getSignature({
      meetingNumber: meetingId,
    });
  };

  const onVideoTimeUpdate = useCallback(
    (e: any) => {
      if (e.seconds - 1 < timeWatched && e.seconds > timeWatched) {
        setTimeWatched(e.seconds);
      }
    },
    [timeWatched]
  );

  const onVideoSeeked = useCallback(
    (e: any) => {
      if (timeWatched < e.seconds) {
        setStartTime(timeWatched);
      }
    },
    [timeWatched]
  );

  const handleAnswer = (value: string) => {
    setSelectedAnswer(value);
    setTouched(true);

    if (currentBlock?.correctAnswer !== value) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  useEffect(() => {
    setSelectedAnswer("");
  }, [currentBlockId]);

  const vimeoRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)/i;
  const vimeoMatch = currentBlock?.html?.match(vimeoRegex);

  const mp3Regex = /<audio[^>]*src="(https?:\/\/[^"]+\.mp3)"[^>]*><\/audio>/i;
  const mp3Match = currentBlock?.html?.match(mp3Regex);

  const removeFigureTags = (html: string) => {
    return html.replace(/<figure[^>]*>.*?<\/figure>/gis, "");
  };

  const sanitizedAudioLessonHtml = currentBlock?.html
    ? removeFigureTags(currentBlock.html)
    : "";

  const isLiveLesson =
    currentBlock &&
    currentBlock.name === "lesson" &&
    currentBlock.additionalData &&
    currentBlock.additionalData.type === "live";

  const isDynamicLesson =
    currentBlock &&
    currentBlock.name === "lesson" &&
    currentBlock.additionalData &&
    currentBlock.additionalData.type === "dynamic";

  const currentDate = new Date();

  const isLiveLessonDisabled =
    isLiveLesson &&
    (currentDate < new Date(currentBlock.additionalData.validFrom) ||
      (currentBlock.additionalData.validTo &&
        currentDate > new Date(currentBlock.additionalData.validTo)));

  const dynamicLessonContent = lessonContent.find(
    (x) => x.key === currentBlock?.id
  )?.value;

  return (
    <>
      {currentBlock && currentBlock.name === "multiple-choice" && (
        <Stack width={"100%"} mb={5}>
          <Heading size={"md"} as={"h3"} mb={5}>
            {currentBlock.label}
          </Heading>
          {currentBlock.choices.map((x: any) => (
            <Center width={"100%"}>
              <Button
                whiteSpace={"normal"}
                size={"md"}
                p={10}
                bg="brand.primary.500"
                color="brand.surface"
                onClick={() => handleAnswer(x.value)}
                overflowWrap={"break-word"}
                height={"fit-content"}
                width={{ base: "100%", md: "50%" }}
              >
                {x.label}
              </Button>
            </Center>
          ))}
          {selectedAnswer === currentBlock.correctAnswer &&
            currentBlock?.correctAnswerExplanation && (
              <Center width={"100%"} paddingTop={".5rem"}>
                <Stack>
                  <Card variant={"outline"}>
                    <CardBody>
                      <Text textAlign={"center"}>
                        <chakra.span fontWeight={"bold"}>
                          💡 More Info:{" "}
                        </chakra.span>
                        {`${currentBlock?.correctAnswerExplanation}`}
                      </Text>
                    </CardBody>
                  </Card>
                </Stack>
              </Center>
            )}
        </Stack>
      )}

      {isLiveLesson && (
        <Box p={currentBlock.label === "Disclaimer" ? 10 : "initial"}>
          <Center width={"100%"} py={"10%"}>
            <Button
              whiteSpace={"normal"}
              size={"md"}
              p={10}
              bg="brand.primary.500"
              color="brand.surface"
              onClick={async () => {
                await initZoomApp(
                  currentBlock.additionalData.zoomMeetingId,
                  currentBlock.additionalData.zoomToken
                );
                setValid(true);
              }}
              overflowWrap={"break-word"}
              height={"fit-content"}
              width={{ base: "100%", md: "50%" }}
              isDisabled={isLiveLessonDisabled}
              _disabled={{
                _hover: { bg: "gray.500" },
                bg: "gray.500",
                cursor: "not-allowed",
              }}
            >
              {isLiveLessonDisabled ? (
                <Stack>
                  <Text color={"brand.surface"}>
                    This lesson is scheduled between:
                  </Text>
                  <Text color={"brand.surface"}>
                    {new Date(
                      currentBlock.additionalData.validFrom
                    ).toLocaleString()}{" "}
                  </Text>
                  <Text color={"brand.surface"}>and </Text>
                  <Text color={"brand.surface"}>
                    {new Date(
                      currentBlock.additionalData.validTo
                    ).toLocaleString()}
                  </Text>
                  <Text color={"brand.surface"} mt={5}>
                    Please check back at that time. Lessons will be recorded and
                    viewable here after the scheduled time.
                  </Text>
                </Stack>
              ) : (
                "Join Live Lesson"
              )}
            </Button>
          </Center>
        </Box>
      )}

      {isDynamicLesson && dynamicLessonContent && (
        <Box>
          <Stack width={"100%"} px={"15%"}>
            <ReactMarkdown
              components={ChakraUIRenderer()}
              children={dynamicLessonContent}
              skipHtml
              // @ts-ignore
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
            />
          </Stack>
        </Box>
      )}

      {currentBlock &&
        currentBlock.name === "lesson" &&
        !isLiveLesson &&
        !dynamicLessonContent && (
          <Box p={currentBlock.label === "Disclaimer" ? 10 : "initial"}>
            {vimeoMatch && (
              <Vimeo
                video={vimeoMatch[1]}
                onEnd={() => setValid(true)}
                responsive
                onTimeUpdate={onVideoTimeUpdate}
                onSeeked={onVideoSeeked}
                start={startTime}
              />
            )}
            {mp3Match && (
              <>
                <AudioPlayer
                  autoPlay={false}
                  src={mp3Match[1]}
                  onEnded={() => setValid(true)}
                />
                <Divider my={5} />
                <Box
                  dangerouslySetInnerHTML={{ __html: sanitizedAudioLessonHtml }}
                  width={"100%"}
                />
              </>
            )}
            {!vimeoMatch && !mp3Match && (
              <Box
                dangerouslySetInnerHTML={{ __html: currentBlock.html }}
                width={"100%"}
              />
            )}
          </Box>
        )}

      {currentBlock?.additionalData?.attachments?.length > 0 && (
        <Stack width={"100%"} mt={5}>
          <Heading size={"md"} as={"h3"} mb={1}>
            Additional Attachments:
          </Heading>
          {currentBlock.additionalData.attachments.map((attachment: any) => (
            <HStack>
              <Tag
                whiteSpace={"normal"}
                size={"md"}
                color="brand.primary.500"
                onClick={() => window.open(attachment.url, "_blank")}
                cursor="pointer"
                width={"fit-content"}
              >
                {attachment.name}
                <Icon as={HiOutlineDownload} ml={2} />
              </Tag>
            </HStack>
          ))}
        </Stack>
      )}
    </>
  );
};

export const MyCourseViewerPage: React.FC = () => {
  const navigate = useNavigate();

  const [currentActivityId, setCurrentActivityId] = useState("");
  const [currentActivityName, setCurrentActivityName] = useState("");
  const [mostRecentLessonIndex, setMostRecentLessonIndex] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [currentCourseDescription, setCurrentCourseDescription] = useState("");
  const [maxIndex, setMaxIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();
  const { data } = useCourse(id as string);

  const { data: myCourses } = useMyCourses();
  const { mutate: updateCourseAttempt } = useUpdateCourseAttempt();
  useEffect(() => {
    registerBlocks();
  }, []);

  const [blocks, setBlocks] = useState<any[]>([]);

  const goToBlock = useCallback((activityId: string) => {
    setCurrentActivityId(activityId);
  }, []);

  const vimeoRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)/i;
  const mp3Regex = /<audio[^>]*src="(https?:\/\/[^"]+\.mp3)"[^>]*><\/audio>/i;

  useEffect(() => {
    console.log(blocks, currentActivityId);
    if (blocks.length > 0 && currentActivityId) {
      const indexOfCurrentBlock = blocks?.findIndex(
        (x) => x.id === currentActivityId
      );

      if (indexOfCurrentBlock < 0) return;

      if (indexOfCurrentBlock > maxIndex) {
        setMaxIndex(indexOfCurrentBlock);
      }

      if (blocks[indexOfCurrentBlock]) {
        if (blocks[indexOfCurrentBlock].name === "multiple-choice") {
          setCurrentActivityName("Knowledge Check");
        } else {
          setCurrentActivityName(blocks[indexOfCurrentBlock].label);

          const index =
            data?.lessons
              .sort((a, b) => a.order - b.order)
              .findIndex((x) => x.id === currentActivityId) ?? -1;
          if (index > -1) {
            setMostRecentLessonIndex(index);
          }
        }
      }
    }
  }, [blocks, currentActivityId]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const activities = [...data.lessons, ...data.quizzes].sort(
      (a, b) => a.order - b.order
    );
    const formData = [];
    for (const activity of activities) {
      if ("questions" in activity) {
        for (const question of activity.questions) {
          formData.push({
            id: activity.id,
            name: question.type,
            nameOverride: question.nameOverride,
            label: question.title,
            correctAnswer: question.correctAnswer,
            correctAnswerExplanation: question.correctAnswerExplanation,
            choices: question.options.map((y) => {
              return { label: y.text, value: y.value };
            }),
          });
        }
      } else {
        formData.push({
          id: activity.id,
          name: "lesson",
          label: activity.name,
          html: activity.body,
          additionalData: activity.additionalData,
        });
      }
    }

    setBlocks(formData);
  }, [data]);

  useEffect(() => {
    if (myCourses) {
      const myCourse = myCourses.find((x) => x.id === id);
      if (myCourse) {
        setCurrentActivityId(myCourse.currentActivityId);
        setCurrentCourseDescription(myCourse.description);
      }
    }
  }, [myCourses]);

  useEffect(() => {
    if (blocks.length > 0 && myCourses) {
      const myCourse = myCourses.find((x) => x.id === id);
      if (myCourse && myCourse.currentActivityId) {
        setCurrentActivityId(myCourse.currentActivityId);
      } else {
        setCurrentActivityId(blocks[0].id);
      }
    }
  }, [blocks, searchParams]);

  useEffect(() => {
    const blockIndex = blocks.findIndex((x) => x.id === currentActivityId);
    if (blockIndex < 0) return;

    const block = blocks[blockIndex];

    if (blockIndex < maxIndex) {
      setIsValid(true);
      return;
    }

    if (block && block.label === "Disclaimer") {
      setIsValid(true);
    } else if (
      block &&
      block.additionalData &&
      block.additionalData.isOptional
    ) {
      setIsValid(true);
    } else if (block && block.name !== "multiple-choice") {
      const match =
        block?.html?.match(vimeoRegex) || block?.html?.match(mp3Regex);
      if (!match) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else if (block && block?.name === "multiple-choice") {
      setIsValid(false);
    }

    setIsTouched(false);
  }, [currentActivityId]);

  const goNext = async () => {
    const blockIndex = blocks.findIndex((x) => x.id === currentActivityId);

    if (blockIndex + 1 < blocks.length) {
      const nextBlockId = blocks[blockIndex + 1].id;
      setCurrentActivityId(nextBlockId);
      await updateCourseAttempt({
        id: id ?? "",
        data: { currentActivityId: nextBlockId },
      });
    } else {
      await updateCourseAttempt({
        id: id ?? "",
        data: { currentActivityId: currentActivityId },
      });
      navigate("/my/courses?finishedCourse=true");
    }
  };

  const goPrevious = () => {
    const blockIndex = blocks.findIndex((x) => x.id === currentActivityId);
    if (blockIndex - 1 >= 0) {
      const previousBlockId = blocks[blockIndex - 1].id;
      setCurrentActivityId(previousBlockId);
    }
  };

  return (
    <Box height={"100vh"} overflowY={"scroll"}>
      <Card m={{ base: 0, lg: 5 }} p={5} variant={"outline"} mb={20}>
        <CardBody display={"flex"} flexDir={"column"} p={0} height={"100%"}>
          <Button
            leftIcon={<BsArrowLeft />}
            variant={"ghost"}
            p={0}
            mb={4}
            alignSelf={"start"}
            onClick={() => navigate("/my/courses")}
          >
            Back
          </Button>
          <Grid
            templateRows={{
              base: `auto 1fr auto auto`,
              lg: `auto auto 1fr`,
            }}
            templateColumns={{
              base: "1fr",
              lg: "4fr 1.5fr",
            }}
            gap={5}
            height={"100%"}
          >
            <GridItem minHeight={0} minWidth={0}>
              <Heading size={"lg"} as="h3">
                {data?.name}
              </Heading>
              <Stack
                justify={"space-between"}
                mt={2}
                direction={{ base: "column", lg: "row" }}
              >
                <HStack gap={0}>
                  <Text>
                    {mostRecentLessonIndex + 1} of {data?.lessons.length ?? 0}
                  </Text>
                  <Text mx={1}>-</Text>
                  <Text fontWeight={"bold"}>
                    {/* //@ts-ignore */}
                    {currentActivityName === "multiple-choice"
                      ? "Knowledge Check"
                      : currentActivityName}
                  </Text>
                </HStack>
                <Stack direction={{ base: "row", lg: "row" }}>
                  <Button
                    variant={"outline"}
                    colorScheme={"brand.primary"}
                    onClick={goPrevious}
                    width={{ base: "50%", lg: "auto" }}
                  >
                    Prev
                  </Button>
                  <Button
                    variant={"outline"}
                    colorScheme={"brand.primary"}
                    onClick={goNext}
                    isDisabled={!isValid}
                    width={{ base: "50%", lg: "auto" }}
                  >
                    Next
                  </Button>
                </Stack>
              </Stack>
            </GridItem>
            <GridItem
              minHeight={0}
              minWidth={0}
              rowSpan={1}
              colStart={1}
              rowStart={2}
            >
              {!isValid && isTouched && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>That's not quite right!</AlertTitle>
                  <AlertDescription>Please try again.</AlertDescription>
                </Alert>
              )}
              {isValid && isTouched && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>That's right!</AlertTitle>
                  <AlertDescription>Press next to continue.</AlertDescription>
                </Alert>
              )}
              <ContentViewer
                blocks={blocks}
                currentBlockId={currentActivityId}
                setValid={setIsValid}
                setTouched={setIsTouched}
              />
            </GridItem>
            <GridItem
              minHeight={0}
              minWidth={0}
              rowSpan={1}
              colStart={1}
              rowStart={3}
            >
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton
                    _expanded={{
                      color: "#204B36",
                      bg: "brand.surface",
                    }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Heading as={"h5"} size={"md"}>
                        Course Description
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <ReactMarkdown
                      components={ChakraUIRenderer()}
                      children={currentCourseDescription}
                      skipHtml
                      remarkPlugins={[remarkGfm]}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </GridItem>
            <GridItem
              minHeight={0}
              minWidth={0}
              rowStart={{ base: 4, lg: 1 }}
              colStart={{ base: 1, lg: 2 }}
              rowSpan={{ base: 1, lg: 3 }}
              maxHeight={{ base: "unset", lg: "95vh" }}
              overflowY={{ base: "hidden", md: "auto" }}
            >
              <Stack height={"100%"}>
                {currentActivityId && (
                  <HStack>
                    <Progress
                      colorScheme="brand.primary"
                      size="sm"
                      value={((maxIndex + 1) / blocks.length) * 100}
                      width={"100%"}
                    />
                    <Text fontWeight={"bold"}>
                      {Math.round(((maxIndex + 1) / blocks.length) * 100)}%
                    </Text>
                  </HStack>
                )}
                {!currentActivityId && <Skeleton noOfLines={1} />}
                <HStack>
                  {blocks && currentActivityId && (
                    <TableOfContents
                      blocks={blocks}
                      currentBlockId={currentActivityId}
                      maxIndex={maxIndex}
                      goToBlock={goToBlock}
                    />
                  )}
                  {!currentActivityId &&
                    [...Array(10)].map((_, i) => <Skeleton />)}
                </HStack>
              </Stack>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </Box>
  );
};
