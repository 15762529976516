import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  Button,
  ModalFooter,
  useDisclosure,
  IconButton,
  Text,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useOrganization } from "@clerk/clerk-react";
import { useTeams } from "../../../api/endpoints/teams";
import { useSites } from "../../../api/endpoints/sites";

import { useFormik, FormikProvider, Field, Form } from "formik";
import * as Yup from "yup";
import { createColumnHelper } from "@tanstack/react-table";
import { InspectionTemplate } from "../../../types";
import { DataTable } from "../../../components/table";
import React, { useEffect, useState } from "react";
import {
  useCreateInspectionTemplate,
  useDeleteInspectionTemplate,
  useInspectionTemplates,
} from "../../../api/endpoints/inspection-templates";
import { BsPencil } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useCreateManualInspection } from "../../../api/endpoints/manual-inspections";
import { OrganizationMembershipResource } from "@clerk/types";
import { DeleteButton } from "../../../components/delete-button";
import { PlusIcon } from "lucide-react";

interface AssignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAssign: (assignees: any[], inspectionTemplateId: string) => void;
  inspectionTemplateId: string;
}

const AssignmentModal: React.FC<AssignmentModalProps> = ({
  isOpen,
  onClose,
  onAssign,
  inspectionTemplateId,
}) => {
  const { data: teams } = useTeams();
  const { data: sites } = useSites();
  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      pageSize: 500,
    },
  });

  useEffect(() => {
    if (memberships && memberships.hasNextPage) {
      memberships.fetchNext();
    }
  }, [memberships]);

  const generateOptions = (
    label: string,
    data: any[],
    nameKey = "name",
    valueKey = "id"
  ) => ({
    label,
    options: data?.map((item) => ({
      label: item[nameKey],
      value: item[valueKey],
    })),
  });

  const memberOptions = memberships?.data?.map(
    (member: OrganizationMembershipResource) => ({
      label: `${member.publicUserData.firstName} ${member.publicUserData.lastName}`,
      value: member.id,
    })
  );

  const assignedToOptions = [
    generateOptions("Teams", teams || []),
    generateOptions("Sites", sites || []),
    { label: "Members", options: memberOptions || [] },
  ];
  const formik = useFormik({
    initialValues: { assignees: [] },
    validationSchema: Yup.object({
      assignees: Yup.array().min(1, "You must select at least one assignee"),
    }),
    onSubmit: (values) => onAssign(values.assignees, inspectionTemplateId),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <FormikProvider value={formik}>
          <Form>
            <ModalHeader>Assign Inspection</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="assignees">Assign To</FormLabel>
                  <Select
                    id="assignees"
                    name="assignees"
                    options={assignedToOptions}
                    isMulti
                    onChange={(option) =>
                      formik.setFieldValue("assignees", option)
                    }
                    closeMenuOnSelect={false}
                    placeholder="Select teams, sites, or members"
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button variant={"inverted"} onClick={onClose} type="submit">
                Assign
              </Button>
              <Button variant="ghost" mr={3}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </FormikProvider>
      </ModalContent>
    </Modal>
  );
};

export const InspectionTemplatesListTab: React.FC = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const assignmentModalDisclosure = useDisclosure();
  const [selectedInspectionTemplateId, setSelectedInspectionTemplateId] =
    useState<string | null>(null);
  const columnHelper = createColumnHelper<InspectionTemplate>();
  const navigate = useNavigate();
  const {
    mutate: createManualInspection,
    isError: createManualInspectionError,
    data: createManualInspectionData,
  } = useCreateManualInspection();

  const {
    mutate: createInspectionTemplate,
    isError: createInspectionTemplateError,
    data: createInspectionTemplateData,
  } = useCreateInspectionTemplate();

  const {
    data: inspectionTemplates,
    isError: isInspectionTemplatessError,
    isLoading: isInsepctionTemplatesLoading,
  } = useInspectionTemplates();

  const { mutate: deleteInspectionTemplate } = useDeleteInspectionTemplate();

  const schema = Yup.object().shape({
    name: Yup.string().min(3).required("Inspection Template name is required."),
    description: Yup.string()
      .min(3)
      .required("Inspection Template description is required."),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: schema,
    onSubmit: async (values: any) => {
      await createInspectionTemplate({
        name: values.name,
        description: values.description,
      });
    },
  });

  useEffect(() => {
    if (createInspectionTemplateData) {
      navigate(
        `/doc-library/inspection-templates/${createInspectionTemplateData}`
      );
    }
  }, [createInspectionTemplateData]);

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: () => <span>Name</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      cell: (info) => {
        return <Text whiteSpace={"normal"}>{info.getValue()}</Text>;
      },
      header: () => <span>Description</span>,
    }),
    columnHelper.display({
      id: "actions",
      header: () => <span>Actions</span>,
      cell: (props) => {
        return (
          <HStack>
            <DeleteButton
              deleteKey={props.row.original.name}
              name={props.row.original.name}
              aria-label="delete inspection template"
              onDelete={() =>
                deleteInspectionTemplate({ id: props.row.original.id })
              }
            />
            <IconButton
              aria-label="edit inspection template"
              icon={<BsPencil />}
              color={"brand.accent"}
              onClick={() =>
                navigate(
                  `/doc-library/inspection-templates/${props.row.original.id}`
                )
              }
              style={{ background: "transparent" }}
            />
            <IconButton
              aria-label="Assign inspection based on template"
              icon={<FiUserPlus />}
              color={"brand.accent"}
              style={{ background: "transparent" }}
              onClick={() => {
                setSelectedInspectionTemplateId(props.row.original.id); // Set the selected ID
                assignmentModalDisclosure.onOpen();
              }}
            />
          </HStack>
        );
      },
    }),
  ];

  return (
    <Box height={"100vh"}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <FormikProvider value={formik}>
            <Form>
              <ModalHeader>Create New Inspection Template</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  spacing="5"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl
                      isInvalid={
                        !!formik?.errors?.name === true &&
                        !!formik?.touched?.name === true
                      }
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name={"name"}
                        type="text"
                        placeholder="Example Name"
                        value={formik.values.name}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.name?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="street">
                      <FormLabel>Description</FormLabel>
                      <Field
                        as={Input}
                        id="description"
                        name={"description"}
                        type="text"
                        placeholder="Example Description"
                        value={formik.values.description}
                      />
                      <FormErrorMessage>
                        {formik?.errors?.description?.toString()}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={onClose}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="brand.primary"
                  isDisabled={!formik.isValid}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </Form>
          </FormikProvider>
        </ModalContent>
      </Modal>
      <AssignmentModal
        isOpen={assignmentModalDisclosure.isOpen}
        onClose={assignmentModalDisclosure.onClose}
        onAssign={(assignees: any[], inspectionTemplateId: string) => {
          console.log("Assigned:", assignees);
          createManualInspection({
            inspectionTemplateId: inspectionTemplateId,
            assignedTo: assignees.map((assignee) => ({
              id: assignee.value,
              name: assignee.label,
            })),
            assignmentDate: new Date().toISOString(),
          });
          assignmentModalDisclosure.onClose(); // Close the modal after assigning
        }}
        inspectionTemplateId={selectedInspectionTemplateId ?? ""}
      />
      <Stack p={5}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={inspectionTemplates ?? []}
              actionChildren={
                <Button
                  width={{ base: "100%", md: "auto" }}
                  leftIcon={<PlusIcon size={".875rem"} />}
                  variant="outline"
                  onClick={onToggle}
                >
                  Add
                </Button>
              }
              title="Inspection Templates"
              subtitle="Manage and create your inspection templates."
            ></DataTable>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};
