import { Flex, Stack, Box, Text, Heading, Center } from "@chakra-ui/layout";

import { Outlet } from "react-router";

export const AuthenticationLayout = (): JSX.Element => {
  return (
    <Flex width="full" height={"100vh"}>
      <Box
        flex="1"
        display={{ base: "none", md: "block" }}
        style={{
          backgroundImage: "url(/truck-2.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "rgba(0,0,0,0.35)",
          backgroundBlendMode: "darken",
        }}
        _before={{}}
      >
        <Flex
          direction="column"
          px={{ base: "4", md: "8" }}
          height="full"
          color="fg.accent.default"
        >
          <Flex align="center" h="24">
            {/* <Logo /> */}
          </Flex>
          <Flex flex="1" align="center">
            <Stack spacing="8">
              <Stack spacing="6">
                <Heading
                  variant={"4xl"}
                  color={"white"}
                  textShadow={"2px 4px 3px rgba(0,0,0,0.2)"}
                >
                  Simplify your electric vehicle operations
                </Heading>
                <Text
                  textStyle="lg"
                  maxW="md"
                  fontWeight="medium"
                  color="fg.accent.muted"
                  textShadow={"2px 4px 3px rgba(0,0,0,0.5)"}
                >
                  We help fleets easily manage the complexity of EVs from
                  planning to training to battery recyling.
                </Text>
              </Stack>
            </Stack>
          </Flex>
          <Flex align="center" h="24">
            <Text color="fg.accent.subtle" textStyle="sm">
              © 2024 Axle Repair Inc. DBA Axle Mobility. All rights reserved.
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Center flex={1}>
        <Outlet />
      </Center>
    </Flex>
  );
};
