import { Button } from "@chakra-ui/button";
import { Card, CardBody, CardHeader } from "@chakra-ui/card";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Stack, Heading, Text, Box } from "@chakra-ui/layout";
import { useSignIn, useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PasswordResetPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const { signIn, setActive } = useSignIn();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const passwordParam = searchParams.get("password");
    if (passwordParam) {
      setCurrentPassword(passwordParam);
    }
  }, []);

  async function login(e: React.FormEvent) {
    e.preventDefault();
    await signIn
      ?.create({
        strategy: "password",
        identifier: email,
        password: currentPassword,
      })
      .then((result) => {
        if (result.status === "complete") {
          setError("");
          setActive({ session: result.createdSessionId });
        } else {
          console.log(result);
        }
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }

  async function reset(e: React.FormEvent) {
    await user
      ?.updatePassword({
        newPassword,
        currentPassword,
      })
      .then((result) => {
        user
          .update({ unsafeMetadata: { password_was_reset: true } })
          .then(() => {
            setError("");
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              navigate("/");
            }, 3000); //Let Clerk propogate this change.
          })
          .catch((err) => {
            console.error("error", err.errors[0].longMessage);
            setError(err.errors[0].longMessage);
          });
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }
  return (
    <Box>
      <Card variant={"outline"} mt={8} bg={"transparent"} border={"none"}>
        <CardHeader>
          <Heading fontSize={"xl"} color={"black"}>
            Reset password
          </Heading>
          <Text>Reset password to continue to Axle Mobility</Text>
        </CardHeader>
        <CardBody>
          <Stack gap={5}>
            {!user && (
              <>
                <FormControl>
                  <FormLabel htmlFor="email" fontSize={"sm"} fontWeight={"600"}>
                    Email address
                  </FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </>
            )}
            <FormControl>
              <FormLabel htmlFor="email" fontSize={"sm"} fontWeight={"600"}>
                Current password
              </FormLabel>
              <Input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </FormControl>
            {user && (
              <FormControl>
                <FormLabel htmlFor="email" fontSize={"sm"} fontWeight={"600"}>
                  New password
                </FormLabel>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormControl>
            )}
            <Button
              variant={"inverted"}
              onClick={user ? reset : login}
              isDisabled={isLoading}
            >
              {isLoading ? "Please Wait" : "Continue"}
            </Button>
            {error && <p>{error}</p>}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
