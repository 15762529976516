import {
  Box,
  Stack,
  Card,
  CardBody,
  Heading,
  Badge,
  Tooltip,
  StackDivider,
  Button,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { CurriculumProgress } from "../../types";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/table";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { useCurriculaAdminReport } from "../../api/endpoints/courses";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
import { HiInformationCircle } from "react-icons/hi";
import { CSVLink, CSVDownload } from "react-csv";

import { BoxProps, HStack, Text } from "@chakra-ui/react";

interface Props extends BoxProps {
  label: string;
  value: string;
  delta: {
    value: string;
    isUpwardsTrend: boolean;
  };
}

ChartJS.register(ArcElement, ChartTooltip, Legend);
dayjs.extend(relativeTime);

type CurriculaProgressReport = {
  memberName: string;
  emailAddress: string;
  curriculumName: string;
  status?: string;
  completedAt?: string;
  validUntil?: string;
  isQualified?: boolean;
};

export const CourseReportingTab: React.FC = () => {
  const { data: adminReport, isError, isLoading } = useCurriculaAdminReport();
  const [trainingPlanCompletionData, setTrainingPlanCompletionData] = useState<{
    complete: number;
    incomplete: number;
  }>();
  const [
    trainingPlanVsQualificationStatusPercentage,
    setTrainingPlanVsQualificationStatusPercentage,
  ] = useState<number>(0);

  const [tableData, setTableData] = useState<CurriculaProgressReport[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    if (!adminReport) return;
    const flattenedReports = [] as CurriculaProgressReport[];

    for (const report of adminReport) {
      for (const progress of report.progresses) {
        flattenedReports.push({
          memberName: report.member?.name || "",
          emailAddress: report.emailAddress || "",
          curriculumName: progress.curriculum.name,
          status: progress.status,
          completedAt: progress.completedAt,
          validUntil: progress.validUntil,
          isQualified: progress.isQualified,
        });
      }
    }

    const csvData = flattenedReports.map((report) => [
      report.memberName,
      report.emailAddress,
      report.curriculumName,
      report.status,
      report.completedAt,
      report.validUntil,
      report.isQualified,
    ]);

    setCsvData(csvData);
    setTableData(flattenedReports);
  }, [adminReport]);

  useEffect(() => {
    if (!adminReport) return;

    const processedData = {
      complete: 0,
      incomplete: 0,
    };

    for (const member of adminReport) {
      for (const progress of member.progresses) {
        if (progress.status === "Completed") {
          processedData.complete++;
        } else {
          processedData.incomplete++;
        }
      }
    }

    setTrainingPlanCompletionData(processedData);
  }, [adminReport]);

  useEffect(() => {
    if (!adminReport) return;

    const processedData = {
      completions: 0,
      qualifications: 0,
    };

    for (const member of adminReport) {
      for (const progress of member.progresses) {
        if (progress.status === "Completed" && progress.isQualified !== null) {
          processedData.completions++;
          if (progress.isQualified === true) {
            processedData.qualifications++;
          }
        }
      }
    }
    if (processedData.completions === 0) {
      setTrainingPlanVsQualificationStatusPercentage(0);
    } else {
      setTrainingPlanVsQualificationStatusPercentage(
        (processedData.qualifications / processedData.completions) * 100
      );
    }
  }, [adminReport]);

  const columns = React.useMemo<ColumnDef<CurriculaProgressReport>[]>(
    () => [
      {
        accessorKey: "memberName",
        header: () => <Box>Assignee</Box>,
        cell: (info: any) => <Text>{info.getValue()}</Text>,
      },
      {
        accessorKey: "curriculumName",
        header: () => <Box>Training Plan</Box>,
      },
      {
        accessorKey: "status",
        header: () => <Box>Progress</Box>,
        cell: (info) => {
          switch (info.getValue()) {
            case "Completed":
              return <Badge variant={"green"}>Completed</Badge>;
            case "Expired":
              return <Badge variant={"red"}>Expired</Badge>;
            case "InProgress":
              return <Badge variant={"blue"}>In Progress</Badge>;
            case "NotStarted":
              return <Badge variant={"gray"}>Not Started</Badge>;
          }
        },
      },
      {
        accessorKey: "isQualified",
        header: () => (
          <Tooltip
            label={
              "Some training plans require certification by an external organization. Members will need to upload proof of certification by that external organization to be considered qualified."
            }
          >
            <HStack>
              <Text>Qualification</Text>
              <HiInformationCircle />
            </HStack>
          </Tooltip>
        ),
        cell: (info) => {
          const value = info.getValue();
          if (value == undefined) {
            return <Text color={"gray.500"}>N/A</Text>;
          } else if (value == true) {
            return <Text>Yes</Text>;
          } else {
            return (
              <Text color={"red.500"} fontWeight={"bold"}>
                No
              </Text>
            );
          }
        },
      },
      {
        accessorKey: "completedAt",
        header: () => <Box>Completed At</Box>,
        cell: (info: any) => {
          const value = info.getValue();
          if (value) {
            return new Date(value).toISOString().split("T")[0];
          }
          return "N/A";
        },
      },
      {
        accessorKey: "validUntil",
        header: () => <Box>Valid Until</Box>,
        cell: (info: any) => {
          const value = info.getValue();
          if (value) {
            return new Date(value).toISOString().split("T")[0];
          }
          return "N/A";
        },
      },
    ],
    []
  );

  return (
    <Box height={"100vh"} overflow={"auto"} pt={5}>
      <Card variant={"outline"} mx={5}>
        <CardBody>
          <Heading size={"md"} as={"h3"}>
            Dashboard
          </Heading>

          <HStack width={"100%"} my={5}>
            <Card variant={"outline"} width={"50%"} height={"100%"}>
              <CardBody>
                <HStack
                  spacing={5}
                  divider={
                    <StackDivider color={"gray.500"} borderWidth={"1px"} />
                  }
                >
                  <Stack width={"40%"}>
                    <Heading as={"h4"} variant={"md"}>
                      Tasks Not Completed
                    </Heading>
                    <HStack spacing={0} mt={5}>
                      <Heading variant={"4xl"} color={"green.500"}>
                        {trainingPlanCompletionData?.incomplete}
                      </Heading>
                      <Heading
                        color={"gray.500"}
                        variant={"4xl"}
                        fontWeight={"300"}
                      >
                        /
                      </Heading>
                      <Heading
                        color={"gray.500"}
                        fontWeight={"300"}
                        variant={"4xl"}
                      >
                        {(trainingPlanCompletionData?.complete ?? 0) +
                          (trainingPlanCompletionData?.incomplete ?? 0)}
                      </Heading>
                    </HStack>
                  </Stack>
                  <Stack>
                    <Heading as={"h4"} variant={"md"}>
                      Task Completion Rate
                    </Heading>
                    <HStack spacing={0} mt={5}>
                      <Heading variant={"4xl"} color={"green.500"}>
                        {Math.round(
                          ((trainingPlanCompletionData?.complete ?? 0) /
                            ((trainingPlanCompletionData?.complete ?? 0) +
                              (trainingPlanCompletionData?.incomplete ?? 0))) *
                            100
                        )}
                        %
                      </Heading>
                    </HStack>
                  </Stack>
                </HStack>
              </CardBody>
            </Card>
            <Card variant={"outline"} width={"50%"} height={"100%"}>
              <CardBody>
                <Stack>
                  <Tooltip
                    label={
                      "The rate of members who have completed a training plan vs how many members have achieved a certification. Higher percentages mean members are successfully achieving their certification goals after completing the assigned training plans."
                    }
                  >
                    <Heading as={"h4"} variant={"md"}>
                      Qualification Completion Rate
                    </Heading>
                  </Tooltip>
                  <HStack mt={5} spacing={0}>
                    <Heading variant={"4xl"} color={"green.500"}>
                      {trainingPlanVsQualificationStatusPercentage}%
                    </Heading>
                  </HStack>
                </Stack>
              </CardBody>
            </Card>
          </HStack>
        </CardBody>
      </Card>
      <Box m={2} p={2}>
        <Card variant={"outline"}>
          <CardBody>
            <DataTable
              columns={columns}
              data={tableData}
              actionChildren={
                <CSVLink
                  data={csvData}
                  filename={`axle-training-plan-completion-report-${new Date().toISOString()}.csv`}
                  headers={[
                    "Assignee",
                    "Email Address",
                    "Training Plan",
                    "Progress",
                    "Completed At",
                    "Valid Until",
                    "Qualification",
                  ]}
                >
                  <Button variant={"outline"}>Download CSV</Button>
                </CSVLink>
              }
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};
