import { useCallback, useEffect, useRef, useState } from "react";

//https://community.hubspot.com/t5/CMS-Development/Embed-Chat-Into-A-ReactJS-SPA/m-p/354887/highlight/true#M17544
export const useHubspotChat = (portalId: string) => {
    const [ hasLoaded, setHasLoaded ] = useState(false);
    const [ activeConversation, setActiveConversation ] = useState(false);
    const eventRef = useRef(null);
    const closeRef = useRef(null);

    useEffect(() => {        
        // Add event listener.
        //@ts-ignore
        window.hsConversationsOnReady = [() => {
            setHasLoaded(true);
        }];

        //@ts-ignore
        window.hsConversationsSettings = {
            loadImmediately: false,
        };
                
        // Create script component.
        let script = document.createElement('script');
        script.src=`//js.hs-scripts.com/${portalId}.js`;
        script.async = true;
        
        document.body.appendChild(script);
        
        return () => {
        document.body.removeChild(script);
        //@ts-ignore
        window.hsConversationsOnReady = [];
        }
        
    }, []);

    // Subscripe to conversation events.
    useEffect(() => {
        //@ts-ignore
        eventRef.current = (payload: any) => {
            setActiveConversation(payload.conversation.conversationId);
        }


        //@ts-ignore
        closeRef.current = (payload: any) => {
            console.log(payload);
            //@ts-ignore
            window.HubSpotConversations?.widget.remove();
        };
        
        if (hasLoaded) {
            //@ts-ignore
            window.HubSpotConversations?.on('conversationStarted', eventRef.current);
            //@ts-ignore
            window.HubSpotConversations?.on('widgetClosed', closeRef.current);

           
        }
        
        return () => {
            //@ts-ignore
            window.HubSpotConversations?.off('conversationStarted', eventRef.current);

            //@ts-ignore
            window.HubSpotConversations?.off('widgetClosed', closeRef.current);
        }
        
    }, [hasLoaded]);

    const openHandler = useCallback(() => {
        if (hasLoaded) {
            //@ts-ignore
            window.HubSpotConversations?.widget.load();
            //@ts-ignore
            window.HubSpotConversations?.widget.open();
        }
    }, [hasLoaded]);

    const closeHandler = useCallback(() => {
        if (hasLoaded) {
            //@ts-ignore
            window.HubSpotConversations?.widget.remove();
        }
    }, [hasLoaded])

    return {
        hasLoaded, 
        activeConversation,
        openHandler,
        closeHandler
    };
}
