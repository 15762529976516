import { Box, Button, Card, CardBody, Center, Divider, Heading, HStack, Icon, IconButton, ListItem, OrderedList, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { CSVImporter } from "csv-import-react";
import { ArrowLeftIcon, TruckIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { BiError } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useCompareVehicles, useCreateVehicles, useDeleteVehicles } from "../../api/endpoints/vehicles";
import { DeleteButton } from "../../components/delete-button";
import { PageHeader } from "../../components/page-header";
import { Vehicle } from "../../types";

export const VehicleCsvImporter: React.FC = () => {
    const { mutate: importVehicles, isError: importVehiclesIsError, isSuccess: importVehiclesIsSuccess, isPending: importVehiclesIsPending } = useCreateVehicles();
    const { mutate: deleteVehicles, isError: deleteVehiclesIsError, isSuccess: deleteVehiclesIsSuccess, isPending: deleteVehiclesIsPending } = useDeleteVehicles();
    const { mutate: compareVehiclesToVins, data: vehicleDiff, isError: compareVehiclesIsError, isSuccess: compareVehiclesIsSuccess, isPending: compareVehiclesIsPending } = useCompareVehicles();

    const [vehiclesToDelete, setVehiclesToDelete] = useState<Vehicle[]>([]);
    const [vinsToImport, setVinsToImport] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (compareVehiclesIsPending) {
            setStep('loading');
        }
    }, [compareVehiclesIsPending]);

    useEffect(() => {
        if (compareVehiclesIsError) {
            createVehicleImportReq(); //DANGEROUS - implicitly continue on to import step
        }
    }, [compareVehiclesIsError]);

    useEffect(() => {
        const vehiclesExistToDelete = compareVehiclesIsSuccess && vehicleDiff && vehicleDiff.length > 0;
        if (vehiclesExistToDelete) {
            setVehiclesToDelete(vehicleDiff);
            setStep('vehicle-delete');
        }
        const noVehiclesToDelete = compareVehiclesIsSuccess && (!vehicleDiff || vehicleDiff.length === 0);
        if (noVehiclesToDelete) {
            createVehicleImportReq(); //DANGEROUS - implicitly continue on to import step
        }

    }, [compareVehiclesIsSuccess, vehicleDiff]);

    useEffect(() => {
        if (deleteVehiclesIsPending) {
            setStep('loading');
        }
    }, [deleteVehiclesIsPending]);

    useEffect(() => {
        if (deleteVehiclesIsError) {
            setStep("vehicle-delete");
        }
    }, [deleteVehiclesIsError]);

    useEffect(() => {
        if (deleteVehiclesIsSuccess) {
            createVehicleImportReq();
        }
    }, [deleteVehiclesIsSuccess]);

    useEffect(() => {
        if (importVehiclesIsPending) {
            setStep('loading');
        }
    }, [importVehiclesIsPending]);

    useEffect(() => {
        if (importVehiclesIsError) {
            setStep('importError');
        }
    }, [importVehiclesIsError]);

    useEffect(() => {
        if (importVehiclesIsSuccess) {
            setStep('success');
        }
    }, [importVehiclesIsSuccess]);

    const createVehicleImportReq = async () => {
        console.log('wtf are the vins: ', vinsToImport);

        await importVehicles({ vins: vinsToImport })
    };

    const createCompareVehiclesToVinsReq = async (vins: string[]) => await compareVehiclesToVins({ vins });

    const csvImporter = useMemo(() => (
        <Box>
            <CSVImporter
                isModal={false}
                onComplete={(data: { rows: { values: { vin: any } }[] }) => {
                    setStep("loading");
                    const vins = data.rows.map(({ values }) => String(values?.vin));
                    setVinsToImport(vins);
                    createCompareVehiclesToVinsReq(vins);
                }}
                template={{
                    columns:
                        [
                            {
                                "name": "VIN",
                                "key": "vin",
                                "required": true,
                                "description": "The unique Vehicle Identification Number assigned to this vehicle.",
                                "suggested_mappings": ["VIN", "Vehicle Identification Number"]
                            },
                        ],
                }}
                primaryColor="#204B36"
            />
        </Box>
    ), []);

    const loader = (
        <Center height={"75%"}>
            <Spinner
                thickness='4px'
                speed='1.5s'
                emptyColor='gray.200'
                color='brand.primary.500'
                height={24}
                width={24}
            />
        </Center>
    );

    const vehicleDelete = (
        <Stack p={8} gap={8}>
            <VStack>
                <Heading as='h1' size="4xl" textAlign={"left"}>Would you like to remove these vehicles?</Heading>
                <Text color={"brand.accent"} textAlign={"center"}>
                    We've detected {vehiclesToDelete.length} saved vehicles that are not present in the uploaded file. Would you like to delete these vehicles now?
                </Text>
                <HStack gap={10} pt={6}>
                    <HStack gap={2}>
                        <Text color={"brand.accent"}>
                            Yes, delete vehicles:
                        </Text>
                        <DeleteButton
                            aria-label="delete vehicles"
                            border={"solid"}
                            padding={3}
                            deleteKey={"vehicles"}
                            deleteButtonText={"Delete"}
                            leftIcon={undefined}
                            modalHeader={`Delete vehicles`}
                            modalText={`Are you sure you want to delete ${vehiclesToDelete.length} vehicles? This action cannot be undone.`}
                            name={"delete-vehicles"}
                            color={"red.500"}
                            onDelete={async () => await deleteVehicles({ ids: vehiclesToDelete.map(v => v.id) })}
                        />
                    </HStack>
                    <HStack gap={2}>
                        <Text color={"brand.accent"} textAlign={"center"}>
                            No, keep vehicles:
                        </Text>
                        <Button
                            variant="outline"
                            colorScheme="brand.primary"
                            onClick={() => createVehicleImportReq()}
                        >
                            Continue
                        </Button>
                    </HStack>
                </HStack>
            </VStack>
            <OrderedList>
                {vehiclesToDelete.map((vehicle, index) => (
                    <>
                        <ListItem>
                            <HStack
                                key={index}
                                p={3}
                                alignItems={"start"}
                                borderRadius={"lg"}
                            >
                                <Stack
                                    background={"brand.tintGray.100"}
                                    p={2}
                                    borderRadius={5}
                                    height={"fit-content"}
                                >
                                    <Icon as={TruckIcon} color={"brand.accent"} />
                                </Stack>
                                <VStack alignItems={"flex-start"} alignSelf={"center"} ml={2}>
                                    <Text
                                        variant={"sm"}
                                        fontWeight={"400"}
                                        color={"brand.text"}
                                    >
                                        {`${vehicle.vin} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                                    </Text>
                                </VStack>
                            </HStack>
                        </ListItem>
                        <Divider />
                    </>
                ))}
            </OrderedList>
        </Stack>
    );

    const success = (
        <Center height={"75%"}>
            <VStack px={4}>
                <HStack>
                    <Heading as='h1' size="4xl" textAlign={"left"}>Success!</Heading>
                    <Icon as={HiCheckCircle} color="brand.primary.500" boxSize={6} />
                </HStack>
                <VStack gap={4} width={"55%"}>
                    <Text color={"brand.accent"} textAlign={"center"}>
                        Your vehicles are now importing. Please check back in a few minutes to see updated information on the vehicles page.
                    </Text>
                </VStack>
            </VStack >
        </Center>
    );

    const importError = (
        <Center height={"75%"}>
            <VStack px={4} gap={4}>
                <HStack>
                    <Heading as='h1' size="4xl">Something went wrong</Heading>
                    <Icon as={BiError} color="red.500" boxSize={6} />
                </HStack>
                <Button onClick={() => setStep('import')} variant={"solid"} colorScheme="brand.primary">
                    Try again
                </Button>
            </VStack >
        </Center>
    );

    const stepper = {
        'import': csvImporter,
        'vehicle-delete': vehicleDelete,
        'loading': loader,
        'success': success,
        'importError': importError,
    }

    const [step, setStep] = useState<keyof typeof stepper>('import');

    return (
        <Box height={"110vh"} overflow={"scroll"}>
            <PageHeader title="CSV Vehicle Import"></PageHeader>
            <Card variant={"outline"} m={5} height={"100%"}>
                <CardBody overflow={"scroll"}>
                    <Box height={"100%"}>
                        <HStack>
                            <IconButton
                                icon={<ArrowLeftIcon />}
                                onClick={() => {
                                    navigate("/vehicles");
                                }}
                                aria-label={"Return to Vehicles"}
                            />
                            <Heading size={"sm"}>Return to vehicles list</Heading>
                        </HStack>
                        {stepper[step]}
                    </Box>
                </CardBody>
            </Card>
        </Box>
    );
}