import { useState, useMemo } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { Vehicle } from "../../types";
import { DataTable } from "../../components/table";
import { ColumnDef } from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import { useUpdateVehicle } from "../../api/endpoints/vehicles";
import { EmissionsRecord } from "../../types-new";

export const VehicleEmissionsTab = ({ vehicle }: { vehicle?: Vehicle }) => {
  const { mutate: updateVehicle } = useUpdateVehicle();
  const [emissionResults, setEmissionResults] = useState<EmissionsRecord[]>(
    vehicle?.complianceData.emissionsRecords || []
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newResult, setNewResult] = useState<Partial<EmissionsRecord>>({});

  const columns: ColumnDef<EmissionsRecord>[] = useMemo(
    () => [
      {
        header: "Test Date",
        accessorKey: "recordDate",
        cell: ({ getValue }) => {
          const date = new Date(getValue<string>());
          return isNaN(date.getTime())
            ? "Invalid Date"
            : date?.toLocaleDateString();
        },
      },
      {
        header: "CO Emissions",
        accessorKey: "coEmissions",
        cell: ({ row }) =>
          row.original.coEmissions !== undefined &&
          row.original.coEmissions !== null
            ? `${row.original.coEmissions} ${row.original.coMeasurementUnit}`
            : "N/A",
      },
      {
        header: "NOx Emissions",
        accessorKey: "noxEmissions",
        cell: ({ row }) =>
          row.original.nOxEmissions !== undefined &&
          row.original.nOxEmissions !== null
            ? `${row.original.nOxEmissions} ${row.original.nOxMeasurementUnit}`
            : "N/A",
      },
      {
        header: "PM Emissions",
        accessorKey: "pmEmissions",
        cell: ({ row }) =>
          row.original.pmEmissions !== undefined &&
          row.original.pmEmissions !== null
            ? `${row.original.pmEmissions} ${row.original.pmMeasurementUnit}`
            : "N/A",
      },
      {
        header: "Notes",
        accessorKey: "notes",
      },
    ],
    []
  );

  const handleAddResult = async () => {
    if (
      newResult.recordDate &&
      newResult.coEmissions &&
      newResult.nOxEmissions &&
      newResult.pmEmissions
    ) {
      const newEmissionResult: EmissionsRecord = {
        id: Date.now().toString(), // Generate a simple ID
        recordDate: new Date(newResult.recordDate).toISOString(),
        coEmissions: newResult.coEmissions || 0,
        nOxEmissions: newResult.nOxEmissions || 0,
        pmEmissions: newResult.pmEmissions || 0,
        coMeasurementUnit: "g/bhp-hr",
        nOxMeasurementUnit: "g/bhp-hr",
        pmMeasurementUnit: "g/bhp-hr",
        notes: newResult.notes || "",
        passed: false,
      };
      const updatedEmissionResults = [...emissionResults, newEmissionResult];
      setEmissionResults(updatedEmissionResults);

      if (vehicle) {
        const updatedVehicle = {
          ...vehicle,
          complianceData: {
            ...vehicle.complianceData,
            emissionsRecords: updatedEmissionResults,
          },
        };
        await updateVehicle({ id: vehicle.id, body: updatedVehicle });
      }

      setNewResult({});
      onClose();
    }
  };

  return (
    <Box>
      <DataTable
        data={emissionResults}
        columns={columns}
        suppressPaginationFooter={true}
        actionChildren={
          <Button variant={"inverted"} onClick={onOpen}>
            Add New Emission Test Result
          </Button>
        }
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Emission Test Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Test Date</FormLabel>
                <DatePicker
                  selected={
                    newResult.recordDate ? new Date(newResult.recordDate) : null
                  }
                  onChange={(date) =>
                    setNewResult({
                      ...newResult,
                      recordDate: date ? date.toISOString() : undefined,
                    })
                  }
                  dateFormat="MM/dd/yyyy"
                  customInput={<Input />}
                />
              </FormControl>
              <FormControl>
                <FormLabel>CO Emissions (g/bhp-hr)</FormLabel>
                <Input
                  value={newResult.coEmissions || ""}
                  type="number"
                  onChange={(e) =>
                    setNewResult({
                      ...newResult,
                      coEmissions: parseFloat(e.target.value),
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>NOx Emissions (g/bhp-hr)</FormLabel>
                <Input
                  value={newResult.nOxEmissions || ""}
                  type="number"
                  onChange={(e) =>
                    setNewResult({
                      ...newResult,
                      nOxEmissions: parseFloat(e.target.value),
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>PM Emissions (g/bhp-hr)</FormLabel>
                <Input
                  value={newResult.pmEmissions || ""}
                  type="number"
                  onChange={(e) =>
                    setNewResult({
                      ...newResult,
                      pmEmissions: parseFloat(e.target.value),
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Input
                  value={newResult.notes || ""}
                  onChange={(e) =>
                    setNewResult({ ...newResult, notes: e.target.value })
                  }
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={"inverted"} mr={3} onClick={handleAddResult}>
              Add Result
            </Button>
            <Button variant={"outline"} color="red" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
